import styled from 'styled-components';

export const TableWrapper = styled.div`
  margin: 0 14%;
  .view {
    overflow-x: hidden !important;
    padding-right: 12px;
  }
  .renderTrackHorizontal,
  .renderThumbHorizontal {
    display: none;
  }
  .MuiTableRow-root:last-child td,
  .MuiTableRow-root:last-child th {
    border: 0px solid !important;
  }
  .MuiPaper-root {
    box-shadow: none;
  }
  .MuiTableHead-root {
    background-color: rgba(235, 234, 244, 0.4);
  }
  .MuiTableCell-head {
    color: #bab8c7;
    font-weight: 400;
    border-bottom: none !important;
    font-family: ${({ lang }) =>
      lang === 'ka'
        ? 'Helvetica Neue LT GEO !important'
        : `'Ubuntu', sans-serif`};
  }
  .MuiTableCell-root {
    padding: 19px;
    border-bottom: 1px solid #f0eff7;
    font-family: ${({ lang }) =>
      lang === 'ka'
        ? 'Helvetica Neue LT GEO !important'
        : `'Ubuntu', sans-serif`};
  }
  .MuiTableCell-body {
    color: #3a3853;
    font-weight: 500;
  }
  table thead tr th:first-child {
    border-radius: 6px 0px 0px 6px;
  }
  table thead tr th:last-child {
    border-radius: 0px 6px 6px 0px;
  }
  @media (max-width: 1685px) {
    margin: 0 8%;
  }
  @media (max-width: 1540px) {
    .prices {
      min-width: 90px;
    }
  }
`;

export const BillingTableWrapper = styled.div`
  margin-bottom: 100px;
  &.emptyBilling {
    height: inherit;
  }
`;
export const ServicesPageWrapper = styled.div`
  margin-bottom: ${({ isOfferPage }) => (isOfferPage ? 0 : '130px')};
`;

export const TransactionsTableWrapper = styled.div`
  margin: 0 13%;
  margin-bottom: 80px;
  .MuiPaper-root {
    min-height: auto !important;
  }
  .view {
    overflow-x: hidden !important;
    padding-right: 12px;
  }
  .renderTrackHorizontal,
  .renderThumbHorizontal {
    display: none;
  }
  @media (max-width: 1540px) {
    margin: 0 7%;
  }
  @media (max-width: 1290px) {
    margin: 0 6%;
  }
  @media (max-width: 900px) {
    margin: 0 22px;
  }
  @media (max-width: 1540px) {
    .prices {
      min-width: 90px;
    }
  }
`;

export const MobileTransactionsHistory = styled.div`
  display: flex;
  flex-direction: column;
`;
export const MobileTransactionsHistoryItem = styled.div``;
export const ItemIdAndDate = styled.div`
  .id {
    margin-bottom: 4px;
    font-weight: bold;
  }
  .date {
    color: #8a87a5;
    font-size: 12px;
  }
`;
export const ItemHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #3a3853;
  border-radius: 4px;
  background: #f7f7fb;
  padding: 10px 13px;
`;
export const ItemPrice = styled.div`
  font-size: 14px;
  color: #3a3853;
  font-weight: bold;
`;
export const TransactionsItemBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  border-bottom: 1px solid #f0eff7;
  margin: 0 13px;
`;
export const ItemTitle = styled.div`
  font-size: 14px;
  color: #3a3853;
  margin-bottom: 12px;
  font-weight: 500;
  .id {
    color: #8a87a5;
    padding-right: 3px;
    font-weight: normal;
  }
  .name {
    font-weight: bold;
    margin-bottom: 4px;
  }
  .date {
    font-size: 12px;
    color: #8a87a5;
  }
`;
export const ItemRows = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ItemLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  .timePeriod {
    color: #bab8c7;
    font-size: 14px;
    text-align: left;
    margin-bottom: 6px;
  }
  .price {
    color: #bab8c7;
    font-size: 14px;
    text-align: left;
    margin-bottom: 6px;
  }
`;
export const ItemRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  .periodValue {
    color: #bab8c7;
    font-size: 14px;
    text-align: right;
    margin-bottom: 6px;
  }
  .priceValue {
    color: #bab8c7;
    font-size: 14px;
    text-align: right;
  }
`;
export const ItemDeleteOrPayNow = styled.div`
  .payNow {
    text-decoration: underline;
    color: #3a3853;
  }
`;
