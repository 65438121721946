import { CloseIcon } from 'assets/svgIcons/CloseIcon';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  ModalBackgroundStyled,
  ModalBody,
  ModalTitle,
  ModalTopPanel,
} from './Modal.styled.js';

const Modal = ({
  isOpen,
  title,
  closeIcon,
  width,
  height,
  size,
  closeModal,
  children,
  overflow,
  bodyStyles,
  spacing,
  modalTopClassName,
  className,
  overflowBody,
  canCloseOnOutsideClick = true,
  ...rest
}) => {
  const [fadeType, setFadeType] = useState('');

  const handleClose = (e) => {
    if (e) e.preventDefault();
    setFadeType('out');
    setTimeout(() => typeof closeModal === 'function' && closeModal(e), 100);
  };

  useEffect(() => {
    setTimeout(() => {
      if (isOpen) setFadeType('in');
    }, 0);
  }, [isOpen]);

  if (isOpen) {
    return ReactDOM.createPortal(
      <ModalBackgroundStyled
        isOpen={isOpen}
        fadeType={fadeType}
        style={{
          overflow: overflowBody ? 'hidden' : undefined,
        }}
        onClick={canCloseOnOutsideClick && handleClose}
      >
        <ModalBody
          width={width}
          className={className}
          height={height}
          size={size}
          overflow={overflow}
          fadeType={fadeType}
          style={bodyStyles}
          spacing={spacing}
          onClick={(e) => e.stopPropagation()}
          {...rest}
        >
          <ModalTopPanel
            hidden={!title && !closeIcon}
            spacing={spacing}
            className={modalTopClassName}
          >
            {title && <ModalTitle>{title}</ModalTitle>}
            {closeIcon && (
              <div className="close_icon">
                <CloseIcon
                  width="19"
                  height="19"
                  stroke="#6b7689"
                  viewBox="0 0 24 24"
                  strokeWidth="3"
                  style={{
                    marginLeft: 'auto',
                    cursor: 'pointer',
                    position: 'relative',
                    zIndex: '4',
                  }}
                  onClick={handleClose}
                />
              </div>
            )}
          </ModalTopPanel>
          {children}
        </ModalBody>
      </ModalBackgroundStyled>,
      document.body,
    );
  }

  return null;
};

export default Modal;
