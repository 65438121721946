import React, { useState } from 'react';
import {
  AccordionContent,
  AccordionIcon,
  FaqAccordionsTrigger,
  FAqAccordionTitle,
} from 'ui/styled/FaqStyled';
import Accordion from 'library/Accordion/Accordion';
import { htmlParser } from 'utils/htmlUtils';
import { AddIconBlog } from 'assets/svgIcons/AddIconBlog';
import { HideIconBlog } from 'assets/svgIcons/HideIconBlog';
import {
  BlogsFaqParagraphTitle,
  FAQBlogsNavWrapper,
} from 'ui/styled/BlogPageStyled';

const BlogPageAccordion = (props) => {
  const { BlogFaqAccordionData, BlogFaqDataTitle } = props;
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  return (
    <>
      <FAQBlogsNavWrapper>
        <BlogsFaqParagraphTitle className="Blogsheadline1WithCaps">
          {BlogFaqDataTitle?.title}
        </BlogsFaqParagraphTitle>
        {BlogFaqAccordionData?.map((item, index) => {
          return (
            <Accordion
              key={index + item.title}
              trigger={
                <FaqAccordionsTrigger onClick={() => toggle(index)}>
                  <AccordionIcon BlogPage>
                    <span className="icon">
                      {clicked === index ? (
                        <HideIconBlog style={{ marginRight: '24px' }} />
                      ) : (
                        <AddIconBlog style={{ marginRight: '24px' }} />
                      )}
                    </span>
                  </AccordionIcon>
                  <AccordionContent>
                    <div className="Collapsible" onClick={() => toggle(index)}>
                      <FAqAccordionTitle
                        className="BlogsHeadlineWithoutCaps"
                        BlogPage
                      >
                        {item.title}
                      </FAqAccordionTitle>
                    </div>
                    <div
                      style={{
                        lineHeight: '24px',
                      }}
                      className={
                        clicked === index
                          ? 'acoordionContent contentShow Blogsbody'
                          : 'acoordionContent content Blogsbody'
                      }
                    >
                      {htmlParser(item.description)}
                    </div>
                  </AccordionContent>
                </FaqAccordionsTrigger>
              }
            />
          );
        })}
      </FAQBlogsNavWrapper>
    </>
  );
};

export default BlogPageAccordion;
