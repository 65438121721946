import { get, put, post } from 'services/axios';
import { getFileAndName } from 'utils/helpers';

const listUrlGenerator = (params) => {
  if (params.idNumber && params.comments) {
    return `admin/purchase/list?paymentId=${params.paymentId}&idNumber=${params.idNumber}&period=${params.period}&onPremise=${params.onPremise}&offset=${params.offset}&limit=${params.limit}&fromDate=${params.fromDate}&toDate=${params.toDate}&status=${params.status}&productType=${params.productType}&query=${params.query}&isOverdue=${params.isOverdue}&isFirstPurchase=${params.isFirstPurchase}&comments=${params.comments}`;
  } else if (params.idNumber) {
    return `admin/purchase/list?paymentId=${params.paymentId}&idNumber=${
      params.idNumber
    }&period=${params.period}&onPremise=${params.onPremise}&offset=${
      params.offset
    }&limit=${params.limit}&fromDate=${params.fromDate}&toDate=${
      params.toDate
    }&status=${params.status}&productType=${
      params.productType
    }&query=${encodeURIComponent(params.query)}&isOverdue=${
      params.isOverdue
    }&isFirstPurchase=${params.isFirstPurchase}`;
  } else if (params.comments) {
    return `admin/purchase/list?paymentId=${params.paymentId}&onPremise=${
      params.onPremise
    }&period=${params.period}&offset=${params.offset}&limit=${
      params.limit
    }&fromDate=${params.fromDate}&toDate=${params.toDate}&status=${
      params.status
    }&productType=${params.productType}&query=${encodeURIComponent(
      params.query,
    )}&isOverdue=${params.isOverdue}&isFirstPurchase=${
      params.isFirstPurchase
    }&comments=${params.comments}`;
  } else {
    return `admin/purchase/list?paymentId=${params.paymentId}&onPremise=${
      params.onPremise
    }&period=${params.period}&offset=${params.offset}&limit=${
      params.limit
    }&fromDate=${params.fromDate}&toDate=${params.toDate}&status=${
      params.status
    }&productType=${params.productType}&query=${encodeURIComponent(
      params.query,
    )}&isOverdue=${params.isOverdue}&isFirstPurchase=${params.isFirstPurchase}`;
  }
};
const listUrlGeneratorExport = (params) => {
  if (params.idNumber && params.comments) {
    return `admin/purchase/file?paymentId=${params.paymentId}&idNumber=${params.idNumber}&period=${params.period}&onPremise=${params.onPremise}&fromDate=${params.fromDate}&toDate=${params.toDate}&status=${params.status}&productType=${params.productType}&query=${params.query}&isOverdue=${params.isOverdue}&isFirstPurchase=${params.isFirstPurchase}&comments=${params.comments}`;
  } else if (params.idNumber) {
    return `admin/purchase/file?paymentId=${params.paymentId}&idNumber=${
      params.idNumber
    }&period=${params.period}&onPremise=${params.onPremise}&fromDate=${
      params.fromDate
    }&toDate=${params.toDate}&status=${params.status}&productType=${
      params.productType
    }&query=${encodeURIComponent(params.query)}&isOverdue=${
      params.isOverdue
    }&isFirstPurchase=${params.isFirstPurchase}`;
  } else if (params.comments) {
    return `admin/purchase/file?paymentId=${params.paymentId}&onPremise=${
      params.onPremise
    }&period=${params.period}&fromDate=${params.fromDate}&toDate=${
      params.toDate
    }&status=${params.status}&productType=${
      params.productType
    }&query=${encodeURIComponent(params.query)}&isOverdue=${
      params.isOverdue
    }&isFirstPurchase=${params.isFirstPurchase}&comments=${params.comments}`;
  } else {
    return `admin/purchase/file?paymentId=${params.paymentId}&onPremise=${
      params.onPremise
    }&period=${params.period}&fromDate=${params.fromDate}&toDate=${
      params.toDate
    }&status=${params.status}&productType=${
      params.productType
    }&query=${encodeURIComponent(params.query)}&isOverdue=${
      params.isOverdue
    }&isFirstPurchase=${params.isFirstPurchase}`;
  }
};

export default {
  getUsersList: async (params) => {
    const defaultValues = {
      status: params.status || '',
      customerType: params.customerType || '',
      query: params.query || '',
    };
    const res = await get(
      `admin/user/list?offset=${params.offset}&limit=${params.limit}&status=${defaultValues.status}&customerType=${defaultValues.customerType}&query=${defaultValues.query}&fromDate=${params.fromDate}&toDate=${params.toDate}`,
      params,
    );
    return res.data;
  },

  getUserFile: (params) =>
    get(
      `admin/user/file?status=${params.status}&customerType=${
        params.customerType
      }&fromDate=${params.fromDate}&toDate=${
        params.toDate
      }&query=${encodeURIComponent(params.query)}`,
      {
        responseType: 'blob',
      },
    ).then((res) => getFileAndName(res)),

  getPurchaseList: async (params) => {
    const res = await get(listUrlGenerator(params));
    return res.data;
  },

  getPaymentHistoryList: async (params) => {
    const res = await get(
      `admin/payment/list?fromDate=${params.fromDate}&toDate=${params.toDate}&offset=${params.offset}&limit=${params.limit}`,
    );
    return res.data;
  },

  getPaymentHistoryFile: () =>
    get('admin/payment/file', {
      responseType: 'blob',
    }).then((res) => getFileAndName(res)),

  sendTestMails: async (params) => {
    await post(
      `admin/notification/test?type=${params.type}&language=${params.language}&email=${params.email}`,
    );
  },

  getPurchaseFile: (params) =>
    get(listUrlGeneratorExport(params), {
      responseType: 'blob',
    }).then((res) => getFileAndName(res)),

  updateUserNote: async (params) => {
    await put(`admin/user/notes?userId=${params.userId}&notes=${params.notes}`);
  },
};
