import React, { useContext, useEffect, useState } from 'react';
import {
  ChangeCard,
  CheckoputFormTotals,
  CheckoutFormCardBlock,
  CheckoutFormGroup,
  CheckoutFormRow,
  CheckoutFormStyled,
  CheckoutFormSubmit,
  FormRowLeft,
  FormRowRight,
  NoCardWrapper,
  AddCardIcon,
  AddCardText,
  SavedCard,
  SaveToPersonalInfoWrapper,
  SavedCardIcon,
  SavedCardMask,
  SavedCardTitle,
  SavedCartInfoWrapper,
} from 'ui/styled/cartPage.styled';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { Button } from 'library/button/Button';
import CustomSelect from 'library/Select/Select';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from 'store/reducers/user/user.slice';

import FormCheckBoxState from 'ui/components/formCheckbox/formCheckbox-formState';
import cardIcon from 'assets/img/cardIcon.svg';
import api from 'services/api';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Modal from 'library/modal/Modal';
import ConfirmBuyModal from './ConfirmBuyModal';
import visaIcon from 'assets/img/visa.svg';
import masterCardIcon from 'assets/img/mastercard.svg';
import FormInputBasic from 'ui/shared/formComponents/FormInputBasic';
import {
  creditCardType,
  getTextFromDataByKey,
  selectOptionsAddapter,
} from 'utils/helpers';
import { cartSuccessErrorUrls, userTypes } from 'constants/global.constants';
import TrainingsModal from './TrainingsModal';
import { useMediaQuery } from 'react-responsive';
import BottomSheet from 'library/bottomSheet/BottomSheet';
import { FormFieldsErrorWrapper } from 'ui/styled/auth.styled';
import { getCurrentUser } from 'store/reducers/user/user.actions';
import ConfirmAddCardModal from './ConfirmAddCardModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { StrapiDataContext } from 'Main';
import { htmlParser } from 'utils/htmlUtils';

const CheckoutForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { totals, getCartData, data, cartData, translatesData, settingsData } =
    props;
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  const errorTranslatesData =
    settingsData?.data?.data?.attributes?.errorTranslate;

  const fieldsOfActivityData = settingsData
    ? settingsData?.data?.data?.attributes?.fieldOfActivityTranslate
    : [];

  const methods = useForm({
    reValidateMode: 'onBlur',
  });
  const {
    control,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors },
    reset,
    watch,
  } = methods;

  const nameVal = watch('companyName');
  const idNumberVal = watch('idNumber');
  const idNumberCompVal = watch('idNumber');
  const fieldOfActivityVal = watch('fieldOfActivity');
  const chiefManagerVal = watch('chiefManager');

  const [savedCardInfo, setSavedCardInfo] = useState(null);
  const [savedCartId, setSavedCartId] = useState(null);

  const [payConfirmModal, setPayConfirmModal] = useState(false);
  const [trainingModal, setTrainingModal] = useState(false);
  const [confirmCardModal, setConfirmCardModal] = useState(false);

  const [saveToPersonal, setSaveToPersonal] = useState(false);
  const [cardError, setCardError] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [trainingsEmailsObj, setTrainingsEmailsObj] = useState({});
  const activeUser = useSelector(userSelectors.userInfo);

  const isOfferInUrlParams = location?.pathname?.includes('isOffer');
  const additionalParam = isOfferInUrlParams ? 'isOffer' : 'default';

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { currentProductsData: strapiData } = useContext(StrapiDataContext);
  const deleteCardStrapiData = strapiData?.data?.data?.attributes;

  const cardTermsData = data?.card;
  const onSubmit = async () => {
    setPaymentLoading(true);
    setPaymentError(null);
    const values = getValues();
    const params =
      activeUser.customerType === userTypes.LEGAL
        ? {
            idNumber: values.idNumber,
            fullName: values.companyName,
            chiefManager: values.chiefManager,
            fieldOfActivity: values.fieldOfActivity.value,
            emails: trainingsEmailsObj,
          }
        : {
            idNumber: values.idNumber,
            fullName: values.companyName,
            emails: trainingsEmailsObj,
          };

    try {
      await api.cart.purchaseProduct(params).then((res) => {
        getCartData();
        navigate(`/transactionHistory/${additionalParam}/id/${res.paymentId}`);
      });
      await dispatch(getCurrentUser());
    } catch (e) {
      setPaymentError(e);
      console.error(e);
    } finally {
      setPaymentLoading(false);
    }
  };

  function add(acc, a) {
    return acc + a.product.price * a.quantity;
  }

  const getPaymentCartInfo = async () => {
    await api.cart.getPaymentCard().then((res) => {
      setSavedCardInfo(res.data.mask);
      setSavedCartId(res.data.cardId);
    });
  };

  const deleteCartInfo = async () => {
    try {
      await api.cart.deleteCartInfo(savedCartId);
      setSavedCardInfo(null);
      setSavedCartId(null);
      setIsDeleteModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  };

  const addPaymentCart = async () => {
    await api.cart
      .addPaymentCard(
        `${window.location.origin}${window.location.pathname}/${additionalParam}/${cartSuccessErrorUrls.SUCCESS}`,
        `${window.location.origin}${window.location.pathname}/${additionalParam}/${cartSuccessErrorUrls.ERROR}`,
      )
      .then((res) => {
        setCardError(false);
        window.location.href = res.data;
      });
  };

  useEffect(() => {
    getPaymentCartInfo();
  }, []);

  useEffect(() => {
    trigger(['companyName', 'idNumber', 'chiefManager', 'fieldOfActivity']);
  }, [idNumberCompVal]);

  useEffect(() => {
    if (activeUser) {
      reset({
        companyName: activeUser?.fullName || undefined,
        idNumber: activeUser?.idNumber || undefined,
        fieldOfActivity: undefined,
        chiefManager: activeUser?.chiefManager || undefined,
      });
    }
    if (!activeUser?.idNumber) {
      setSaveToPersonal(true);
    }
  }, [activeUser]);

  const renderCardIcon = () => {
    if (!savedCardInfo) return '';

    if (
      creditCardType(savedCardInfo) === '5' ||
      creditCardType(savedCardInfo) === '2'
    ) {
      return <img src={masterCardIcon} alt="MASTERCARD" />;
    }
    if (creditCardType(savedCardInfo) === '4') {
      return <img src={visaIcon} alt="Visa" />;
    }
  };

  const renderCardName = () => {
    if (!savedCardInfo) return '';
    if (
      creditCardType(savedCardInfo) === '5' ||
      creditCardType(savedCardInfo) === '2'
    ) {
      return 'Mastercard';
    }
    if (creditCardType(savedCardInfo) === '4') {
      return 'Visa';
    }
  };

  const permiseChanged = totals.permiseData.map((item) => {
    if (item.isPermise && item.quantity > 1) {
      return { ...item, quantity: item.quantity - 1 };
    }
    if (item.product?.basePrice && item.quantity === 1) {
      return {
        ...item,
        product: {
          ...item.product,
          price: item.product.basePrice,
        },
      };
    }
    return item;
  });

  const monthlyTotal = totals.monthlyData.reduce(add, 0);
  const annualyTotal = totals.annualyData.reduce(add, 0);
  const permiseTotal = totals.permiseData.reduce(add, 0);
  const totalPrice = monthlyTotal + annualyTotal + permiseTotal;

  const trainingsArray = cartData.filter((item) => item.training);

  return (
    <CheckoutFormStyled>
      {!isTabletOrMobile ? (
        <Modal
          closeIcon
          width="464px"
          height="auto"
          className="addCard-modal"
          isOpen={confirmCardModal}
          closeModal={() => {
            setConfirmCardModal(false);
          }}
        >
          <ConfirmAddCardModal
            cardTermsData={cardTermsData}
            addPaymentCart={addPaymentCart}
            closeModal={() => {
              setConfirmCardModal(false);
            }}
          />
        </Modal>
      ) : (
        <BottomSheet
          isOpen={confirmCardModal}
          padding="40px 55px"
          closeBottomSheet={() => setConfirmCardModal(false)}
        >
          <ConfirmAddCardModal
            cardTermsData={cardTermsData}
            addPaymentCart={addPaymentCart}
            closeModal={() => {
              setConfirmCardModal(false);
            }}
          />
        </BottomSheet>
      )}
      <Modal
        closeIcon
        width="464px"
        height="auto"
        className="trainings-modal"
        isOpen={trainingModal}
        closeModal={() => {
          setTrainingModal(false);
        }}
      >
        <TrainingsModal
          data={trainingsArray}
          translatesData={translatesData}
          trainingsEmailsObj={trainingsEmailsObj}
          setTrainingsEmailsObj={setTrainingsEmailsObj}
          setPayConfirmModal={setPayConfirmModal}
          translatesCard={data}
          closeModal={() => {
            setTrainingModal(false);
          }}
        />
      </Modal>
      {!isTabletOrMobile ? (
        <Modal
          closeIcon
          width="464px"
          height="auto"
          className="pay-confirm"
          canCloseOnOutsideClick={false}
          isOpen={payConfirmModal}
          closeModal={() => {
            setPaymentError(null);
            setPayConfirmModal(false);
          }}
        >
          <ConfirmBuyModal
            confirmPay={onSubmit}
            totalPrice={totalPrice}
            paymentError={paymentError}
            paymentLoading={paymentLoading}
            data={data}
            errorTranslatesData={errorTranslatesData}
          />
        </Modal>
      ) : (
        <BottomSheet
          isOpen={payConfirmModal}
          padding="40px 55px"
          closeBottomSheet={() => setPayConfirmModal(false)}
        >
          <ConfirmBuyModal
            confirmPay={onSubmit}
            totalPrice={totalPrice}
            paymentError={paymentError}
            paymentLoading={paymentLoading}
            errorTranslatesData={errorTranslatesData}
            data={data}
          />
        </BottomSheet>
      )}

      {!isTabletOrMobile ? (
        <Modal
          closeIcon
          width="500px"
          height="auto"
          isOpen={isDeleteModalOpen}
          className="delete-confirm"
          closeModal={() => setIsDeleteModalOpen(false)}
        >
          <ConfirmDeleteModal
            closeModal={() => setIsDeleteModalOpen(false)}
            ConfirmDeleteText={htmlParser(data?.card?.deleteCard?.description)}
            DeleteTextTitle={data?.card?.deleteCard?.title}
            ConfirmDelete={data?.card?.deleteCard?.btn}
            confirmDelete={deleteCartInfo}
            // CancelDelete={deleteCardStrapiData?.cancelSubscription?.cancel}
          />
        </Modal>
      ) : (
        <BottomSheet
          isOpen={isDeleteModalOpen}
          padding="40px 55px"
          closeBottomSheet={() => setIsDeleteModalOpen(false)}
        >
          <ConfirmDeleteModal
            closeModal={() => setIsDeleteModalOpen(false)}
            ConfirmDeleteText={htmlParser(data?.card?.deleteCard?.description)}
            DeleteTextTitle={data?.card?.deleteCard?.title}
            ConfirmDelete={data?.card?.deleteCard?.btn}
            confirmDelete={deleteCartInfo}
            // CancelDelete={deleteCardStrapiData?.cancelSubscription?.cancel}
          />
        </BottomSheet>
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CheckoutFormCardBlock
            className="bodyL"
            style={{
              border: !savedCardInfo ? '1px dashed #dddbed' : undefined,
              minHeight: !savedCardInfo ? '158px' : 'auto',
            }}
          >
            {!savedCardInfo ? (
              <NoCardWrapper onClick={() => setConfirmCardModal(true)}>
                <AddCardIcon>
                  <img src={cardIcon} alt="Add Card" />
                </AddCardIcon>
                <AddCardText
                  className="bodyM"
                  style={{
                    color: cardError ? '#f55e1b' : undefined,
                  }}
                >
                  <div className="title">
                    {getTextFromDataByKey('noCard', data?.card?.translate)}
                  </div>
                  <div className="add">
                    {getTextFromDataByKey('addCard', data?.card?.translate)}
                  </div>
                </AddCardText>
              </NoCardWrapper>
            ) : (
              savedCardInfo && (
                <>
                  <SavedCard>
                    <SavedCardIcon>{renderCardIcon()}</SavedCardIcon>
                    <SavedCartInfoWrapper>
                      <SavedCardMask className="bodyL">
                        {`** ${savedCardInfo.substr(savedCardInfo.length - 4)}`}
                      </SavedCardMask>
                      <SavedCardTitle className="bodyM">
                        {renderCardName()}
                      </SavedCardTitle>
                    </SavedCartInfoWrapper>
                  </SavedCard>
                  <ChangeCard onClick={() => setIsDeleteModalOpen(true)}>
                    {getTextFromDataByKey('delete', data?.card?.translate)}
                  </ChangeCard>
                </>
              )
            )}
          </CheckoutFormCardBlock>
          {!savedCardInfo ? null : (
            <>
              {activeUser?.customerType === userTypes.PERSONAL ? (
                <>
                  <CheckoutFormGroup>
                    <Controller
                      render={({ field }) => (
                        <FormInputBasic
                          {...field}
                          type="text"
                          search={false}
                          placeholder={getTextFromDataByKey(
                            'companyName',
                            data?.checkout,
                          )}
                          borderColor={nameVal ? undefined : errors.companyName}
                        />
                      )}
                      rules={{
                        required: 'Is Required',
                      }}
                      control={control}
                      name="companyName"
                    />
                  </CheckoutFormGroup>
                  <CheckoutFormGroup>
                    <Controller
                      render={({ field }) => (
                        <FormInputBasic
                          {...field}
                          type="text"
                          search={false}
                          borderColor={
                            idNumberVal ? undefined : errors.idNumber
                          }
                          placeholder={getTextFromDataByKey(
                            'personalNumber',
                            data?.checkout,
                          )}
                          disabled={activeUser?.idNumber}
                        />
                      )}
                      rules={{
                        required: 'Is Required',
                        validate: (value) =>
                          value.length === 11
                            ? undefined
                            : 'should contain  11 digits',
                      }}
                      control={control}
                      name="idNumber"
                    />
                    {errors.idNumber ? (
                      <FormFieldsErrorWrapper>
                        {getTextFromDataByKey('incorrectID', data?.checkout)}
                      </FormFieldsErrorWrapper>
                    ) : null}
                  </CheckoutFormGroup>
                </>
              ) : (
                <>
                  <CheckoutFormGroup>
                    <Controller
                      render={({ field }) => (
                        <FormInputBasic
                          {...field}
                          type="text"
                          search={false}
                          borderColor={nameVal ? undefined : errors.companyName}
                          placeholder={getTextFromDataByKey(
                            'companyName',
                            data?.checkout,
                          )}
                        />
                      )}
                      rules={{
                        required: 'Is Required',
                      }}
                      control={control}
                      name="companyName"
                    />
                  </CheckoutFormGroup>
                  <CheckoutFormGroup>
                    <Controller
                      render={({ field }) => (
                        <FormInputBasic
                          {...field}
                          type="text"
                          search={false}
                          borderColor={errors.idNumber}
                          placeholder={getTextFromDataByKey(
                            'companyNumber',
                            data?.checkout,
                          )}
                          disabled={activeUser?.idNumber}
                        />
                      )}
                      rules={{
                        required: 'Is Required',
                        validate: (value) =>
                          value.length === 9
                            ? undefined
                            : 'should contain  9 digits',
                      }}
                      control={control}
                      name="idNumber"
                    />
                    {errors.idNumber ? (
                      <FormFieldsErrorWrapper>
                        {getTextFromDataByKey('incorrectID', data?.checkout)}
                      </FormFieldsErrorWrapper>
                    ) : null}
                  </CheckoutFormGroup>
                  <CheckoutFormGroup
                    border={
                      fieldOfActivityVal ? undefined : errors.fieldOfActivity
                    }
                  >
                    <Controller
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          type="text"
                          search={false}
                          placeholder={getTextFromDataByKey(
                            'chooseActivity',
                            data?.checkout,
                          )}
                          options={selectOptionsAddapter(fieldsOfActivityData)}
                        />
                      )}
                      rules={{
                        required: 'Is Required',
                      }}
                      control={control}
                      name="fieldOfActivity"
                    />
                  </CheckoutFormGroup>
                  <CheckoutFormGroup>
                    <Controller
                      render={({ field }) => (
                        <FormInputBasic
                          {...field}
                          type="text"
                          search={false}
                          borderColor={
                            chiefManagerVal ? undefined : errors.chiefManager
                          }
                          placeholder={getTextFromDataByKey(
                            'directorName',
                            data?.checkout,
                          )}
                        />
                      )}
                      rules={{
                        required: 'Is Required',
                      }}
                      control={control}
                      name="chiefManager"
                    />
                  </CheckoutFormGroup>
                </>
              )}
            </>
          )}
          {!savedCardInfo ? null : (
            <SaveToPersonalInfoWrapper
              className="bodyL"
              style={{
                opacity: !activeUser?.idNumber ? 0.6 : 1,
              }}
            >
              <FormCheckBoxState
                checked={saveToPersonal}
                label={getTextFromDataByKey('savePersonalInfo', data?.checkout)}
                labelPosition="right"
                value="yes"
                labelOnchange
                onChange={() => {
                  if (!activeUser?.idNumber) return;
                  setSaveToPersonal(!saveToPersonal);
                }}
              />
            </SaveToPersonalInfoWrapper>
          )}

          <CheckoputFormTotals>
            <CheckoutFormRow>
              <FormRowLeft>
                {getTextFromDataByKey('premiseTotal', data?.checkout)}
              </FormRowLeft>
              <FormRowRight>{`₾ ${permiseTotal.toFixed(2)}`}</FormRowRight>
            </CheckoutFormRow>
            <CheckoutFormRow>
              <FormRowLeft>
                {getTextFromDataByKey('monthlyTotal', data?.checkout)}
              </FormRowLeft>
              <FormRowRight>{`₾ ${monthlyTotal.toFixed(2)}`}</FormRowRight>
            </CheckoutFormRow>
            <CheckoutFormRow>
              <FormRowLeft>
                {getTextFromDataByKey('annualTotal', data?.checkout)}
              </FormRowLeft>
              <FormRowRight>{`₾ ${annualyTotal.toFixed(2)}`}</FormRowRight>
            </CheckoutFormRow>
            <CheckoutFormRow className="total">
              <FormRowLeft>
                {getTextFromDataByKey('total', data?.checkout)}
              </FormRowLeft>
              <FormRowRight>{`₾ ${totalPrice.toFixed(2)}`}</FormRowRight>
            </CheckoutFormRow>
          </CheckoputFormTotals>

          <CheckoutFormSubmit className="CheckoutFormSubmit">
            <Button
              className="submit button1"
              type="button"
              onClick={async () => {
                await trigger(
                  activeUser.customerType === userTypes.LEGAL
                    ? [
                        'companyName',
                        'idNumber',
                        'chiefManager',
                        'fieldOfActivity',
                      ]
                    : ['companyName', 'idNumber'],
                ).then((passValidation) => {
                  if (
                    passValidation &&
                    trainingsArray &&
                    trainingsArray.length > 0 &&
                    savedCardInfo
                  ) {
                    setTrainingModal(true);
                    return;
                  }
                  if (passValidation && savedCardInfo) {
                    setPayConfirmModal(true);
                  } else {
                    setCardError(true);
                  }
                });
              }}
            >
              {getTextFromDataByKey('checkoutButton', data?.checkout)}
            </Button>
          </CheckoutFormSubmit>
        </form>
      </FormProvider>
    </CheckoutFormStyled>
  );
};

export default CheckoutForm;
