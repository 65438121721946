import React, { useState } from 'react';
import { Tab } from './tab.styled';
import { TabMenu, TabContainer } from './inline.styled';
import { ScrollSpyNav } from 'ui/styled/terms.styled';
import { focusonFormsGroup } from 'utils/helpers';

function renderElem(elem) {
  if (elem && elem.props) {
    return elem.props.title;
  }
  return null;
}

export const Tabs = (args) => {
  const [active, setActive] = useState('');
  const [overflow, setOverflow] = useState('');

  function handleChange(index, el = { props: { title: '' } }) {
    const element = document.getElementById(el.props.title);
    if (element && window && args.content) {
      const yOffset = -1; // header height
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'auto' });
    }
    if (index === args.selected) return;
    if (!args.content) setOverflow('hidden');

    if (typeof args.handleChangeIndex === 'function') {
      setTimeout(() => {
        if (typeof args.handleChangeIndex === 'function')
          args.handleChangeIndex(index);
      }, 100);
    }

    setActive('active');
    setTimeout(() => {
      setActive('');
      if (!args.content) setOverflow('');
    }, 100);
  }

  function renderTabMenu() {
    if (args.children) {
      return args.children.map((elem, index) => {
        if (!elem) return null;
        const style = index === args.selected ? 'selected' : '';
        return (
          <ScrollSpyNav>
            <a
              href={`#section-${index}`}
              className={style}
              key={index}
              onKeyDown={() => handleChange(index, elem)}
              onClick={() => {
                focusonFormsGroup(`section-${index}`);
                handleChange(index, elem);
              }}
            >
              <div data-to-scrollspy-id={`section-${index}`}>
                {renderElem(elem)}
              </div>
            </a>
          </ScrollSpyNav>
        );
      });
    }
    return null;
  }

  return (
    <TabContainer overflow={overflow}>
      <TabMenu>{renderTabMenu()}</TabMenu>
      <Tab className="headline4">
        {args.content ? (
          <div className={active}>{args.content}</div>
        ) : (
          <div className={active}>{args.children[args.selected || 0]}</div>
        )}
      </Tab>
    </TabContainer>
  );
};

export default Tabs;

export const Panel = (args) => {
  return <div>{args?.children}</div>;
};
