import React, { useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import api from 'services/api';
import Pagination from 'library/Pagination/Pagination';
import formatDate, { DateFormats } from 'utils/date';
import { TransactionsListWrapper } from 'ui/styled/admin.styled';
import Modal from 'library/modal/Modal';
import MoreDetailsModal from './MoreDetailsModal';
import TransactionsFilter from './TransactionsFilter';
import {
  getTextFromDataByProductKey,
  getTextFromDataByPeriod,
  downloadBlobFile,
} from 'utils/helpers';
import TransactionsExport from './TransactionsExport';
import EmptyTransactionsInfo from 'ui/components/common/EmptyTransactionsInfo';
import { StrapiDataContext } from 'Main';
import TableLoading from 'ui/components/common/TableLoading';

const TransactionsList = (props) => {
  const {
    translatesData,
    isOpenTransactionsFilter,
    setIsOpenTransactionsFilter,
    searchValue,
    isTransactionsExportOpen,
    setTransactionsExportOpen,
    pagination,
    setPagination,
    errorTranslatesData,
  } = props;
  const userListHeaders = [
    { key: 'userId', value: 'User', align: 'left' },
    { key: 'product', value: 'Product', align: 'left' },
    { key: 'comment', value: 'Comment', align: 'right' },
    { key: 'identificator', value: 'Check ID', align: 'left' },
    { key: 'contactInfo', value: 'Purchase Date', align: 'left' },
    { key: 'price', value: 'Price', align: 'right' },
    { key: 'more', value: '', align: 'right' },
  ];

  const translateKeys = translatesData?.data.attributes.enumTranslate;

  const [purchaseList, setPurchaseList] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactionFilters, setTransactionFilters] = useState(null);
  const [transactionExportFields, setTransactionExportFields] = useState(null);
  const [formError, setFormError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [onPremiseCheckbox, setOnPremiseCheckbox] = useState(false);
  const [isOverdue, setIsOverdue] = useState(false);
  const [isFirstPurchase, setIsFirstPurchase] = useState(false);
  const [purchaseListDataError, setPurchaseListDataError] = useState(false);

  const { transactionHistoryData: data } = useContext(StrapiDataContext);
  const NotFoundData = data?.data?.data?.attributes?.search?.notFound;

  const getTransactionList = async (values, isFilter) => {
    setLoading(true);
    if (isFilter) {
      setPagination(() => {
        return {
          offset: 0,
          limit: 5,
          count: 0,
          call: false,
        };
      });
    }

    const params = {
      paymentId: values.paymentId || '',
      idNumber: values.idNumber || '',
      offset: isFilter ? 0 : pagination.offset,
      limit: pagination.limit,
      onPremise: true,
      query: isOpenTransactionsFilter
        ? values?.query || searchValue
        : searchValue,
      status: values?.status || '',
      productType: values?.productType || '',
      period: values?.period || '',
      isOverdue: isOverdue ? isOverdue : '',
      fromDate: values?.fromDate
        ? formatDate({
            inputDate: values.fromDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      toDate: values?.toDate
        ? formatDate({
            inputDate: values.toDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      isFirstPurchase: isFirstPurchase ? isFirstPurchase : '',
      comments: values?.comments || '',
    };
    try {
      const result = await api.admin.getPurchaseList(params);
      setPurchaseList(result.list);
      setPagination((prev) => {
        return {
          ...prev,
          count: result.count,
        };
      });
      isOpenTransactionsFilter && setIsOpenTransactionsFilter(false);
    } catch (e) {
      console.error(e);
      setPurchaseListDataError(true);
    } finally {
      setLoading(false);
    }
  };
  const getTransactionsExport = async (values) => {
    setLoadingExport(true);

    const search = isOpenTransactionsFilter
      ? values?.query || searchValue
      : searchValue;

    const params = {
      paymentId: values.paymentId || '',
      idNumber: values.idNumber || '',
      onPremise: onPremiseCheckbox ? '' : true,
      query: search || '',
      status: values?.status || '',
      productType: values?.productType || '',
      period: values?.period || '',
      isOverdue: isOverdue ? isOverdue : '',
      fromDate: values?.fromDate
        ? formatDate({
            inputDate: values.fromDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      toDate: values?.toDate
        ? formatDate({
            inputDate: values.toDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      isFirstPurchase: isFirstPurchase ? isFirstPurchase : '',
      comments: values?.comments || '',
    };
    try {
      const { data, filename } = await api.admin.getPurchaseFile(params);
      downloadBlobFile(data, filename);
      isOpenTransactionsFilter && setIsOpenTransactionsFilter(false);
    } catch (e) {
      setFormError(e);
      console.error(e);
    } finally {
      setLoadingExport(false);
    }
  };

  const handlePaginationClick = (i) => {
    setPagination({
      ...pagination,
      offset: i * pagination.limit,
      call: true,
    });
  };

  useEffect(() => {
    if (!pagination.call) return;
    getTransactionList({
      ...transactionFilters,
      query: searchValue,
    });
  }, [pagination.offset]);

  useEffect(() => {
    if (searchValue === null || searchValue === undefined) return;
    getTransactionList({
      ...transactionFilters,
      query: searchValue,
    });
  }, [searchValue]);

  return (
    <>
      <Modal
        closeIcon
        width="520px"
        height="auto"
        title="Transaction Filter"
        className="admin-details-modal"
        isOpen={isOpenTransactionsFilter}
        closeModal={() => setIsOpenTransactionsFilter(false)}
      >
        <TransactionsFilter
          loading={loading}
          getTransactionList={getTransactionList}
          transactionFilters={transactionFilters}
          setTransactionFilters={setTransactionFilters}
          isOverdue={isOverdue}
          setIsOverdue={setIsOverdue}
          setIsFirstPurchase={setIsFirstPurchase}
          isFirstPurchase={isFirstPurchase}
          setPagination={setPagination}
        />
      </Modal>
      {purchaseListDataError ? (
        <EmptyTransactionsInfo noData={NotFoundData} />
      ) : (
        <TransactionsListWrapper>
          <Modal
            closeIcon
            width="520px"
            height="auto"
            title="Export"
            className="admin-details-modal"
            isOpen={isTransactionsExportOpen}
            closeModal={() => {
              setOnPremiseCheckbox(false);
              setTransactionsExportOpen(false);
            }}
          >
            <TransactionsExport
              loading={loadingExport}
              setTransactionExportFields={setTransactionExportFields}
              transactionExportFields={transactionExportFields}
              getTransactionsExport={getTransactionsExport}
              onPremiseCheckbox={onPremiseCheckbox}
              setOnPremiseCheckbox={setOnPremiseCheckbox}
              errorTranslatesData={errorTranslatesData}
              formError={formError}
              isFirstPurchase={isFirstPurchase}
              setIsFirstPurchase={setIsFirstPurchase}
              setPagination={setPagination}
            />
          </Modal>
          <Modal
            closeIcon
            width="520px"
            height="auto"
            title="Other Details"
            className="admin-details-modal"
            isOpen={isOpen}
            closeModal={() => setIsOpen(false)}
          >
            <MoreDetailsModal selectedTransaction={selectedTransaction} />
          </Modal>
          {loading ? (
            <TableLoading />
          ) : (
            <>
              {purchaseList && purchaseList.length === 0 ? (
                <EmptyTransactionsInfo notFoundData={NotFoundData} />
              ) : (
                <Pagination
                  limit={pagination.limit}
                  offset={pagination.offset}
                  count={pagination.count}
                  className="adminTablesPagination"
                  onClick={handlePaginationClick}
                >
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {userListHeaders.map((item) => {
                            return (
                              <TableCell key={item.key} align={item.align}>
                                {item.value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {purchaseList?.map((item) => {
                          return (
                            <TableRow key={item.userId}>
                              <TableCell component="td">
                                <div className="userInfo">
                                  <div className="username">
                                    <span style={{ paddingRight: '4px' }}>
                                      ID/CIN:
                                    </span>
                                    {item.idNumber}
                                  </div>
                                  <div className="type"> {item.username}</div>
                                </div>
                              </TableCell>
                              <TableCell component="td">
                                <div className="userInfo">
                                  <div className="username">
                                    {getTextFromDataByProductKey(
                                      item.product.productKey,
                                      translateKeys,
                                    )}
                                  </div>
                                  <div className="type">
                                    {`ID:${item.purchasedItemId}`}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell component="td" align="right">
                                <div className="userInfo">
                                  <div
                                    className="username"
                                    style={{
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      whiteSpace: 'pre',
                                    }}
                                  >
                                    {item.comment}
                                  </div>
                                  {item.isFirstPurchase ? (
                                    <div className="type">First</div>
                                  ) : undefined}
                                </div>
                              </TableCell>
                              <TableCell component="td">{`#${item.paymentId}`}</TableCell>
                              <TableCell component="td" align="left">
                                <div className="userInfo">
                                  <div className="username">
                                    {formatDate({
                                      inputDate: item.createTime,
                                      outputFormat:
                                        DateFormats.DefaultWithoutLines,
                                    })}
                                  </div>
                                  <div className="type">
                                    {getTextFromDataByPeriod(
                                      item.product.productKey,
                                      translateKeys,
                                    )}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell
                                align="right"
                                className="prices"
                                component="td"
                                style={{ whiteSpace: 'pre' }}
                              >
                                <span className="price">{`₾ ${item.price}`}</span>
                              </TableCell>
                              <TableCell align="right" component="td">
                                <div className="userInfo">
                                  <div
                                    className="underline"
                                    onClick={() => {
                                      setSelectedTransaction(item);
                                      setIsOpen(true);
                                    }}
                                  >
                                    More
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Pagination>
              )}
            </>
          )}
        </TransactionsListWrapper>
      )}
    </>
  );
};

export default TransactionsList;
