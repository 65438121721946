import React from 'react';

export const EmptyPageIcon = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    width="134.007"
    height="109.92"
    viewBox="0 0 134.007 109.92"
    {...rest}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_5701"
          data-name="Rectangle 5701"
          width="14.736"
          height="14.738"
          transform="translate(0 0)"
          fill="#8a87a5"
        />
      </clipPath>
    </defs>
    <g id="Empty" transform="translate(-1185 -706.754)">
      <path
        id="Rectangle_5482"
        data-name="Rectangle 5482"
        d="M85.322,0h0a0,0,0,0,1,0,0V89.765a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V85.322A85.322,85.322,0,0,1,85.322,0Z"
        transform="translate(1185 706.754)"
        fill="#fdf6e7"
        opacity="0.86"
      />
      <path
        id="Rectangle_5483"
        data-name="Rectangle 5483"
        d="M60.971,0h.465a0,0,0,0,1,0,0V60.971a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0v0A60.971,60.971,0,0,1,60.971,0Z"
        transform="translate(1319.007 722.367) rotate(90)"
        fill="#ebeaf4"
        opacity="0.48"
      />
      <rect
        id="Rectangle_5699"
        data-name="Rectangle 5699"
        width="71.807"
        height="56.025"
        transform="translate(1216.264 747.332)"
        fill="none"
      />
      <path
        id="Path_66439"
        data-name="Path 66439"
        d="M59.023,19.038v.741C59.023,32.711,59,49,59,61.933c0,3.655-1.1,6.538-4.451,7.457a8.129,8.129,0,0,1-2.12.271c-14.619.015-31.239.017-45.858.008-4.36,0-6.56-3.25-6.562-7.667C0,43.886,0,24.772.006,6.657.008,2.35,3.178.026,7.355.015c10.5-.027,20.995-.01,31.492-.009.126,0,.252.013.488.026v.83c0,4.561,0-.878,0,3.683,0,4.664,3.155,13.847,7.77,13.851,3.661,0,7.347.642,11.009.642h.91"
        transform="translate(1223 747)"
        fill="#d4d2e5"
      />
      <path
        id="Path_66440"
        data-name="Path 66440"
        d="M142.7,5.041l19.717,19.165s-7.406.006-11.937,0c-5.893.282-7.776-4.023-7.779-6.03C142.688,13.432,142.7,5.041,142.7,5.041Z"
        transform="translate(1119.632 741.959)"
        fill="#8a87a5"
      />
      <rect
        id="Rectangle_5700"
        data-name="Rectangle 5700"
        width="23"
        height="5"
        rx="1"
        transform="translate(1241 783)"
        fill="#8a87a5"
      />
      <g
        id="Group_52611"
        data-name="Group 52611"
        transform="translate(1298.646 732.889)"
      >
        <g
          id="Group_52611-2"
          data-name="Group 52611"
          transform="translate(0 0)"
          clipPath="url(#clip-path)"
        >
          <path
            id="Path_66441"
            data-name="Path 66441"
            d="M0,7.36a1.023,1.023,0,0,1,1.1-.994A5.217,5.217,0,0,0,4.41,5.141,5.378,5.378,0,0,0,6.39.981.927.927,0,0,1,7.117.037a.932.932,0,0,1,1.067.4,1.71,1.71,0,0,1,.185.721,5.287,5.287,0,0,0,1.751,3.8,5.2,5.2,0,0,0,3.406,1.41,2.737,2.737,0,0,1,.394.035.961.961,0,0,1,.814,1.039.939.939,0,0,1-.92.908,5.528,5.528,0,0,0-2.886.822,5.286,5.286,0,0,0-2.551,4.419,2.3,2.3,0,0,1-.022.3,1,1,0,0,1-1.017.858.983.983,0,0,1-.946-.966,5.5,5.5,0,0,0-.477-2.213,5.338,5.338,0,0,0-4.857-3.2A1.008,1.008,0,0,1,0,7.36"
            transform="translate(0 0)"
            fill="#8a87a5"
          />
        </g>
      </g>
      <path
        id="Path_66441-2"
        data-name="Path 66441"
        d="M0,5.657a.786.786,0,0,1,.844-.764A4.01,4.01,0,0,0,3.39,3.952,4.134,4.134,0,0,0,4.911.754.712.712,0,0,1,5.47.028a.716.716,0,0,1,.82.305,1.314,1.314,0,0,1,.142.554A4.064,4.064,0,0,0,7.778,3.806,4,4,0,0,0,10.4,4.889a2.1,2.1,0,0,1,.3.027.739.739,0,0,1,.625.8.722.722,0,0,1-.707.7A4.249,4.249,0,0,0,8.4,7.044a4.063,4.063,0,0,0-1.961,3.4,1.767,1.767,0,0,1-.017.228.771.771,0,0,1-.782.66.756.756,0,0,1-.727-.742,4.231,4.231,0,0,0-.367-1.7A4.1,4.1,0,0,0,.812,6.421.774.774,0,0,1,0,5.657"
        transform="translate(1288.646 748.582)"
        fill="#8a87a5"
        opacity="0.5"
      />
    </g>
  </svg>
);
