import { DownloadIcon } from 'assets/svgIcons/Download';
import { FilterIcon } from 'assets/svgIcons/FilterIcon';
import { SendEmailIcon } from 'assets/svgIcons/SendEmailIcon';
import { Button } from 'library/button/Button';
import { Input } from 'library/InputCustom';
import Modal from 'library/modal/Modal';
import { debounce } from 'lodash';
import { StrapiDataContext } from 'Main';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import api from 'services/api';
import {
  AdminPanelWrapper,
  AdminPanelBody,
  TabListHeaderAdmin,
  AdminTabsAndInputWrapper,
  AdminFilterButton,
  AdminExportButton,
  InputWrapperAdmin,
  AdminHeaderTitle,
} from 'ui/styled/admin.styled';
import { downloadBlobFile } from 'utils/helpers';

import AdminUsersList from './AdminUsersList';
import BillingList from './BillingList';
import TestEmailForm from './TestEmailForm';
import TransactionsHistoryList from './TransactionsHistoryList ';

import TransactionsList from './TransactionsList';

const adminTabs = {
  userList: 'USER_LIST',
  transactionsList: 'TRANSACTIONS_LIST',
  billingList: 'BILLING_LIST',
  paymentHistory: 'PAYMENT_HISTORY',
};
const AdminPanel = ({ settingsData: { data } }) => {
  const { settingsData } = useContext(StrapiDataContext);
  const errorTranslatesData =
    settingsData?.data?.data?.attributes?.errorTranslate;

  const [searchValue, setSearchValue] = useState(null);
  const [searchValueLabel, setSearchValueLabel] = useState('');
  const [isOpenTransactionsFilter, setIsOpenTransactionsFilter] =
    useState(false);
  const [isUsersExportOpen, setUsersExportOpen] = useState(false);
  const [isTransactionsExportOpen, setTransactionsExportOpen] = useState(false);
  const [isOpenUserFilter, setIsOpenUserFilter] = useState(false);
  const [activeTab, setActiveTab] = useState(adminTabs.userList);

  //pagings
  const [paymentHistoryPagination, setPaymentHistoryPagination] = useState({
    offset: 0,
    limit: 5,
    count: 0,
    call: false,
  });
  const [transactionsPagination, setTransactionsPagination] = useState({
    offset: 0,
    limit: 5,
    count: 0,
    call: false,
  });
  const [billingPagination, setBillingPagination] = useState({
    offset: 0,
    limit: 5,
    count: 0,
    call: false,
  });
  const [usersPagination, setUsersPagination] = useState({
    offset: 0,
    limit: 5,
    count: 0,
    call: false,
  });
  const [testEmailOpen, setTestEmailOpen] = useState(false);

  const debounceHandler = useCallback(
    debounce((input) => setSearchValue(input), 800),
    [],
  );
  const handleText = (input) => {
    if (activeTab === adminTabs.userList) {
      setUsersPagination((prev) => {
        return {
          ...prev,
          call: false,
          offset: 0,
        };
      });
    }
    if (activeTab === adminTabs.transactionsList) {
      setTransactionsPagination((prev) => {
        return {
          ...prev,
          call: false,
          offset: 0,
        };
      });
    }
    if (activeTab === adminTabs.paymentHistory) {
      setPaymentHistoryPagination((prev) => {
        return {
          ...prev,
          call: false,
          offset: 0,
        };
      });
    }
    if (activeTab === adminTabs.billingList) {
      setBillingPagination((prev) => {
        return {
          ...prev,
          call: false,
          offset: 0,
        };
      });
    }

    debounceHandler(input);
  };

  const clearSearchValue = () => {
    setSearchValue('');
    setSearchValueLabel('');
  };

  const [loadingExport, setLoadingExport] = useState(false);

  const getTransactionsExport = async () => {
    setLoadingExport(true);

    try {
      const { data, filename } = await api.admin.getPaymentHistoryFile();
      downloadBlobFile(data, filename);
      isOpenTransactionsFilter && setIsOpenTransactionsFilter(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingExport(false);
    }
  };

  return (
    <AdminPanelWrapper>
      <AdminHeaderTitle className="headline2" />
      <AdminPanelBody>
        <Tabs default>
          <TabListHeaderAdmin>
            <TabList>
              <Tab
                onClick={() => {
                  clearSearchValue();
                  setActiveTab(adminTabs.userList);
                  setUsersPagination({
                    offset: 0,
                    limit: 5,
                    count: 0,
                    call: false,
                  });
                }}
              >
                Users
              </Tab>
              <Tab
                onClick={() => {
                  clearSearchValue();
                  setActiveTab(adminTabs.transactionsList);
                  setTransactionsPagination({
                    offset: 0,
                    limit: 5,
                    count: 0,
                    call: false,
                  });
                }}
              >
                On Premise
              </Tab>
              <Tab
                onClick={() => {
                  clearSearchValue();
                  setActiveTab(adminTabs.billingList);
                  setBillingPagination({
                    offset: 0,
                    limit: 5,
                    count: 0,
                    call: false,
                  });
                }}
              >
                Current Billings
              </Tab>
              <Tab
                onClick={() => {
                  clearSearchValue();
                  setActiveTab(adminTabs.paymentHistory);
                  setPaymentHistoryPagination({
                    offset: 0,
                    limit: 5,
                    count: 0,
                    call: false,
                  });
                }}
              >
                Payment History
              </Tab>
            </TabList>
            <Modal
              closeIcon
              width="640px"
              height="auto"
              className="admin-details-modal"
              isOpen={testEmailOpen}
              canCloseOnOutsideClick={true}
              closeModal={() => {
                setTestEmailOpen(false);
              }}
            >
              <TestEmailForm
                testEmailOpen={testEmailOpen}
                setTestEmailOpen={setTestEmailOpen}
                data={data}
              />
            </Modal>
            <AdminTabsAndInputWrapper>
              {adminTabs.paymentHistory === activeTab ? null : (
                <InputWrapperAdmin>
                  <Input
                    search
                    color="#DDDBED"
                    type="text"
                    placeholder="Search By"
                    value={searchValueLabel}
                    height="50px"
                    fontSize="16px"
                    border="1px solid #DDDBED"
                    onChange={(e) => {
                      setSearchValueLabel(e.target.value);
                      handleText(e.target.value);
                    }}
                    // value={searchValue}
                  />
                </InputWrapperAdmin>
              )}
              <AdminFilterButton>
                <Button
                  height="50px"
                  fontSize="18px"
                  background="#FFF8CC"
                  color="#242333"
                  fontWeight="900"
                  className="demo-btn"
                  onClick={() =>
                    activeTab === adminTabs.userList
                      ? setIsOpenUserFilter(true)
                      : setIsOpenTransactionsFilter(true)
                  }
                >
                  <FilterIcon />
                </Button>
              </AdminFilterButton>
              <AdminExportButton>
                {activeTab === adminTabs.paymentHistory ? (
                  <Button
                    height="50px"
                    fontSize="18px"
                    background="rgb(255, 220, 4)"
                    color="#242333"
                    fontWeight="900"
                    className="demo-btn"
                    loading={loadingExport}
                    onClick={getTransactionsExport}
                  >
                    <DownloadIcon />
                  </Button>
                ) : (
                  <Button
                    height="50px"
                    fontSize="18px"
                    background="rgb(255, 220, 4)"
                    color="#242333"
                    fontWeight="900"
                    className="demo-btn"
                    onClick={() =>
                      activeTab === adminTabs.userList
                        ? setUsersExportOpen(true)
                        : setTransactionsExportOpen(true)
                    }
                  >
                    <DownloadIcon />
                  </Button>
                )}
              </AdminExportButton>
              <Button
                height="50px"
                fontSize="18px"
                background="rgb(255, 220, 4)"
                color="#242333"
                fontWeight="500"
                onClick={() => setTestEmailOpen(true)}
                padding="0 34px"
              >
                <SendEmailIcon />
              </Button>
            </AdminTabsAndInputWrapper>
          </TabListHeaderAdmin>
          <TabPanel>
            <AdminUsersList
              isOpenUserFilter={isOpenUserFilter}
              setIsOpenUserFilter={setIsOpenUserFilter}
              searchValue={searchValue}
              clearSearchValue={clearSearchValue}
              translatesData={data}
              isUsersExportOpen={isUsersExportOpen}
              setUsersExportOpen={setUsersExportOpen}
              pagination={usersPagination}
              setPagination={setUsersPagination}
            />
          </TabPanel>
          <TabPanel>
            <TransactionsList
              translatesData={data}
              searchValue={searchValue}
              clearSearchValue={clearSearchValue}
              isOpenTransactionsFilter={isOpenTransactionsFilter}
              setIsOpenTransactionsFilter={setIsOpenTransactionsFilter}
              isTransactionsExportOpen={isTransactionsExportOpen}
              setTransactionsExportOpen={setTransactionsExportOpen}
              pagination={transactionsPagination}
              setPagination={setTransactionsPagination}
              errorTranslatesData={errorTranslatesData}
            />
          </TabPanel>
          <TabPanel>
            <BillingList
              translatesData={data}
              searchValue={searchValue}
              clearSearchValue={clearSearchValue}
              isOpenTransactionsFilter={isOpenTransactionsFilter}
              setIsOpenTransactionsFilter={setIsOpenTransactionsFilter}
              isTransactionsExportOpen={isTransactionsExportOpen}
              setTransactionsExportOpen={setTransactionsExportOpen}
              pagination={billingPagination}
              setPagination={setBillingPagination}
              errorTranslatesData={errorTranslatesData}
            />
          </TabPanel>
          <TabPanel>
            <TransactionsHistoryList
              translatesData={data}
              isOpenTransactionsFilter={isOpenTransactionsFilter}
              setIsOpenTransactionsFilter={setIsOpenTransactionsFilter}
              pagination={paymentHistoryPagination}
              setPagination={setPaymentHistoryPagination}
              errorTranslatesData={errorTranslatesData}
            />
          </TabPanel>
        </Tabs>
      </AdminPanelBody>
    </AdminPanelWrapper>
  );
};

export default AdminPanel;
