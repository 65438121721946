import React from 'react';
import { ItemsList } from 'ui/styled/PlansSection.styled';

const PlansList = (props) => {
  return (
    <>
      {props.services ? (
        <ItemsList services className="bodyL">
          {props.planItems.map((i) => (
            <ul key={i.id}>
              <li>{i.plansListItem}</li>
            </ul>
          ))}
        </ItemsList>
      ) : (
        <ItemsList className="bodyL">
          {props.planItems &&
            props.planItems.map((i, index) => (
              <ul key={i}>
                <li>{i}</li>
              </ul>
            ))}
        </ItemsList>
      )}
    </>
  );
};

export default PlansList;
