import React from 'react';
import {
  HardwareNoteModalWrapper,
  InfoDescriptionBlock,
  NoteAddToCartButton,
  InfoNoteBlock,
} from 'ui/styled/hardware.styled';
import { Button } from 'library/button/Button';
import { CartIcon } from 'assets/svgIcons/CartIcon';
import { htmlParser } from 'utils/htmlUtils';

const SupportMatchModal = (props) => {
  const { updateItemInCart, closeModal, activeSupportItem } = props;

  return (
    <HardwareNoteModalWrapper>
      <InfoNoteBlock className="headline4">
        {activeSupportItem?.cartRemoveModal?.title}
      </InfoNoteBlock>
      <InfoDescriptionBlock>
        <span className="bottom">
          {htmlParser(activeSupportItem?.cartRemoveModal?.description)}
        </span>
      </InfoDescriptionBlock>
      <NoteAddToCartButton>
        <Button
          className="button1"
          background="#FFDC04"
          color="#242333"
          width="100%"
          padding="20px 100px"
          fontWeight="500"
          height="50px"
          onClick={() => {
            updateItemInCart();
            closeModal();
          }}
        >
          <CartIcon style={{ marginRight: '12px' }} />
          {activeSupportItem?.cartRemoveModal?.addButton}
        </Button>
        <Button
          background="transparent"
          color="#242333"
          padding="23px 100px"
          fontWeight="500"
          className="cancel"
          onClick={() => {
            closeModal();
          }}
        >
          {activeSupportItem?.cartRemoveModal?.removeButton}
        </Button>
      </NoteAddToCartButton>
    </HardwareNoteModalWrapper>
  );
};

export default SupportMatchModal;
