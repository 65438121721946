import React, { useEffect, useState } from 'react';
import {
  AddToCartComponentStyled,
  AddToCartModalBody,
  AddToCartModalFoot,
  AddToCartModalTitle,
  AddToCartModalType,
  AddToCartProductQyt,
  AddToCartTypesContainer,
  AddToCartOnPermiseInfo,
} from 'ui/styled/home.styled';
import { Button } from 'library/button/Button';
import check from 'assets/img/checkedItem.svg';
import IncrementalInputComponent from 'library/incrementalInput/IncrementalInput';
import infoIcon from 'assets/img/info.svg';
import { payTypes } from 'constants/global.constants';
import { focusonFormsGroup } from 'utils/helpers';
import api from 'services/api';
import { htmlParser } from 'utils/htmlUtils';
import { CartIcon } from 'assets/svgIcons/CartIcon';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { addCartLastItem } from 'store/reducers/app/app.slice';

const AddToCartComponent = (props) => {
  const {
    selectedProduct,
    closeModal,
    getCartData,
    cartData,
    setIsCartOpen,
    setIsOpen,
    token,
    selectedProductFromBlogs,
  } = props;
  const distpath = useDispatch();
  const [selectedType, setSelectedType] = useState(payTypes.ANNUAL);
  const [selectedKey, setSelectedKey] = useState();
  const [value, setValue] = useState(1);
  const [valueError, setValueError] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  const productToUse = selectedProductFromBlogs || selectedProduct;

  useEffect(() => {
    if (productToUse) {
      const res = productToUse?.attributes?.prices?.find(
        (item) => item.period === 'Y1',
      );
      setSelectedKey(res?.productKey);
    }
  }, [productToUse]);

  const findItemQuantityInCart = (key) => {
    const res = cartData.find((item) => item.product.productKey === key);
    if (res && res.quantity) {
      return Number(res.quantity);
    }
    return 0;
  };

  const addToCartHandler = async () => {
    if (!token) {
      setIsOpen(true);
      closeModal();
      return;
    }
    getCartData();
    setValueError(false);
    if (value === 0) {
      setValueError(true);
      return;
    }
    closeModal();
    focusonFormsGroup('header');

    const quantityValue = findItemQuantityInCart(selectedKey) + Number(value);
    try {
      await api.cart.addItemToCart(selectedKey, quantityValue);
      distpath(addCartLastItem(selectedKey));
      getCartData();
      setTimeout(() => setIsCartOpen(true), 500);
      focusonFormsGroup('bottomCart');
    } catch (e) {
      console.error(e);
    }
  };

  const selectTypeHanlder = (type) => {
    setSelectedType(type);
    setValue(1);
  };

  const filredPremiseData = productToUse?.attributes?.prices.find(
    (item) => item.period === payTypes.ON_PREMISE,
  );

  const pricesToRender = productToUse?.attributes?.prices.filter(
    (item) => !item.isDemo,
  );

  useEffect(() => {
    if (
      (pricesToRender && pricesToRender.length === 1) ||
      (pricesToRender && pricesToRender.length === 2)
    ) {
      setSelectedType(payTypes.MONTHLY);
    }
  }, []);

  return (
    <AddToCartComponentStyled>
      <AddToCartModalTitle>
        <div className="title headline4">{productToUse?.attributes?.name}</div>
        <div className="subTitle bodyL">
          {productToUse?.attributes?.modal?.subTitle}
        </div>
      </AddToCartModalTitle>
      <AddToCartModalBody>
        <AddToCartTypesContainer
          style={{
            justifyContent:
              pricesToRender && pricesToRender.length === 1
                ? 'center'
                : pricesToRender && pricesToRender.length === 2
                ? 'space-evenly'
                : undefined,
          }}
        >
          {pricesToRender?.map((item, index) => {
            return (
              <AddToCartModalType
                onPremise={item.period === payTypes.ON_PREMISE}
                key={index + item.productKey}
                className={item.period === selectedType ? 'active' : ''}
                onClick={() => {
                  setSelectedKey(item.productKey);
                  selectTypeHanlder(item.period);
                }}
              >
                <div className="type">{item.title}</div>
                <div className="price">{`₾ ${item.price}`}</div>
                {item.period === payTypes.ANNUAL && item.sale ? (
                  <div className="offer">
                    {isTabletOrMobile ? undefined : (
                      <span>{productToUse?.attributes?.modal?.bestOffers}</span>
                    )}

                    <span className="sale">{item.sale}</span>
                  </div>
                ) : null}
                <div className="active-check">
                  <img src={check} alt="active" />
                </div>
              </AddToCartModalType>
            );
          })}
        </AddToCartTypesContainer>

        {selectedType === payTypes.ON_PREMISE && filredPremiseData?.info ? (
          <AddToCartOnPermiseInfo className="bodyS">
            <div className="left">
              <img src={infoIcon} alt="info" />
            </div>
            <div className="right">
              <div className="top">{htmlParser(filredPremiseData?.info)}</div>
              {/* <div className="bottom">
                In case of premise you need your own server
              </div> */}
            </div>
          </AddToCartOnPermiseInfo>
        ) : null}
        <AddToCartProductQyt>
          <div className="left">
            {productToUse?.attributes?.modal?.quantity}
          </div>
          <div className="right">
            <IncrementalInputComponent
              width="100%"
              height="48px"
              value={value}
              errorColor={valueError}
              setValueError={setValueError}
              setValue={setValue}
            />
          </div>
        </AddToCartProductQyt>
      </AddToCartModalBody>
      <AddToCartModalFoot>
        <Button
          background="#FFDC04"
          color="#242333"
          padding="23px 50px"
          fontWeight="700"
          width="100%"
          height="50px"
          onClick={addToCartHandler}
        >
          <CartIcon style={{ marginRight: '12px' }} />
          {productToUse?.attributes?.modal?.btn}
        </Button>
      </AddToCartModalFoot>
    </AddToCartComponentStyled>
  );
};

export default AddToCartComponent;
