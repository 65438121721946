import React from 'react';
export const CartIcon = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 22 20"
    {...rest}
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_5934"
          data-name="Rectangle 5934"
          width="22"
          height="20"
          transform="translate(1176 1728)"
          fill="none"
        />
      </clipPath>
    </defs>
    <g
      id="Mask_Group_15"
      data-name="Mask Group 15"
      transform="translate(-1176 -1728)"
      clip-path="url(#clip-path)"
    >
      <g
        id="Group_52949"
        data-name="Group 52949"
        transform="translate(1176 1729.146)"
      >
        <path
          id="Path_61372"
          data-name="Path 61372"
          d="M131.816,307.8h-1.648v-1.724h.26c1.025,0,2.05,0,3.075,0,.182,0,.359-.217.359-.217l3.832-6.19,1.535.767-3.543,5.617h10.957l-3.542-5.615,1.533-.767,3.837,6.2s.168.212.342.21c1.032-.008,2.065,0,3.1,0h.258v1.715H150.5c-.06.348-.118.684-.175,1.019-.4,2.394-.791,4.79-1.2,7.182a2.528,2.528,0,0,1-2.415,2.122c-.079,0-.158.007-.236.007q-5.312,0-10.624,0a2.566,2.566,0,0,1-2.668-2.219c-.07-.373-.126-.748-.188-1.123q-.565-3.4-1.131-6.8C131.855,307.923,131.835,307.869,131.816,307.8Zm1.777.011c.006.077.007.133.016.188q.636,3.812,1.274,7.625a.846.846,0,0,0,.9.774q5.385,0,10.771,0a.848.848,0,0,0,.894-.781q.552-3.3,1.1-6.608c.066-.393.128-.788.194-1.2Z"
          transform="translate(-130.168 -299.673)"
          fill="#242333"
          stroke="#242333"
          strokeWidth="0.3"
        />
        <path
          id="Path_61374"
          data-name="Path 61374"
          d="M249.851,414.311v5.127h-1.7v-5.127Z"
          transform="translate(-239.864 -404.448)"
          fill="#242333"
          stroke="#242333"
          strokeWidth="0.3"
        />
        <path
          id="Path_61375"
          data-name="Path 61375"
          d="M289.849,419.455h-1.693v-5.134h1.693Z"
          transform="translate(-275.496 -404.457)"
          fill="#242333"
          stroke="#242333"
          strokeWidth="0.3"
        />
      </g>
    </g>
  </svg>
);
