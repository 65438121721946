import parse from 'html-react-parser';

const getSymbolCount = (text, symbol) =>
  (text.match(new RegExp(symbol, 'g')) || []).length;

export const validateHTML = (text) => {
  const openingTagsCount = getSymbolCount(text, '<');
  const closingTagsCount = getSymbolCount(text, '>');
  return openingTagsCount === closingTagsCount;
};

export const htmlParser = (dataToParse) =>
  validateHTML(dataToParse || '') ? parse(dataToParse || '') : '';
export const parseHTML = (html, opts) => {
  return parse(String(html || '').replace(/&nbsp;/g, ''), opts);
};
