import React from 'react';
import { SolutionSliderCompWrapper } from 'ui/styled/home.styled';
import { imagesBaseUrl } from 'constants/global.constants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';

const SolutionSliderComp = (props) => {
  const isLarge = useMediaQuery({ minWidth: 1421 });
  const isMedium = useMediaQuery({ minWidth: 901 });
  const isSmall = useMediaQuery({ maxWidth: 900 });
  const { data } = props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const imageSizeHanlder = () => {
    if (isLarge) {
      return 'LG';
    }
    if (isMedium) {
      return 'MD';
    }
    if (isSmall) {
      return 'XS';
    }
  };

  return data && data.length > 0 ? (
    <SolutionSliderCompWrapper>
      <Slider {...settings}>
        {data.map((item, index) => {
          return item.LG.data && item.MD.data && item.XS.data ? (
            <div
              key={item.id + index}
              className="carouselItem"
              id={imageSizeHanlder()}
            >
              {item.url && item.url.length > 0 ? (
                <a href={item.url} target="_blank">
                  <img
                    src={`${imagesBaseUrl}${
                      item[imageSizeHanlder()]?.data[0]?.attributes.url
                    }`}
                  />
                </a>
              ) : (
                <div className="bannerWithoutUrl">
                  <img
                    src={`${imagesBaseUrl}${
                      item[imageSizeHanlder()]?.data[0]?.attributes.url
                    }`}
                  />
                </div>
              )}
            </div>
          ) : null;
        })}
      </Slider>
    </SolutionSliderCompWrapper>
  ) : null;
};

export default SolutionSliderComp;
