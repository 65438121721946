import React from 'react';

export const CheckIconGreen = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.622"
    height="10.71"
    viewBox="0 0 15.622 10.71"
  >
    <g id="mail" transform="translate(-1000.579 -354.215)">
      <path
        id="Path_11044"
        data-name="Path 11044"
        d="M0,0,7.207,7.023,2.618,12.089"
        transform="translate(1014.434 355.983) rotate(90)"
        fill="none"
        stroke="#46db85"
        stroke-linecap="round"
        stroke-width="2.5"
      />
    </g>
  </svg>
);

export default CheckIconGreen;
