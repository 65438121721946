// @ts-nocheck

import store from 'store/configureStore';
import { logout } from 'store/reducers/user/user.actions';
import cacheManager from 'utils/cacheManager';
import { getErrorObjectFromBlob } from 'utils/helpers';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const apiHeaderNameResponse = {
  AUTH_TOKEN: 'authorization',
};
export const apiHeaderNameRequest = {
  AUTH_TOKEN: 'Authorization',
};

export const TOKEN_NAME = 'token';

const token = cacheManager(TOKEN_NAME).get();

const instance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    common: {
      [apiHeaderNameRequest.AUTH_TOKEN]: `Bearer ${token}`,
    },
  },
});

export const setAuthHeader = (token) => {
  instance.defaults.headers.common[
    apiHeaderNameRequest.AUTH_TOKEN
  ] = `Bearer ${token}`; // set on getCurrentUser success action
};

export const deleteAuthHeader = () => {
  delete instance.defaults.headers.common[apiHeaderNameRequest.AUTH_TOKEN]; // remove on logout action
};

// interceptors
const responseSuccessInterceptor = (response) => {
  const token = response.headers[apiHeaderNameRequest.AUTH_TOKEN];
  if (token) {
    setAuthHeader(token);
  }
  return response;
};

const responseErrorInterceptor = async (error) => {
  // custom handler for all calls
  if (error.response !== undefined) {
    if (error.response.status === 401) {
      // logout user from imported store
      store.dispatch(logout(false, true));
    } else {
      let errorObj = error;
      if (
        error.request.responseType === 'blob' &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().includes('json')
      ) {
        try {
          errorObj = await getErrorObjectFromBlob(error).then();
        } catch (e) {
          console.error('error converting:', e, errorObj);
        }
      }
    }
  }

  return Promise.reject(error.response?.data);
};

instance.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor,
);

export const get = instance.get;

export const post = instance.post;

export const put = instance.put;

export const del = instance.delete;

export default instance;
