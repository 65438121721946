import React, { useState } from 'react';
import {
  AccordionContent,
  AccordionIcon,
  FaqAccordionsTrigger,
  FAqAccordionTitle,
  FAQNavWrapper,
} from 'ui/styled/FaqStyled';

import Accordion from 'library/Accordion/Accordion';
import { htmlParser } from 'utils/htmlUtils';
import { HideIcon } from 'assets/svgIcons/HideIcon';
import { AddIcon } from 'assets/svgIcons/AddIcon';
import EmptyTransactionsInfo from 'ui/components/common/EmptyTransactionsInfo';

const FaqAccordions = (props) => {
  const { accordionData, searchValue, notFoundData } = props;

  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  const dataToRender = accordionData.map((item) => item.block).flat();
  const filtredData = dataToRender.filter(
    (item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.text.toLowerCase().includes(searchValue.toLowerCase()),
  );

  return (
    <>
      <FAQNavWrapper>
        {!filtredData || filtredData.length === 0 ? (
          <EmptyTransactionsInfo notFoundData={notFoundData} />
        ) : (
          filtredData?.map((item, index) => {
            return (
              <Accordion
                key={index + item.title}
                trigger={
                  <FaqAccordionsTrigger onClick={() => toggle(index)}>
                    <AccordionIcon>
                      <span className="icon">
                        {clicked === index ? (
                          <HideIcon style={{ marginRight: '53px' }} />
                        ) : (
                          <AddIcon style={{ marginRight: '53px' }} />
                        )}
                      </span>
                    </AccordionIcon>
                    <AccordionContent>
                      <div
                        className="Collapsible"
                        onClick={() => toggle(index)}
                      >
                        <FAqAccordionTitle className="headline4">
                          {item.title}
                        </FAqAccordionTitle>
                      </div>
                      <div
                        style={{
                          lineHeight: '28px',
                          color: '#8A87A5',
                          fontSize: '16px',
                        }}
                        className={
                          clicked === index
                            ? 'acoordionContent contentShow'
                            : 'acoordionContent content'
                        }
                      >
                        {htmlParser(item.text)}
                      </div>
                    </AccordionContent>
                  </FaqAccordionsTrigger>
                }
              />
            );
          })
        )}
      </FAQNavWrapper>
    </>
  );
};

export default FaqAccordions;
