import React from 'react';
import {
  ErrorAndSuccessModalIcon,
  ErrorAndSuccessModalStyled,
  ErrorAndSuccessModalSubTitle,
  ErrorAndSuccessModalTitle,
} from 'ui/styled/cartPage.styled';
import successIcon from 'assets/img/successIcon.svg';
import errorIcon from 'assets/img/errorIcon.svg';

const ErrorAndSuccessModal = (props) => {
  const { isError = false, title, subTitle } = props;
  return (
    <ErrorAndSuccessModalStyled>
      <ErrorAndSuccessModalIcon>
        {isError ? (
          <img src={errorIcon} alt="Error" />
        ) : (
          <img src={successIcon} alt="Success" />
        )}
      </ErrorAndSuccessModalIcon>
      <ErrorAndSuccessModalTitle className="headline4">
        {title}
      </ErrorAndSuccessModalTitle>
      <ErrorAndSuccessModalSubTitle className="bodyL">
        {subTitle}
      </ErrorAndSuccessModalSubTitle>
    </ErrorAndSuccessModalStyled>
  );
};

export default ErrorAndSuccessModal;
