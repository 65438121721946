import React from "react";
export const EmailSuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="78.689"
    height="78.689"
    viewBox="0 0 78.689 78.689"
  >
    <g id="mail" transform="translate(-942.311 -335.311)">
      <path
        id="Path_86"
        data-name="Path 86"
        d="M39.345,0A39.345,39.345,0,1,1,0,39.345,39.345,39.345,0,0,1,39.345,0Z"
        transform="translate(942.311 335.311)"
        fill="#ffdc04"
      />
      <path
        id="Path_11044"
        data-name="Path 11044"
        d="M0,0,11.887,11.584,4.319,19.939"
        transform="translate(992.767 370.092) rotate(90)"
        fill="none"
        stroke="#242333"
        strokeLinecap="round"
        strokeWidth="5"
      />
    </g>
  </svg>
);
