import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import api from 'services/api';
import { Button } from 'library/button/Button';
import {
  LoginWrapper,
  LoginWelcomeText,
  LoginStyled,
  RegisterRowItem,
  RegisterRowFull,
  ForgotPassDesc,
  PasswordRequirementsForAuth,
  ForgetPassTextWrapper,
  BtnGroupMobile,
  FormFieldsErrorWrapper,
} from 'ui/styled/auth.styled';
import { authTypes } from '../Auth';
import { getTextFromDataByKey } from 'utils/helpers';
import FormErrorComponent from 'ui/components/common/FormError';
import FormInput from 'ui/shared/formComponents/FormInput';
import { isValidPassword } from 'utils/validator';

const ForgotPasswordForm = (props) => {
  const { resetPassUserEmail, resetKey, setAuthType, passwordRecoveryData } =
    props;
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();
  const newPasswordValue = watch('newPassword');

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);

  const onSubmit = async (data) => {
    setFormError(null);
    setLoading(true);

    const resetKeyVal = localStorage.getItem('resetKey');
    const userNameVal = localStorage.getItem('username');

    try {
      await api.auth.resetPassword(data.newPassword, resetKeyVal, userNameVal);
      setAuthType(authTypes.forgetSuccess);
      localStorage.setItem('authType', authTypes.forgetSuccess);
    } catch (e) {
      setFormError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setFormError(null);
    };
  }, []);

  const checkForNumbers = () => {
    if (!newPasswordValue) return;
    var matches = newPasswordValue.match(/\d+/g);

    return matches != null ? true : false;
  };

  const checkForUpperCase = () => {
    if (!newPasswordValue) return;
    var matches = newPasswordValue.match(/[A-Z]/);
    let matchesLower = newPasswordValue.match(/[a-z]/);
    return matches != null && matchesLower != null ? true : false;
  };

  const checkForOnlySymbols = () => {
    if (!newPasswordValue) return;
    var symbols = new RegExp(/[^A-Z a-z0-9]/);
    return symbols.test(newPasswordValue) ? true : false;
  };

  const checkForMinCharacters = () => {
    if (!newPasswordValue) return;
    return newPasswordValue.length >= 8 ? true : false;
  };

  return (
    <LoginWrapper>
      <LoginWelcomeText forgetForm className="headline4">
        {getTextFromDataByKey('title', passwordRecoveryData)}
      </LoginWelcomeText>
      <ForgotPassDesc className="bodyL">
        {getTextFromDataByKey('subTitle', passwordRecoveryData)}
      </ForgotPassDesc>
      {formError ? <FormErrorComponent error={formError} /> : null}
      <LoginStyled forgotForm>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ForgetPassTextWrapper>
            <RegisterRowFull>
              <RegisterRowItem errors={errors.newPassword}>
                <Controller
                  rules={{ required: 'Is Required', validate: isValidPassword }}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="password"
                      label={getTextFromDataByKey(
                        'newPassword',
                        passwordRecoveryData,
                      )}
                      borderColor={errors.newPassword}
                    />
                  )}
                  control={control}
                  name="newPassword"
                />
                {errors.newPassword ? (
                  <FormFieldsErrorWrapper>
                    {getTextFromDataByKey('onlyLatins', passwordRecoveryData)}
                  </FormFieldsErrorWrapper>
                ) : null}
              </RegisterRowItem>
            </RegisterRowFull>
            <RegisterRowFull>
              <RegisterRowItem
                errors={errors.repeatPassword}
                className="lastInForget"
              >
                <Controller
                  rules={{
                    required: 'Is Required',
                    validate: (value) =>
                      value !== newPasswordValue
                        ? 'passwords do not match'
                        : undefined,
                  }}
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="password"
                      label={getTextFromDataByKey(
                        'repeatPassword',
                        passwordRecoveryData,
                      )}
                      borderColor={errors.repeatPassword}
                    />
                  )}
                  control={control}
                  name="repeatPassword"
                />
                {errors.repeatPassword ? (
                  <FormFieldsErrorWrapper>
                    {getTextFromDataByKey('onlyLatins', passwordRecoveryData)}
                  </FormFieldsErrorWrapper>
                ) : null}
              </RegisterRowItem>
            </RegisterRowFull>
            <PasswordRequirementsForAuth
              className="bodyS forgotForm"
              margin="6px 0px 64px 0px"
            >
              <div>
                <span
                  className={
                    checkForUpperCase()
                      ? 'isValid'
                      : newPasswordValue &&
                        newPasswordValue.length > 0 &&
                        !checkForUpperCase()
                      ? 'inValid'
                      : ''
                  }
                >
                  {getTextFromDataByKey(
                    'bulletUppercase',
                    passwordRecoveryData,
                  )}
                </span>
                <span
                  className={
                    checkForNumbers()
                      ? 'isValid'
                      : newPasswordValue &&
                        newPasswordValue.length > 0 &&
                        !checkForNumbers()
                      ? 'inValid'
                      : ''
                  }
                >
                  {getTextFromDataByKey('bulletNumber', passwordRecoveryData)}
                </span>
                <span
                  className={
                    checkForOnlySymbols()
                      ? 'isValid'
                      : newPasswordValue &&
                        newPasswordValue.length > 0 &&
                        !checkForOnlySymbols()
                      ? 'inValid'
                      : ''
                  }
                >
                  {getTextFromDataByKey('bulletSymbol', passwordRecoveryData)}
                </span>
                <span
                  className={
                    checkForMinCharacters()
                      ? 'isValid'
                      : newPasswordValue &&
                        newPasswordValue.length > 0 &&
                        !checkForMinCharacters()
                      ? 'inValid'
                      : ''
                  }
                >
                  {getTextFromDataByKey(
                    'bulletMinCharacters',
                    passwordRecoveryData,
                  )}
                </span>
              </div>
            </PasswordRequirementsForAuth>
          </ForgetPassTextWrapper>

          <BtnGroupMobile>
            <Button loading={loading} className="submit">
              {getTextFromDataByKey('ChangePasswordBtn', passwordRecoveryData)}
            </Button>
          </BtnGroupMobile>
        </form>
      </LoginStyled>
    </LoginWrapper>
  );
};

export default ForgotPasswordForm;
