import React from 'react';
import {
  HeadingStyled,
  TextStyled,
  ButtonWrapper,
  ParagraphStyled,
  SearchFormWrapper,
  TopParagraphWithInputStyled,
} from '../../styled/topParagraphWithInputStyled';
// import video from 'assets/img/video.svg';
import { Button } from 'library/button/Button';
import { authTypes } from '../Auth/Auth';
import { htmlParser } from 'utils/htmlUtils';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/reducers/user/user.slice';

const TopParagraphWithInput = (props) => {
  const isUserLoggedIn = useSelector(userSelectors.selectIsUserLoggedIn);

  const { title, description, videoPlayHandler, registerButton } = props;

  return (
    <TopParagraphWithInputStyled>
      <ParagraphStyled>
        <HeadingStyled className="headline1"> {title} </HeadingStyled>
        <TextStyled className="bodyL"> {htmlParser(description)}</TextStyled>
      </ParagraphStyled>
      {isUserLoggedIn ? null : (
        <SearchFormWrapper>
          <ButtonWrapper>
            <Button
              padding="25px 87px"
              background="#FFDC04"
              color="#242333"
              fontWeight="700"
              onClick={() => {
                localStorage.setItem('authType', authTypes.register);
                props.setIsOpen(true);
              }}
            >
              {/* to Do translate from strapi */}
              {registerButton}
            </Button>
          </ButtonWrapper>
        </SearchFormWrapper>
      )}
    </TopParagraphWithInputStyled>
  );
};

export default TopParagraphWithInput;
