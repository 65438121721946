import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import api from 'services/api';
import { Button } from 'library/button/Button';
import {
  LoginWrapper,
  LoginWelcomeText,
  LoginStyled,
  RegisterRowItem,
  RegisterRowFull,
  ForgotPassDesc,
  BtnGroupMobile,
} from 'ui/styled/auth.styled';
import { authTypes } from '../Auth';
import { getTextFromDataByKey } from 'utils/helpers';
import FormErrorComponent from 'ui/components/common/FormError';
import FormInputBasic from 'ui/shared/formComponents/FormInputBasic';

const ForgotPassword = (props) => {
  const {
    setAuthType,
    closeAuthModal,
    setUserName,
    forgotPasswordData,
    errorTranslatesData,
  } = props;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);

  const onSubmit = async (data) => {
    setFormError(null);
    setLoading(true);
    setUserName(data.email);
    localStorage.removeItem('otp');
    localStorage.removeItem('username');
    localStorage.removeItem('resetKey');
    try {
      await api.auth.sendPasswordReset(data.email);
      setAuthType(authTypes.forgotVerify);
      localStorage.setItem('authType', authTypes.forgotVerify);

      closeAuthModal();
    } catch (e) {
      setFormError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setFormError(null);
    };
  }, []);

  return (
    <LoginWrapper className="forget">
      <LoginWelcomeText className="headline4">
        {getTextFromDataByKey('title', forgotPasswordData)}
      </LoginWelcomeText>
      <ForgotPassDesc className="bodyL forgetPas">
        {getTextFromDataByKey('subTitle', forgotPasswordData)}
      </ForgotPassDesc>
      {formError ? (
        <FormErrorComponent
          error={formError}
          errorTranslatesData={errorTranslatesData}
        />
      ) : null}
      <LoginStyled>
        <form onSubmit={handleSubmit(onSubmit)}>
          <RegisterRowFull>
            <RegisterRowItem>
              <Controller
                render={({ field }) => (
                  <FormInputBasic
                    {...field}
                    type="email"
                    placeholder={getTextFromDataByKey(
                      'email',
                      forgotPasswordData,
                    )}
                    borderColor={errors.email}
                  />
                )}
                rules={{
                  required: 'Is Required',
                }}
                control={control}
                name="email"
              />
            </RegisterRowItem>
          </RegisterRowFull>
          <BtnGroupMobile>
            <Button loading={loading} className="submit">
              {getTextFromDataByKey('sendBtn', forgotPasswordData)}
            </Button>
          </BtnGroupMobile>
        </form>
      </LoginStyled>
    </LoginWrapper>
  );
};

export default ForgotPassword;
