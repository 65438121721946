import styled from 'styled-components';

export const SliderWrapper = styled.div`
  margin: 101px 0px 80px 0px;
  padding: 0px 14%;
  /* h2 {
    font-size: 44px;
    color: #1b1d46;
    margin-bottom: 55px;
  } */
  .slider-title {
    margin-bottom: 50px;
    font-weight: 500;
    color: #242333;
  }
  @media (max-width: 1540px) {
    padding: 0 8%;
    margin-top: 0px;
  }
  @media (max-width: 900px) {
    .slider-title {
      margin-bottom: 26px;
      font-weight: bold;
      color: #3a3853;
      font-size: 22px;
    }
  }
`;
export const CarouselArrow = styled.div`
  display: inline-flex;
  position: relative;
  bottom: 50px;
`;

export const CarouselContainer = styled.div`
  position: relative;

  &.news-carousel {
    /* margin-left: calc(16.7% - 34px); */
    margin-right: 0;
  }

  .news-carousel-item {
    /* padding: 0 34px; */
  }

  .react-multi-carousel-item {
  }
  @media (max-width: 1685px) {
    /* min-width: 1290px; */
  }

  @media (max-width: 900px) {
    .react-multi-carousel-item {
      min-width: 0;
    }
  }
`;
export const CarouselButtons = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  margin: 0 -85px;
  height: 0;
  width: calc(100% + 170px);
  top: 50%;
  transform: translate(0%, -50%);

  &.news-buttons {
    margin: unset;
    height: 0;
    width: auto;
    top: unset;
    transform: unset;
    right: 34px;
    top: -84px;

    .right-arrow {
      margin-left: 20px;
    }
  }

  &.related-buttons {
    top: 25px;
    right: 26px;
  }

  &.tags-buttons {
    .arrow {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #adaec9;
      border-radius: 18px;
    }
  }
  @media (max-width: 900px) {
    &.news-buttons {
      margin: unset;
      height: 0;
      width: auto;
      top: unset;
      transform: unset;
      right: 72%;
      bottom: -50px;

      .right-arrow {
        margin-left: 20px;
      }
    }
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
  .icon-color-1 {
    opacity: 0.5;
  }
  .icon-color-2 {
    &:hover path {
      fill: #242333;
      fill-opacity: 1;
    }
    &:hover #Ellipse_357 {
      stroke: #242333;
    }
  }
`;
export const NewsSliderItem = styled.div`
  color: #8a87a5;
  font-weight: 400;
  line-height: 28px;
  p {
    margin: 0%;
  }
  @media (max-width: 900px) {
    margin-bottom: 26px;
  }
`;
