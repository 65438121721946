import { ButtonStyled } from 'library/button/Buttons.styled';
import { IncrementalInputCounter } from 'library/incrementalInput/incrementalInput.styled';
import { InputStyled } from 'library/input/inputStyled';
import { PaginationButtonsConatainerStyled } from 'library/Pagination/paginationStyled';
import {
  ItemDeleteOrPayNow,
  TableWrapper,
} from 'library/table/TableWrapperStyled';
import { Tab } from 'library/tabsScrollSpy/tab.styled';
import { createGlobalStyle } from 'styled-components';
import FormCheckBoxState from 'ui/components/formCheckbox/formCheckbox-formState';
import { CheckboxLabelStyled } from 'ui/components/formCheckbox/formCheckbox.styled';
import { OurPartnersText } from 'ui/components/outPartners/ourPartners.styled';
import {
  FooterInfoTitle,
  FooterNavigationWrapper,
} from 'ui/shared/footer/footer.styled';
import {
  CartListItemLeft,
  DropListItem,
  GoToCartButton,
  HeaderArrow,
  HeaderStyled,
  LanguageChooserWrapper,
  MobileLanguageItem,
  MobileMenuNav,
  MobileUserName,
} from 'ui/shared/header/header.styled';
import {
  BottomSignInfo,
  LoginWelcomeText,
  RegisterRowFull,
  RegisterRowHalf,
  RegisterRowItem,
  VerifyDesc,
  VerifyDescBottomSheet,
  VerifyIconBlock,
  VerifyTitle,
  VerifyTitleBottomSheet,
} from './auth.styled';
import { BillingModalProductWrapper } from './billingModalStyled';
import {
  CartListProducts,
  CartListTypePayment,
  CartListTypeTitle,
  CartPageClearCart,
  CartPageTitle,
  CheckoutFormGroup,
  CheckoutFormRow,
  CheckoutFormTitle,
  ConfirmDeleteModalSubTitle,
  ConfirmDeleteModalTitle,
  ErrorAndSuccessModalSubTitle,
  ErrorAndSuccessModalTitle,
  FormRowLeft,
  FormRowRight,
  MobileCartOverlayNextBtn,
  TrainingModalFormGroup,
} from './cartPage.styled';
import { CurrentProductsTitle } from './currentProducts.styled';
import { EmptyPageText } from './emptyTransactionsInfoStyled';
import {
  FaqInputWrapper,
  FaqMobileHead,
  FAQNavWrapper,
  FaqParagraphTitle,
} from './FaqStyled';
import {
  HardwareParagraphTitle,
  HarwdarePlanParagraph,
  HarwdarePlanTitle,
  InfoDescriptionBlock,
  InfoNoteBlock,
} from './hardware.styled';
import {
  AddToCartModalTitle,
  AddToCartModalType,
  AddToCartProductQyt,
  DemoModalFormStyled,
  DemoModalStyled,
} from './home.styled';
import {
  GlobalErrorContanctInfo,
  GlobalErrorSubTitle,
  GlobalErrorTitle,
} from './layout';
import { PersonalInfoDetails } from './PersonalInfoForm.styled';
import { ItemsList, PlanItemTitle } from './PlansSection.styled';
import {
  ServicesPlanListWrapper,
  ServicesPlanMobileWrapper,
  ServicesPlanTitle,
} from './servicesPlansStyled';
import { ServicesParagraphTitle } from './ServicesStyled';
import { ScrollSpyNav, TermsHeaderInner } from './terms.styled';
import { HeadingStyled, TextStyled } from './topParagraphWithInputStyled';
import { ProductDetails, ProductNames } from './transactionsModalStyled';
import {
  TableWrapperTransactions,
  TransactionHistoryMobileHead,
  TransactionTableTitle,
} from './transactionsTable.styled';
import { ChangeProperties } from './UserProfile.styled';
import { BlogParagraphListTitleStyled } from './BlogPageStyled';
import { GreyHeadingStyled } from './BlogParagraphStyled';
import { BlogMiddleContTitle } from './BlogsMiddleInfoContainerStyled';
import { BlogsSliderTitle, LeftSliderTitle } from './BlogsParallaxInfoStyled';

export const GlobalStyle = createGlobalStyle`
html {
    height: 100%;
    line-height: 1.15; 
    -webkit-text-size-adjust: 100%; 
    scroll-behavior: smooth;
  }

 #root{
   height:100%;
   overflow-x: hidden;
   @media(max-width:900px){
    overflow-x: hidden;
     scrollbar-width: none;
     -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
   
 }
   * {
    opacity: 1;
    letter-spacing: 0px;
    box-sizing: border-box;
    
    ::-webkit-scrollbar-track {
      border-radius: 0;
      background-color: #f9f9f97a;
    }

    ::-webkit-scrollbar {
      width: 8px;
      background-color: #d9d9eb;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #d1d1de;
    }
    // for mozilla
    scrollbar-width: auto;
    scrollbar-color: inherit;
  }
  .react-datepicker__close-icon::after{
    background-color: transparent !important;
    color:#3a3853 !important;
    font-weight: bold;
    font-size: 21.5px !important;

  }
  body {
    margin: 0;
    height: 100%;
    font-size: 18px;
    transition:all 0.3s;
    scroll-behavior: smooth;
    overflow-x: hidden;
    font-family: ${({ lang }) =>
      lang === 'ka' ? 'Helvetica Neue LT GEO' : `'Ubuntu', sans-serif`};
    font-weight: 400;
   }
   input, button, table{
    font-family: ${({ lang }) =>
      lang === 'ka' ? 'Helvetica Neue LT GEO' : `'Ubuntu', sans-serif`};
   }
   .react-tabs__tab{
    font-size: ${({ lang }) => (lang === 'ka' ? '21px' : '24px')};
    @media (max-width:900px) {
      font-size: ${({ lang }) => (lang === 'ka' ? '18px' : '18px')};
     }
   }
 .billing {
  overflow: visible;
    padding:36px 69px;
    @media (max-width: 900px) {
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding: 48px 34px;
    }
 }
   .transactions {
    overflow: visible;
    padding:35px 52px;
    @media (max-width: 900px) {
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding: 48px 34px;
      }
  }

  .logo-main{
    @media (max-width: 900px) {
      width: 131px;
    }
  }
  .transactions .emptyBg {
    position: absolute;
    bottom: -60px;
    left: 0;
    right: 0;
    @media (min-height: 890px) {
      bottom: -82px;
      }
      @media (min-height: 1161px) {
      bottom: -80px;
       }
      @media (min-height: 1238px) {
      bottom: -95px;
        }
       @media (min-height: 1858px) {
      bottom:  -138px;
       }
   }
   .errorLabel {
    color: #f55e1b !important;
  }

   .fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 3;
}

.hardware-modal{
  padding: 70px 80px;
  @media (max-width:900px){
    padding: 0px;
     height: -webkit-fill-available;
     border-radius: 0px;
     display: flex;
     justify-content: space-around;
    }  
}
  .close_icon{
    top:44px
    svg{
      width: 34px;
      height: 34px;
    }
  @media (max-width:900px){
    top:40px;
    right: 40px !important;
    }
  @media (max-height:600px){
    top: 17px;
    right: 30px !important;
    } 
  }
  .orders-pagination{
    @media (max-width:900px){
      justify-content: center;
      padding: 0;
      margin-top: 90px;
      padding-bottom: 20px;
      .pagination {
        width: 34px;
        height: 34px;
      }
      svg{
        width: 8px;
      }
    }
  }
 .pay-confirm {
    padding: 52px 80px 70px 80px !important;
  }
  .addCard-modal{
    padding: 40px 60px 84px 60px !important;
    @media (max-width:900px){
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding:70px 35px 60px 35px !important;
    }
    .close_icon{
      right:38px;
    }
  }
.trainings-modal {
    padding: 40px 86px 84px 86px !important;
    @media (max-width:900px){
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding:70px 35px 60px 35px !important;
    }
    .close_icon{
      right:38px;
    }
  }
.homeAddcartModal {
    @media (max-width:900px){
      padding: 0px;
      height: -webkit-fill-available;
      border-radius: 0px;
      display: flex;
      justify-content: space-around;
  }  
}

.authModalResp {
    @media (max-width:900px){
      padding: 64px 34px 52px 34px;
      height: 100%;
      border-radius: 0px;
  }  
  @media (max-width:320px){
      padding: 30px 24px 14px 24px;
      margin: 5px 0 5px 0;
      border-radius: 0px;
      height:100%;
   }
   @media (max-height:700px){
      padding: 64px 24px 52px 24px;
      margin: 5px 0 5px 0;
      border-radius: 0px;
      height:100%;
   }
   @media (max-height:500px){
      padding: 42px 24px 10px 24px;
      margin: 5px 0 5px 0;
      border-radius: 0px;
      height:100%;
   }
   .close_icon{
      right:40px;
    }
}
.mobileCheckoutForm{
  width: 100%;
  height: 100%;
  border-radius: 0px;
  padding: 71px 34px 46px 34px;
}
.demoAddModal {
    @media (max-width:900px){
      padding: 71px 34px 52px 34px;
      height: -webkit-fill-available;
      border-radius: 0px;
   }  
   @media (max-height:600px){
    padding: 45px 24px 15px 24px;
    height: -webkit-fill-available;
    border-radius: 0px;
   }  
}
  .main{
    position: relative;
    @media (max-width:900px){
      /* height:100%; */
      position: static !important;
    }
  }
.successPass{
  padding: 0;
}
  .youtube-modal{
    background: transparent;
    box-shadow: none;
    overflow: hidden;
    @media (max-width:900px){
      height: 35%;
      padding: 0 22px;
    }
  }
.slick-slider div{
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
 .main {
  padding-top: 125px;
  position: relative;
}

.admin-details-modal{
  padding: 50px 80px;
  overflow: visible;
}
.mobileMenu{
  padding: 0;
  width: 100%;
  height:100%;
  border-radius: 0;
  .close_icon{
    right:40px
  }
}

@media(max-width:900px){
 .main {
  padding-top: 79px;
     scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
}

   
   // language changes:

   ${InputStyled}{
    font-size: 14px; 
   };
   ${RegisterRowFull}{ 
    font-size: 16px; 
      input{
        font-size: 14px;
      }
  };
  ${RegisterRowHalf}{ 
    font-size: 16px; 
      input{
        font-size: 14px;
     }
     @media (max-width:900px) {
      font-size: 14px;
      input{
        font-size: 14px !important;
     }
    }
  };
  ${BottomSignInfo}{
    font-size: 16px;
    @media (max-width:900px) {
     font-size: ${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
    }
  };

  ${GreyHeadingStyled}{
    .addit-title-grey {
      font-size: 14px;
      font-family: ${({ lang }) =>
        lang === 'ka' ? 'Helvetica Caps' : undefined};
      display: flex;
      align-items: center;
      color: #3a3853;
      font-weight: 400;
      padding: 7px 13px;
      opacity: 0.7;
  }
};
   
  ${HeaderArrow}{
    display: ${({ lang }) => (lang === 'ka' ? 'flex' : '')};
   };
  ${VerifyTitle}{
    font-size: ${({ lang }) => (lang === 'ka' ? '28px' : '34px')};
    @media (max-width:900px) {
      font-size:22px;
    }
  };
  ${VerifyTitleBottomSheet}{
     @media (max-width:900px) {
      font-size:${({ lang }) => (lang === 'ka' ? '22px' : '22px')};

    }
  };
   ${VerifyDescBottomSheet}{
     @media (max-width:900px) {
      font-size: ${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
    }
  };
   
  ${VerifyTitle}{
     @media (max-width:900px) {
      font-size: ${({ lang }) => (lang === 'ka' ? '22px' : '22px')};
    }
  };
    
  ${VerifyDesc}{
    font-size: 16px; 
    @media (max-width:900px) {
      font-size: ${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
    }
   };
   ${FooterNavigationWrapper}{
    font-size: 16px; 
    a{
      font-size: 16px; 
     }
    };
    ${IncrementalInputCounter}{
     input{
      font-size: 16px; 
     }
    };
     ${CheckoutFormGroup}{
      font-size: 16px; 
      input{
      font-size: 16px; 
     }
    };
    ${TableWrapperTransactions}{
      .MuiTableCell-body {
         font-size: 16px
      } 
      .MuiTableCell-head {
        font-size: 12px; 
      }
    };
    ${PaginationButtonsConatainerStyled}{
      font-size: 16px; 
     };
    ${TableWrapper}{
      .MuiTableCell-head{
        font-size: 12px; 
      }
      .MuiTableCell-body{
        font-size: 16px
      }
    };
  ${PersonalInfoDetails}{
    input {
      font-size: 14px; 
    }
  };
  ${CartListProducts}{
    input {
      font-size: 14px; 
    }
  };
  ${HeaderStyled}{
    button {
      font-size: 14px; 
     }
  };
  ${ButtonStyled}{
       font-size: 16px;
       font-family:  ${({ lang }) =>
         lang === 'ka' ? 'Helvetica Caps' : undefined};
      @media (max-width:900px) {
        font-size: 14px;
      }
    };
  ${GoToCartButton}{
       font-size: 16px; 
       font-family:  ${({ lang }) =>
         lang === 'ka' ? 'Helvetica Caps' : undefined};
   };

   ${MobileCartOverlayNextBtn}{
        font-family:  ${({ lang }) =>
          lang === 'ka' ? 'Helvetica Caps' : undefined};
   };

  ${AddToCartModalType}{
    .type{
      font-size: 12px;
      }
    .price{
      font-size: 24px;
      @media (max-width: 900px) {
        font-size: 22px;
      }
     }
    .offer{
      font-size: ${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
        .sale{
        font-size: 12px;
       }
    }
   };
   ${AddToCartProductQyt}{
    .left {
      font-size: 16px;  
      }
    .right{
      input{
        font-size: 14px;   
      }
    }
  };
  ${DemoModalFormStyled}{
    .submit {
      font-size: 16px;
      @media (max-width:900px) {
        font-size: 14px !important;
      }
    } 
   };
   ${InfoDescriptionBlock}{
    font-size: 16px;
    @media (max-width:900px) {
      font-size: ${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
    }
   };
   ${CartListItemLeft}{
     input{
      font-size: 14px;
      }
    };
    ${BillingModalProductWrapper}{
     font-size: 16px;
    };
    ${LanguageChooserWrapper}{
      .react-select__single-value,
    .react-select__input-container {
      font-size: 16px;
    }
    .react-select__option{
      font-size: 16px;
     }
  };
  ${FaqInputWrapper}{
      .react-select__single-value,
      .react-select__input-container {
      font-size: 16px;
    }
    .react-select__option{
      font-size: 16px;
     }
  };
  ${FAQNavWrapper}{
    font-size: 16px;
   };
  ${ScrollSpyNav}{
    font-size: 16px;
   };
  ${ProductNames}{
    font-size: 16px;
   };
  ${ProductDetails}{
    font-size: 16px;
   };
   ${TrainingModalFormGroup}{
     input {
      font-size: 14px; 
     }
    };
  ${CartListTypePayment}{
    font-size: 16px;
   }; 
   ${CartListTypeTitle}{
    font-size: 16px;
   }; 
   ${FormRowLeft}{
    font-size: 14px;
    }; 
   ${DropListItem}{
     a{
      font-size: 16px;
      }
    }; 

  ${DemoModalStyled}{
    .react-tabs__tab{
      font-size: ${({ lang }) => (lang === 'ka' ? '22px' : '24px')};
      @media(max-width:900px){
        font-size:${({ lang }) => (lang === 'ka' ? '18px' : '18px')};
      }
    }
  };
   ${ServicesPlanListWrapper}{
    li{
      font-size: 16px;
      @media(max-width:900px){
        font-size: ${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
      }
    }
   };  
   ${RegisterRowItem}{
    input{
      font-size: 14px;      
    }
    .forgetPass {
     @media (max-width: 900px) {
       font-size: ${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
    }
  }
   };
${CheckoutFormRow}{
  font-size: 16px;
   };
${FormRowRight}{
    min-width: ${({ lang }) => (lang === 'ka' ? '50px' : undefined)};
   };
// Helvetica Caps
 ${HeadingStyled}{
    font-size: ${({ lang }) => (lang === 'ka' ? '40px' : '42px')};
    font-family:  ${({ lang }) =>
      lang === 'ka' ? 'Helvetica Caps' : undefined};
   };
  
  ${ServicesParagraphTitle}{
    font-size: ${({ lang }) => (lang === 'ka' ? '28px' : '34px')};
    font-family:  ${({ lang }) =>
      lang === 'ka' ? 'Helvetica Caps' : undefined};
      @media(max-width:900px){
        font-size:${({ lang }) => (lang === 'ka' ? '20px' : '22px')};
      };
   };

  ${ServicesPlanTitle}{
    font-size: ${({ lang }) => (lang === 'ka' ? '24px' : '28px')};
    font-family:  ${({ lang }) =>
      lang === 'ka' ? 'Helvetica Caps' : undefined};
     @media(max-width:900px){
        font-size:${({ lang }) => (lang === 'ka' ? '20px' : '22px')};
      };
   };
   ${ItemsList}{
    @media(max-width:900px){
      li {
        font-size: ${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
      }
    }
   };
    
   ${ServicesPlanMobileWrapper}{
    font-size: ${({ lang }) => (lang === 'ka' ? '24px' : '28px')};
    font-family:  ${({ lang }) =>
      lang === 'ka' ? 'Helvetica Caps' : undefined};
   };

   ${HardwareParagraphTitle}{
     font-size: ${({ lang }) => (lang === 'ka' ? '28px' : '34px')};
     font-family: ${({ lang }) =>
       lang === 'ka' ? 'Helvetica Caps' : undefined};
        @media(max-width:900px) {
           font-size:${({ lang }) => (lang === 'ka' ? '20px' : '22px')};
         }
   };

   ${HarwdarePlanTitle} {
    font-size: ${({ lang }) => (lang === 'ka' ? '24px' : '28px')};
     font-family: ${({ lang }) =>
       lang === 'ka' ? 'Helvetica Caps' : undefined};
       @media(max-width:900px) {
           font-size: ${({ lang }) => (lang === 'ka' ? '20px' : '22px')};
         }
   };
   ${HarwdarePlanParagraph} {
      @media (max-width:900px){
      font-size:  ${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
      }
   };
    
   ${CartPageTitle} {
    font-size: ${({ lang }) => (lang === 'ka' ? '24px' : '28px')};
     font-family: ${({ lang }) =>
       lang === 'ka' ? 'Helvetica Caps' : undefined};
   };
   ${CheckoutFormTitle} {
    font-size: ${({ lang }) => (lang === 'ka' ? '22px' : '24px')};
     font-family: ${({ lang }) =>
       lang === 'ka' ? 'Helvetica Caps' : undefined};
   };
   ${TransactionTableTitle}{
     font-size: ${({ lang }) => (lang === 'ka' ? '28px' : '34px')};
     font-family: ${({ lang }) =>
       lang === 'ka' ? 'Helvetica Caps' : undefined};
   };
    
   ${ErrorAndSuccessModalTitle}{
    @media(max-width:900px) {
      font-size: 22px !important;
     }
    }; 
    ${ErrorAndSuccessModalSubTitle}{
    @media(max-width: 900px) {
      font-size: ${({ lang }) =>
        lang === 'ka' ? '12px!important' : '14px!important'};
     }
    }; 
   ${CurrentProductsTitle}{
     font-size: ${({ lang }) => (lang === 'ka' ? '28px' : '34px')};
     font-family: ${({ lang }) =>
       lang === 'ka' ? 'Helvetica Caps' : undefined};
   };   
    
   ${FaqParagraphTitle}{
     font-size: ${({ lang }) => (lang === 'ka' ? '28px' : '34px')};
     font-family: ${({ lang }) =>
       lang === 'ka' ? 'Helvetica Caps' : undefined};
   }; 
   ${TermsHeaderInner} {
    font-size: ${({ lang }) => (lang === 'ka' ? '28px' : '34px')};
     font-family: ${({ lang }) =>
       lang === 'ka' ? 'Helvetica Caps' : undefined};
       .title{}
       .date{
        font-family: ${({ lang }) =>
          lang === 'ka' ? 'Helvetica Neue LT GEO' : `'Ubuntu', sans-serif`};
       }
   };
   ${Tab} {
    font-size: ${({ lang }) => (lang === 'ka' ? '28px' : '34px')};
    ul,p{
      font-size: 14px;
    }
   };
   ${CheckboxLabelStyled} {
      font-size: 16px; 
      @media(max-width: 900px) {
      font-size:${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
    }
     };
  ${LoginWelcomeText} {
       @media(max-width: 900px) {
        font-size:22px !important;
    }
     };
   ${TextStyled} {
      @media(max-width: 900px) {
        font-size:${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
    }
  };
   ${PlanItemTitle} {
      @media(max-width: 900px) {
        .plan-p {
          font-size:${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
         }
     }
  };
   ${OurPartnersText} {
    font-family:  ${({ lang }) =>
      lang === 'ka' ? 'Helvetica Caps' : undefined};
      @media(max-width: 900px) {
        font-size:${({ lang }) => (lang === 'ka' ? '18px' : '22px')};
      }
  };
  ${AddToCartModalTitle} {
      @media(max-width: 900px) {
        .subTitle{
          font-size:${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
         }
       }
  };
  ${InfoNoteBlock} {
    font-family:  ${({ lang }) =>
      lang === 'ka' ? 'Helvetica Caps' : undefined};
      @media(max-width: 900px) {
       font-size :${({ lang }) =>
         lang === 'ka' ? '20px!important' : '22px!important'};
       }
  };
  ${CartPageClearCart} {
    font-family:  ${({ lang }) =>
      lang === 'ka' ? 'Helvetica Caps' : undefined};
  };
  ${MobileUserName} {
      @media(max-width: 900px) {
        .name {
          font-size :${({ lang }) => (lang === 'ka' ? '14px' : '16px')};
      }
    }
  };
  ${MobileMenuNav} {
      @media(max-width: 900px) {
        a {
          font-size :${({ lang }) => (lang === 'ka' ? '14px' : '16px')};
      }
    }
  };
  ${MobileLanguageItem} {
      @media(max-width: 900px) {
           font-size :${({ lang }) => (lang === 'ka' ? '14px' : '16px')};
     }
  };
  ${ConfirmDeleteModalTitle} {
      @media(max-width: 900px) {
           font-size :${({ lang }) => (lang === 'ka' ? '20px' : '22px')};
     }
  };
  ${ConfirmDeleteModalSubTitle} {
      @media(max-width: 900px) {
           font-size :${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
     }
  };
  ${ItemDeleteOrPayNow} {
    .payNow {
      font-size:14px;
        @media(max-width: 900px) {
          font-size :14px;
     }
  }
 };
 ${GlobalErrorTitle} {
    @media(max-width: 900px) {
      font-size :22px;
     }
  };
  ${ChangeProperties}{
    .react-tabs {
      .react-tabs__tab-list {
        gap :${({ lang }) => (lang === 'ka' ? '40%' : '')};
        @media(max-width: 900px) {
          gap :${({ lang }) => (lang === 'ka' ? '25%' : '')};
      }
      @media(max-width: 700px) {
          gap :${({ lang }) => (lang === 'ka' ? '15%' : '')};
      }
    }
  }
};
  ${GlobalErrorSubTitle} {
    @media(max-width: 900px) {
      font-size :${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
     }
  };
  ${GlobalErrorContanctInfo} {
    .email{
      @media(max-width: 900px){
        font-size : 14px;
      }
    }
    .number{
      @media(max-width: 900px){
        font-size : 12px;
      }
     }
  };
  ${TransactionHistoryMobileHead} {
    @media(max-width: 900px) {
      font-size :${({ lang }) => (lang === 'ka' ? '20px' : '22px')};
     }
  };
  ${EmptyPageText} {
    @media(max-width: 900px) {
      font-size :${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
     }
  };
  ${FaqMobileHead} {
      @media(max-width: 900px) {
           font-size :${({ lang }) => (lang === 'ka' ? '20px' : '22px')};
     }
  };
   
  ${FooterInfoTitle} {
     font-size :${({ lang }) => (lang === 'ka' ? '16px' : '18px')};
      @media(max-width: 900px) {
           font-size :${({ lang }) => (lang === 'ka' ? '14px' : '16px')};
     }
  };
   
  //Blog Page Different designs Eng&Geo:
  ${BlogParagraphListTitleStyled}{
    .plan-title{
      font-weight: ${({ lang }) => (lang === 'ka' ? '600' : '700')};
      font-family: ${({ lang }) =>
        lang === 'ka' ? 'Helvetica Caps' : undefined};
    }
};

${BlogMiddleContTitle}{
    font-size: 22px;
    font-family: ${({ lang }) =>
      lang === 'ka' ? 'Helvetica Caps' : undefined};
    @media (max-width:900px) {
        font-size: ${({ lang }) => (lang === 'ka' ? '14px' : '16px')};
        font-family: ${({ lang }) =>
          lang === 'ka' ? 'Helvetica Caps' : undefined};
        }
};

${BlogsSliderTitle}{
    font-size: ${({ lang }) => (lang === 'ka' ? '22px' : '34px')};
    font-weight:500;
    font-family: ${({ lang }) =>
      lang === 'ka' ? 'Helvetica Caps' : undefined};
};

${LeftSliderTitle}{
  @media (max-width:900px) {
     font-size: ${({ lang }) => (lang === 'ka' ? '18px' : '22px')};
    }
};
  // Language ClassNames  
   .headline1{
     font-size: ${({ lang }) => (lang === 'ka' ? '40px' : '42px')};
     @media(max-width:900px) {
      font-size:${({ lang }) => (lang === 'ka' ? '28px' : '28px')};
     }
  };

  .headline2 {
    font-size: ${({ lang }) => (lang === 'ka' ? '28px' : '34px')};
      .dolar,
      .plan-title  {
       font-size: ${({ lang }) => (lang === 'ka' ? '28px' : '34px')}};
    @media (max-width:900px) {
      font-size:${({ lang }) => (lang === 'ka' ? '20px' : '22px')};
      .dolar,
      .plan-title  {
       font-size: 22px}
    }
     };

  .headline3{
      font-size: ${({ lang }) => (lang === 'ka' ? '24px' : '28px')};
      .title{
        font-size: ${({ lang }) => (lang === 'ka' ? '24px' : '28px')};
          @media (max-width:900px) {
            font-size: ${({ lang }) => (lang === 'ka' ? '22px' : '22px')};
          }
       }
      @media (max-width:900px) {
        font-size: ${({ lang }) => (lang === 'ka' ? '22px' : '22px')};
      }
    };

  .headline4{
    font-size: ${({ lang }) => (lang === 'ka' ? '22px' : '24px')};
    @media (max-width:900px) {
        font-size: ${({ lang }) => (lang === 'ka' ? '18px' : '22px')};
        }
    };

    
  .headline5{
    font-size: ${({ lang }) => (lang === 'ka' ? '20px' : '22px')};
    @media (max-width:900px) {
        font-size: ${({ lang }) => (lang === 'ka' ? '18px' : '22px')};
        }
    };

    //blogs page Headlines:
    .Blogsheadline1{
      font-size: ${({ lang }) => (lang === 'ka' ? '22px' : '34px')};
      font-weight:500 !important;
      @media (max-width:900px) {
        font-size:${({ lang }) => (lang === 'ka' ? '20px' : '22px')};
        .dolar,
        .plan-title  {
        font-size: 22px}
      }
    };

    .Blogsheadline2{
     font-size: ${({ lang }) => (lang === 'ka' ? '32px' : '42px')};
     font-family: ${({ lang }) =>
       lang === 'ka' ? 'Helvetica Caps' : undefined};
     @media(max-width:900px) {
      font-size:${({ lang }) => (lang === 'ka' ? '28px' : '28px')};
     }
  };

  .Blogsheadline2WithCaps{
     font-size: 34px;
     font-family: ${({ lang }) =>
       lang === 'ka' ? 'Helvetica Caps' : undefined};
     @media(max-width:900px) {
      font-size:${({ lang }) => (lang === 'ka' ? '20px' : '22px')};
     }
  };

  .Blogsheadline1WithCaps{
      font-size: ${({ lang }) => (lang === 'ka' ? '22px' : '34px')};
      font-family: ${({ lang }) =>
        lang === 'ka' ? 'Helvetica Caps' : undefined};
      font-weight:500 !important;

      @media (max-width:900px) {
          font-size:${({ lang }) => (lang === 'ka' ? '20px' : '22px')};
          font-family: ${({ lang }) =>
            lang === 'ka' ? 'Helvetica Caps' : undefined};
          .dolar,
          .plan-title  {
          font-size: 22px
          }
      }
  };
   
  .BlogsHeadlineWithoutCaps{
      font-size:22px;
      
      @media (max-width:900px) {
        font-size: ${({ lang }) => (lang === 'ka' ? '18px' : '22px')};
        }
   };

   .BlogsHeadlineWithCaps{
      font-size:22px;
      font-family: ${({ lang }) =>
        lang === 'ka' ? 'Helvetica Caps' : undefined};
      @media (max-width:900px) {
        font-size: ${({ lang }) => (lang === 'ka' ? '18px' : '22px')};
      }
   };
    // body
    .bodyL{
      font-size: 16px; 
      @media (max-width: 900px) {
        font-size:${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
       }
     };
    .bodyM {
      font-size: 14px;
      @media (max-width: 900px) {
        font-size:${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
       }
    };
    .bodyS{
      font-size: 12px;
      @media (max-width: 900px) {
        font-size:${({ lang }) => (lang === 'ka' ? '12px' : '12px')};
       }
    };
    // BLOGS BODY:
    .Blogsbody{
      font-size:${({ lang }) => (lang === 'ka' ? '14px' : '16px')};
      @media (max-width: 900px) {
        font-size:${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
       }
     };
     
     .Blogs-first-paragraph-body{
      font-size:16px;
      @media (max-width: 900px) {
        font-size:${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
       }
     };

     .BlogsbodyWithCaps{
      font-size:${({ lang }) => (lang === 'ka' ? '14px' : '16px')};
      font-family:  ${({ lang }) =>
        lang === 'ka' ? 'Helvetica Caps' : undefined};
      @media (max-width: 900px) {
        font-size:${({ lang }) => (lang === 'ka' ? '12px' : '14px')};
        font-family:  ${({ lang }) =>
          lang === 'ka' ? 'Helvetica Caps' : undefined};
       }
     };
    // buttons
    .button1{
      font-size:16px;
      font-family:  ${({ lang }) =>
        lang === 'ka' ? 'Helvetica Caps' : undefined};
      @media (max-width: 900px) {
        font-size: 14px;
       }
    };

    .Blogsbutton{
      font-size:${({ lang }) => (lang === 'ka' ? '14px' : '16px')};
      font-family:  ${({ lang }) =>
        lang === 'ka' ? 'Helvetica Caps' : undefined};
      @media (max-width: 900px) {
        font-size: 14px;
       }
    };
   `;
