import styled from 'styled-components';
import sideBg from 'assets/img/sideBg.svg';

export const FaqInputWrapper = styled.div`
  display: flex;
  width: 41%;
  justify-content: center;
  border: 1px solid #dddbed;
  border-radius: 5px;
  margin: 0px auto 90px auto;
  align-items: center;
  height: 60px;
  svg {
    height: 18px;
    width: 18px;
    fill: #3a3853;
  }
  @media (max-width: 900px) {
    width: calc(100% - 47px);
    margin: 0px auto 26px auto;
    border: 1px solid #f0eff7;
    border-radius: 4px;
    padding: 15px 22px;
    height: 44px;
    svg {
      height: 14.8px;
      width: 14.8px;
      fill: #3a3853;
      position: absolute;
      right: 0px;
      margin-right: 23px;
    }
  }

  .react-select__control {
    background: transparent;
    width: 100%;
    outline: none !important;
    border: none;
    width: max-content;
    box-shadow: none;
    margin-right: 40px;
    cursor: pointer;
    @media (max-width: 900px) {
      margin-right: 10px;
    }
  }
  .react-select__control:hover,
  .react-select__control:focus {
    border-color: #dddbed !important;
    box-shadow: none;
    outline: none;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__value-container {
    padding: 2px 0px 2px 15px;
    @media (max-width: 900px) {
      padding: 15px 10px;
    }
  }
  .react-select__single-value,
  .react-select__input-container {
    font-weight: 500;
    color: #3a3853;
  }
  .react-select__indicator {
    padding: 8px 0px 8px 2px;
    @media (max-width: 900px) {
      padding: 0px;
    }
  }
  .react-select__indicator svg {
    width: 18px;
  }
  .react-select__indicator svg path {
    fill: #3a3853;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__dropdown-indicator svg path {
    fill: #3a3853;
  }
  .react-select__option--is-focused {
    background-color: #ffdc04;
  }
  .react-select__menu {
    border: none;
    background-color: #ffffff;
    padding: 15px 20px;
    box-shadow: 0px 43px 66px #4b4a5014;
    border-radius: 2px 0px 15px 15px;
    @media (max-width: 900px) {
      padding: 5px 2px;
      box-shadow: 0px 43px 66px #4b4a5014;
      border-radius: 2px 0px 0px 0px;
    }
  }
  .react-select__control--menu-is-open svg {
    transform: rotate(0.5turn);
  }
  .react-select__option {
    color: #8a87a5;
    cursor: pointer;
    transition: all 0.1s;
    border-radius: 5px;
    font-weight: 400;
    padding: 15px 0px 15px 15px;
    @media (max-width: 900px) {
      padding: 10px 0px 10px 10px;
    }
  }
  .react-select__option:hover,
  .react-select__option--is-focused {
    background: none;
    background-color: none;
  }
  .react-select__option--is-selected,
  .react-select__option:active,
  .react-select__option:hover {
    background-color: rgba(235, 234, 244, 0.38) !important;
    color: #3a3853;
    font-weight: 500;
    padding: 13px auto;
    @media (max-width: 900px) {
      padding: 10px auto;
    }
  }
  input {
    border: none;
  }
  svg {
    margin-left: 40px;
    margin-right: 0px;
    @media (max-width: 900px) {
      margin-left: 23px;
      margin-right: 10px;
    }
  }
`;

export const FaqInputSeperator = styled.div`
  margin: 0 0px;
  height: 36px;
  width: 1px;
  background: #dddbed;
`;
export const FaqMobileHead = styled.div`
  margin-bottom: 22px;
  padding: 28px 22px 0 22px;
  font-weight: bold;
`;
export const FaqHeaderWithText = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: column;
  background: url(${sideBg});
  background-repeat: no-repeat;
  width: 100%;
  height: 155px;
  background-position: bottom right;
  margin-bottom: 60px;
  background-size: 55%;
`;

export const FaqHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  color: #3a3853;
`;
export const FaqParagraphText = styled.div`
  display: flex;
  color: #bab8c7;
  font-weight: 400;
  line-height: 30px;
`;

export const InputVerticalLine = styled.div`
  position: absolute;
  border-right: 1px solid #dddbed;
  right: 26%;
  height: 36px;
  bottom: 20px;
`;

export const FaqParagraphTitle = styled.div`
  color: #3a3853;
  font-weight: bold;
  margin-bottom: 16px;
`;
export const FAqAccordionTitle = styled.div`
  color: #3a3853;
  transition: all 0.05s;
  font-weight: ${(props) => (props.BlogPage ? '400' : '500')};
  margin-bottom: 10px;
  margin-top: 5px;
  cursor: pointer;
  @media (max-width: 900px) {
    font-size: 16px !important;
  }
`;
export const FaqAccordionsTrigger = styled.div`
  display: flex;
`;
export const AccordionIcon = styled.div`
  margin-top: ${(props) => (props.BlogPage ? '22px' : '14px')};

  @media (max-width: 900px) {
    margin-top: ${(props) => (props.BlogPage ? '13px' : '10px')};

    svg {
      width: 37px;
      height: 37px;
      margin-right: 18px !important;
    }
  }
`;
export const AccordionContent = styled.div`
  border-bottom: 1px solid #f0eff7;
  width: 100%;
  padding: 25px 0px;
  p {
    margin: 0;
  }
  @media (max-width: 900px) {
    padding: 18px 0;
    .acoordionContent {
      line-height: 18px !important;
      font-size: 12px !important;
    }
  }
`;

export const FAQNavWrapper = styled.div`
  margin-bottom: 160px;
  padding: 0 14%;
  .react-tabs__tab-list {
    border-bottom: 1px solid #e4e2eb;
    margin: 0 30px;
    display: flex;
  }

  .react-tabs__tab:after {
    background: transparent;
  }
  .react-tabs__tab:focus {
    border-bottom: 1px solid #ffdc04;
    color: #3a3853;
    border-radius: 0;
    box-shadow: none;
    border-color: #ffdc04;
    outline: none;
  }

  .react-tabs__tab-panel--selected {
    padding: 0px 14% 0px 15%;
    @media (max-width: 900px) {
      padding: 0 22px;
    }
    .Collapsible {
      display: flex;
      cursor: pointer;
      align-items: center;
      transition: max-height 0.9s ease-out;
    }
  }
  .content {
    max-height: 0;
    transition: max-height 0.9s ease-out;
    overflow: hidden;
  }
  .contentShow {
    color: #8a87a5;
    font-weight: 400;
    overflow: hidden;
    max-height: 500px;
    transition: max-height 0.9s ease-in;
  }
  @media (max-width: 1540px) {
    padding: 0 8%;
    height: inherit;
  }
  @media (max-width: 900px) {
    padding: 0 22px;
    height: inherit;
  }
`;
