import React from 'react';
export const PassHideIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.713"
    height="13.735"
    viewBox="0 0 19.713 13.735"
  >
    <g id="hide" transform="translate(-0.001 -3.015)">
      <path
        id="Path_11039"
        data-name="Path 11039"
        d="M-415.106,386.873a9.939,9.939,0,0,0,5.94-2.031,12.847,12.847,0,0,0,3.81-4.351.768.768,0,0,0-.463-1.151.762.762,0,0,0-.885.411,10.833,10.833,0,0,1-1.575,2.225,10.208,10.208,0,0,1-4.494,2.994,7.657,7.657,0,0,1-5.662-.386,10.75,10.75,0,0,1-5.022-4.761.806.806,0,0,0-.884-.495.77.77,0,0,0-.525,1.125,11.518,11.518,0,0,0,1.47,2.2,11.884,11.884,0,0,0,5.469,3.774A9.432,9.432,0,0,0-415.106,386.873Z"
        transform="translate(424.959 -376.296)"
        fill="#3a3853"
      />
      <path
        id="Path_11041"
        data-name="Path 11041"
        d="M-422,351.468a2.259,2.259,0,0,0-.5.251c-.582.5-1.151,1.021-1.717,1.542a.754.754,0,0,0-.061,1.072.755.755,0,0,0,1.085.074q.881-.775,1.742-1.572a.737.737,0,0,0,.18-.847A.882.882,0,0,0-422,351.468Z"
        transform="translate(424.512 -341.88)"
        fill="#3a3853"
      />
      <path
        id="Path_11042"
        data-name="Path 11042"
        d="M-211.093,351.5a.858.858,0,0,0-.74.517.746.746,0,0,0,.2.859c.467.43.941.852,1.413,1.277.11.1.215.2.33.294a.761.761,0,0,0,1.057-.078.748.748,0,0,0-.03-1.059q-.871-.814-1.773-1.595A1.855,1.855,0,0,0-211.093,351.5Z"
        transform="translate(228.308 -341.905)"
        fill="#3a3853"
      />
      <path
        id="Path_11043"
        data-name="Path 11043"
        d="M-305.48,301.094c0-.372,0-.744,0-1.116a.775.775,0,0,0-.777-.8.771.771,0,0,0-.763.8q0,1.126,0,2.251a.766.766,0,0,0,.776.8.769.769,0,0,0,.764-.8c0-.013,0-.026,0-.038Q-305.479,301.642-305.48,301.094Z"
        transform="translate(316.109 -286.275)"
        fill="#3a3853"
      />
    </g>
  </svg>
);
