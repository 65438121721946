import React from 'react';
import Select from 'react-select';

const CustomSelect = (props) => {
  const {
    name,
    options,
    placeholder,
    value,
    onChange,
    showSecondaryValue,
    isClearable = false,
    disabled,
    className,
    isMulti,
  } = props;

  return (
    <Select
      classNamePrefix={
        isMulti ? 'react-select react-multi-select' : 'react-select'
      }
      className={className}
      isSearchable={false}
      isDisabled={disabled}
      name={name}
      isMulti={isMulti}
      onChange={onChange}
      isClearable={isClearable}
      value={value}
      defaultValue={
        value
          ? {
              label: showSecondaryValue ? value.secondaryValue : value.label,
              value: value.value,
            }
          : undefined
      }
      options={options}
      placeholder={placeholder}
    />
  );
};

export default CustomSelect;
