import styled from 'styled-components';

export const FooterStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FooterBottomLine = styled.div`
  border-bottom: 1px solid #bab8c7;
  opacity: 0.4;
  margin-top: 40px;
  margin-bottom: 40px;

  @media (max-width: 900px) {
    padding: 0px 22px;
    margin-top: 30px;
    margin-bottom: 28px;
  }
`;

export const FooterWithInfoStyled = styled.div`
  display: flex;

  @media (max-width: 900px) {
    display: flex;
    flex-wrap: wrap;
  }
`;
export const FooterInfoTitle = styled.div`
  color: #3a3853;
  font-weight: 700;
  margin-bottom: 17px;
  opacity: 0.8;

  @media (max-width: 900px) {
    width: 100%;
  }
`;
export const FooterInfoList = styled.div`
  display: flex;
  flex-direction: column;
  color: #8a87a5;
  font-weight: 400;
  margin-bottom: 17px;
  a {
    color: #8a87a5;
    text-decoration: none;
    margin-bottom: 17px;
  }

  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 14px;
    font-size: 14px;
  }
`;
export const FooterNavigationWrapper = styled.div`
  display: flex;
  text-decoration: none;
  color: #8a87a5;
  font-weight: normal;
  padding-right: 25px;
  a {
    text-decoration: none;
    color: #8a87a5;
  }
`;

export const FooterSocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  .logo-bottom {
    display: flex;
    margin-left: auto;
  }
`;
export const ContactMediaInfo = styled.div`
  color: #8a87a5;
  width: auto;
  margin: 5px;
  padding-right: 2px;
  margin-bottom: 60px;
`;
export const FooterWrapper = styled.div`
  @media (max-width: 900px) {
    background: none;
  }
`;

export const FootersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 14% 80px 14%;
  opacity: 0.7;
  background: #fcfaf5 0% 0% no-repeat padding-box;
  @media (max-width: 1540px) {
    padding: 80px 8% 80px 8%;
  }
  @media (max-width: 900px) {
    padding: 34px 28px 0px 28px;
  }
`;
export const FooterLeftBlock = styled.div`
  display: flex;
  width: 100%;
`;
export const MobileFooter = styled.div`
  width: 100%;
  margin-top: auto;
  .left a {
    font-size: 12px;

    text-decoration: none;
    line-height: 24px;
  }
  .right {
    display: flex;
  }
  .foot-in {
    width: 100%;
    padding: 28px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const FooterNavItem = styled.div`
  a {
    color: #8a87a5;
    text-decoration: none;
  }
  margin-bottom: 23px;
`;
export const FooterNav = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8%;
  ${FooterNavItem} {
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const FooterInfoWrapper = styled.div`
  flex: 50%;
  @media (max-width: 900px) {
    /* margin-bottom: 30%; */
    :last-child {
      margin-bottom: 0;
    }
  }
`;
