import styled from 'styled-components';

export const GlobalErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  background: #fff;
  overflow: hidden;
  @media (max-width: 900px) {
    position: unset;
  }
`;
export const GlobalErrorTop = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 17%;
  @media (max-width: 1604px) {
    padding-top: 11%;
  }
  @media (max-width: 900px) {
    padding-top: 50%;
  }
  @media (max-height: 600px) {
    padding-top: 30%;
  }
`;
export const GlobalErrorIcon = styled.div`
  margin-bottom: 40px;
  @media (max-width: 900px) {
    margin-bottom: 36px;
  }
`;
export const GlobalErrorTitle = styled.div`
  color: #3a3853;
  margin-bottom: 12px;
  @media (max-width: 900px) {
    font-weight: 700;
    margin-bottom: 13px;
  }
`;
export const GlobalErrorSubTitle = styled.div`
  color: #8a87a5;
  @media (max-width: 900px) {
    line-height: 21px;
  }
`;

export const GlobalErrorContanctInfo = styled.div`
  justify-self: flex-end;
  padding-bottom: 100px;
  text-align: center;
  @media (max-width: 900px) {
    padding-bottom: 56px;
  }
  .email {
    color: #3a3853;
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 18px;
    @media (max-width: 900px) {
      font-weight: 700;
      margin-bottom: 12px;
      line-height: 16px;
    }
  }
  .number {
    color: #8a87a5;
    margin-top: 18px;
    @media (max-width: 900px) {
      margin-top: 12px;
    }
  }
`;

export const GlobalLoading = styled.div`
  overflow-x: hidden;
`;
export const FormInputErrorWrapper = styled.div`
  color: rgba(245, 94, 27, 1);
  position: absolute;
  left: 6px;
  margin-top: 3px;
`;
