import React, { useContext, useEffect, useState } from 'react';
import { BillingPayButton } from 'ui/styled/billingTable.styled';
import api from 'services/api';
import Modal from 'library/modal/Modal';
import formatDate, { DateFormats } from 'utils/date';
import Pagination from '../../../library/Pagination/Pagination';
import EmptyTransactionsInfo from 'ui/components/common/EmptyTransactionsInfo';
import ConfirmDeleteModal from '../Cart/CartPageComponents/ConfirmDeleteModal';
import {
  getTextFromDataByPeriod,
  getTextFromDataByKey,
  getTextFromDataByProductKey,
} from 'utils/helpers';
import ToolTipComponent from 'library/tooltip/ToolTipComponent';
import infoIcon from 'assets/img/info.svg';
import clearIcon from 'assets/img/clearIcon.svg';
import warningIcon from 'assets/img/warningIcon.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  TableWrapper,
  ItemHead,
  TransactionsItemBody,
  ItemTitle,
  ItemRows,
  ItemLeftColumn,
  MobileTransactionsHistoryItem,
  ItemRightColumn,
  BillingTableWrapper,
  ItemDeleteOrPayNow,
} from 'library/table/TableWrapperStyled';
import BillingTableModal from './BillingTableModal';
import { useLocation } from 'react-router-dom';
import { cartSuccessErrorUrls } from 'constants/global.constants';
import { htmlParser } from 'utils/htmlUtils';
import TableLoading from 'ui/components/common/TableLoading';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { MobileOnPersmiseTable } from 'ui/styled/currentProducts.styled';
import BottomSheet from 'library/bottomSheet/BottomSheet';
import { StrapiDataContext } from 'Main';
import ConfirmAddCardModal from '../Cart/CartPageComponents/ConfirmAddCardModal';

const BillingTable = (props) => {
  const {
    setBillingData,
    billingData,
    settingsData,
    notFoundData,
    currentProductsData,
    strapiData,
    setSearchParams,
    productIdFromSearch,
    setPagination,
    pagination,
    searchValue,
    isTabletOrMobile,
  } = props;

  const { pathname } = useLocation();

  const translateKeys = settingsData?.data?.data?.attributes.enumTranslate;
  const errorTranslatesData =
    settingsData?.data?.data?.attributes?.errorTranslate;

  const [isOpen, setIsOpen] = useState(false);
  const [deletedProducts, setDeletedProducts] = useState();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [headerColumnsData, setHeaderColumnsData] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const contextData = useSelector((state) => state.app.language, _.isEqual);
  const [billingDataError, setBillingDataError] = useState(false);

  const { transactionHistoryData: data, cartStrapiData } =
    useContext(StrapiDataContext);
  const NotFoundData = data?.data?.data?.attributes?.search?.notFound;

  const [confirmCardModalOpen, setConfirmCardModalOpen] = useState(false);
  const cardTermsData = cartStrapiData?.data
    ? cartStrapiData.data.data.attributes?.card
    : null;
  const [paymentStatus, setPaymentStatus] = useState({});

  const handlePaginationClick = (i) => {
    setPagination({
      ...pagination,
      offset: i * pagination.limit,
      call: true,
    });
  };

  const getPurchaseHistoryData = async ({ search }) => {
    setLoading(true);
    try {
      const data = await api.user.getPurchaseHistory(
        false,
        search,
        pagination.offset,
        pagination.limit,
      );
      setDataCount(data.data?.count);
      setBillingData(
        data.data?.list.map((item) => {
          return {
            ...item,
            itemName: getTextFromDataByProductKey(
              item.product.productKey,
              translateKeys,
            ),
          };
        }),
      );
    } catch (e) {
      console.error(e);
      setBillingDataError(true);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (!pagination.call) return;
    getPurchaseHistoryData({
      search: searchValue || '',
    });
  }, [pagination]);

  useEffect(() => {
    if (searchValue === null || searchValue === undefined) return;
    getPurchaseHistoryData({
      search: searchValue || '',
    });
  }, [searchValue]);

  const headerColumns = [
    {
      key: '',
      value: '',
    },
    {
      key: 'start_date',
      value: getTextFromDataByKey(
        'start_date',
        currentProductsData?.billing?.translate,
      ),
      align: 'left',
    },
    {
      value: getTextFromDataByKey(
        'upcoming_payment',
        currentProductsData?.billing?.translate,
      ),
      key: 'upcoming_payment',
    },
    {
      value: getTextFromDataByKey(
        'ID_number',
        currentProductsData?.billing?.translate,
      ),
      key: 'ID_number',
    },
    {
      value: getTextFromDataByKey(
        'product_name',
        currentProductsData?.billing?.translate,
      ),
      key: 'product_name',
    },
    {
      value: getTextFromDataByKey(
        'time_period',
        currentProductsData?.billing?.translate,
      ),
      key: 'time_period',
    },
    {
      value: getTextFromDataByKey(
        'price',
        currentProductsData?.billing?.translate,
      ),
      key: 'unitCost',
      align: 'right',
    },
    {
      value: getTextFromDataByKey(
        'upcomingPayment',
        currentProductsData?.billing?.translate,
      ),
      key: 'upcomingPayment',
      align: 'right',
    },
  ];

  useEffect(() => {
    if (pathname && pathname.includes(cartSuccessErrorUrls.SUCCESS)) {
      // setCardSuccessModal(true);
      return;
    }
    if (pathname && pathname.includes(cartSuccessErrorUrls.ERROR)) {
      // setCardErrorModal(true);
      return;
    }

    return () => {
      localStorage.removeItem('activeBillingItem');
    };
  }, []);

  useEffect(() => {
    setHeaderColumnsData(
      headerColumns?.map((item) => ({
        ID: item?.id,
        Header: item?.value,
        accessor: item?.key,
        align: item.align,
      })),
    );
  }, [settingsData]);

  const deleteHandler = (selectedId) => {
    const selectedProductId = billingData.find(
      (rows) => rows.purchasedItemId === selectedId,
    );
    setDeletedProducts(selectedProductId?.purchasedItemId);
    return selectedProductId?.purchasedItemId;
  };

  const cancelBillingItemWithID = async () => {
    const deletedItemId = deleteHandler(deletedProducts);
    try {
      await api.cart.cancelBillingItem(deletedItemId);
      setIsDeleteModalOpen(false);
      getPurchaseHistoryData({
        search: searchValue || '',
      });
    } catch (e) {
      console.error(e);
    }
  };
  const buyButtonClickHandler = (e) => {
    setIsOpen(true);
    const billing = [...billingData];
    const selectedProduct = billing.find(
      (i) => i.purchasedItemId === Number(e),
    );
    setSelectedProductId(selectedProduct);
  };

  const activeBillingItemData = JSON.parse(
    localStorage.getItem('activeBillingItem'),
  );

  useEffect(() => {
    if (
      productIdFromSearch &&
      activeBillingItemData &&
      activeBillingItemData.id === productIdFromSearch
    ) {
      const selectedProduct = billingData.find(
        (i) => i.purchasedItemId === Number(activeBillingItemData.id),
      );
      setSelectedProductId(selectedProduct);
      selectedProduct && selectedProduct.overdueDays >= 0 && setIsOpen(true);
    }
  }, [productIdFromSearch, billingData]);

  const closeBillingModal = () => {
    setIsOpen(false);
    localStorage.removeItem('activeBillingItem');
    setSelectedProductId(undefined);
  };

  const addPaymentCart = async () => {
    await api.cart
      .addPaymentCard(
        `${window.location.origin}${window.location.pathname}?productId=${selectedProductId?.purchasedItemId}`,
        `${window.location.origin}${window.location.pathname}?&productId=${selectedProductId?.purchasedItemId}`,
      )
      .then((res) => {
        localStorage.setItem(
          'activeBillingItem',
          JSON.stringify({
            id: selectedProductId?.purchasedItemId,
            offset: pagination.offset,
            limit: pagination.limit,
            defaultTabIndex: 1,
          }),
        );
        window.location.href = res.data;
      });
  };
  const iconRenderer = (row) => {
    if (row.overdueDays >= 0) {
      return (
        <div className="info">
          <ToolTipComponent title="Warning Icon" placement="top">
            <img
              src={warningIcon}
              alt="info"
              style={{
                verticalAlign: 'baseline',
              }}
            />
          </ToolTipComponent>
        </div>
      );
    } else if (row.overdueDays <= 0 && row.status === 'ACTIVE') {
      return (
        <div className="info">
          <ToolTipComponent title="Clear Icon" placement="top">
            <img
              src={clearIcon}
              alt="info"
              style={{
                verticalAlign: 'baseline',
              }}
              onClick={() => {
                setIsDeleteModalOpen(true);
                deleteHandler(row.purchasedItemId);
              }}
            />
          </ToolTipComponent>
        </div>
      );
    } else if (row.status === 'CANCELING') {
      return (
        <div className="info">
          <ToolTipComponent title="Info Icon" placement="top">
            <img
              src={infoIcon}
              alt="info"
              style={{
                verticalAlign: 'baseline',
              }}
            />
          </ToolTipComponent>
        </div>
      );
    }
  };

  if (loading) {
    return <TableLoading />;
  }

  return billingDataError ? (
    <EmptyTransactionsInfo noData={notFoundData} />
  ) : (
    <>
      <Modal
        closeIcon
        width="616px"
        height="auto"
        className="billing"
        isOpen={isOpen}
        closeModal={closeBillingModal}
      >
        <BillingTableModal
          onPaymentSuccess={(purchasedItemId) => {
            setPaymentStatus((prevStatus) => ({
              ...prevStatus,
              [purchasedItemId]: 'paid',
            }));
          }}
          openModal={() => {
            setIsOpen(true);
          }}
          productId={selectedProductId}
          labelsTranslate={currentProductsData?.billing?.translate}
          payNowModalData={currentProductsData?.payNow}
          translateKeys={translateKeys}
          getPurchaseHistoryData={getPurchaseHistoryData}
          pagination={pagination}
          closeModal={closeBillingModal}
          isTabletOrMobile={isTabletOrMobile}
          errorTranslatesData={errorTranslatesData}
          setConfirmCardModalOpen={setConfirmCardModalOpen}
          confirmCardModalOpen={confirmCardModalOpen}
        />
      </Modal>
      {!isTabletOrMobile ? (
        <Modal
          closeIcon
          width="464px"
          height="auto"
          className="addCard-modal"
          isOpen={confirmCardModalOpen}
          closeModal={() => {
            setConfirmCardModalOpen(false);
          }}
        >
          <ConfirmAddCardModal
            cardTermsData={cardTermsData}
            addPaymentCart={addPaymentCart}
            closeModal={() => {
              setConfirmCardModalOpen(false);
            }}
          />
        </Modal>
      ) : (
        <BottomSheet
          isOpen={confirmCardModalOpen}
          padding="40px 55px"
          closeBottomSheet={() => setConfirmCardModalOpen(false)}
        >
          <ConfirmAddCardModal
            cardTermsData={cardTermsData}
            addPaymentCart={addPaymentCart}
            closeModal={() => {
              setConfirmCardModalOpen(false);
            }}
          />
        </BottomSheet>
      )}
      {!isTabletOrMobile ? (
        <Modal
          closeIcon
          width="500px"
          height="auto"
          isOpen={isDeleteModalOpen}
          className="delete-confirm"
          closeModal={() => setIsDeleteModalOpen(false)}
        >
          <ConfirmDeleteModal
            closeModal={() => setIsDeleteModalOpen(false)}
            ConfirmDeleteText={htmlParser(
              strapiData?.cancelSubscription?.description,
            )}
            DeleteTextTitle={strapiData?.cancelSubscription?.title}
            ConfirmDelete={strapiData?.cancelSubscription?.ok}
            confirmDelete={cancelBillingItemWithID}
            CancelDelete={strapiData?.cancelSubscription?.cancel}
          />
        </Modal>
      ) : (
        <BottomSheet
          isOpen={isDeleteModalOpen}
          padding="40px 0"
          closeBottomSheet={() => setIsDeleteModalOpen(false)}
        >
          <ConfirmDeleteModal
            closeModal={() => setIsDeleteModalOpen(false)}
            ConfirmDeleteText={htmlParser(
              strapiData?.cancelSubscription?.description,
            )}
            DeleteTextTitle={strapiData?.cancelSubscription?.title}
            ConfirmDelete={strapiData?.cancelSubscription?.ok}
            confirmDelete={cancelBillingItemWithID}
            CancelDelete={strapiData?.cancelSubscription?.cancel}
          />
        </BottomSheet>
      )}
      <>
        {!billingData || (billingData && billingData.length === 0) ? (
          <EmptyTransactionsInfo notFoundData={NotFoundData} />
        ) : (
          <Pagination
            limit={pagination.limit}
            offset={pagination.offset}
            count={dataCount || pagination.count}
            length={billingData?.limit}
            className="orders-pagination"
            onClick={handlePaginationClick}
          >
            {isTabletOrMobile ? (
              <MobileOnPersmiseTable>
                {billingData &&
                  billingData?.map((row, index) => {
                    return (
                      <MobileTransactionsHistoryItem key={index}>
                        <ItemHead
                          style={{
                            padding: '13px 18px',
                            marginBottom: '0',
                            background:
                              row.overdueDays >= 0 ? '#FDF6E7' : undefined,
                          }}
                        >
                          <ItemTitle
                            style={{ marginBottom: '0', maxWidth: '81%' }}
                          >
                            <div className="id">ID: {row?.paymentId}</div>
                            <div className="name">{row.itemName}</div>
                            <div className="date">
                              <span>
                                {`${getTextFromDataByKey(
                                  'start_date',
                                  currentProductsData?.billing?.translate,
                                )}: `}
                              </span>
                              <span>
                                {row.createTime
                                  ? formatDate({
                                      inputDate: row.createTime,
                                      outputFormat:
                                        DateFormats.DefaultWithoutLines,
                                    })
                                  : '-'}
                              </span>
                            </div>
                          </ItemTitle>
                          <ItemDeleteOrPayNow>
                            {row.overdueDays >= 0 ? (
                              <div
                                className="info payNow"
                                onClick={() =>
                                  buyButtonClickHandler(row.purchasedItemId)
                                }
                              >
                                {getTextFromDataByKey(
                                  'payNow',
                                  strapiData?.payNow,
                                )}
                              </div>
                            ) : row.status === 'CANCELING' ? (
                              <div className="info">
                                <ToolTipComponent
                                  title="Info Icon"
                                  placement="top"
                                >
                                  <img
                                    src={infoIcon}
                                    alt="info"
                                    style={{
                                      verticalAlign: 'baseline',
                                    }}
                                  />
                                </ToolTipComponent>
                              </div>
                            ) : (
                              <div className="info">
                                <ToolTipComponent
                                  title="Clear Icon"
                                  placement="top"
                                >
                                  <img
                                    src={clearIcon}
                                    alt="info"
                                    style={{
                                      verticalAlign: 'baseline',
                                    }}
                                    onClick={() => {
                                      setIsDeleteModalOpen(true);
                                      deleteHandler(row.purchasedItemId);
                                    }}
                                  />
                                </ToolTipComponent>
                              </div>
                            )}
                          </ItemDeleteOrPayNow>
                        </ItemHead>

                        <TransactionsItemBody
                          style={{
                            padding: '6px 0 0 0',
                            marginBottom: '16px',
                            border: 'none',
                          }}
                        >
                          <ItemRows>
                            <ItemLeftColumn>
                              <div className="timePeriod">
                                {getTextFromDataByKey(
                                  'ID_number',
                                  currentProductsData?.billing?.translate,
                                )}
                                {`:`}
                              </div>
                              <div className="timePeriod">
                                {getTextFromDataByKey(
                                  'upcomingPayment',
                                  currentProductsData?.billing?.translate,
                                )}
                                {`:`}
                              </div>
                              <div className="price">
                                {getTextFromDataByKey(
                                  'time_period',
                                  currentProductsData?.billing?.translate,
                                )}
                                {`:`}
                              </div>
                              <div className="price">
                                {getTextFromDataByKey(
                                  'price',
                                  currentProductsData?.billing?.translate,
                                )}
                                {`:`}
                              </div>
                              <div className="price">
                                {getTextFromDataByKey(
                                  'upcoming_payment',
                                  currentProductsData?.billing?.translate,
                                )}
                                {`:`}
                              </div>
                            </ItemLeftColumn>
                            <ItemRightColumn>
                              <div className="periodValue">
                                {row.purchasedItemId}
                              </div>
                              <div className="periodValue">
                                {formatDate({
                                  inputDate: row.nextPaymentTime,
                                  outputFormat: DateFormats.DefaultWithoutLines,
                                })}
                              </div>
                              <div
                                className="priceValue"
                                style={{ marginBottom: '6px' }}
                              >
                                {getTextFromDataByPeriod(
                                  row.product.productKey,
                                  translateKeys,
                                )}
                              </div>
                              <div
                                className="priceValue"
                                style={{ marginBottom: '6px' }}
                              >
                                {`₾ ${row.upcomingPayment.toFixed(2)}`}
                              </div>

                              <div className="priceValue">
                                {`₾ ${row.unitCost.toFixed(2)}`}
                              </div>
                            </ItemRightColumn>
                          </ItemRows>
                        </TransactionsItemBody>
                      </MobileTransactionsHistoryItem>
                    );
                  })}
              </MobileOnPersmiseTable>
            ) : (
              <TableWrapper lang={contextData.value}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headerColumnsData?.map((i, index, array) => (
                          <TableCell align={i.align}>{i.Header}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {billingData &&
                        billingData?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                            }}
                            style={{
                              opacity: row.status === 'CANCELING' ? 0.4 : 1,
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {iconRenderer(row)}
                            </TableCell>
                            <TableCell>
                              {row.createTime
                                ? formatDate({
                                    inputDate: row.createTime,
                                    outputFormat:
                                      DateFormats.DefaultWithoutLines,
                                  })
                                : '-'}
                            </TableCell>
                            <TableCell align="left">
                              {row.nextPaymentTime ? (
                                formatDate({
                                  inputDate: row.nextPaymentTime,
                                  outputFormat: DateFormats.DefaultWithoutLines,
                                })
                              ) : (
                                <span style={{ paddingLeft: '28%' }}>-</span>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {row.purchasedItemId}
                            </TableCell>
                            <TableCell
                              style={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                maxWidth: '200px',
                              }}
                            >
                              {row.itemName}
                            </TableCell>
                            <TableCell>
                              {getTextFromDataByPeriod(
                                row.product.productKey,
                                translateKeys,
                              )}
                            </TableCell>
                            <TableCell className="prices" align="right">
                              {`₾ ${row.unitCost.toFixed(2)}`}
                            </TableCell>
                            {row.overdueDays >= 0 &&
                            paymentStatus[row.purchasedItemId] !== 'paid' &&
                            row.status !== 'CANCELING' ? (
                              <TableCell align="right">
                                <BillingPayButton
                                  className="button1"
                                  onClick={() =>
                                    buyButtonClickHandler(row.purchasedItemId)
                                  }
                                >
                                  <span>{`₾ ${row.upcomingPayment.toFixed(
                                    2,
                                  )}`}</span>
                                  <span className="pay">
                                    {getTextFromDataByKey(
                                      'payNow',
                                      strapiData?.payNow,
                                    )}
                                  </span>
                                </BillingPayButton>
                              </TableCell>
                            ) : (
                              <TableCell
                                className="prices"
                                align="right"
                              >{`₾ ${row.upcomingPayment.toFixed(
                                2,
                              )}`}</TableCell>
                            )}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TableWrapper>
            )}
          </Pagination>
        )}
      </>
    </>
  );
};
export default BillingTable;
