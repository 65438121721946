import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const DateFormats = {
  Default: 'DD-MM-YYYY',
  DefaultForBack: 'YYYY-MM-DD',
  DefaultWithoutLines: 'DD/MM/YYYY',
  DefaultWithMonth: 'DD/MM/YYYY',
  DefaultWithDots: 'DD.MM.YYYY',
  DefaultWithMonthName: 'DD MMMM, YYYY',
  WithTime: 'DD-MM-YYYY HH:mm:ss',
  WithTimeForBots: 'DD-MM-YY-HH:mm:ss',
  Time: 'HH:mm:ss',
  TimeOnly: 'HH:mm',
  dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
};

const timeZoneOffset = new Date().getTimezoneOffset();

const getDateReturnValue = (inputDate) => {
  if (inputDate === undefined) return null;

  let dateToReturn = inputDate;

  if (!(inputDate instanceof Date)) dateToReturn = new Date(inputDate);

  if (dateToReturn instanceof Date) {
    if (isNaN(dateToReturn.getHours())) {
      return null;
    }
  }

  return dateToReturn;
};

// if fromUTC is true returns converted time, else returns without converting
const formatDate = ({ inputDate, outputFormat, fromUTC = false }) => {
  const dateToReturn = getDateReturnValue(inputDate);

  if (!dateToReturn) return null;

  const dateWithTimeZone = dayjs(dateToReturn).add(
    (timeZoneOffset / 60) * -1,
    'hour',
  );

  return fromUTC
    ? dayjs(dateToReturn).format(outputFormat)
    : dateWithTimeZone.format(outputFormat);
};

// this is used to send date to back
export const formatToIsoUtcDate = (inputDate, outputFormat) => {
  const dateToReturn = getDateReturnValue(inputDate);

  if (!dateToReturn) return null;

  const dateWithInvertedTimeZone = dayjs(dateToReturn).add(
    timeZoneOffset / 60,
    'hour',
  );

  return dateWithInvertedTimeZone.format(
    outputFormat || DateFormats.dateTimeFormat,
  );
};

// in case of UTC+x returns previous day -x hours
export const getStartOfDay = (inputDate) => {
  const dateToReturn = getDateReturnValue(inputDate);

  if (!dateToReturn) return null;

  return new Date(inputDate).setHours(0, 0, 0);
};

export default formatDate;
