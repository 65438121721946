import { createSelector, createSlice } from "@reduxjs/toolkit";
import * as actions from "./user.actions";

const initialState = {
  userInfo: null,
  loading: false,
  isLoggedIn: false,
  unauthRedir: false,
  error: false,
};

// slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStartAction: () => ({ ...initialState, loading: true }),
    loginSuccessAction: (state, action) => ({
      ...state,
      userInfo: action.payload,
      loading: false,
      isLoggedIn: true,
    }),
    unAuthRedirAction: () => ({ ...initialState, unauthRedir: true }),
    unAuthLoginSuccessAction: () => ({
      ...initialState,
      loading: false,
      isLoggedIn: false,
    }),
    loginErrorAction: () => initialState,
    logoutAction: () => initialState,
    changeUserInfo: (state, action) => ({
      ...initialState,
      isLoggedIn: true,
      userInfo: {
        ...state.userInfo,
        fullName: action.payload.fullName,
        idNumber: action.payload.idNumber,
        mobile: action.payload.mobile,
      },
    }),
  },
});

export const {
  loginStartAction,
  loginSuccessAction,
  unAuthLoginSuccessAction,
  logoutAction,
  changeUserInfo,
} = userSlice.actions;

// selectors

export const userSelectors = {
  selectIsUserLoggedIn: createSelector(
    (state) => state.user,
    (user) => user.isLoggedIn
  ),
  userInfo: createSelector(
    (state) => state.user,
    (user) => user.userInfo
  ),
  userInitials: createSelector(
    (state) => state.user,
    (user) =>
      user.userInfo?.fname && user.userInfo?.lname
        ? `${String(user.userInfo.fname).charAt(0).toUpperCase()}${String(
            user.userInfo.lname
          )
            .charAt(0)
            .toUpperCase()}`
        : ""
  ),
  error: createSelector(
    (state) => state.user,
    (user) => user.error
  ),
};

export const userActions = actions;

export const user = userSlice;

export default userSlice.reducer;
