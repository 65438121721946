import React from 'react';

export const BlogNextIcon = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.898"
      height="8.919"
      viewBox="0 0 5.898 8.919"
    >
      <g id="next" transform="translate(0 -0.203)">
        <g
          id="Group_40"
          data-name="Group 40"
          transform="translate(0 9.122) rotate(-90)"
        >
          <g id="Group_39" data-name="Group 39" transform="translate(0)">
            <path
              id="Path_52"
              data-name="Path 52"
              d="M7.7,0,4.459,3.336,1.222,0,0,1.268,4.459,5.9l4.459-4.63Z"
              transform="translate(0)"
              fill="#242333"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
