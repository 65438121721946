import React from 'react';

export const LeftIconArrow = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.917"
      height="15.203"
      viewBox="0 0 9.917 15.203"
      {...rest}
    >
      <g id="next" transform="translate(0.423 0.426)">
        <g
          id="Group_40"
          data-name="Group 40"
          transform="translate(0 14.352) rotate(-90)"
        >
          <g id="Group_39" data-name="Group 39" transform="translate(0)">
            <path
              id="Path_52"
              data-name="Path 52"
              d="M12.386,9.073,7.176,3.941,1.966,9.073,0,7.122,7.176,0l7.176,7.122Z"
              fill="#cdccd9"
              stroke="#fff"
              strokeWidth="0.6"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
