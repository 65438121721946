import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  BlogFlexContainer,
  BlogInfoFlexContainer,
  BlogInfoList,
  BlogMiddleContTitle,
  ContainerWrapper,
  SingleInfoContainer,
} from 'ui/styled/BlogsMiddleInfoContainerStyled';
import { ImagesRenderer } from 'utils/imagesRenderer';
import Slider from 'react-slick';
import { htmlParser } from 'utils/htmlUtils';

const BlogMiddleInfoContainer = (props) => {
  const { data } = props;
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <BlogFlexContainer style={{ margin: '0' }}>
      <BlogInfoFlexContainer>
        {isTabletOrMobile ? (
          <>
            <Slider {...settings}>
              {data?.map((item) => (
                <BlogInfoList key={item.id}>
                  <div className="icon">
                    <ImagesRenderer
                      url={item?.icon?.data?.attributes?.url}
                      alt={item?.icon?.data?.attributes?.alternativeText}
                    />
                  </div>
                  <BlogMiddleContTitle>{item?.title}</BlogMiddleContTitle>
                  <div className="text bodyL">{item?.description}</div>
                </BlogInfoList>
              ))}
            </Slider>
          </>
        ) : (
          <ContainerWrapper>
            {data?.map((item) => (
              <SingleInfoContainer key={item.id}>
                <div className="icon">
                  <ImagesRenderer
                    url={item?.icon?.data?.attributes?.url}
                    alt={item?.icon?.data?.attributes?.alternativeText}
                  />
                </div>
                <BlogMiddleContTitle>{item.title}</BlogMiddleContTitle>
                <div className="text Blogsbody">
                  {htmlParser(item.description)}
                </div>
              </SingleInfoContainer>
            ))}
          </ContainerWrapper>
        )}
      </BlogInfoFlexContainer>
    </BlogFlexContainer>
  );
};
export default BlogMiddleInfoContainer;
