import styled from 'styled-components';
import priceBG from 'assets/img/priceBG.svg';

export const SliderImgWrapper = styled.div`
  img {
    max-width: 100%;
  }
`;
export const PlayIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -43.5px;
  left: -43.5px;
  cursor: pointer;
`;
export const PlayIconWrapperMobile = styled.div`
  position: absolute;
  right: 36px;
  bottom: -10px;
  svg {
    width: 62px;
    height: 62px;
  }
`;

export const AddToCartComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 40px;
  .increment {
    top: -2 !important;
    right: 7px !important;
  }
  @media (max-width: 900px) {
    padding: 71px 34px 46px 34px;
  }
`;
export const AddToCartModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  .title {
    color: #242333;
    margin-bottom: 12px;
    font-weight: bold;
    @media (max-width: 900px) {
      margin-bottom: 9px;
      font-size: 22px;
      align-items: flex-start;
    }
  }
  .subTitle {
    color: #8a87a5;
    @media (max-width: 900px) {
      align-items: flex-start;
    }
  }
  @media (max-width: 900px) {
    margin-bottom: 20px;
    margin-top: 21px;
  }
`;
export const AddToCartModalBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  @media (max-width: 900px) {
    margin-bottom: auto;
  }
`;

export const AddToCartModalType = styled.div`
  flex-basis: 30%;
  border: 1px solid #dddbed;
  border-radius: 5px;
  padding-top: 24px;
  height: 108px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
  @media (max-width: 900px) {
    padding-top: 8px;
    height: 60px;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 8px;
    padding-left: 30px;
    align-items: center;
  }
  .type {
    color: #8a87a5;
    margin-bottom: 4px;
    text-align: center;
  }
  .price {
    color: #242333;
    font-weight: bold;
  }
  .offer {
    color: #8a87a5;
    position: absolute;
    bottom: 15px;
    .sale {
      color: #242333;
      font-weight: bold;
      background: url(${priceBG});
      padding: 0px 4px 0px 8px;
      background-repeat: no-repeat;
      text-align: right;
      background-size: cover;
      height: 18px;
      line-height: 18px;
      margin-left: 5px;
      display: inline-block;
      @media (max-width: 900px) {
        padding: 0px 4px 0px 13px;
        height: 24px;
        line-height: 25px;
      }
    }
    @media (max-width: 900px) {
      right: 30px;
      bottom: -1px;
    }
  }
  .active-check {
    width: 38px;
    height: 38px;
    background: #fff;
    position: absolute;
    z-index: 2;
    top: -15px;
    right: -15px;
    display: none;
    align-items: center;
    justify-content: center;
    @media (max-width: 900px) {
      width: 26px;
      height: 26px;
      top: -9px;
      right: -13px;
      background-color: transparent;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media (max-width: 900px) {
    .price {
      font-size: 22px !important;
    }
  }
`;
export const AddToCartTypesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  .active {
    border: 1px solid #ffdc04;
    .active-check {
      display: flex;
    }
  }
  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: ${(props) => (props.onPremise ? '16px' : '30px')};
    div {
      margin-bottom: 12px;
      :last-child {
        margin-bottom: 0px;
      }
    }
  }
`;
export const AddToCartProductQyt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    color: #3a3853;
  }
  .right {
    position: relative;
    flex-basis: 30%;
  }
  @media (max-width: 900px) {
    .left {
      font-size: 12px !important;
    }
  }
`;
export const AddToCartModalFoot = styled.div`
  @media (max-width: 900px) {
    button {
      height: 52px;
      font-size: 14px;
    }
  }
`;
export const AddToCartOnPermiseInfo = styled.div`
  margin-bottom: 20px;
  background: rgba(226, 182, 35, 0.1);
  padding: 28px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  @media (max-width: 900px) {
    margin-bottom: 24px;
    .left {
      display: none;
    }
    .right {
      .top {
        color: #7c6f67;
        line-height: 20px;
        font-weight: 500 !important;
      }
    }
  }
  @media (max-height: 700px) {
    margin-bottom: 10px;
    padding: 10px 25px;
  }
  .left {
    margin-right: 15px;
    img {
      width: 29px;
      height: 29px;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    .top {
      color: rgba(83, 69, 58, 1);
      margin-bottom: 5px;
      font-weight: bold;
      p {
        margin: 0;
        padding: 0;
      }
    }
    .bottom {
      color: #7c6f67;
    }
  }
`;

export const DemoModalStyled = styled.div`
  padding-top: 30px;
  @media (max-width: 900px) {
    padding: 0px;
    height: inherit;
  }
  .react-tabs {
    height: 100%;
    .react-tabs__tab-list {
      border-bottom: 1px solid #e4e2eb;
      margin: 0 30px;
      display: flex;
      justify-content: space-between;
      @media (max-width: 900px) {
        padding: 0;
        margin: 0 25px;
      }
    }
    .react-tabs__tab {
      border: none;
      color: #8a87a5;
      border-radius: 0;
      padding: 22px 0px;
      @media (max-width: 900px) {
        padding: 22px 0px;
        height: 100%;
      }
    }
    .react-tabs__tab:after {
      background: transparent;
    }
    .react-tabs__tab:focus {
      color: #3a3853;
      border-radius: 0;
      box-shadow: none;
      border-color: #ffdc04;
    }
    .react-tabs__tab--selected {
      .personalModal {
        padding: 0px 1px 0px 1px;
        background-color: red;
      }
      .companyModal {
        padding: 0px 1px 0px 1px;
        background-color: blue;
      }
      border-bottom: 2px solid #ffdc04;
      color: #3a3853;
      border-radius: 0;
      box-shadow: none;
      font-weight: 500;
      border-color: #ffdc04;
      outline: none;
      @media (max-width: 900px) {
        height: 100%;
      }
    }
    .react-tabs__tab-panel--selected {
      display: block;
      height: inherit;
    }
    @media (max-width: 900px) {
      .react-tabs__tab-panel--selected {
        height: inherit;
      }
    }
  }
`;

export const DemoModalFormStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 48px;
  form {
    width: 100%;
    @media (max-width: 900px) {
      height: inherit;
      display: flex;
      flex-direction: column;
    }
  }
  .react-select__control {
    height: 50px;
    border: 1px solid #dddbed;
    background: transparent;
    width: 100%;
    outline: none !important;
  }
  .react-select__control:hover,
  .react-select__control:focus {
    border-color: #dddbed !important;
    box-shadow: none;
    outline: none;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__value-container {
    padding: 0px 0px 0px 18px;
  }
  .react-select__placeholder {
    font-size: 14px;
  }
  .react-select__single-value,
  .react-select__input-container {
    color: #3a3853;
    margin-top: -5px;
  }

  .react-select__indicator svg {
    width: 18px;
  }
  .react-select__indicator svg path {
    fill: #3a3853;
  }
  .react-select__option--is-focused {
    background-color: #ffdc04;
  }
  .react-select__menu {
    border: none;
    background: #fff;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 33px 76px #83819f48;
    padding: 10px 18px;
  }
  .react-select__option {
    color: #8a87a5;
    padding-left: 27px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
  }
  .react-select__option:hover,
  .react-select__option--is-focused {
    background: none;
    background-color: none;
  }

  .react-select__option--is-selected,
  .react-select__option:active {
    background-color: rgba(235, 234, 244, 0.38) !important;
    color: #3a3853;
    font-weight: bold;
  }
  .submit {
    border: none;
    background: #ffdc04;
    width: 100%;
    padding: 14px 0;
    border-radius: 5px;
    color: #242333;
    outline: none;
    margin: 28px 0 38px 0;
    cursor: pointer;
    font-weight: bold;
    @media (max-width: 900px) {
      height: 52px;
      margin: auto 0px 0px 0px;
    }
  }
  @media (max-width: 900px) {
    padding-top: 34px;
    height: 95%;
  }
  @media (max-height: 600px) {
    padding-top: 12px;
    height: 95%;
  }
  .errorLang {
    border-color: #f55e1b;
    border-radius: 5px;
    border: 1px solid #f55e1b;
  }
`;

export const SolutionSliderCompWrapper = styled.div`
  display: flex;
  padding: 0 14%;
  margin: 80px 0;

  .slick-slider {
    max-height: 300px;
    width: 100%;
    border-radius: 8px;
    div {
      border-radius: 8px;
    }
  }
  .carouselItem {
    border-radius: 8px;
    img {
      width: 100%;
      max-height: 300px;
      border-radius: 8px;
    }
  }
  @media (max-width: 1540px) {
    padding: 0 8%;
  }
  @media (max-width: 1290px) {
    padding: 0 5%;
  }
  @media (max-width: 900px) {
    padding: 0 22px;
    margin: 40px 0;
    .carouselItem {
      img {
        max-height: 258px;
      }
    }
  }
  .bannerWithoutUrl {
    cursor: grabbing;
  }
`;

export const VideoBackDropWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  div,
  iframe {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 900px) {
    min-height: 335px;
    div,
    iframe {
      width: 100%;
      height: unset;
    }
    iframe {
      height: 288px;
    }
  }
`;

export const VideoBackDropClose = styled.div`
  position: absolute;
  position: absolute;
  top: -34px;
  right: -41px;
  z-index: 99969;
  cursor: pointer;
  width: auto !important;
  height: auto !important;
  @media (max-width: 900px) {
    top: 0px;
    right: -14px;
    img {
      width: 28px;
    }
  }
`;
export const OfferPageServicesWrap = styled.div`
  height: auto;
  width: 100%;
  background: #fcfaf5;
  padding: 66px 0 22px 0;
  margin-bottom: 100px;
  @media (max-width: 900px) {
    background: transparent;
    padding: 0;
  }
`;
