import { CartIcon } from 'assets/svgIcons/CartIcon';
import React from 'react';

import {
  CartCounter,
  CartIconWrapper,
  CartComponentStyled,
} from '../header.styled';
import CartDropdown from './CartDropdown';

import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addCartLastItem } from 'store/reducers/app/app.slice';

const CartComponent = (props) => {
  const {
    cartData,
    getCartData,
    isCartOpen,
    setIsCartOpen,
    translatesData,
    isOfferInUrlParams,
  } = props;
  const navigate = useNavigate();
  const distpath = useDispatch();
  const location = useLocation();

  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  function add(acc, a) {
    return acc + a.quantity;
  }

  const totalCartItems = cartData.reduce(add, 0);

  return (
    <CartComponentStyled
      onClick={() =>
        isTabletOrMobile
          ? navigate(
              location.pathname === '/offer' || isOfferInUrlParams
                ? `/cart/isOffer/${'default'}`
                : '/cart',
            )
          : setIsCartOpen(!isCartOpen)
      }
    >
      <CartIconWrapper
        style={{
          pointerEvents: isCartOpen ? 'none' : undefined,
        }}
      >
        <CartIcon />
        {cartData.length === 0 ? null : (
          <CartCounter className="bodyS">{totalCartItems}</CartCounter>
        )}
      </CartIconWrapper>
      {isCartOpen && !isTabletOrMobile ? (
        <CartDropdown
          cartList={cartData}
          isOfferInUrlParams={isOfferInUrlParams}
          getCartData={getCartData}
          close={() => {
            setIsCartOpen(false);
            distpath(addCartLastItem(null));
          }}
          translatesData={translatesData}
        />
      ) : null}
    </CartComponentStyled>
  );
};

export default CartComponent;
