import React from "react";
export const EmailConfIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="114"
    height="63.067"
    viewBox="0 0 114 63.067"
  >
    <g id="mail" transform="translate(-911 -354.933)">
      <g id="mail-send" transform="translate(899 269.673)">
        <path
          id="Path_79"
          data-name="Path 79"
          d="M138.969,140.508c.041-.008.081-.019.121-.03s.069-.016.1-.026.078-.027.116-.041.068-.023.1-.038.071-.033.107-.05.069-.032.1-.05.066-.038.1-.058.068-.039.1-.061.067-.049.1-.074.057-.041.085-.064c.051-.042.1-.087.147-.133l.026-.024c.009-.009.016-.019.025-.028.045-.047.09-.095.131-.145.024-.03.045-.061.068-.091s.048-.062.07-.095.044-.071.065-.107.037-.06.054-.091.036-.074.053-.111.032-.065.046-.1.027-.073.04-.11.027-.072.038-.108.02-.075.029-.113.02-.074.027-.111.014-.089.021-.134.01-.063.014-.095a2.357,2.357,0,0,0,.012-.236V87.626a2.365,2.365,0,0,0-2.365-2.366H67.286c-.021,0-.041,0-.062,0-.045,0-.09,0-.135.007s-.071.007-.106.012-.08.011-.12.018-.075.015-.112.023-.075.018-.112.029-.073.022-.109.034-.074.026-.11.04-.068.027-.1.043-.074.035-.111.053l-.092.05c-.037.021-.073.043-.108.066l-.086.058c-.033.024-.067.048-.1.074s-.059.048-.087.073-.056.05-.084.076-.061.061-.091.093c-.015.016-.031.03-.045.046s-.015.02-.023.029c-.03.034-.057.07-.085.107s-.041.053-.06.08-.047.072-.07.108-.038.06-.055.09-.037.072-.055.108-.033.066-.048.1-.029.073-.043.109-.027.071-.039.107-.021.073-.031.11-.021.075-.029.114-.013.072-.02.108-.014.082-.019.123-.006.069-.009.1-.006.088-.007.133c0,.013,0,.026,0,.038V99.864a2.366,2.366,0,0,0,4.731,0V92.879l20.423,18.638a2.39,2.39,0,0,0-.314.261l-20.11,20.7V117.489a2.366,2.366,0,0,0-4.731,0v2.334H52.7a2.365,2.365,0,1,0,0,4.731H64.92v13.635a2.365,2.365,0,0,0,2.366,2.365h71.221c.078,0,.156,0,.233-.012.036,0,.071-.01.107-.015S138.929,140.516,138.969,140.508Zm-2.827-8.031-19.726-20.925c-.013-.013-.027-.024-.041-.037l11.084-9.349A2.365,2.365,0,1,0,124.4,98.56L103.219,116.8,73.526,89.991h62.615ZM93.107,115.123a2.358,2.358,0,0,0,.413-.557l8.1,7.137a2.366,2.366,0,0,0,3.1.033l8.073-7.178a2.38,2.38,0,0,0,.281.341L132.8,135.823H73Z"
          transform="translate(-29.873)"
          fill="#242333"
        />
        <path
          id="Path_80"
          data-name="Path 80"
          d="M14.366,160.407H43.554a2.365,2.365,0,1,0,0-4.731H14.366a2.365,2.365,0,1,0,0,4.731Z"
          transform="translate(0 -48.207)"
          fill="#242333"
        />
        <path
          id="Path_81"
          data-name="Path 81"
          d="M26.491,119.076h9.8a2.366,2.366,0,1,0,0-4.731h-9.8a2.366,2.366,0,0,0,0,4.731Z"
          transform="translate(-7.985 -19.912)"
          fill="#242333"
        />
      </g>
      <circle
        id="Ellipse_23"
        data-name="Ellipse 23"
        cx="18.5"
        cy="18.5"
        r="18.5"
        transform="translate(988 381)"
        fill="#fff"
      />
      <path
        id="Path_86"
        data-name="Path 86"
        d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z"
        transform="translate(991 384)"
        fill="#ffdc04"
      />
      <path
        id="Path_11044"
        data-name="Path 11044"
        d="M-.208-.414l4.716,4.6-3,3.314"
        transform="translate(1010.131 397.284) rotate(90)"
        fill="none"
        stroke="#242333"
        strokeLinecap="round"
        strokeWidth="2.5"
      />
    </g>
  </svg>
);
