import React from 'react';
export const TableCheckIcon = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.917"
      height="19.406"
      viewBox="0 0 16.917 19.406"
      {...rest}
    >
      <g id="check" transform="translate(-351.664 -278.105)">
        <path
          id="Path_65234"
          data-name="Path 65234"
          d="M351.864,279.1c0-.556.269-.8.71-.794q7.56.017,15.12.007c.5,0,.687.209.687.759,0,7.386,0,10.08,0,17.465,0,.046,0,.092,0,.139a.589.589,0,0,1-.313.575.472.472,0,0,1-.59-.126c-.442-.452-.881-.908-1.321-1.362-.137-.141-.272-.283-.41-.423a.522.522,0,0,0-.871,0c-.35.348-.7.7-1.047,1.046a1.464,1.464,0,0,1-2.248-.018c-.332-.335-.663-.672-1-1.007s-.578-.344-.918,0c-.39.39-.767.8-1.174,1.163a1.38,1.38,0,0,1-1.923,0c-.4-.359-.777-.76-1.162-1.144-.36-.359-.589-.36-.948.009-.557.574-1.109,1.153-1.67,1.722a.5.5,0,0,1-.894-.22,1.389,1.389,0,0,1-.025-.343C351.87,294.668,351.877,282.777,351.864,279.1Zm1.073.409v15.843c.3-.31.571-.592.845-.871a1.472,1.472,0,0,1,2.293-.02c.345.343.685.69,1.031,1.031a.521.521,0,0,0,.871-.012c.338-.34.672-.684,1.011-1.022a1.467,1.467,0,0,1,2.278,0c.324.323.644.649.965.976.369.375.592.378.964.009s.759-.784,1.163-1.142a1.391,1.391,0,0,1,1.939.018c.236.21.45.452.675.68l.349.354V279.509Z"
          transform="translate(0)"
          fill="#242333"
          stroke="#242333"
          strokeWidth="0.4"
        />
        <path
          id="Path_65236"
          data-name="Path 65236"
          d="M406.6,410.371h-3.19v-1.177s4.22,0,6.272,0v1.177H406.6Z"
          transform="translate(-46.358 -120.994)"
          fill="#242333"
          stroke="#242333"
          strokeWidth="0.4"
        />
        <path
          id="Path_65237"
          data-name="Path 65237"
          d="M406.6,410.371h-3.19v-1.177s4.22,0,6.272,0v1.177H406.6Z"
          transform="translate(-46.358 -124.994)"
          fill="#242333"
          stroke="#242333"
          strokeWidth="0.4"
        />
      </g>
    </svg>
  );
};
