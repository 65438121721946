import React, { useContext, useEffect, useState } from 'react';

import ComponentWithLoaderAndError from 'ui/shared/componentWithLoaderAndError/ComponentWithLoaderAndError';
import {
  MobileTermsContent,
  MobileTermsFilter,
  MobileTermsHeader,
  MobileTermsList,
  MobileTermsListItem,
  MobileTermsNavList,
  MobileTermsNavListItem,
  MobileTermsNavTitle,
  MobileTermsNavWrapper,
  TermsBody,
  TermsHeader,
  TermsHeaderInner,
} from 'ui/styled/terms.styled';
import { FaqHeaderSection } from 'ui/styled/FaqStyled';

import TabContent from 'library/tab-content';
import Tabs, { Panel } from 'library/tabsScrollSpy';
import { focusonFormsGroup } from 'utils/helpers';
import { StrapiDataContext } from 'Main';
import { useMediaQuery } from 'react-responsive';
import BottomSheet from 'library/bottomSheet/BottomSheet';
import CustomSelect from 'library/Select/Select';
import { htmlParser } from 'utils/htmlUtils';
import CustomHead from 'ui/components/customHead/custom-head';
import { useSearchParams } from 'react-router-dom';

const Terms = () => {
  const { termsData: data } = useContext(StrapiDataContext);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const [termsBottomSheet, setTermsBottomSheet] = useState(false);
  const termsData =
    data && data.data ? data.data.data.attributes.accordion : [];

  const [selected, setSelected] = React.useState(0);

  const content = termsData.map((e, index) => {
    return (
      <TabContent
        key={index}
        e={e}
        index={index}
        id={`section${index}`}
        onChange={(isVisible) => isVisible && setSelected(index)}
      />
    );
  });

  useEffect(() => {
    if (isTabletOrMobile && termsBottomSheet) {
      focusonFormsGroup(selected);
    }
  }, [selected, termsBottomSheet]);

  const termsDataOptions = termsData.map((item) => {
    return {
      label: item.title,
      value: item.id,
    };
  });

  let [searchParams, setSearchParams] = useSearchParams();
  const searchParamId = searchParams.get('id');
  const searchParamIdFromLocalStorage = localStorage.getItem('searchParamId');

  useEffect(() => {
    if (searchParamId) {
      localStorage.setItem('searchParamId', searchParamId);
      focusonFormsGroup(`section${searchParamId}`);
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParamIdFromLocalStorage) {
      focusonFormsGroup(`section${searchParamIdFromLocalStorage}`);
    }
  }, [searchParams]);

  return (
    <ComponentWithLoaderAndError data={termsData} error={data.error}>
      <CustomHead seo={data?.data?.data.attributes?.seo} />
      <>
        <BottomSheet
          isOpen={termsBottomSheet}
          padding="51px 24px 10px 24px"
          closeBottomSheet={() => setTermsBottomSheet(false)}
        >
          <MobileTermsNavWrapper>
            <MobileTermsNavTitle>Choose Type</MobileTermsNavTitle>
            <MobileTermsNavList>
              {termsData.map((item, index) => (
                <MobileTermsNavListItem
                  key={index}
                  id={index}
                  className={selected === index ? 'active' : ''}
                  onClick={() => {
                    setSelected(index);
                    focusonFormsGroup(`content-${index}`);
                    setTermsBottomSheet(false);
                  }}
                >
                  {item.title}
                </MobileTermsNavListItem>
              ))}
            </MobileTermsNavList>
          </MobileTermsNavWrapper>
        </BottomSheet>
        <FaqHeaderSection>
          {isTabletOrMobile ? (
            <MobileTermsHeader>
              <div className="title">{data?.data?.data?.attributes?.title}</div>
              <div className="date">
                {data?.data?.data?.attributes?.subTitle}
              </div>
            </MobileTermsHeader>
          ) : (
            <TermsHeader>
              <TermsHeaderInner>
                <span className="title">
                  {data?.data?.data?.attributes?.title}
                </span>
                <span className="date bodyL">
                  {data?.data?.data?.attributes?.subTitle}
                </span>
              </TermsHeaderInner>
            </TermsHeader>
          )}

          {isTabletOrMobile ? (
            <MobileTermsContent>
              <MobileTermsFilter onClick={() => setTermsBottomSheet(true)}>
                <CustomSelect
                  type="text"
                  search={false}
                  disabled
                  value={termsDataOptions[selected]}
                  options={termsDataOptions}
                />
              </MobileTermsFilter>
              <MobileTermsList>
                {termsData.map((p, index) => (
                  <MobileTermsListItem
                    key={index + p.id}
                    id={`content-${index}`}
                  >
                    <div className="title">{p.title}</div>
                    <div className="text">{htmlParser(p.text)}</div>
                  </MobileTermsListItem>
                ))}
              </MobileTermsList>
            </MobileTermsContent>
          ) : (
            <TermsBody
              style={{ marginBottom: !isTabletOrMobile ? '100px' : '0' }}
            >
              <Tabs
                content={content}
                selected={selected}
                handleChangeIndex={(index) => {
                  focusonFormsGroup(`section${index}`);
                  setSelected(index);
                  localStorage.removeItem('searchParamId');
                }}
              >
                {termsData.map((p) => (
                  <Panel title={p.title} key={p.title} />
                ))}
              </Tabs>
            </TermsBody>
          )}
        </FaqHeaderSection>
      </>
    </ComponentWithLoaderAndError>
  );
};

export default Terms;
