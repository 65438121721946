import styled from 'styled-components';

export const BottomSheetBackgroundStyled = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(20, 22, 31, 0.7);
  transition: all 0.15s;
  padding: 1em;
  overflow: overlay;
  @media (max-width: 900px) {
    .BottmoSheetBody {
      .thumb-vertical {
        background: transparent !important;
      }
      ${({ overflowHidden }) => {
        return {
          overflow: overflowHidden ? 'hidden' : undefined,
        };
      }}
    }

    overflow-x: scroll;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const BottmoSheetBody = styled.div`
  position: absolute;

  color: #fff;
  padding: ${(props) => props.padding || '40px'};
  box-sizing: border-box;
  background-color: ${(props) => props.background || '#fff'};
  flex: 0 0 auto;
  width: 100%;
  height: ${(props) => props.height || 'auto'};
  overflow-y: ${(props) => (props.isFullSize ? 'auto' : 'visible')};
  bottom: 0;
  border-top-left-radius: ${(props) => (props.isFullSize ? '0' : '25px')};
  border-top-right-radius: ${(props) => (props.isFullSize ? '0' : '25px')};
  display: flex;
  justify-content: center;
  @media (max-width: 900px) {
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const BottomSheetSwipeTrigger = styled.div`
  position: absolute;
  top: -20px;
  left: 50%;
  right: 0;
  margin-left: -35px;
  border-radius: 38px;
  width: 70px;
  height: 9px;
  background: rgb(40, 48, 59);
`;
