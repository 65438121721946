import styled from 'styled-components';
import reactBg from 'assets/img/reactBg.svg';

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 42px;
  .last {
    .lastInForget:last-child {
      margin-bottom: 0px !important;
    }
  }
  @media (max-width: 900px) {
    .last:last-child {
      margin-bottom: 12px;
    }
    .lastInLogin {
      margin-bottom: 50px !important;
    }
    .lastInForget:last-child {
      margin-bottom: 18px;
    }
  }
`;
export const AuthModalWrapper = styled.div`
  height: ${(props) =>
    props.className === 'register' ? 'inherit' : undefined};
  @media (max-width: 900px) {
    .register {
      height: inherit;
      .react-tabs {
        height: inherit;
        .react-tabs__tab-panel--selected {
          height: calc(100% - 60px);
          .formErr {
            height: calc(100% - 160px);
          }
        }
      }
    }
  }
  @media (max-width: 320px) {
    height: unset;
  }
  @media (max-width: 900px) {
    height: ${(props) =>
      props.className === 'forget' || props.className === 'forgetForm'
        ? 'inherit'
        : undefined};
    .forget {
      height: inherit;
      ${LoginWrapper} {
        height: inherit;
        padding-top: 0px;
      }
    }
  }
  .login {
    ${LoginWrapper} {
      @media (max-width: 900px) {
        padding-top: 0px;
        height: inherit;
        justify-content: flex-start;
        position: relative;
        bottom: 118px;
      }
    }
  }

  @media (max-width: 900px) {
    .EmailSuccess {
      height: inherit;
      .react-tabs {
        height: inherit;
        .react-tabs__tab-panel--selected {
          height: calc(100% - 25px);
        }
      }
    }
  }

  @media (max-width: 900px) {
    .forgetForm {
      height: inherit;

      ${LoginWrapper} {
        height: inherit;
        padding-top: 0px;
      }
    }
  }

  .react-tabs {
    .react-tabs__tab-list {
      border-bottom: 1px solid #e4e2eb;
      margin: 0 30px;
      display: flex;
      justify-content: space-between;

      @media (max-width: 900px) {
        padding: 0;
      }
      @media (max-height: 700px) {
        margin: 0 23px;
      }
    }
    .react-tabs__tab {
      border: none;
      color: #8a87a5;
      padding: 22px 0px;
      border-radius: 0;
      @media (max-width: 900px) {
        padding: 22px 0px;
      }
    }
    .react-tabs__tab:after {
      background: transparent;
    }
    .react-tabs__tab:focus {
      color: #3a3853;
      border-radius: 0;
      box-shadow: none;
      border-color: #ffdc04;
    }
    .react-tabs__tab--selected {
      border-bottom: 3px solid #ffdc04;
      color: #3a3853;
      border-radius: 0;
      box-shadow: none;
      border-color: #ffdc04;
      outline: none;
      padding-bottom: 20px;
      font-weight: 500;
      background: transparent;
      @media (max-width: 900px) {
        height: 100%;
      }
    }
    .react-tabs__tab-panel--selected {
      width: 100%;
    }
  }
`;
export const MobileBg = styled.div`
  background: url(${reactBg});
  background-repeat: no-repeat;
  min-height: 261px;
  max-height: 261px;
  border-bottom-right-radius: 332px;
  background-position: top left;
  position: relative;
  top: -70px;
  left: -35px;
  width: 390px;
  @media (max-height: 700px) {
    min-height: 195px;
    max-height: 195px;
    top: -76px;
    left: -57px;
  }
  @media (max-width: 360px) {
    left: -72px;
  }
`;

export const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  .login {
    @media (max-width: 900px) {
      height: inherit;
      justify-content: flex-start;
    }
  }
  .forget {
    @media (max-width: 900px) {
    }
  }
`;

export const BottomSignInfo = styled.div`
  color: #8a87a5;
  text-align: center;
  @media (max-width: 900px) {
    padding-top: 10px !important;
    bottom: -5px !important;
    position: relative;
  }
  @media (max-width: 385px) {
    padding-bottom: 100px !important;
  }
  .sign {
    color: #3a3853;
    padding-left: 3px;
    cursor: pointer;
    font-weight: 500;
  }
`;
export const LoginWelcomeText = styled.div`
  color: #242333;
  font-weight: bold;
  margin-bottom: 20px;
  @media (max-width: 900px) {
    display: flex;
    justify-content: ${(props) => (props.login ? 'center' : 'flex-start')};
    align-items: center;
    margin-bottom: ${(props) =>
      props.login ? '38px' : props.forgetForm ? '9px' : '11px'};
  }
`;
export const RegisterRowItem = styled.div`
  margin-bottom: ${(props) => (props.errors ? '15px' : '22px')};
  &.lastInForget {
    margin-bottom: 0px !important;
  }

  position: relative;
  .input-label {
    font-weight: normal;
  }
  .errorBorder {
    border-color: #f55e1b;
  }
  input {
    width: 100%;
    height: 50px;
    color: #3a3853;
    border: 1px solid rgba(58, 56, 83, 0.2);
    border-radius: 5px;
    background: transparent;
    outline: none;
    padding-left: 20px;
    transition: all 0.3s;
    cursor: auto;
    :focus {
      color: #3a3853;
      border: 1px solid rgba(58, 56, 83, 0.6);
    }
    @media (max-width: 900px) {
      height: 52px;
      padding-left: 24px;
      color: #8a87a5;
    }
  }
  .showPass {
    position: absolute;
    right: 0px;
    cursor: pointer;
    transition: all 0.3s;
    top: 5px;
    bottom: 5px;
    padding: 15px 40px 15px 40px;
  }
  .activeShown {
    top: 0px;
    bottom: 0px;
    padding: 15px 40px 15px 40px;
    svg {
      transition: all 0.3s;
    }
  }
  .forgetPass {
    position: absolute;
    top: 15px;
    right: 36px;
    cursor: pointer;
    font-weight: bold;
    @media (max-width: 900px) {
      top: 16px;
    }
  }
  @media (max-width: 900px) {
    margin-bottom: 14px;
  }
`;
export const RegisterRowFull = styled.div`
  display: flex;
  flex-direction: column;
  ${RegisterRowItem} {
    flex-basis: 100%;
  }
`;

export const LoginStyled = styled.div`
  .submit {
    border: none;
    background: #ffdc04;
    width: 100%;
    padding: 14px 0;
    border-radius: 5px;
    color: #242333;
    outline: none;
    margin: 28px 0 38px 0;
    cursor: pointer;
    font-weight: bold;
    @media (max-width: 700px) {
      margin: 0px;
    }
  }
  @media (max-width: 900px) {
    height: inherit;
    form {
      height: inherit;
      display: flex;
      flex-direction: column;
      ${RegisterRowFull} {
        margin-bottom: auto;
      }
    }
  }
`;
export const ForgetPassTextWrapper = styled.div`
  @media (max-width: 900px) {
    margin-bottom: auto;
    height: calc(100% - 85px);
  }
`;
export const RegisterStyled = styled.div`
  padding-top: 42px;
  .last :last-child {
    margin-bottom: 0px;
  }
  @media (max-width: 900px) {
    padding-top: 34px;
    height: 100%;
    .last :last-child {
      margin-bottom: 0px;
    }
    &.formErr {
      padding-top: 18px;
    }
  }
  @media (max-height: 700px) {
    padding-top: 16px;
    height: inherit;
  }
  form input {
    height: 50px;
  }
  form {
    width: 100%;
    @media (max-width: 900px) {
      height: 100%;
      display: flex;
      flex-direction: column;
      .agreement {
        margin-bottom: auto;
      }
    }
  }
  .submit {
    border: none;
    background: #ffdc04;
    width: 100%;
    padding: 14px 0;
    border-radius: 5px;
    color: #242333;
    outline: none;
    margin: 28px 0 38px 0;
    cursor: pointer;
    font-weight: bold;
    @media (max-width: 900px) {
      height: 52px;
      margin: 0 0 36px 0;
      font-size: 14px;
    }
    @media (max-width: 400px) {
      height: 52px;
      margin: 36px 0 26px 0;
    }
  }
`;

export const RegisterRowHalf = styled.div`
  display: flex;
  justify-content: space-between;
  ${RegisterRowItem} {
    flex-basis: 48%;
  }
`;

export const RegisterVerifyWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-top: 50px;
  @media (max-width: 900px) {
    padding-top: 0px;
    flex-grow: 1;
  }
  @media (max-height: 700px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;
export const RegisterVerifyWrapperBottomSht = styled.div`
  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 0px;
  }
`;
export const RegisterTextWrapper = styled.div`
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
export const VerifyIconBlock = styled.div`
  margin-bottom: 5px;
  padding-top: 32px;
  @media (max-width: 900px) {
    padding-top: 0px;
    margin-bottom: 16px;
  }
  svg {
    width: 58px;
    @media (max-width: 900px) {
      width: 104px;
    }
  }
`;
export const VerifyIconBlockBottomSheet = styled.div`
  @media (max-width: 900px) {
    margin-bottom: 10px;
  }
  svg {
    width: 58px;
  }
`;
export const VerifyTitle = styled.div`
  color: #3a3853;
  font-weight: bold;
  @media (max-width: 900px) {
    margin-bottom: 15px;
  }
`;
export const VerifyTitleBottomSheet = styled.div`
  @media (max-width: 900px) {
    color: #3a3853;
    font-weight: bold;
    margin-bottom: 13px;
  }
`;
export const VerifyDesc = styled.div`
  line-height: 33px;
  color: #8a87a5;
  margin-bottom: 74px;
  @media (max-width: 900px) {
    margin-bottom: 37px;
    line-height: 22px;
  }
`;
export const VerifyDescBottomSheet = styled.div`
  @media (max-width: 900px) {
    color: #8a87a5;
    margin-bottom: 37px;
    p {
      margin: 0px;
    }
  }
`;
export const ResendCodeBlock = styled.div`
  color: #8a87a5;
  @media (max-width: 900px) {
    margin-top: auto;
  }
  .timer,
  .resend {
    color: #f2d20e;
    padding-left: 2px;
  }
  .resend {
    color: #242333;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const BtnGroupDemo = styled.div`
  position: relative;
  @media (max-width: 900px) {
    margin-top: auto;
  }
`;

export const BtnGroupMobile = styled.div`
  position: relative;
  @media (max-width: 900px) {
  }
  @media (max-height: 700px) {
  }
`;
export const ForgotPassDesc = styled.div`
  color: #8a87a5;
  margin-bottom: 30px;
  @media (max-width: 900px) {
    margin-bottom: ${(props) => (props.forgetPas ? '34px' : '27px')};
  }
`;

export const FormErrorWrapper = styled.div`
  background: rgba(245, 94, 27, 0.08);
  padding: 12px 40px;
  border-radius: 5px;
  margin-bottom: 20px;
  color: rgba(245, 94, 27, 1);
  text-align: center;
  overflow: hidden;
  word-break: break-all;
  @media (max-width: 900px) {
    margin-bottom: 22px;
    border-radius: 4px;
  }
`;

export const PasswordRequirements = styled.div`
  margin: 20px 0 40px 0;
  ul {
    margin: 0;
    padding: 0 0 0 5px;
    list-style-type: none;
    li {
      color: rgba(196, 195, 211, 1);
      line-height: 26px;
      font-weight: 400;
      display: flex;
      align-items: center;
      span {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: rgba(196, 195, 211, 1);
        display: inline-block;
        margin-right: 18px;
      }
    }
  }
  .inValid {
    color: rgba(196, 195, 211, 1);
    span {
      background: rgba(245, 94, 27, 1);
    }
  }
  .isValid {
    color: rgba(138, 135, 165, 1);
    span {
      background: rgba(70, 219, 133, 1);
    }
  }
`;
export const PasswordRequirementsForAuth = styled.div`
  ${({ margin }) => {
    return { margin: margin ? margin : ' 6px 0px 0px 0px' };
  }}
  @media (max-width: 900px) {
    margin: 6px 0px 37px 0px;
    font-size: 12px;
    &.forgotForm {
      margin: 18px 0px auto 0px;
    }
  }
  @media (max-height: 700px) {
    margin: 6px 0px 37px 0px;
  }
  span {
    color: rgba(196, 195, 211, 1);
    line-height: 26px;
    font-weight: 400;
    align-items: center;
    @media (max-width: 900px) {
      font-size: 12px;
      line-height: 14px;
    }
    span {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: rgba(196, 195, 211, 1);
      display: inline-block;
      margin-right: 18px;
    }
  }
  .inValid {
    color: #f55e1b;
  }
  .isValid {
    color: #8a87a5;
  }
`;

export const FormFieldsErrorWrapper = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 6px;
`;
