import React, { useContext, useEffect, useState } from 'react';
import Modal from 'library/modal/Modal';
import {
  OfferPageServicesWrap,
  PlayIconWrapper,
  PlayIconWrapperMobile,
  SliderImgWrapper,
  VideoBackDropClose,
  VideoBackDropWrapper,
} from 'ui/styled/home.styled';
import YouTube from 'react-youtube';
import { textRenderer, youtube_parser } from 'utils/helpers';
import closeIcon from 'assets/img/closeVideoIcon.svg';
import ComponentWithLoaderAndError from 'ui/shared/componentWithLoaderAndError/ComponentWithLoaderAndError';
import {
  LeftSection,
  RightSection,
  SeciontBlock,
} from 'ui/components/common/layout';
import { useMediaQuery } from 'react-responsive';
import TopParagraphWithInput from 'ui/components/plansSection/TopParagraphWithInput';
import { imagesBaseUrl, TOKEN_NAME } from 'constants/global.constants';
import { PlayIcon } from 'assets/svgIcons/PlayIcon';
import { StrapiDataContext } from 'Main';
import SolutionSliderComp from '../Home/SolutionSliderComp';
import {
  PlanItemButtons,
  PlansSectionWrapper,
} from 'ui/styled/PlansSection.styled';
import PlanTitle from 'ui/components/plansSection/PlanTitle';
import PlansList from 'ui/components/plansSection/PlansList';
import { Button } from 'library/button/Button';
import { CartIcon } from 'assets/svgIcons/CartIcon';
import { DownloadIcon } from 'assets/svgIcons/Download';
import video from 'assets/img/video.svg';
import { ImagesRenderer } from 'utils/imagesRenderer';
import Services from '../Services/Services';
import AddToCartComponent from '../Home/AddToCartComponent';
import cacheManager from 'utils/cacheManager';
import DemoModalComponent from '../Home/Demo/DemoModal';
import { useSearchParams } from 'react-router-dom';

const Offers = (props) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const tokenCache = cacheManager(TOKEN_NAME);
  const token = tokenCache.get();
  const { isCartOpen, setIsCartOpen, setIsOpen, cartData, getCartData } = props;
  const [videoBackdrop, setVideoBackdrop] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [addToCartModal, setAddToCartModal] = useState(false);
  const [demoModal, setDemoModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedItemVideo, setSelectedItemVideo] = useState(null);

  const { homeData, offerData, offerProducts } = useContext(StrapiDataContext);

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  const homePageData = homeData.data ? homeData?.data?.data?.attributes : null;

  const offerPageData = offerData.data
    ? offerData?.data?.data?.attributes
    : null;

  const offerPageProducts = offerProducts?.data
    ? offerProducts?.data?.data
    : null;

  const offerPageProductsToShow = offerPageProducts
    ? offerPageProducts.filter(
        (item) =>
          item.attributes.offerPage === 'offer' ||
          item.attributes.offerPage === 'all',
      )
    : [];

  const pricesToRender = selectedProduct?.attributes?.prices.filter(
    (item) => !item.isDemo,
  );
  const onlyOnePrice = pricesToRender && pricesToRender.length === 1;
  if (!offerPageData) return <div></div>;

  return (
    <>
      <Modal
        closeIcon
        width={onlyOnePrice ? '500px' : '640px'}
        spacing={onlyOnePrice ? '30px 60px 40px 60px' : undefined}
        height="auto"
        className="homeAddcartModal"
        isOpen={addToCartModal}
        canCloseOnOutsideClick={false}
        closeModal={() => {
          setAddToCartModal(false);
        }}
      >
        <AddToCartComponent
          selectedProduct={selectedProduct}
          closeModal={() => setAddToCartModal(false)}
          getCartData={getCartData}
          cartData={cartData}
          isCartOpen={isCartOpen}
          setIsCartOpen={setIsCartOpen}
          setIsOpen={setIsOpen}
          token={token}
        />
      </Modal>
      <Modal
        closeIcon
        width="640px"
        height="auto"
        isOpen={demoModal}
        canCloseOnOutsideClick={false}
        className="demoAddModal"
        closeModal={() => {
          setDemoModal(false);
        }}
      >
        <DemoModalComponent
          closeModal={() => {
            setDemoModal(false);
          }}
          selectedProduct={selectedProduct}
          homePageData={homePageData}
          isTabletOrMobile={isTabletOrMobile}
        />
      </Modal>
      <Modal
        width="60%"
        height="60%"
        isOpen={videoBackdrop}
        className="youtube-modal"
        canCloseOnOutsideClick={false}
        closeModal={() => {
          setVideoBackdrop(false);
        }}
      >
        <VideoBackDropWrapper>
          {selectedItemVideo ? (
            <YouTube videoId={youtube_parser(selectedItemVideo)} opts={opts} />
          ) : null}
          <VideoBackDropClose onClick={() => setVideoBackdrop(false)}>
            <img src={closeIcon} alt="close" />
          </VideoBackDropClose>
        </VideoBackDropWrapper>
      </Modal>

      <ComponentWithLoaderAndError
        data={offerPageData || offerPageProducts}
        error={offerPageData?.error || offerPageProducts?.error}
      >
        {offerPageData ? (
          <SeciontBlock>
            <LeftSection leftToRight>
              <TopParagraphWithInput
                title={textRenderer(offerPageData?.header?.title)}
                description={textRenderer(offerPageData?.header?.description)}
                registerButton={textRenderer(
                  offerPageData?.header?.registration,
                )}
                setIsOpen={setIsOpen}
              />
            </LeftSection>

            <RightSection
              id="top-bg-img"
              className="top-bg-img"
              style={{
                backgroundImage: `url(
                ${imagesBaseUrl}${offerPageData?.header?.banner?.data?.attributes?.url}
              )`,
              }}
            >
              <SliderImgWrapper>
                <>
                  {isTabletOrMobile ? (
                    <PlayIconWrapperMobile
                      onClick={() => {
                        setVideoBackdrop(true);
                        setSelectedItemVideo(
                          offerPageData?.header?.youtubeVideo,
                        );
                      }}
                    >
                      <PlayIcon />
                    </PlayIconWrapperMobile>
                  ) : (
                    <PlayIconWrapper
                      onClick={() => {
                        setVideoBackdrop(true);
                        setSelectedItemVideo(
                          offerPageData?.header?.youtubeVideo,
                        );
                      }}
                    >
                      <PlayIcon />
                    </PlayIconWrapper>
                  )}
                </>
              </SliderImgWrapper>
            </RightSection>
          </SeciontBlock>
        ) : null}
        <SolutionSliderComp data={offerPageData?.banners} />

        {offerPageProductsToShow ? (
          <>
            {offerPageProductsToShow.map((product, index) => {
              const showDemo = product?.attributes?.prices?.find(
                (item) => item.isDemo,
              );

              return (
                <SeciontBlock key={product.id + product?.attributes?.name}>
                  <LeftSection leftToRight>
                    <PlansSectionWrapper left>
                      <PlanTitle
                        isOfferPage
                        visualPrice={product?.attributes?.visualPrice}
                        oldPrice={product?.attributes?.oldPrice}
                        planTitle={product?.attributes?.name}
                        planText={product?.attributes?.description}
                      />

                      <PlansList planItems={product?.attributes?.bullet} />
                      <PlanItemButtons>
                        <Button
                          className="cart-btn"
                          background="#FFDC04"
                          color="#242333"
                          padding="7px 38px"
                          fontWeight="500"
                          onClick={() => {
                            setSelectedProduct(product);
                            setAddToCartModal(true);
                          }}
                        >
                          <CartIcon style={{ marginRight: '12px' }} />
                          {product?.attributes?.buyBtn}
                        </Button>
                        {showDemo && showDemo.isDemo ? (
                          <Button
                            width="150px"
                            background="rgba(255, 220, 4, 0.3)"
                            color="#242333"
                            fontWeight="500"
                            className="demo-btn"
                            onClick={() => {
                              setSelectedProduct(product);
                              setDemoModal(true);
                            }}
                          >
                            <DownloadIcon
                              style={{
                                marginRight: isTabletOrMobile ? '0' : '7px',
                              }}
                            />
                            {isTabletOrMobile
                              ? null
                              : product?.attributes?.demoBtn}
                          </Button>
                        ) : null}
                        <img
                          className="videoBtn"
                          src={video}
                          alt="videoBtn"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setVideoBackdrop(true);
                            setSelectedItemVideo(product.attributes.video);
                          }}
                        />
                      </PlanItemButtons>
                    </PlansSectionWrapper>
                  </LeftSection>

                  <RightSection>
                    <ImagesRenderer
                      url={
                        !isTabletOrMobile
                          ? product?.attributes?.img?.data?.attributes?.url
                          : product?.attributes?.responsiveImg?.data?.attributes
                              ?.url
                      }
                      alt={
                        product?.attributes?.img?.data?.attributes
                          ?.alternativeText
                      }
                    />
                  </RightSection>
                </SeciontBlock>
              );
            })}
          </>
        ) : null}
        <OfferPageServicesWrap>
          <Services {...props} isOfferPage />
        </OfferPageServicesWrap>
      </ComponentWithLoaderAndError>
    </>
  );
};

export default Offers;
