import React from 'react';

export const CalendarIcon = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.677"
      height="16.108"
      viewBox="0 0 14.677 16.108"
      {...rest}
    >
      <g id="XMLID_14_" transform="translate(0.05 0.05)">
        <path
          id="XMLID_15_"
          d="M.712,15.008H13.865c.393,0,.712-.712.712-.712V1.136a.712.712,0,0,0-.712-.712H11.423V-.288A.712.712,0,0,0,10.711-1,.712.712,0,0,0,10-.288V.424H4.577V-.288A.712.712,0,0,0,3.865-1a.712.712,0,0,0-.712.712V.424H.712A.712.712,0,0,0,0,1.136V14.3A.712.712,0,0,0,.712,15.008Zm.712-1.424V5.695H13.153v7.889ZM13.153,1.847V4.271H1.424V1.847Z"
          transform="translate(0 1)"
          fill="#8a87a5"
          stroke="#8a87a5"
          strokeWidth="0.1"
        />
      </g>
    </svg>
  );
};
