import styled from 'styled-components';
import lineBG from 'assets/img/lineBg.svg';
export const BillingModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  height: 100%;
  @media (max-width: 900px) {
    margin-bottom: 0;
  }
`;
export const InfoNoteBlock = styled.div`
  margin-bottom: 25px;
  color: #242333;
  font-weight: bold;
  padding-top: 30px;
  padding-left: 10px;
`;

export const BillingModalProductWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  position: relative;
`;

export const BillingModalProductInfo = styled.div`
  color: #8a87a5;
  font-weight: 400;
  padding-right: 10px;
  background: #fff;
  z-index: 2;
`;
export const DashedLine = styled.div`
  height: 2px;
  background-image: url(${lineBG});
  position: absolute;
  background-repeat: repeat-x;
  width: 90%;
`;
export const BillingModalProductDescr = styled.div`
  color: #3a3853;
  font-weight: 500;
  padding-left: 10px;
  background: #fff;
  z-index: 2;
`;

export const CardAndChangeButtonStyled = styled.div`
  display: flex;
`;
export const BillingModalTotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding: 12px 10px;
  margin: 20px 0px 25px 0px;
  background-color: rgba(235, 234, 244, 0.4);
  border-radius: 6px;
  .totalPrice {
    font-weight: bold;
    color: #3a3853;
  }
  :before {
    content: '';
    position: absolute;
    z-index: -1;
  }
`;
