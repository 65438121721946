import React, { useContext, useEffect, useState } from 'react';
import {
  UserProfileWrapper,
  ChangeProperties,
  UserProfileBg,
} from 'ui/styled/UserProfile.styled';
import ChangePassForm from './ChangePassForm';
import PersonalInfoForm from './PersonalInfoForm';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import useFetchData from 'hooks/useFetchData';
import { getDataUrlTypes } from 'constants/global.constants';
import { appSelectors } from 'store/reducers/app/app.slice';
import ComponentWithLoaderAndError from 'ui/shared/componentWithLoaderAndError/ComponentWithLoaderAndError';
import { getTextFromDataByKey } from 'utils/helpers';
import { StrapiDataContext } from 'Main';
import CustomHead from 'ui/components/customHead/custom-head';

export const tabs = {
  personalInfo: 'Personal Info',
  changePassword: 'Change Password',
};

const UserProfile = (props) => {
  const { activeTab } = props;
  const { userProfileData: data, settingsData } = useContext(StrapiDataContext);

  const [activeTabIndex, setActiveTabIndex] = useState(null);

  useEffect(() => {
    const { activeTab } = queryString.parse(window.location.search);
    if (activeTab && activeTab === 'purchase') {
      setActiveTabIndex(2);
    }
  }, []);

  const personalInfoData = data.data
    ? data.data.data.attributes.personalInfo
    : [];
  const changePasswordData = data.data
    ? data.data.data.attributes.changePassword
    : [];
  const passwordChange = data?.data
    ? data?.data?.data?.attributes?.passwordChange
    : [];

  return (
    <ComponentWithLoaderAndError
      data={personalInfoData || changePasswordData}
      error={data.error}
    >
      <CustomHead seo={data?.data?.data.attributes?.seo} />
      <UserProfileWrapper>
        <UserProfileBg />
        <ChangeProperties>
          <Tabs
            defaultIndex={activeTabIndex}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <TabList>
              <Tab>{getTextFromDataByKey('tabName', personalInfoData)}</Tab>
              <Tab>{getTextFromDataByKey('tabName', changePasswordData)}</Tab>
            </TabList>

            <TabPanel>
              <PersonalInfoForm
                activeTab={activeTab}
                personalInfoData={personalInfoData}
                settingsData={settingsData}
              />
            </TabPanel>

            <TabPanel>
              <ChangePassForm
                activeTab={activeTab}
                changePasswordData={changePasswordData}
                passwordChange={passwordChange}
              />
            </TabPanel>
          </Tabs>
        </ChangeProperties>
      </UserProfileWrapper>
    </ComponentWithLoaderAndError>
  );
};

export default UserProfile;
