import styled from 'styled-components';

export const TabMenu = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 280px;

  width: 100%;
  position: sticky;
  top: 126px;
  align-self: flex-start;
  overflow: auto;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;

  ::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0;
    padding: 5px 40px 15px 0px;
    font-size: 20px;
    cursor: pointer;
    color: #6c7686;
    text-align: left;
    position: relative;

    span.line {
      width: 50px;
      height: 2px;
      background-color: transparent;
      margin-left: 24px;
    }
  }
  &.selected {
    color: #4a6dff;

    span.line {
      background-color: #4a6dff;
    }
  }
`;

export const TabContainer = styled.div`
  display: flex;
  overflow: ${(props) => props.overflow || 'initial'};
  padding: 0px 0;
`;
