import React, { useEffect, useState } from 'react';
import { Button } from 'library/button/Button';
import {
  FormInputGroup,
  PersonalInfoBtnWrapper,
  PersonalInfoDetails,
  PersonalInfoWrapper,
} from 'ui/styled/PersonalInfoForm.styled';
import { useDispatch, useSelector } from 'react-redux';
import { changeUserInfo, userSelectors } from 'store/reducers/user/user.slice';
import { Controller, useForm } from 'react-hook-form';
import api from 'services/api';

import { getTextFromDataByKey } from 'utils/helpers';
import FormErrorComponent from 'ui/components/common/FormError';
import FormInput from 'ui/shared/formComponents/FormInput';
import { userTypes } from 'constants/global.constants';
import { FormFieldsErrorWrapper } from 'ui/styled/auth.styled';
import { onlyLatinLetters } from 'utils/validator';

const PersonalInfoForm = (props) => {
  const { personalInfoData, settingsData } = props;
  const dispatch = useDispatch();
  const activeUser = useSelector(userSelectors.userInfo);
  const isPersonal = userTypes.PERSONAL === activeUser?.customerType;
  const errorTranslatesData =
    settingsData?.data?.data?.attributes?.errorTranslate;

  const firstNameValue =
    activeUser?.fullName && activeUser?.fullName.split(' ')[0];
  const lastNameValue =
    activeUser?.fullName && activeUser?.fullName.split(' ')[1];
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: activeUser?.firstName,
      lastName: activeUser?.lastName,
      fullName: activeUser?.fullName,
      mobile: activeUser?.mobile,
      idNumber: activeUser?.idNumber,
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    setFormError(null);
    const params = {
      idNumber: data.idNumber,
      fullName: isPersonal
        ? data.firstName + ' ' + data.lastName
        : data.companyName,
      mobile: data.mobile,
      chiefManager: isPersonal ? undefined : data.companyDirector,
      repMobile: isPersonal ? undefined : data.repMobile,
    };
    try {
      await api.auth.updatePersonalInfo(params);
      dispatch(
        changeUserInfo({
          ...activeUser,
          fullName: isPersonal
            ? data.firstName + ' ' + data.lastName
            : data.companyName,
          idNumber: data.idNumber,
          mobile: data.mobile,
          chiefManager: isPersonal ? undefined : data.companyDirector,
          repMobile: isPersonal ? undefined : data.repMobile,
        }),
      );
    } catch (e) {
      setFormError(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (activeUser) {
      reset({
        email: activeUser.username || undefined,
        companyName: !isPersonal ? activeUser.fullName || undefined : undefined,
        firstName: firstNameValue || undefined,
        lastName: lastNameValue || undefined,
        mobile: activeUser.mobile || undefined,
        idNumber: activeUser.idNumber || undefined,
        repMobile: !isPersonal ? activeUser.repMobile || undefined : undefined,
      });
    }
  }, [activeUser]);

  useEffect(() => {
    return () => {
      setFormError(null);
    };
  }, []);

  return (
    <PersonalInfoWrapper>
      {formError ? (
        <FormErrorComponent
          error={formError}
          errorTranslatesData={errorTranslatesData}
        />
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <PersonalInfoDetails>
          <FormInputGroup>
            <Controller
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="email"
                  label={getTextFromDataByKey('email', personalInfoData)}
                  disabled
                />
              )}
              control={control}
              name="email"
            />
          </FormInputGroup>
          {isPersonal ? (
            <>
              <FormInputGroup errorsLatin={errors?.firstName}>
                <Controller
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label={getTextFromDataByKey(
                        'firstName',
                        personalInfoData,
                      )}
                    />
                  )}
                  rules={{
                    validate: onlyLatinLetters,
                  }}
                  control={control}
                  name="firstName"
                />
                {errors.firstName ? (
                  <FormFieldsErrorWrapper>
                    {getTextFromDataByKey('onlyLatins', personalInfoData)}
                  </FormFieldsErrorWrapper>
                ) : null}
              </FormInputGroup>
              <FormInputGroup errorsLatin={errors?.lastName}>
                <Controller
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label={getTextFromDataByKey('lastName', personalInfoData)}
                    />
                  )}
                  rules={{
                    validate: onlyLatinLetters,
                  }}
                  control={control}
                  name="lastName"
                />
                {errors.lastName ? (
                  <FormFieldsErrorWrapper>
                    {getTextFromDataByKey('onlyLatins', personalInfoData)}
                  </FormFieldsErrorWrapper>
                ) : null}
              </FormInputGroup>
            </>
          ) : null}
          <FormInputGroup>
            <Controller
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="tel"
                  label={getTextFromDataByKey('mobileNumber', personalInfoData)}
                />
              )}
              control={control}
              name="mobile"
            />
          </FormInputGroup>
          {isPersonal ? null : (
            <FormInputGroup>
              <Controller
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="text"
                    label={getTextFromDataByKey(
                      'companyName',
                      personalInfoData,
                    )}
                  />
                )}
                rules={{
                  validate: onlyLatinLetters,
                }}
                control={control}
                name="companyName"
              />
              {errors?.companyName ? (
                <FormFieldsErrorWrapper>
                  {getTextFromDataByKey('onlyLatins', personalInfoData)}
                </FormFieldsErrorWrapper>
              ) : null}
            </FormInputGroup>
          )}
          <FormInputGroup>
            {isPersonal ? (
              <Controller
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="number"
                    label={getTextFromDataByKey('idNumber', personalInfoData)}
                    borderColor={errors.idNumber}
                    disabled={activeUser?.idNumber}
                  />
                )}
                rules={{
                  required: 'Is Required',
                  validate: (value) =>
                    value.length === 11
                      ? undefined
                      : 'should contain  11 digits',
                }}
                control={control}
                name="idNumber"
              />
            ) : (
              <Controller
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="number"
                    label={getTextFromDataByKey('idNumber', personalInfoData)}
                    borderColor={errors.idNumber}
                    disabled={activeUser?.idNumber}
                  />
                )}
                rules={{
                  required: 'Is Required',
                  validate: (value) =>
                    value.length === 9 ? undefined : 'should contain  9 digits',
                }}
                control={control}
                name="idNumber"
              />
            )}
            {errors.idNumber ? (
              <FormFieldsErrorWrapper>
                {getTextFromDataByKey('incorrectID', personalInfoData)}
              </FormFieldsErrorWrapper>
            ) : null}
          </FormInputGroup>
          {isPersonal ? null : (
            <>
              <FormInputGroup>
                <Controller
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label={getTextFromDataByKey(
                        'repMobile',
                        personalInfoData,
                      )}
                    />
                  )}
                  control={control}
                  name="repMobile"
                />
              </FormInputGroup>
              <FormInputGroup>
                <Controller
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label={getTextFromDataByKey(
                        'directorFirstName',
                        personalInfoData,
                      )}
                    />
                  )}
                  rules={{
                    validate: onlyLatinLetters,
                  }}
                  control={control}
                  name="chiefManager"
                />
                {errors?.chiefManager ? (
                  <FormFieldsErrorWrapper>
                    {getTextFromDataByKey('onlyLatins', personalInfoData)}
                  </FormFieldsErrorWrapper>
                ) : null}
              </FormInputGroup>
            </>
          )}
        </PersonalInfoDetails>
        <PersonalInfoBtnWrapper>
          <Button
            width="100%"
            type={isDirty ? 'submit' : 'button'}
            background={isDirty ? '#ffdc04' : '#8A87A5'}
            color="#242333"
            borderRadius="5px"
            fontWeight="500"
            loading={loading}
            opacity={isDirty ? 1 : '0.3'}
          >
            {getTextFromDataByKey('saveBtn', personalInfoData)}
          </Button>
        </PersonalInfoBtnWrapper>
      </form>
    </PersonalInfoWrapper>
  );
};

export default PersonalInfoForm;
