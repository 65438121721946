import React from 'react';
import loading from 'assets/img/puff.svg';
import { GlobalErrorWrapper, GlobalLoading } from 'ui/styled/layout';

const GlobalLoadingComponent = () => {
  return (
    <GlobalErrorWrapper>
      <GlobalLoading>
        <img src={loading} alt="Loading..." />
      </GlobalLoading>
    </GlobalErrorWrapper>
  );
};

export default GlobalLoadingComponent;
