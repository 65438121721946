import React, { useContext, useEffect, useState } from 'react';
import {
  HeaderArrow,
  HeaderNav,
  HeaderNavigationWrapper,
  HeaderSeperator,
  HeaderStyled,
  LanguageChooserWrapper,
  MobileCartAndBurger,
  MobileCartIcon,
  MobileMenuBurger,
} from 'ui/shared/header/header.styled';
import { Button } from 'library/button/Button';

import Modal from 'library/modal/Modal';
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Auth, { authTypes } from 'ui/components/Auth/Auth';
import queryString from 'query-string';
import { tabs } from 'ui/components/Auth/Auth';
import api from 'services/api';
import UserDropDown from 'ui/components/UserDropdown/UserDropDown';
import { userSelectors } from 'store/reducers/user/user.slice';
import { useDispatch, useSelector } from 'react-redux';

import {
  appSelectors,
  languageChangeAction,
} from 'store/reducers/app/app.slice';
import CustomSelect from 'library/Select/Select';
import cacheManager from 'utils/cacheManager';
import ComponentWithLoaderAndError from '../componentWithLoaderAndError/ComponentWithLoaderAndError';
import CartComponent from './Cart/Cart';
import { getLanguageFromDataByKey, getTextFromDataByKey } from 'utils/helpers';
import { StrapiDataContext } from 'Main';
import ErrorAndSuccessModal from 'ui/pages/Cart/CartPageComponents/ErrorAndSuccessModal';
import { Arrow } from 'assets/svgIcons/Arrow';
import { useMediaQuery } from 'react-responsive';
import menuBurger from 'assets/img/menuBurger.svg';
import { LogoIcon } from 'assets/svgIcons/LogoIcon';
import MobileMenu from './mobile/MobileMenu';
import BottomSheet from 'library/bottomSheet/BottomSheet';

const Header = (props) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { settingsData, authData } = useContext(StrapiDataContext);

  const {
    isOpen,
    setIsOpen,
    isCartOpen,
    setIsCartOpen,
    cartData,
    getCartData,
  } = props;

  const isUserLoggedIn = useSelector(userSelectors.selectIsUserLoggedIn);

  let [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(tabs.personal);
  const [verificationCodeValue, setVerificationCodeValue] = useState('');
  const [authType, setAuthType] = useState(authTypes.login);
  const [resetKey, setResetkey] = useState('');
  const [resetPassUserEmail, setResetPassUserEmail] = useState('');
  const [checkResetOtpError, setCheckResetOtpError] = useState(null);
  const [verifyError, setVerifyError] = useState(null);
  const [expiredTokenModal, setExpiredTokenModal] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const activeLanguage = useSelector(appSelectors.selectLanguage);
  const [userName, setUserName] = useState(null);

  const verifyUserEmail = async (verificationCode) => {
    localStorage.removeItem('verifyError');
    try {
      await api.auth.userVerify(verificationCode);
      setAuthType(authTypes.EmailSuccess);
      localStorage.setItem('authType', authTypes.EmailSuccess);
    } catch (e) {
      localStorage.setItem('verifyError', e?.errorMessage);

      setVerifyError(e);
    }
  };

  const resendFromSearchParam = searchParams.get('resend');
  const isOfferInUrlParams = location?.pathname?.includes('isOffer');

  const sendVerificationEmail = async () => {
    if (!userName) return;
    try {
      await api.auth.resendVerificationEmail(userName);
    } catch (e) {
      console.error(e);
    } finally {
      searchParams.delete('resend');
    }
  };
  useEffect(() => {
    if (resendFromSearchParam === 'verifyResend') {
      sendVerificationEmail();
    }
  }, [resendFromSearchParam]);

  const checkResetPasswordOtp = async (username, otp) => {
    setCheckResetOtpError(null);
    const otpVal = otp ? otp : localStorage.getItem('resetKey');
    const usernameVal = username ? username : localStorage.getItem('username');
    try {
      await api.auth.checkPasswordResetOtp(usernameVal, otpVal).then((res) => {
        setResetkey(res.resetKey);
        localStorage.setItem('resetKey', res.resetKey);
      });
      setAuthType(authTypes.forgetForm);
      localStorage.setItem('authType', authTypes.forgetForm);
      setIsOpen(true);
    } catch (e) {
      localStorage.setItem('authType', authTypes.expiredModal);
      setCheckResetOtpError(e);
      setExpiredTokenModal(true);
    }
  };

  useEffect(() => {
    const { verificationCode } = queryString.parse(window.location.search);
    const { otp } = queryString.parse(window.location.search);
    const { username } = queryString.parse(window.location.search);

    if (otp && username) {
      localStorage.setItem('otp', otp);
      localStorage.setItem('username', decodeURIComponent(username));
      setResetPassUserEmail(decodeURIComponent(username));
      checkResetPasswordOtp(decodeURIComponent(username), otp);
    }

    if (verificationCode) {
      setVerificationCodeValue(verificationCode);
      setIsOpen(true);
      verifyUserEmail(verificationCode);
    }

    return () => {
      navigate(window.location.pathname);
    };
  }, []);

  const headerData = settingsData?.data
    ? settingsData?.data?.data?.attributes?.navigation
    : [];

  const headerDataToRender =
    (headerData && isOfferInUrlParams) ||
    (headerData && location.pathname === '/offer')
      ? headerData.filter((item) => item.key === 'offer')
      : headerData.filter((item) => item.key !== 'offer');

  const langOptions = settingsData?.data?.data?.attributes?.language.map(
    (item) => {
      return {
        label: item.responsive,
        secondaryValue: item.desktop,
        value: item.key,
      };
    },
  );

  const langCache = cacheManager('lang');
  const langFromCache = langCache.get();

  const changeServerDefaultLanguage = async (lang) => {
    await api.user.changeDefaultLanguage(lang);
  };

  const languageChangeHandler = (e) => {
    dispatch(languageChangeAction(e));
    changeServerDefaultLanguage(e.value.toUpperCase());
    langCache.set(e);
  };

  useEffect(() => {
    if (langFromCache) {
      dispatch(languageChangeAction(JSON.parse(langFromCache)));
    }
    return () => {
      localStorage.setItem('authType', authTypes.login);
    };
  }, []);

  const authFromStorage = localStorage.getItem('authType');
  useEffect(() => {
    if (isOpen && authFromStorage) {
      setAuthType(authFromStorage);
    }
  }, [isOpen]);

  useEffect(() => {
    if (authFromStorage === authTypes.expiredModal) {
      setExpiredTokenModal(true);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/offer') {
      navigate('/offer/isOffer');
    }
  }, [location.pathname, isUserLoggedIn]);

  const loginData = authData?.data
    ? authData?.data?.data?.attributes?.logIn
    : [];

  const activeLanguageTranslated = {
    ...activeLanguage,
    secondaryValue: getLanguageFromDataByKey(
      activeLanguage.value,
      settingsData?.data?.data?.attributes?.language,
    ),
  };

  const isInModal =
    authType === authTypes.EmailSuccess ||
    authType === authTypes.forgetSuccess ||
    authType === authTypes.registerVerify ||
    authType === authTypes.forgotVerify;

  return (
    <ComponentWithLoaderAndError
      data={headerDataToRender}
      error={settingsData.error}
    >
      <Modal
        closeIcon
        width="590px"
        height="auto"
        isOpen={mobileMenu}
        className="mobileMenu"
        closeModal={() => {
          setMobileMenu(false);
        }}
      >
        <MobileMenu
          headerData={headerDataToRender}
          langOptions={langOptions}
          translatesData={settingsData}
          loginData={loginData}
          closeMenu={() => setMobileMenu(false)}
          login={() => setIsOpen(true)}
          languageChangeHandler={languageChangeHandler}
        />
      </Modal>
      <HeaderStyled>
        {!isTabletOrMobile ? (
          <Modal
            closeIcon
            width="590px"
            height="auto"
            isOpen={expiredTokenModal}
            closeModal={() => {
              setExpiredTokenModal(false);
              localStorage.removeItem('verifyError');
              localStorage.setItem('authType', authTypes.login);
            }}
          >
            <ErrorAndSuccessModal
              isError
              title="Verification Failed"
              subTitle="The verification token has been expired, please try again"
            />
          </Modal>
        ) : (
          <BottomSheet
            isOpen={expiredTokenModal}
            padding="40px 0"
            closeBottomSheet={() => {
              setExpiredTokenModal(false);
              localStorage.removeItem('verifyError');
              localStorage.setItem('authType', authTypes.login);
            }}
          >
            <ErrorAndSuccessModal
              isError
              title="Verification Failed"
              subTitle="The verification token has been expired, please try again"
            />
          </BottomSheet>
        )}

        {isInModal && isTabletOrMobile ? (
          <BottomSheet
            isOpen={isOpen}
            padding="40px 0"
            closeBottomSheet={() => {
              setIsOpen(false);
              localStorage.setItem('authType', authTypes.login);
              setSearchParams();
              localStorage.removeItem('verifyError');
            }}
          >
            <Auth
              userName={userName}
              setUserName={setUserName}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              verificationCodeValue={verificationCodeValue}
              setVerificationCodeValue={setVerificationCodeValue}
              authType={authType}
              setAuthType={setAuthType}
              verifyUserEmail={verifyUserEmail}
              resetPassUserEmail={resetPassUserEmail}
              resetKey={resetKey}
              getCartData={getCartData}
              sendVerificationEmail={sendVerificationEmail}
              data={authData}
              verifyError={verifyError}
              settingsData={settingsData}
              closeAuthModal={() => {
                setIsOpen(false);
                setAuthType(authTypes.login);
                setSearchParams();
                localStorage.removeItem('verifyError');
                localStorage.setItem('authType', authTypes.login);
              }}
            />
          </BottomSheet>
        ) : (
          <Modal
            closeIcon
            width="616px"
            height="auto"
            isOpen={isOpen}
            spacing=" 18px 80px 45px 98px"
            className="authModalResp"
            canCloseOnOutsideClick={false}
            closeModal={() => {
              setIsOpen(false);
              localStorage.setItem('authType', authTypes.login);
              setSearchParams();
              localStorage.removeItem('verifyError');
            }}
          >
            <Auth
              userName={userName}
              setUserName={setUserName}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              verificationCodeValue={verificationCodeValue}
              setVerificationCodeValue={setVerificationCodeValue}
              authType={authType}
              setAuthType={setAuthType}
              verifyUserEmail={verifyUserEmail}
              resetPassUserEmail={resetPassUserEmail}
              resetKey={resetKey}
              getCartData={getCartData}
              sendVerificationEmail={sendVerificationEmail}
              data={authData}
              verifyError={verifyError}
              settingsData={settingsData}
              closeAuthModal={() => {
                setIsOpen(false);
                setAuthType(authTypes.login);
                setSearchParams();
                localStorage.removeItem('verifyError');
              }}
            />
          </Modal>
        )}

        <NavLink to={isOfferInUrlParams ? '/offer/isOffer' : '/'}>
          <LogoIcon className="logo-main" style={{ cursor: 'pointer' }} />
        </NavLink>
        {headerData && !isTabletOrMobile ? (
          <HeaderNavigationWrapper>
            <HeaderNav className="bodyL">
              {headerDataToRender.map((item, index) => {
                return (
                  <NavLink
                    className={
                      location.pathname === '/' && item.key === 'solutions'
                        ? 'active'
                        : undefined
                    }
                    key={item.id}
                    to={`/${
                      item.key === 'offer' || isOfferInUrlParams
                        ? `${item.key}/isOffer`
                        : item.key
                    }`}
                  >
                    {item.value}
                  </NavLink>
                );
              })}
            </HeaderNav>
            {(langOptions && langOptions.length === 0) ||
            (langOptions && langOptions.length === 1) ? null : (
              <LanguageChooserWrapper>
                <CustomSelect
                  name="lang"
                  value={activeLanguageTranslated}
                  options={langOptions}
                  showSecondaryValue
                  disabled={!langOptions || langOptions?.length === 0}
                  onChange={(e) => languageChangeHandler(e)}
                />
              </LanguageChooserWrapper>
            )}
            <HeaderSeperator />
            <CartComponent
              cartData={cartData}
              getCartData={getCartData}
              isCartOpen={isCartOpen}
              setIsCartOpen={setIsCartOpen}
              translatesData={settingsData}
              isOfferInUrlParams={isOfferInUrlParams}
            />
            {!isUserLoggedIn ? (
              <Button
                className="login-btn"
                type="button"
                width="100%"
                height="100%"
                background="rgba(255,220,4,0.2)"
                color="#242333"
                fontWeight="700"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                {getTextFromDataByKey('logInBtn', loginData)}
                <HeaderArrow>
                  <Arrow
                    style={{
                      marginLeft: '6px',
                    }}
                  />
                </HeaderArrow>
              </Button>
            ) : (
              <UserDropDown
                translatesData={settingsData}
                isOfferInUrlParams={isOfferInUrlParams}
              />
            )}
          </HeaderNavigationWrapper>
        ) : null}
        {isTabletOrMobile ? (
          <MobileCartAndBurger>
            <MobileCartIcon>
              <CartComponent
                cartData={cartData}
                getCartData={getCartData}
                isCartOpen={isCartOpen}
                setIsCartOpen={setIsCartOpen}
                translatesData={settingsData}
                isOfferInUrlParams={isOfferInUrlParams}
              />
            </MobileCartIcon>
            <MobileMenuBurger onClick={() => setMobileMenu(true)}>
              <img src={menuBurger} alt="menu" />
            </MobileMenuBurger>
          </MobileCartAndBurger>
        ) : null}
      </HeaderStyled>
    </ComponentWithLoaderAndError>
  );
};
export default Header;
