import styled from 'styled-components';

export const PersonalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    height: 100%;
  }
`;
export const PersonalInfoDetails = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    height: calc(100% - 60px);
  }
  .input-label {
    font-weight: normal;
  }
  input {
    width: 100%;
    height: 50px;
    color: #3a3853 !important;
    border: 1px solid rgba(58, 56, 83, 0.2);
    border-radius: 5px;
    background: transparent;
    outline: none;
    padding-left: 20px;
    transition: all 0.3s;

    :last-child {
      margin-bottom: 50px;
    }
    ::placeholder {
      color: #3a3853;
    }
    @media (max-width: 900px) {
      height: 52px;
      padding-left: 26px;
    }
  }
`;

export const PersonalInfoBtnWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 25px;
  position: relative;
  button {
    padding: 14px 0;
  }
  @media (max-width: 1685px) {
    margin-top: 0px;
  }
  @media (max-width: 900px) {
    margin-top: auto;
    padding-bottom: 60px;
    button {
      width: 100%;
      font-size: 14px;
      margin-top: auto;
      padding: 18px 0px;
    }
  }
`;
export const FormInputGroup = styled.div`
  position: relative;
  margin-bottom: ${(props) => (props.errorsLatin ? '18px' : '25px')};
  :last-child {
    margin-bottom: ${(props) => (props.changePassForm ? '0px' : '25px')};
  }
  .input-label {
    opacity: 1 !important;
  }
  input[disabled] {
    opacity: 1 !important;
  }
  input[disabled] {
    @media (max-width: 900px) {
      color: #bab8c7 !important;
    }
  }
  @media (max-width: 900px) {
    margin-bottom: 20px;
  }
`;
