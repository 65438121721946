import React, { useEffect, useState } from 'react';
import { EmailConfIcon } from 'assets/svgIcons/EmailConfIcon';
import {
  RegisterTextWrapper,
  RegisterVerifyWrapper,
  ResendCodeBlock,
  VerifyDesc,
  VerifyIconBlock,
  VerifyTitle,
} from 'ui/styled/auth.styled';
import { setTimeOutUtil } from 'utils/setTimeOutUtil';
import { getTextFromDataByKey } from 'utils/helpers';
import { useSearchParams } from 'react-router-dom';

const RegisterVerify = (props) => {
  const { sendVerificationEmail, userName, verifyEmailData } = props;

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    counter > 0 && setTimeOutUtil(setCounter, 1000, counter - 1);
  }, [counter]);

  useEffect(() => {
    setCounter(30);
  }, []);

  return (
    <RegisterVerifyWrapper>
      <RegisterTextWrapper>
        <VerifyIconBlock>
          <EmailConfIcon />
        </VerifyIconBlock>
        <VerifyTitle>
          {getTextFromDataByKey('title', verifyEmailData)}
        </VerifyTitle>
        <VerifyDesc>
          {`${getTextFromDataByKey('textBeforeEmail', verifyEmailData)} 
        ${userName} ${getTextFromDataByKey('textAfterEmail', verifyEmailData)}`}
        </VerifyDesc>
      </RegisterTextWrapper>
      <ResendCodeBlock className="bodyL">
        <span>
          {counter === 0
            ? null
            : getTextFromDataByKey('resend', verifyEmailData)}
        </span>
        {counter === 0 ? (
          <span
            className="resend"
            onClick={() => {
              sendVerificationEmail();
              setCounter(30);
            }}
          >
            {getTextFromDataByKey('resend', verifyEmailData)}
          </span>
        ) : (
          <span className="timer">
            00:{counter < 10 ? '0' : ''}
            <span>{counter}</span>
          </span>
        )}
      </ResendCodeBlock>
    </RegisterVerifyWrapper>
  );
};

export default RegisterVerify;
