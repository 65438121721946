import React, { useState } from 'react';
import { EmailSuccessIcon } from 'assets/svgIcons/EmailSuccessIcon';
import {
  RegisterVerifyWrapper,
  VerifyDesc,
  VerifyIconBlock,
  VerifyTitle,
} from 'ui/styled/auth.styled';
import { htmlParser } from 'utils/htmlUtils';

const PasswordChangeSuccess = ({ data }) => {
  return (
    <RegisterVerifyWrapper>
      <VerifyIconBlock>
        <EmailSuccessIcon />
      </VerifyIconBlock>
      <VerifyTitle>{data?.PasswordReset?.title}</VerifyTitle>
      <VerifyDesc>{htmlParser(data?.PasswordReset?.description)}</VerifyDesc>
    </RegisterVerifyWrapper>
  );
};

export default PasswordChangeSuccess;
