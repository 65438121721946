import React, { useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  TableWrapper,
  ItemHead,
  TransactionsItemBody,
  ItemTitle,
  ItemRows,
  ItemLeftColumn,
  MobileTransactionsHistoryItem,
  ItemRightColumn,
} from 'library/table/TableWrapperStyled';
import {
  getTextFromDataByKey,
  getTextFromDataByProductKey,
} from 'utils/helpers';
import api from 'services/api';
import formatDate, { DateFormats } from 'utils/date';
import EmptyTransactionsInfo from 'ui/components/common/EmptyTransactionsInfo';
import Pagination from '../../../library/Pagination/Pagination';
import TableLoading from 'ui/components/common/TableLoading';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { MobileOnPersmiseTable } from 'ui/styled/currentProducts.styled';
import { StrapiDataContext } from 'Main';

const OnPremiseTable = (props) => {
  const {
    onPremiseData,
    setOnPremiseData,
    settingsData,
    notFoundData,
    currentProductsData,
    searchValue,
    pagination,
    setPagination,
    isTabletOrMobile,
  } = props;

  const translateKeys = settingsData?.data?.data.attributes.enumTranslate;

  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [headerColumnsData, setHeaderColumnsData] = useState([]);
  const contextData = useSelector((state) => state.app.language, _.isEqual);

  const [onPremiseDataError, setOnPremiseDataError] = useState(false);

  const { transactionHistoryData: data } = useContext(StrapiDataContext);
  const NotFoundData = data?.data?.data?.attributes?.search?.notFound;

  const handlePaginationClick = (i) => {
    setPagination({
      ...pagination,
      offset: i * pagination.limit,
      call: true,
    });
  };

  const getOnPremisTableData = async ({ search }) => {
    setLoading(true);
    try {
      const data = await api.user.getPurchaseHistory(
        true,
        search,
        pagination.offset,
        pagination.limit,
      );
      setDataCount(data.data?.count);
      setOnPremiseData(
        data.data?.list.map((item) => {
          return {
            ...item,
            itemName: getTextFromDataByProductKey(
              item.product.productKey,
              translateKeys,
            ),
          };
        }),
      );
    } catch (e) {
      console.error(e);
      setOnPremiseDataError(true);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (!pagination.call) return;
    getOnPremisTableData({
      search: searchValue || '',
    });
  }, [pagination]);

  useEffect(() => {
    if (searchValue === null || searchValue === undefined) return;
    getOnPremisTableData({
      search: searchValue || '',
    });
  }, [searchValue]);

  const headerColumns = [
    {
      key: 'payment_date',
      value: getTextFromDataByKey(
        'payment_date',
        currentProductsData?.translate,
      ),
      align: 'left',
    },
    {
      value: getTextFromDataByKey('ID_number', currentProductsData?.translate),
      key: 'ID_number',
      align: 'left',
    },
    {
      value: getTextFromDataByKey(
        'product_name',
        currentProductsData?.translate,
      ),
      key: 'product_name',
      align: 'left',
    },
    {
      value: getTextFromDataByKey('price', currentProductsData?.translate),
      key: 'price',
      align: 'right',
    },
  ];

  useEffect(() => {
    setHeaderColumnsData(
      headerColumns?.map((item) => ({
        ID: item?.id,
        Header: item?.value,
        accessor: item?.key,
      })),
    );
  }, [settingsData]);

  if (loading) {
    return <TableLoading />;
  }

  return onPremiseDataError ? (
    <EmptyTransactionsInfo noData={notFoundData} />
  ) : !onPremiseData || (onPremiseData && onPremiseData.length === 0) ? (
    <EmptyTransactionsInfo notFoundData={NotFoundData} />
  ) : (
    <Pagination
      limit={pagination.limit}
      offset={pagination.offset}
      count={dataCount || pagination.count}
      length={onPremiseData?.limit}
      className="orders-pagination"
      onClick={handlePaginationClick}
    >
      {isTabletOrMobile ? (
        <MobileOnPersmiseTable>
          {onPremiseData &&
            onPremiseData?.map((row, index) => {
              return (
                <MobileTransactionsHistoryItem key={index}>
                  <ItemHead style={{ padding: '13px 18px', marginBottom: '0' }}>
                    <ItemTitle style={{ marginBottom: '0' }}>
                      <span className="id">{`# ${row.purchasedItemId} - `}</span>
                      {row.itemName}
                    </ItemTitle>
                  </ItemHead>

                  <TransactionsItemBody
                    style={{
                      padding: '6px 0 0 0',
                      marginBottom: '16px',
                      border: 'none',
                    }}
                  >
                    <ItemRows>
                      <ItemLeftColumn>
                        <div className="timePeriod">
                          {getTextFromDataByKey(
                            'payment_date',
                            currentProductsData?.translate,
                          )}
                          {`:`}
                        </div>
                        <div className="price">
                          {getTextFromDataByKey(
                            'price',
                            currentProductsData?.translate,
                          )}
                          {`:`}
                        </div>
                      </ItemLeftColumn>
                      <ItemRightColumn>
                        <div className="periodValue">
                          {row.createTime
                            ? formatDate({
                                inputDate: row.createTime,
                                outputFormat: DateFormats.DefaultWithoutLines,
                              })
                            : '-'}
                        </div>
                        <div className="priceValue">{`₾ ${row.product.price.toFixed(
                          2,
                        )}`}</div>
                      </ItemRightColumn>
                    </ItemRows>
                  </TransactionsItemBody>
                </MobileTransactionsHistoryItem>
              );
            })}
        </MobileOnPersmiseTable>
      ) : (
        <TableWrapper lang={contextData.value}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {headerColumnsData?.map((i, index, array) => (
                    <TableCell
                      align={`${i.align}`}
                      style={{
                        textAlign:
                          array.length - 1 === index ? 'right' : undefined,
                        width: array.length - 2 === index ? '45%' : undefined,
                      }}
                    >
                      {i.Header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {onPremiseData &&
                  onPremiseData?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.createTime
                          ? formatDate({
                              inputDate: row.createTime,
                              outputFormat: DateFormats.DefaultWithoutLines,
                            })
                          : '-'}
                      </TableCell>
                      <TableCell align="left">{row.purchasedItemId}</TableCell>
                      <TableCell align="left">{row.itemName}</TableCell>
                      <TableCell
                        className="prices"
                        align="right"
                      >{`₾ ${row.product.price.toFixed(2)}`}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TableWrapper>
      )}
    </Pagination>
  );
};
export default OnPremiseTable;
