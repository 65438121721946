import { Button } from 'library/button/Button';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import FormCheckBoxState from 'ui/components/formCheckbox/formCheckbox-formState';
import {
  ConfirmAddCardModalButton,
  ConfirmAddCardModalButtons,
  ConfirmAddCardModalForm,
  ConfirmAddCardModalTitle,
  ConfirmAddCardModalWrapper,
} from 'ui/styled/ConfirmAddCardModalStyled';
import { htmlParser } from 'utils/htmlUtils';

const ConfirmAddCardModal = (props) => {
  const methods = useForm({
    reValidateMode: 'onSubmit',
  });
  const { control, handleSubmit } = methods;

  const CardTerms = props.cardTermsData.addCardTerms;
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeTermsError, setAgreeTermsError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);

  const onSubmit = () => {
    if (!agreeTerms) {
      setAgreeTermsError(true);
      return;
    }
    setLoading(true);
    setFormError(null);
    props.addPaymentCart();
    props.closeModal();
  };

  return (
    <ConfirmAddCardModalWrapper>
      <ConfirmAddCardModalTitle>
        <div className="title headline3">{htmlParser(CardTerms[0]?.title)}</div>
        <div className="subtitle bodyL">
          {htmlParser(CardTerms[0]?.description)}
        </div>
      </ConfirmAddCardModalTitle>
      <ConfirmAddCardModalForm>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ConfirmAddCardModalButton>
              <div className="agreement">
                <Controller
                  render={({ field }) => (
                    <FormCheckBoxState
                      {...field}
                      checked={agreeTerms}
                      label={CardTerms[0]?.termsText}
                      labelColored={
                        <Link target="_blank" to={CardTerms[0]?.termsUrl}>
                          {CardTerms[0]?.termsUrlName}
                        </Link>
                      }
                      labelPosition="right"
                      value="yes"
                      stroke={agreeTermsError ? '#f55e1b' : undefined}
                      onChange={() => {
                        setAgreeTerms(!agreeTerms);
                        setAgreeTermsError(false);
                      }}
                    />
                  )}
                  control={control}
                  name="agreeTerms"
                />
              </div>

              <ConfirmAddCardModalButtons>
                <Button loading={loading} className="submit button1">
                  {CardTerms[0]?.btn}
                </Button>
              </ConfirmAddCardModalButtons>
            </ConfirmAddCardModalButton>
          </form>
        </FormProvider>
      </ConfirmAddCardModalForm>
    </ConfirmAddCardModalWrapper>
  );
};

export default ConfirmAddCardModal;
