import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FooterBottomLine,
  FooterInfoList,
  FooterInfoTitle,
  FooterInfoWrapper,
  FooterWithInfoStyled,
} from './footer.styled';
import { useMediaQuery } from 'react-responsive';

const FooterWithInfo = (props) => {
  const location = useLocation();
  const { data } = props;
  const FooterInfoData = data ? data?.data?.attributes?.footerNav : null;

  const isOfferPage =
    location.pathname === '/offer/isOffer' || location.pathname === '/offer';

  const FooterInfoDataFiltered = FooterInfoData?.filter(
    (item) => item.pages && item.pages.length > 0,
  );

  const footerDataToShow = FooterInfoDataFiltered?.filter((section) => {
    const filteredPages = section.pages.filter(
      (page) =>
        (isOfferPage && page.offerPage !== 'standard') ||
        (!isOfferPage && page.offerPage !== 'offer'),
    );
    return filteredPages.length > 0;
  }).map((section) => ({
    ...section,
    pages: section.pages.filter(
      (page) =>
        (isOfferPage && page.offerPage !== 'standard') ||
        (!isOfferPage && page.offerPage !== 'offer'),
    ),
  }));

  const pathnamesToHide = [
    '/cart',
    '/profile/default',
    '/transactionHistory',
    '/currentProducts',
  ];

  return (
    <>
      <FooterWithInfoStyled
        style={{
          display: pathnamesToHide.includes(location.pathname)
            ? 'none'
            : undefined,
        }}
      >
        {footerDataToShow?.map((section, index) => (
          <FooterInfoWrapper key={index}>
            <FooterInfoTitle>{section.title}</FooterInfoTitle>
            <FooterInfoList className="bodyM">
              {section.pages.map((page) => (
                <Link to={`/${page.key}`}>{page.value}</Link>
              ))}
            </FooterInfoList>
          </FooterInfoWrapper>
        ))}
      </FooterWithInfoStyled>
      <FooterBottomLine />
    </>
  );
};

export default FooterWithInfo;
