import React from 'react';
import {
  FooterNavigationWrapper,
  FooterSocialsContainer,
  FooterStyled,
  FooterWrapper,
  FooterLeftBlock,
  FooterNav,
  FooterNavItem,
  MobileFooter,
  FootersWrapper,
} from 'ui/shared/footer/footer.styled';
import { LogoBottomStyled, SeciontBlock } from 'ui/components/common/layout';
import { ImagesRenderer } from 'utils/imagesRenderer';
import { Link, NavLink, useLocation } from 'react-router-dom';
import oneSoftLogo from 'assets/img/onesoftLogo.svg';
import { useMediaQuery } from 'react-responsive';
import FooterWithInfo from './FooterWithInfo';

const Footer = (props) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const { location } = useLocation();

  const {
    withBg = true,
    settingsData: { data },
    videoOverlayVisible,
  } = props;

  const isOfferInUrlParams = location?.includes('isOffer');
  const additionalParam = isOfferInUrlParams ? '/isOffer' : '';

  const footerData = data ? data?.data?.attributes?.footer : null;

  const pathnamesToHide = [
    '/cart',
    '/profile/default',
    '/transactionHistory',
    '/currentProducts',
  ];

  return isTabletOrMobile ? (
    <FootersWrapper>
      <FooterWithInfo data={data} />
      <MobileFooter
        style={{
          display: pathnamesToHide.includes(location?.pathname)
            ? 'none'
            : undefined,
        }}
      >
        <div className="foot-in">
          <div className="left">
            <div>
              <a
                href={`tel:${footerData?.contact[1].text}`}
                style={{
                  color: '#8a87a5',
                }}
              >
                {footerData?.contact[1].text}
              </a>
            </div>
            <div
              style={{
                color: '#3A3853',
                fontWeight: '700',
                textDecoration: 'underline',
              }}
            >
              <a
                href={`mailto:${footerData?.contact[0].text}`}
                style={{
                  color: '#3A3853',
                }}
              >
                {footerData?.contact[0].text}
              </a>
            </div>
          </div>
          <div className="right">
            {footerData?.social?.map((item, index) => {
              return (
                <div
                  key={item.logo.data.url + index}
                  style={{ marginRight: '22px' }}
                >
                  <a href={item.url}>
                    <ImagesRenderer
                      url={item?.logo?.data?.attributes?.url}
                      alt={item?.logo?.data?.attributes?.alternativeText}
                    />
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </MobileFooter>
    </FootersWrapper>
  ) : (
    <FootersWrapper>
      <FooterWithInfo data={data} />
      <FooterWrapper
        style={{ display: videoOverlayVisible ? 'none' : undefined }}
      >
        <SeciontBlock noMargin>
          {footerData ? (
            <FooterStyled>
              <FooterLeftBlock>
                <FooterSocialsContainer>
                  <FooterNavigationWrapper
                    style={{
                      marginBottom: '16px',
                    }}
                  >
                    {footerData?.social?.map((item, index) => {
                      return (
                        <div
                          key={item.logo.data.url + index}
                          style={{ marginRight: '22px' }}
                        >
                          <a href={item.url}>
                            <ImagesRenderer
                              url={item?.logo?.data?.attributes?.url}
                              alt={
                                item?.logo?.data?.attributes?.alternativeText
                              }
                            />
                          </a>
                        </div>
                      );
                    })}
                  </FooterNavigationWrapper>

                  <FooterNavigationWrapper>
                    <div>
                      <a href={`mailto:${footerData?.contact[0].text}`}>
                        {footerData?.contact[0].text + ` / `}
                      </a>
                    </div>
                    <div style={{ paddingLeft: '7px' }}>
                      <a href={`tel:${footerData?.contact[1].text}`}>
                        {footerData?.contact[1].text}
                      </a>
                    </div>
                  </FooterNavigationWrapper>
                </FooterSocialsContainer>
                {/* <FooterNav>
                  {footerData &&
                    footerData?.navigation?.map((item) => {
                      return (
                        <FooterNavItem key={item.id} className="bodyL">
                          <Link to={`${item.key}${additionalParam}`}>
                            {item.value}
                          </Link>
                        </FooterNavItem>
                      );
                    })}
                </FooterNav> */}
              </FooterLeftBlock>
              <LogoBottomStyled>
                <NavLink to="/">
                  <img
                    src={oneSoftLogo}
                    width="137px"
                    height="45px"
                    alt="logo-main"
                  />
                </NavLink>
              </LogoBottomStyled>
            </FooterStyled>
          ) : null}
        </SeciontBlock>
      </FooterWrapper>
    </FootersWrapper>
  );
};

export default Footer;
