import React, { useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import api from 'services/api';
import { userTypes } from 'constants/global.constants';
import Pagination from 'library/Pagination/Pagination';
import UserInfoModal from './UserInfoModal';
import Modal from 'library/modal/Modal';
import UserListFilter from './UserListFilter';
import { TransactionsListWrapper } from 'ui/styled/admin.styled';
import UsersExport from './UsersExport';
import { downloadBlobFile } from 'utils/helpers';
import formatDate, { DateFormats } from 'utils/date';
import TableLoading from 'ui/components/common/TableLoading';
import { StrapiDataContext } from 'Main';
import EmptyTransactionsInfo from 'ui/components/common/EmptyTransactionsInfo';

const AdminUsersList = (props) => {
  const {
    isOpenUserFilter,
    setIsOpenUserFilter,
    searchValue,
    isUsersExportOpen,
    setUsersExportOpen,
    pagination,
    setPagination,
    translatesData,
  } = props;
  const userListHeaders = [
    { key: 'userId', value: 'User ID', align: 'left' },
    { key: 'username', value: 'UserName', align: 'left' },
    { key: 'identificator', value: 'Identificator', align: 'left' },
    { key: 'time', value: 'Time', align: 'left' },
    { key: 'status', value: 'Status', align: 'right' },
  ];

  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [userFilters, setUserFilters] = useState(null);
  const [userExportFields, setUserExportFields] = useState(null);
  const [activeUserType, setActiveUserType] = useState(null);
  const [usersListDataError, setUsersListDataError] = useState(false);

  const { transactionHistoryData: data } = useContext(StrapiDataContext);
  const NotFoundData = data?.data?.data?.attributes?.search?.notFound;

  const getUserList = async (values, isFilter) => {
    setLoading(true);
    if (isFilter) {
      setPagination(() => {
        return {
          offset: 0,
          limit: 5,
          count: 0,
          call: false,
        };
      });
    }

    const params = {
      offset: isFilter ? 0 : pagination.offset,
      limit: pagination.limit,
      status: values?.status,
      query: searchValue
        ? encodeURIComponent(searchValue)
        : values?.query
        ? encodeURIComponent(values?.query)
        : '',
      customerType: values?.customerType,
      fromDate: values?.fromDate
        ? formatDate({
            inputDate: values.fromDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      toDate: values?.toDate
        ? formatDate({
            inputDate: values.toDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
    };
    try {
      const result = await api.admin.getUsersList(params);
      setUsersList(result.list);
      setPagination((prev) => {
        return {
          ...prev,
          count: result.count,
        };
      });
      setIsOpenUserFilter(false);
    } catch (e) {
      console.error(e);
      setUsersListDataError(true);
    } finally {
      setLoading(false);
    }
  };

  const getUserExportFile = async (values) => {
    setLoadingExport(true);

    const params = {
      status: values?.status,
      query: searchValue
        ? encodeURIComponent(searchValue)
        : encodeURIComponent(values.query),
      customerType: activeUserType || '',
      fromDate: values?.fromDate
        ? formatDate({
            inputDate: values.fromDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      toDate: values?.toDate
        ? formatDate({
            inputDate: values.toDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
    };
    try {
      const { data, filename } = await api.admin.getUserFile(params);
      downloadBlobFile(data, filename);
      setUsersExportOpen(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingExport(false);
    }
  };

  const handlePaginationClick = (i) => {
    // clearSearchValue();
    setPagination({
      ...pagination,
      offset: i * pagination.limit,
      call: true,
    });
  };

  useEffect(() => {
    if (!pagination.call) return;
    getUserList({
      ...userFilters,
      query: searchValue,
    });
  }, [pagination.offset]);

  useEffect(() => {
    getUserList({
      ...userFilters,
      query: searchValue,
    });
  }, [searchValue]);

  return (
    <>
      <Modal
        closeIcon
        width="520px"
        height="auto"
        title="User Filter"
        className="admin-details-modal"
        isOpen={isOpenUserFilter}
        closeModal={() => setIsOpenUserFilter(false)}
      >
        <UserListFilter
          loading={loading}
          getUserList={getUserList}
          userFilters={userFilters}
          setUserFilters={setUserFilters}
          activeUserType={activeUserType}
          setActiveUserType={setActiveUserType}
        />
      </Modal>
      {usersListDataError ? (
        <EmptyTransactionsInfo noData={NotFoundData} />
      ) : (
        <TransactionsListWrapper>
          <Modal
            closeIcon
            width="520px"
            height="auto"
            title="Export"
            className="admin-details-modal"
            isOpen={isUsersExportOpen}
            closeModal={() => setUsersExportOpen(false)}
          >
            <UsersExport
              loading={loadingExport}
              setUserExportFields={setUserExportFields}
              userExportFields={userExportFields}
              getUserExportFile={getUserExportFile}
              activeUserType={activeUserType}
              setActiveUserType={setActiveUserType}
              setPagination={setPagination}
            />
          </Modal>
          <Modal
            closeIcon
            width="520px"
            height="auto"
            title={
              selectedUser?.username
                ? selectedUser?.customer?.fullName
                : 'User Info'
            }
            className="admin-details-modal"
            isOpen={isUserInfoOpen}
            closeModal={() => setIsUserInfoOpen(false)}
          >
            <UserInfoModal
              selectedUser={selectedUser}
              getUserList={getUserList}
              searchValue={searchValue}
              translatesData={translatesData}
            />
          </Modal>
          {loading ? (
            <TableLoading />
          ) : (
            <>
              {usersList && usersList.length === 0 ? (
                <EmptyTransactionsInfo notFoundData={NotFoundData} />
              ) : (
                <Pagination
                  limit={pagination.limit}
                  offset={pagination.offset}
                  count={pagination.count}
                  className="adminTablesPagination"
                  onClick={handlePaginationClick}
                >
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {userListHeaders.map((item) => {
                            return (
                              <TableCell key={item.key} align={item.align}>
                                {item.value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {usersList.map((item) => {
                          return (
                            <TableRow
                              key={item.id}
                              onClick={() => {
                                setSelectedUser(item);
                                setIsUserInfoOpen(true);
                              }}
                            >
                              <TableCell component="td">{`#${item.id}`}</TableCell>
                              <TableCell component="td">
                                <div className="userInfo">
                                  <div className="username">
                                    {item.customer.fullName}
                                  </div>
                                  <div className="type">
                                    {item.customer.customerType}
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell component="td">
                                <div classname="centredFlex">
                                  <div>{item.customer.email}</div>
                                  <span style={{ paddingRight: '4px' }}>
                                    {item.customer.customerType ===
                                    userTypes.PERSONAL
                                      ? 'ID:'
                                      : 'CIN:'}
                                  </span>
                                  {item.customer.idNumber || ' '}
                                </div>
                              </TableCell>
                              <TableCell component="td">
                                {item.createTime
                                  ? formatDate({
                                      inputDate: item.createTime,
                                      outputFormat:
                                        DateFormats.DefaultWithoutLines,
                                    })
                                  : '-'}
                              </TableCell>
                              <TableCell component="td" align="right">
                                <div className="centredFlex">{item.status}</div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Pagination>
              )}
            </>
          )}
        </TransactionsListWrapper>
      )}
    </>
  );
};

export default AdminUsersList;
