import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { htmlParser } from 'utils/htmlUtils';

const TabContent = ({ e, id, index, onChange, ...props }) => {
  return (
    <VisibilitySensor
      onChange={onChange}
      partialVisibility
      scrollCheck
      minTopValue={55}
      offset={{ bottom: 150, top: 130 }}
      {...props}
    >
      <div
        id={`section${index}`}
        style={{
          marginBottom: '50px',
        }}
      >
        <div className="title">{e.title}</div>
        {htmlParser(e.text)}
      </div>
    </VisibilitySensor>
  );
};

export default TabContent;
