import styled from 'styled-components';
import priceBGBig from 'assets/img/priceBGBig.svg';
import priceBGSmall from 'assets/img/BG-small.svg';

export const ServicesSectionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 46px;
  @media (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 44px;
  }
`;
export const ServicesPlanWrapper = styled.div`
  .isOfferPage {
    flex-direction: column-reverse;
    align-items: flex-start !important;
  }
  span {
    background-color: unset !important;
    color: unset !important;
  }
  flex-direction: column;
  @media (max-width: 900px) {
    .isOfferPage {
      flex-direction: column-reverse;
      align-items: flex-start !important;
      padding-left: 0 !important;
    }
    button {
      height: 52px;
      font-size: 14px;
      width: 100%;
    }
  }
`;

export const ServicesPlanMobileWrapper = styled.div`
  margin-bottom: 14px;
  font-weight: 500;
  color: #242333;
  display: flex;
  align-items: center;
`;
export const VisualAndOldPriceWrapper = styled.div`
  padding-left: 3px;
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    font-size: 22px !important;
  }
`;

export const OldPriceOverLineComp = styled.div`
  display: flex;
  align-items: center;
  .new {
    font-weight: bold;
    font-size: 30px !important;
    color: #02af6c !important;
  }
  .old {
    font-size: 24px !important;
    text-decoration: line-through;
    padding-left: 5px;
    font-weight: 500 !important;
    color: rgba(58, 56, 83, 0.8) !important;
  }
  @media (max-width: 900px) {
    .new,
    .old {
      font-size: 22px !important;
    }
  }
`;

export const ServicesPlanTitle = styled.div`
  margin-bottom: 14px;
  font-weight: 500;
  color: #242333;
  display: flex;
  align-items: center;

  .title {
    padding-right: 5px;
  }

  .offer {
    margin-left: 8px;
    color: #242333;
    font-weight: bold;
    background: url(${priceBGBig});
    background-repeat: no-repeat;
    height: 34px;
    width: 71px;
    padding-left: 5px;
    line-height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  @media (max-width: 900px) {
    align-items: center;
    flex-direction: column-reverse;
    align-items: flex-start !important;
    .offer {
      width: 68px;
      height: 26px;
      line-height: 26px;
      background: url(${priceBGSmall});
      background-repeat: no-repeat;
    }
  }
`;

export const PlansPrice = styled.span`
  font-weight: bold;
`;

export const ServicesPlanParagraph = styled.div`
  margin-bottom: 38px;
  font-weight: 400;
  color: #8a87a5 !important;
  @media (max-width: 900px) {
    margin-bottom: 20px;
    line-height: 22px;
  }
`;

export const ServicesPlanListWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
