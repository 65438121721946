import React from 'react';
import {
  BottomDashedLine,
  ProductDetails,
  ProductInfoRow,
  ProductNames,
  ProductsTotalPriceWrapper,
  TransactionsModalPaymentTime,
  TransactionsModalTitle,
  TransactionsModalWrapper,
} from 'ui/styled/transactionsModalStyled';
import {
  getTextFromDataByKey,
  getTextFromDataByPeriod,
  getTextFromDataByProductKey,
} from 'utils/helpers';

import { CalendarIcon } from 'assets/svgIcons/CalendarIcon';
import formatDate, { DateFormats } from 'utils/date';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { DashedLine } from 'ui/styled/transactionsModalStyled';
import { LogoIcon } from 'assets/svgIcons/LogoIcon';

const TransactionsModal = (props) => {
  const { order, settingsData, checkModalTranslates, isTabletOrMobile } = props;

  const translateKeys = settingsData?.data?.data?.attributes?.enumTranslate;

  return (
    <>
      <TransactionsModalWrapper>
        {isTabletOrMobile ? (
          <LogoIcon className="logo-main" style={{ cursor: 'pointer' }} />
        ) : null}
        <TransactionsModalTitle className="headline4">
          {`${getTextFromDataByKey('orderNumber', checkModalTranslates)} # ${
            order.paymentId
          }`}
        </TransactionsModalTitle>
        <TransactionsModalPaymentTime className="bodyL">
          <CalendarIcon style={{ marginRight: '11px' }} />
          {formatDate({
            inputDate: order.paymentTime,
            outputFormat: DateFormats.DefaultWithoutLines,
          })}
        </TransactionsModalPaymentTime>
        <Scrollbars
          autoHeight
          autoHeightMin={50}
          autoHeightMax={400}
          renderView={(props) => <div {...props} className="view" />}
        >
          {order?.purchaseList?.map((i, index, array) => (
            <>
              <ProductInfoRow>
                <ProductNames>
                  {getTextFromDataByKey('product_name', checkModalTranslates)}
                </ProductNames>
                <span />
                <ProductDetails>
                  {getTextFromDataByProductKey(i.productKey, translateKeys)}
                </ProductDetails>
              </ProductInfoRow>
              <ProductInfoRow>
                <ProductNames>
                  {getTextFromDataByKey('ID_number', checkModalTranslates)}
                </ProductNames>
                <span />
                <ProductDetails> {i.purchasedItemId} </ProductDetails>
              </ProductInfoRow>
              <ProductInfoRow>
                <ProductNames>
                  {getTextFromDataByKey('time_period', checkModalTranslates)}
                </ProductNames>
                <span />
                <ProductDetails>
                  {getTextFromDataByPeriod(i.productKey, translateKeys)}
                </ProductDetails>
              </ProductInfoRow>
              <ProductInfoRow>
                <ProductNames>
                  {getTextFromDataByKey('price', checkModalTranslates)}
                </ProductNames>
                <span />
                <ProductDetails>{`₾ ${i.price}`} </ProductDetails>
              </ProductInfoRow>
              <DashedLine
                style={{
                  borderTop: array.length - 1 === index ? '0px' : undefined,
                }}
              />
            </>
          ))}
          {isTabletOrMobile ? null : (
            <ProductsTotalPriceWrapper className="bodyL">
              <ProductNames>
                {getTextFromDataByKey('totalPrice', checkModalTranslates)}
              </ProductNames>
              <ProductDetails className="totalPrice">{`₾ ${order.amount?.toFixed(
                2,
              )}`}</ProductDetails>
            </ProductsTotalPriceWrapper>
          )}
        </Scrollbars>
        {isTabletOrMobile ? (
          <ProductsTotalPriceWrapper className="bodyL">
            <ProductNames>
              {getTextFromDataByKey('totalPrice', checkModalTranslates)}
            </ProductNames>
            <ProductDetails className="totalPrice">{`₾ ${order.amount?.toFixed(
              2,
            )}`}</ProductDetails>
          </ProductsTotalPriceWrapper>
        ) : null}
      </TransactionsModalWrapper>
      {isTabletOrMobile ? null : <BottomDashedLine />}
    </>
  );
};

export default TransactionsModal;
