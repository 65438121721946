import { imagesBaseUrl } from "constants/global.constants";
import React from "react";

export const ImagesRenderer = (props) => {
  const { url, alt, width, height } = props;
  return (
    <img
      width={width}
      height={height}
      src={`${imagesBaseUrl}${url}`}
      alt={alt}
    />
  );
};
