/* eslint-disable react/jsx-no-literals */
import React from "react";

export const SearchIcon = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#676E77"
      width="10.3"
      height="10.496"
      viewBox="0 0 10.3 10.496"
      {...rest}
    >
      <path
        className="a-search"
        d="M11.115,9.47,9.185,7.632A4.644,4.644,0,1,0,5.629,9.291,4.6,4.6,0,0,0,8.291,8.45l1.951,1.86a.606.606,0,1,0,.873-.84ZM5.629,1.212A3.434,3.434,0,1,1,2.2,4.645,3.437,3.437,0,0,1,5.629,1.212Z"
        transform="translate(-0.984)"
      />
    </svg>
  );
};
