import React, { useEffect, useRef, useState } from 'react';
import {
  UserProfileDropDown,
  UserAvatar,
  UserDropDownOverlay,
  UserDropList,
  UserNameBlock,
  UserNameWrap,
  DropListItem,
  LogoutButton,
} from 'ui/shared/header/header.styled';
import { DropIcon } from 'assets/svgIcons/DropIcon';
import { ArrowRightIcon } from 'assets/svgIcons/ArrowRightIcon';
import { logout } from 'store/reducers/user/user.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { userSelectors } from 'store/reducers/user/user.slice';
import { userTypes } from 'constants/global.constants';
import api from 'services/api';
import { getTextFromDataByKey } from 'utils/helpers';

const UserDropDown = ({ translatesData, isOfferInUrlParams }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef(null);
  const activeUser = useSelector(userSelectors.userInfo);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const nameValue =
    activeUser && activeUser.fullName ? activeUser.fullName.split(' ') : null;

  const renderUserInitials = () => {
    if (activeUser && activeUser.customerType === userTypes.PERSONAL) {
      if (nameValue && nameValue[0] && nameValue[1]) {
        return nameValue[0].charAt(0) + nameValue[1].charAt(0);
      } else {
        return '';
      }
    } else if (
      activeUser &&
      activeUser.customerType === userTypes.LEGAL &&
      nameValue
    ) {
      return nameValue[0] && nameValue[0]?.substring(0, 2);
    }
  };

  const logoutHandler = async () => {
    try {
      await api.auth.logout();
      dispatch(logout(false));
      localStorage.removeItem('lang');
      isOfferInUrlParams ? navigate('/offer/isOffer') : navigate('/');
    } catch (e) {
      console.error(e);
    }
  };

  const additionalParam = isOfferInUrlParams ? 'isOffer' : 'default';

  return (
    <UserProfileDropDown>
      <UserAvatar className="bodyL">{renderUserInitials()}</UserAvatar>
      <UserNameBlock onClick={() => setIsOpen(!isOpen)}>
        <UserNameWrap>
          <div className="name bodyL">
            <span style={{ paddingRight: '2px' }}>
              {getTextFromDataByKey(
                'hello',
                translatesData?.data?.data?.attributes?.headerTranslates,
              )}
              ,
            </span>
            <span>{nameValue ? nameValue[0] : null}</span>
          </div>
          <div className="account bodyM">
            {getTextFromDataByKey(
              'yourAccount',
              translatesData?.data?.data?.attributes?.headerTranslates,
            )}
          </div>
        </UserNameWrap>
        <div>
          <DropIcon />
        </div>
      </UserNameBlock>
      {isOpen ? (
        <UserDropDownOverlay ref={ref}>
          <UserDropList>
            <DropListItem>
              <Link
                to={`/transactionHistory/${additionalParam}/id/${'0'}`}
                onClick={() => setIsOpen(false)}
              >
                {getTextFromDataByKey(
                  'transactionHistory',
                  translatesData?.data?.data?.attributes?.dropDownNavigation,
                )}
              </Link>
            </DropListItem>
            <DropListItem>
              <Link
                to={`/currentProducts/${additionalParam}/id/${'0'}`}
                onClick={() => setIsOpen(false)}
              >
                {getTextFromDataByKey(
                  'currentProducts',
                  translatesData?.data?.data?.attributes?.dropDownNavigation,
                )}
              </Link>
            </DropListItem>
            <DropListItem>
              <Link
                to={`/profile/${additionalParam}`}
                onClick={() => setIsOpen(false)}
              >
                {getTextFromDataByKey(
                  'profile',
                  translatesData?.data?.data?.attributes?.dropDownNavigation,
                )}
              </Link>
            </DropListItem>
          </UserDropList>
          <LogoutButton onClick={logoutHandler} className="button1">
            <span>
              {getTextFromDataByKey(
                'logout',
                translatesData?.data?.data?.attributes?.headerTranslates,
              )}
            </span>
            <span className="arrow">
              <ArrowRightIcon />
            </span>
          </LogoutButton>
        </UserDropDownOverlay>
      ) : null}
    </UserProfileDropDown>
  );
};
export default UserDropDown;
