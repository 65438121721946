import React, { useEffect, useState } from 'react';
import Register from './Register/Register';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Login from './Login/Login';
import {
  AuthContainer,
  AuthModalWrapper,
  BottomSignInfo,
} from 'ui/styled/auth.styled';
import RegisterVerify from './Register/RegisterVerify';
import EmailSuccess from './Register/EmailSuccess';
import api from 'services/api';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ForgotPasswordVerify from './ForgotPassword/ForgotPasswordVerify';
import ForgotPasswordForm from './ForgotPassword/ForgotPasswordForm';
import PasswordChangeSuccess from './ForgotPassword/PasswordChangeSuccess';

import { getTextFromDataByKey } from 'utils/helpers';
import { htmlParser } from 'utils/htmlUtils';
import { useMediaQuery } from 'react-responsive';
import BottomSheet from 'library/bottomSheet/BottomSheet';

export const tabs = {
  personal: 'Personal',
  company: 'Company',
};

export const authTypes = {
  login: 'login',
  register: 'register',
  forget: 'forget',
  forgetForm: 'forgetForm',
  registerVerify: 'registerVerify',
  forgotVerify: 'forgotVerify',
  EmailSuccess: 'EmailSuccess',
  forgetSuccess: 'forgetSuccess',
  expiredModal: 'expiredModal',
};

const Auth = (props) => {
  const {
    activeTab,
    setActiveTab,
    resetPassUserEmail,
    resetKey,
    authType,
    setAuthType,
    closeAuthModal,
    getCartData,
    data,
    verifyError,
    userName,
    setUserName,
    sendVerificationEmail,
    settingsData,
  } = props;

  const resendPasswordResetRequest = async () => {
    if (!userName) return;
    try {
      await api.auth.sendPasswordReset(userName);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setActiveTab(tabs.personal);
  }, []);

  const registrationData = data.data
    ? data.data.data.attributes.registration
    : [];
  const loginData = data.data ? data.data.data.attributes.logIn : [];
  const verifyEmailData = data.data
    ? data.data.data.attributes.verifyEmail
    : [];
  const passwordRecoveryData = data.data
    ? data.data.data.attributes.passwordRecovery
    : [];
  const forgotPasswordData = data.data
    ? data.data.data.attributes.forgotPassword
    : [];
  const authModalsData = data.data ? data.data.data.attributes?.modal : [];

  const errorTranslatesData =
    settingsData?.data?.data?.attributes?.errorTranslate;

  let renderAuthLayout;
  let classType;
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const [isOpen, setIsOpen] = useState(true);

  if (authType === authTypes.login) {
    classType = authTypes.login;
    renderAuthLayout = (
      <>
        <Login
          sendVerificationEmail={sendVerificationEmail}
          setUserName={setUserName}
          closeAuthModal={closeAuthModal}
          setAuthType={setAuthType}
          loginData={loginData}
          verifyError={verifyError}
          getCartData={getCartData}
          errorTranslatesData={errorTranslatesData}
        />
        <BottomSignInfo>
          <span>{getTextFromDataByKey('dontHaveAccount', loginData)}</span>
          <a
            href="#"
            className="sign"
            onClick={() => setAuthType(authTypes.register)}
          >
            {getTextFromDataByKey('signUpBtn', loginData)}
          </a>
        </BottomSignInfo>
      </>
    );
  } else if (authType === authTypes.register) {
    classType = authTypes.register;
    renderAuthLayout = (
      <>
        <Tabs>
          <TabList>
            <Tab onClick={() => setActiveTab(tabs.personal)}>
              {getTextFromDataByKey('personal', registrationData)}
            </Tab>
            <Tab onClick={() => setActiveTab(tabs.company)}>
              {getTextFromDataByKey('company', registrationData)}
            </Tab>
          </TabList>
          <TabPanel>
            <Register
              activeTab={activeTab}
              setAuthType={setAuthType}
              setUserName={setUserName}
              userName={userName}
              registrationData={registrationData}
              errorTranslatesData={errorTranslatesData}
            />
          </TabPanel>
          <TabPanel>
            <Register
              activeTab={activeTab}
              setAuthType={setAuthType}
              setUserName={setUserName}
              userName={userName}
              registrationData={registrationData}
              errorTranslatesData={errorTranslatesData}
            />
          </TabPanel>
        </Tabs>
        {activeTab === tabs.personal ? (
          <BottomSignInfo>
            <span className="registerBottomPersonal">
              {getTextFromDataByKey('alreadyHaveAccount', registrationData)}
            </span>
            <a
              href="#"
              className="sign"
              onClick={() => setAuthType(authTypes.login)}
            >
              {getTextFromDataByKey('logInBtn', registrationData)}
            </a>
          </BottomSignInfo>
        ) : (
          <BottomSignInfo>
            <span className="registerBottomCompany">
              {getTextFromDataByKey('alreadyHaveAccount', registrationData)}
            </span>
            <a
              href="#"
              className="sign"
              onClick={() => setAuthType(authTypes.login)}
            >
              {getTextFromDataByKey('logInBtn', registrationData)}
            </a>
          </BottomSignInfo>
        )}
      </>
    );
  } else if (authType === authTypes.registerVerify) {
    classType = authTypes.registerVerify;
    renderAuthLayout = (
      <RegisterVerify
        sendVerificationEmail={sendVerificationEmail}
        userName={userName}
        verifyEmailData={verifyEmailData}
      />
    );
  } else if (authType === authTypes.EmailSuccess) {
    classType = authTypes.EmailSuccess;
    renderAuthLayout = (
      <>
        <EmailSuccess
          authModalsData={authModalsData}
          title={authModalsData?.emailVerification?.title}
          desc={htmlParser(authModalsData?.emailVerification?.description)}
        />
        <BottomSignInfo>
          <span>{authModalsData?.PasswordReset?.urlLeftSide}</span>
          <a
            href="#"
            className="sign"
            onClick={() => setAuthType(authTypes.login)}
          >
            {authModalsData?.PasswordReset?.urlRightSide}
          </a>
        </BottomSignInfo>
      </>
    );
  } else if (authType === authTypes.forget) {
    classType = authTypes.forget;
    renderAuthLayout = (
      <>
        <ForgotPassword
          setAuthType={setAuthType}
          setUserName={setUserName}
          forgotPasswordData={forgotPasswordData}
          errorTranslatesData={errorTranslatesData}
        />
      </>
    );
  } else if (authType === authTypes.forgetForm) {
    classType = authTypes.forgetForm;
    renderAuthLayout = (
      <>
        <ForgotPasswordForm
          setAuthType={setAuthType}
          resetPassUserEmail={resetPassUserEmail}
          resetKey={resetKey}
          forgotPasswordData={forgotPasswordData}
          passwordRecoveryData={passwordRecoveryData}
        />
      </>
    );
  } else if (authType === authTypes.forgotVerify) {
    classType = authTypes.forgotVerify;
    renderAuthLayout = (
      <ForgotPasswordVerify
        resendPasswordResetRequest={resendPasswordResetRequest}
        userName={userName}
        verifyEmailData={verifyEmailData}
      />
    );
  } else if (authType === authTypes.forgetSuccess) {
    classType = authTypes.forgetSuccess;
    renderAuthLayout = (
      <>
        <PasswordChangeSuccess
          data={authModalsData}
          setAuthType={setAuthType}
        />
        <BottomSignInfo>
          <span>{authModalsData?.PasswordReset?.urlLeftSide}</span>
          <span className="sign" onClick={() => setAuthType(authTypes.login)}>
            {authModalsData?.PasswordReset?.urlRightSide}
          </span>
        </BottomSignInfo>
      </>
    );
  }

  return (
    <AuthModalWrapper className={classType}>
      <AuthContainer className={classType}>{renderAuthLayout}</AuthContainer>
    </AuthModalWrapper>
  );
};
export default Auth;
