import React from 'react';
import { PlansPrice } from 'ui/styled/servicesPlansStyled';
import { Button } from 'library/button/Button';
import {
  HarwdarePlanParagraph,
  HarwdarePlanTitle,
  HarwdarePlanWrapper,
} from 'ui/styled/hardware.styled';
import api from 'services/api';
import { htmlParser } from 'utils/htmlUtils';
import { focusonFormsGroup } from 'utils/helpers';
import cacheManager from 'utils/cacheManager';
import { TOKEN_NAME } from 'constants/global.constants';
import { CartIcon } from 'assets/svgIcons/CartIcon';
import { addCartLastItem } from 'store/reducers/app/app.slice';
import { useDispatch } from 'react-redux';

const HardwarePlans = (props) => {
  const {
    servicesPlanTitle,
    servicesPlanPrices,
    servicesPlanParagraph,
    buttonText,
    cartData,
    productKey,
    getCartData,
    setIsCartOpen,
    setAuthModalOpen,
  } = props;

  const tokenCache = cacheManager(TOKEN_NAME);
  const token = tokenCache.get();
  const distpath = useDispatch();

  const productKeyFromPrices = productKey[0]?.productKey;

  const isAddedToCart = cartData.find(
    (item) => item.product.productKey === productKeyFromPrices,
  );

  const updateItemInCart = async () => {
    if (!token) {
      setAuthModalOpen(true);
      return;
    }
    getCartData();
    try {
      await api.cart.addItemToCart(
        productKeyFromPrices,
        isAddedToCart ? isAddedToCart.quantity + 1 : 1,
      );
      getCartData();
      distpath(addCartLastItem(productKeyFromPrices));
      focusonFormsGroup('header');
      setIsCartOpen(true);
      focusonFormsGroup('bottomCart');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <HarwdarePlanWrapper>
      <HarwdarePlanTitle>
        {servicesPlanTitle}
        {servicesPlanPrices ? (
          <PlansPrice> {servicesPlanPrices} </PlansPrice>
        ) : null}
      </HarwdarePlanTitle>

      <HarwdarePlanParagraph className="bodyL">
        {htmlParser(servicesPlanParagraph)}
      </HarwdarePlanParagraph>

      <Button
        fontWeight="500"
        height="50px"
        fontSize="18px"
        background="#FFDC04"
        color="#242333"
        padding="0px 37px"
        onClick={updateItemInCart}
      >
        <CartIcon style={{ marginRight: '12px' }} />
        {buttonText}
      </Button>
    </HarwdarePlanWrapper>
  );
};
export default HardwarePlans;
