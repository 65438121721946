import styled from 'styled-components';

export const NotificationStyled = styled.div`
  padding: 12px 42px 13px 42px;
  border-radius: 23px;
  background: #3a3853;
  font-size: 14px;
  color: #ffffff;
  position: absolute;
  font-weight: 400;
  bottom: 56px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  font-family: 'Ubuntu', sans-serif;
  z-index: 1000;
  width: max-content;
`;

export const CopiedIcon = styled.div`
  margin-right: 8px;
`;

export const CopiedText = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;
