import React, { useEffect } from 'react';
import {
  HardwareNoteModalWrapper,
  InfoDescriptionBlock,
  NoteAddToCartButton,
  InfoNoteBlock,
} from 'ui/styled/hardware.styled';
import { Button } from 'library/button/Button';
import FormCheckBoxState from 'ui/components/formCheckbox/formCheckbox-formState';
import { htmlParser } from 'utils/htmlUtils';
import { CartIcon } from 'assets/svgIcons/CartIcon';

const SupportNoteModal = (props) => {
  const {
    updateItemInCart,
    closeModal,
    openMatchModal,
    activeSupportItem,
    supportCheck,
    selectedCartItem,
    setSupportCheck,
    cartData,
  } = props;

  const shouldSkipFirstStep = props.selectedCartItem?.modal === null;

  const cartUpdateMatchHandler = () => {
    const existingProductKeysInCart =
      cartData?.map((i) => i.product?.productKey) || [];
    const PlusItemProductKey = selectedCartItem?.prices[0]?.productKey;
    const plusItemAlreadyExistsInCart =
      existingProductKeysInCart?.includes(PlusItemProductKey);

    const productKeyAlreadyExistsInCart =
      selectedCartItem?.cartRemoveModal?.checkProduct?.some((i) =>
        existingProductKeysInCart?.includes(i),
      );
    const productBlockerNotExistInCart =
      selectedCartItem?.cartRemoveModal?.blocker?.some((i) =>
        existingProductKeysInCart?.includes(i),
      );
    if (productKeyAlreadyExistsInCart) {
      openMatchModal();
    } else if (plusItemAlreadyExistsInCart || !shouldSkipFirstStep) {
      updateItemInCart();
    }
    if (
      selectedCartItem.cartRemoveModal?.blocker &&
      productBlockerNotExistInCart === true
    ) {
      updateItemInCart();
    } else if (
      productBlockerNotExistInCart === false &&
      plusItemAlreadyExistsInCart === false
    ) {
      openMatchModal();
    }
  };

  useEffect(() => {
    if (shouldSkipFirstStep) {
      cartUpdateMatchHandler();
      closeModal();
    }
  }, [shouldSkipFirstStep]);

  if (shouldSkipFirstStep) return null;

  return (
    <HardwareNoteModalWrapper>
      <InfoNoteBlock className="headline4">
        {activeSupportItem?.title}
      </InfoNoteBlock>
      <InfoDescriptionBlock>
        {/* <span className="top">In case of premise you need your own server</span> */}
        <span className="bottom">
          {htmlParser(activeSupportItem?.description)}
        </span>
        {activeSupportItem?.Porting ? (
          <div className="agreement">
            <FormCheckBoxState
              checked={supportCheck}
              label={activeSupportItem?.Porting}
              labelPosition="right"
              labelOnchange
              value="yes"
              onChange={() => {
                setSupportCheck(!supportCheck);
              }}
            />
          </div>
        ) : (
          <div className="agreement"> {activeSupportItem?.Porting}</div>
        )}
      </InfoDescriptionBlock>
      <NoteAddToCartButton>
        <Button
          className="button1"
          background="#FFDC04"
          color="#242333"
          width="100%"
          padding="20px 100px"
          fontWeight="500"
          height="50px"
          onClick={() => {
            cartUpdateMatchHandler();
            closeModal();
          }}
        >
          <CartIcon style={{ marginRight: '12px' }} />
          {activeSupportItem?.btn}
        </Button>
      </NoteAddToCartButton>
    </HardwareNoteModalWrapper>
  );
};

export default SupportNoteModal;
