import React from 'react';
import { ItemsListServices } from 'ui/styled/PlansSection.styled';

const PlansListServices = (props) => {
  return (
    <>
      <ItemsListServices services isOfferPage={props.isOfferPage}>
        <ul>
          {props.planItems &&
            props.planItems.map((i, index) => <li key={i + index}>{i}</li>)}
        </ul>
      </ItemsListServices>
    </>
  );
};

export default PlansListServices;
