import { LoadingIcon } from 'assets/svgIcons/Loading.icon';
import React from 'react';
import { EmptyTransactionsWrapper } from 'ui/styled/emptyTransactionsInfoStyled';

const TableLoading = () => (
  <EmptyTransactionsWrapper style={{ marginTop: '10%' }}>
    <LoadingIcon color="#FFDC04" />
  </EmptyTransactionsWrapper>
);

export default TableLoading;
