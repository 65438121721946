import React from 'react';
import { BannerLinkWrapper, BannerWrapper } from 'ui/styled/BlogPageStyled';
import { Button } from 'library/button/Button';
import { imagesBaseUrl } from 'constants/global.constants';
import { useMediaQuery } from 'react-responsive';
import { BlogNextIcon } from 'assets/svgIcons/BlogNextIcon';

const BlogBanner = ({ BlogBannerData }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  const handleButtonClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <BannerWrapper>
      {!isTabletOrMobile ? (
        <img
          src={`${imagesBaseUrl}${BlogBannerData?.desktop?.data?.attributes?.url}`}
        />
      ) : (
        <img
          src={`${imagesBaseUrl}${
            BlogBannerData?.responsive?.data?.[0]?.attributes?.url ||
            BlogBannerData?.responsive?.data?.attributes?.url
          }`}
        />
      )}
      <div className="wrapper">
        <div className="BlogsHeadlineWithoutCaps title">
          {BlogBannerData?.subTitle}
        </div>
        <div className="Blogsheadline2WithCaps text">
          {BlogBannerData?.title}
        </div>

        {!isTabletOrMobile && BlogBannerData?.button?.name ? (
          <Button
            height="55px"
            padding="17px 84px"
            background="#FFDC04"
            color="#242333"
            fontWeight="500"
            className="Blogsbutton"
            onClick={() => handleButtonClick(BlogBannerData?.button?.url)}
          >
            {BlogBannerData?.button?.name}
          </Button>
        ) : (
          <>
            <BannerLinkWrapper
              onClick={() => handleButtonClick(BlogBannerData?.button?.url)}
            >
              <div className="Blogsbutton link">
                {BlogBannerData?.button?.name}
              </div>
              {isTabletOrMobile ? <BlogNextIcon /> : undefined}
            </BannerLinkWrapper>
          </>
        )}
      </div>
    </BannerWrapper>
  );
};
export default BlogBanner;
