import styled from 'styled-components';

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 14%;
  margin-bottom: 124px;
  .partners {
    margin-right: 30px;
  }
  img {
    transform: translateY(-3px);
  }
  @media (max-width: 1540px) {
    padding: 0 8%;
  }
  @media (max-width: 1290px) {
    padding: 0 5%;
  }
  @media (max-width: 900px) {
    padding: 0 22px !important;
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
    .slick-initialized .slick-slide {
      display: flex;
      align-items: center;
      min-height: 40px;
    }
  }
`;
export const PartnetsLogoList = styled.div`
  display: flex;
  flex-basis: 12%;
  img {
    filter: grayscale(100%);
    max-width: 100%;
  }
  img:hover {
    filter: grayscale(0);
  }
  @media (max-width: 900px) {
    flex-basis: 25%;
    img {
      max-width: 70%;
    }
  }
`;

export const OurPartnersParagraph = styled.div`
  color: #8a87a5;
  font-weight: 400;
  @media (max-width: 900px) {
    line-height: normal;
    font-size: 14px;
    margin-bottom: 28px;
  }
`;
export const PartnetsInfo = styled.div`
  width: auto;
`;
export const OurPartnersText = styled.div`
  color: #3a3853;
  font-weight: bold;
  line-height: 61px;
  @media (max-width: 900px) {
    line-height: normal;
    margin-bottom: 8px;
  }
`;
export const PartnersFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 100px;
  @media (max-width: 900px) {
    justify-content: flex-start;
    align-items: unset;
    flex-direction: column;
    padding-bottom: 0px;
    margin-bottom: 20px;
  }
`;

export const MobileCarouselWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
