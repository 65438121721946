import { userTypes } from 'constants/global.constants';
import BottomSheet from 'library/bottomSheet/BottomSheet';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { userSelectors } from 'store/reducers/user/user.slice';
import ErrorAndSuccessModal from 'ui/pages/Cart/CartPageComponents/ErrorAndSuccessModal';
import { DemoModalStyled } from 'ui/styled/home.styled';
import { getTextFromDataByKey, textRenderer } from 'utils/helpers';
import { htmlParser } from 'utils/htmlUtils';
import DemoModalForm from './DemoModalForm';

export const Demotabs = {
  PERSONAL: 'Personal',
  COMPANY: 'Company',
};

const DemoModalComponent = (props) => {
  const {
    selectedProduct,
    closeModal,
    homePageData,
    isTabletOrMobile,
    selectedProductFromBlogs,
  } = props;
  const [demoSuccess, setShowDemoSuccess] = useState(false);
  const [activeTab, setActiveTab] = useState(Demotabs.PERSONAL);
  const activeUser = useSelector(userSelectors.userInfo);
  const [isOpen, setIsOpen] = useState(true);
  const isUserLoggedIn = useSelector(userSelectors.selectIsUserLoggedIn);

  useEffect(() => {
    if (activeUser) {
      if (activeUser.customerType === userTypes.PERSONAL) {
        setActiveTab(Demotabs.PERSONAL);
      }
      if (activeUser.customerType === userTypes.LEGAL) {
        setActiveTab(Demotabs.COMPANY);
      }
    }
  }, [activeUser]);

  const productToCheck = selectedProductFromBlogs || selectedProduct;

  const demoProduct = productToCheck?.attributes.prices.find(
    (item) => item.isDemo,
  );

  return (
    <DemoModalStyled>
      {demoSuccess ? (
        isTabletOrMobile ? (
          <BottomSheet
            isOpen={isOpen}
            closeBottomSheet={() => setIsOpen(false)}
          >
            <ErrorAndSuccessModal
              title={textRenderer(homePageData?.demoModal?.success?.title)}
              subTitle={htmlParser(
                textRenderer(homePageData?.demoModal?.success?.description),
              )}
            />
          </BottomSheet>
        ) : (
          <ErrorAndSuccessModal
            title={textRenderer(homePageData?.demoModal?.success?.title)}
            subTitle={htmlParser(
              textRenderer(homePageData?.demoModal?.success?.description),
            )}
          />
        )
      ) : !isUserLoggedIn ? (
        <Tabs>
          <TabList
            style={{
              display: activeUser && isTabletOrMobile ? 'none' : undefined,
            }}
          >
            <Tab onClick={() => setActiveTab(Demotabs.PERSONAL)}>
              {getTextFromDataByKey(
                'personalTabName',
                homePageData?.demoModal?.enum,
              )}
            </Tab>
            <Tab onClick={() => setActiveTab(Demotabs.COMPANY)}>
              {getTextFromDataByKey(
                'companyTabName',
                homePageData?.demoModal?.enum,
              )}
            </Tab>
          </TabList>
          <TabPanel>
            <DemoModalForm
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              activeDemoKey={demoProduct?.productKey}
              closeModal={closeModal}
              setShowDemoSuccess={setShowDemoSuccess}
              homePageData={homePageData}
            />
          </TabPanel>
          <TabPanel>
            <DemoModalForm
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              closeModal={closeModal}
              activeDemoKey={demoProduct?.productKey}
              setShowDemoSuccess={setShowDemoSuccess}
              homePageData={homePageData}
            />
          </TabPanel>
        </Tabs>
      ) : (
        <DemoModalForm
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          closeModal={closeModal}
          activeDemoKey={demoProduct?.productKey}
          setShowDemoSuccess={setShowDemoSuccess}
          homePageData={homePageData}
        />
      )}
    </DemoModalStyled>
  );
};

export default DemoModalComponent;
