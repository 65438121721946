import React, { memo, useState } from 'react';
import { AbsoluteCompStyled, InputStyled, SearchInput } from './input-styled';
import { LabelStyled } from './label-styled';
import searchIcon from 'assets/img/searchIcon.svg';
export const Input = ({
  value,
  id,
  type = 'text',
  label,
  onChange,
  active,
  placeholder,
  autoFocus,
  name,
  register,
  showPassword,
  errorText,
  AbsoluteComp,
  onClick,
  switcher,
  numberSpinners,
  disabled,
  onBlur,
  readOnly,
  borderColor,
  outLineBg,
  disableFocus,
  maxLength,
  incrementHandler,
  decrementHandler,
  search,
  ...other
}) => {
  const [typeState, setTypeState] = useState(type);
  const toggleShowPassword = () => {
    setTypeState((prev) => (prev === 'password' ? 'text' : 'password'));
  };

  // disable up/ down arrows defaults
  function checkKey(e) {
    e = e || window.event;

    if (e.keyCode === '38') {
      // up arrow
      e.preventDefault();
    } else if (e.keyCode === '40') {
      // down arrow
      e.preventDefault();
    }
  }
  document.onkeydown = checkKey;

  return (
    <LabelStyled
      numberSpinners={numberSpinners}
      type={typeState}
      outLineBg={outLineBg}
      disabled={disabled}
    >
      <InputStyled
        ref={register}
        id={id || name}
        className={borderColor ? ' errorBorder' : ''}
        value={value}
        type={type}
        active={active}
        placeholder={placeholder}
        disableFocus={disableFocus}
        autoFocus={autoFocus}
        name={name}
        autoComplete="off"
        errorText={errorText}
        disabled={disabled}
        readOnly={readOnly}
        switcher={switcher}
        AbsoluteComp={AbsoluteComp}
        numberSpinners={numberSpinners}
        maxLength={maxLength}
        step="any"
        onChange={onChange}
        onBlur={onBlur}
      />
      {label && (
        <label
          className={borderColor ? 'input-label errorLabel' : 'input-label'}
          htmlFor={id || name}
          input-type={type}
        >
          {label}
        </label>
      )}
      {search && (
        <SearchInput>
          <img src={searchIcon} alt="Search" />
        </SearchInput>
      )}
      {showPassword && (
        <AbsoluteCompStyled
          numberSpinners={numberSpinners}
          readOnly={readOnly}
          disabled={disabled}
          onClick={toggleShowPassword}
        ></AbsoluteCompStyled>
      )}
      {AbsoluteComp && (
        <AbsoluteCompStyled
          className="AbsoluteCompStyled"
          numberSpinners={numberSpinners}
          readOnly={readOnly}
          disabled={disabled}
        >
          {AbsoluteComp}
        </AbsoluteCompStyled>
      )}

      {switcher && <div className="switcher" />}
    </LabelStyled>
  );
};

Input.defaultProps = {
  type: 'text',
  active: false,
  autoFocus: false,
  placeholder: ' ',
  showPassword: false,
  AbsoluteComp: null,
  switcher: false,
};

export default memo(Input);
