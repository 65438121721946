import styled from 'styled-components';
import check from 'assets/img/Check.svg';

export const SeciontBlockBlogs = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 14%;
  margin-bottom: 160px;

  .top-bg-img {
    background-size: cover;
    min-width: 60%;
  }
  .blog-bg-img {
    background-size: contain;
    background-position: unset;
    min-height: 450px;
    background-repeat: no-repeat;
  }

  @media (max-width: 900px) {
    .blog-bg-img {
      min-height: 230px;
      background-size: cover;
    }
  }

  @media (max-width: 1540px) {
    padding: 0px 8%;
    .top-bg-img {
      min-width: 58%;
    }
  }
  @media (max-width: 1290px) {
    padding: 0px 4%;

    .top-bg-img {
      min-width: 60%;
      flex-basis: 100%;
    }
  }
  @media (max-width: 900px) {
    margin-bottom: 44px;
    padding: 0px 0%;

    .blog-bg-img {
      flex-basis: 100%;
      width: 100%;
      min-height: 230px;
      max-height: 230px;
      border-bottom-right-radius: 165px;
      margin-bottom: 36px;
      padding: 0%;
    }
    .top-bg-img {
      flex-basis: 100%;
      width: 100%;
      min-height: 230px;
      max-height: 230px;
      margin-bottom: 36px;
      padding: 0%;
    }
  }
`;

export const LeftSectionBlogs = styled.div`
  height: 100%;
  flex-basis: 49%;
  padding: ${(props) => (props.leftToRight ? '0 0 0 0' : '0 0 0 5%')};

  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (max-width: 1540px) {
    flex-basis: 100%;
  }
  @media (max-width: 1290px) {
    flex-basis: 100%;
  }
  @media (max-width: 900px) {
    padding: 0 28px !important;
  }
`;

export const RightSectionBlogs = styled.div`
  height: 100%;
  min-height: 510px;
  flex-basis: ${(props) => (props.rightToLeft ? '51%' : '49%')};

  img {
    max-width: 100%;
  }
  @media (max-width: 1540px) {
    min-width: 60%;
  }
  @media (max-width: 1290px) {
    flex-basis: 100%;
  }
  @media (max-width: 900px) {
    padding: ${(props) => (props.rightToLeft ? '25px 22px 0 5%' : '0 0 0 0')};
  }
`;

export const BannerWrapper = styled.div`
  padding: 0 14%;
  margin: 0px auto 100px 0px;
  position: relative;
  img {
    position: relative;
    max-width: 100%;
  }
  .wrapper {
    position: absolute;
    top: 40px;
    left: 18%;
  }
  .title {
    color: #8a87a5;
    line-height: 26px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .text {
    color: #242333;
    line-height: 53px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .banner-container {
    width: 100%;
    min-height: 238px;
  }

  @media (max-width: 1685px) {
    padding: 0px 8%;
    img {
      max-width: 1290px;
    }
    .wrapper {
      top: 25px;
      left: 12%;
    }
  }
  @media (max-width: 900px) {
    padding: 0 28px;
    margin: 0px auto 44px 0px;

    img {
      width: 100%;
    }
    .title {
      color: #8a87a5;
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .text {
      color: #242333;
      font-weight: 500;
      margin-bottom: 26px;
      line-height: 26px;
    }
    .link {
      color: #242333;
      font-weight: 500;
      margin-right: 6px;
    }
    .wrapper {
      left: 14%;
      right: 9%;
    }
  }
`;

export const AccordionWrapper = styled.div``;

export const BlogsPlan = styled.div`
  ul {
    list-style: none;
    margin-top: 0px;
    margin-bottom: 46px;
    padding-left: 21px;
  }
  li {
    color: #8a87a5;
    font-weight: 400;
    padding-left: 19px;
    list-style-image: url(${check});
    margin-bottom: 24px;
    img {
      width: 7px;
      height: 7px;
    }
  }
  @media (max-width: 900px) {
    ul {
      margin-bottom: 29px;
      padding-left: 26px;
    }
    li {
      padding-left: 14px;
      margin-bottom: 18px;
    }
  }
`;

export const BlogParagraphListTitleStyled = styled.div`
  .plan-title {
    color: #242333;
    margin-bottom: 20px;
    line-height: 50px;
  }

  p {
    margin: 0px;
  }
  @media (max-width: 900px) {
    .plan-title {
      line-height: 30px;
      font-size: 22px !important;
      margin-bottom: 14px;
      width: 95%;
    }
  }
`;
export const BlogParagraphListWrapper = styled.div`
  p {
    margin-top: 0px;
    margin-bottom: 30px;
    color: #8a87a5;
    font-weight: 400;
    line-height: 26px;
  }
`;

export const FAQBlogsNavWrapper = styled.div`
  margin-bottom: 100px;
  padding: 0 14%;
  .react-tabs__tab-list {
    border-bottom: 1px solid #e4e2eb;
    margin: 0 30px;
    display: flex;
  }

  .react-tabs__tab:after {
    background: transparent;
  }
  .react-tabs__tab:focus {
    border-bottom: 1px solid #ffdc04;
    color: #3a3853;
    border-radius: 0;
    box-shadow: none;
    border-color: #ffdc04;
    outline: none;
  }

  .react-tabs__tab-panel--selected {
    padding: 0px 14% 0px 15%;
    @media (max-width: 900px) {
      padding: 0 22px;
    }
    .Collapsible {
      display: flex;
      cursor: pointer;
      align-items: center;
      transition: max-height 0.9s ease-out;
    }
  }
  .content {
    max-height: 0;
    transition: max-height 0.9s ease-out;
    overflow: hidden;
    color: #8a87a5;
  }
  .contentShow {
    color: #8a87a5;
    font-weight: 400;
    overflow: hidden;
    max-height: 500px;
    transition: max-height 0.9s ease-in;
  }
  @media (max-width: 1540px) {
    padding: 0 8%;
    height: inherit;
  }
  @media (max-width: 900px) {
    padding: 0 22px;
    height: inherit;
    margin-bottom: 44px;
  }
`;
export const MobileImgWrapper = styled.div`
  width: 100%;
  flex-basis: 100%;
  margin-bottom: 4px;

  img {
    width: 100%;
  }
`;

export const BlogsFaqParagraphTitle = styled.div`
  color: #242333;
  margin-bottom: 52px;
  @media (max-width: 900px) {
    margin-bottom: 26px;
    color: #3a3853;
  }
`;

export const BannerLinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;
