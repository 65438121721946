import { CloseIcon } from 'assets/svgIcons/CloseIcon';
import { MobileAvatar } from 'assets/svgIcons/MobileAvatar';
import { TickIcon } from 'assets/svgIcons/TickIcon';
import { Button } from 'library/button/Button';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import api from 'services/api';
import { appSelectors } from 'store/reducers/app/app.slice';
import { logout } from 'store/reducers/user/user.actions';
import { userSelectors } from 'store/reducers/user/user.slice';
import { getTextFromDataByKey } from 'utils/helpers';
import {
  MobileMenuWrapper,
  MenuTitle,
  MobileMenuNav,
  MenuSeperator,
  MobileLanguageChooser,
  MobileLanguageItem,
  MobileLoginLogoutButton,
  MobileUserInfo,
  MobileUserAvatar,
  MobileUserName,
} from '../header.styled';

const MobileMenu = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    headerData,
    langOptions,
    translatesData,
    loginData,
    login,
    closeMenu,
    languageChangeHandler,
  } = props;
  const isUserLoggedIn = useSelector(userSelectors.selectIsUserLoggedIn);
  const activeUser = useSelector(userSelectors.userInfo);
  const activeLanguage = useSelector(appSelectors.selectLanguage);

  const footerData = translatesData
    ? translatesData?.data?.data?.attributes?.footer
    : null;

  const nameValue =
    activeUser && activeUser.fullName ? activeUser.fullName.split(' ') : null;

  const logoutHandler = async () => {
    try {
      await api.auth.logout();
      closeMenu();
      dispatch(logout(false));
      localStorage.removeItem('lang');
      navigate('/');
    } catch (e) {
      console.error(e);
    }
  };

  const isOfferInUrlParams = location?.pathname?.includes('isOffer');
  const additionalParam = isOfferInUrlParams ? 'isOffer' : 'default';

  return (
    <MobileMenuWrapper>
      {isUserLoggedIn ? (
        <MobileUserInfo>
          <MobileUserAvatar>
            <MobileAvatar />
          </MobileUserAvatar>
          <MobileUserName>
            <div className="name">
              <span style={{ paddingRight: '2px' }}>
                {getTextFromDataByKey(
                  'hello',
                  translatesData?.data?.data?.attributes?.headerTranslates,
                )}
                ,
              </span>
              <span>{nameValue ? nameValue[0] : null}</span>
            </div>
            <div className="email">{activeUser?.username}</div>
          </MobileUserName>
        </MobileUserInfo>
      ) : (
        <MenuTitle>Menu</MenuTitle>
      )}
      <MobileMenuNav>
        {isUserLoggedIn ? (
          <NavLink to={`/profile/${additionalParam}`} onClick={closeMenu}>
            {getTextFromDataByKey(
              'profile',
              translatesData?.data?.data?.attributes?.dropDownNavigation,
            )}
          </NavLink>
        ) : null}

        {headerData.map((item, index) => {
          return (
            <NavLink
              key={item.id + index}
              to={`/${item.key}`}
              onClick={closeMenu}
            >
              {item.value}
            </NavLink>
          );
        })}

        {isUserLoggedIn ? (
          <>
            <NavLink
              to={`/currentProducts/${additionalParam}/id/${'0'}`}
              onClick={closeMenu}
            >
              {getTextFromDataByKey(
                'currentProducts',
                translatesData?.data?.data?.attributes?.dropDownNavigation,
              )}
            </NavLink>
            <NavLink
              to={`/transactionHistory/${additionalParam}/id/${'0'}`}
              onClick={closeMenu}
            >
              {getTextFromDataByKey(
                'transactionHistory',
                translatesData?.data?.data?.attributes?.dropDownNavigation,
              )}
            </NavLink>
          </>
        ) : null}

        {footerData &&
          footerData?.navigation?.map((item) => {
            return (
              <NavLink to={item.key} onClick={closeMenu}>
                {item.value}
              </NavLink>
            );
          })}
      </MobileMenuNav>
      <MenuSeperator />

      <MobileLanguageChooser>
        {langOptions.map((item, index) => {
          return (
            <MobileLanguageItem
              key={index + item.label}
              onClick={() => languageChangeHandler(item)}
              className={item?.value === activeLanguage?.value ? 'active' : ''}
              style={{
                fontFamily:
                  item.value === 'ka' ? 'Helvetica Neue LT GEO' : undefined,
              }}
            >
              <span>{item.label}</span>
              {item?.value === activeLanguage?.value ? (
                <span>
                  <TickIcon />
                </span>
              ) : null}
            </MobileLanguageItem>
          );
        })}
      </MobileLanguageChooser>
      <MobileLoginLogoutButton>
        {isUserLoggedIn ? (
          <Button
            type="button"
            onClick={logoutHandler}
            text={getTextFromDataByKey(
              'logout',
              translatesData?.data?.data?.attributes?.headerTranslates,
            )}
          />
        ) : (
          <Button
            type="button"
            onClick={() => {
              login();
              closeMenu();
            }}
            text={getTextFromDataByKey('logInBtn', loginData)}
          />
        )}
      </MobileLoginLogoutButton>
    </MobileMenuWrapper>
  );
};

export default MobileMenu;
