import styled from 'styled-components';

export const ParagraphWrapper = styled.div`
  margin: 0px 0px 100px 0px;
  padding: 0px 14%;
  display: flex;
  @media (max-width: 1540px) {
    padding: 0 8%;
  }
  @media (max-width: 900px) {
    padding: 0px;
    margin-bottom: 20px;
  }
`;
export const ParagraphWithPictureWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ParagraphImg = styled.div`
  margin-right: 24px;
  img {
    max-width: 58px;
  }
`;

export const ParagraphTitle = styled.div`
  margin-bottom: 8px;
  color: #3a3853;
  font-weight: 500;
`;
export const ParagraphText = styled.div`
  color: #8a87a5;
  font-weight: 400;
  .name {
    color: #3a3853;
    margin-left: 3px;
    font-weight: 500;
  }
`;
