import React from "react";

export const TickIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.608"
      height="9.99"
      viewBox="0 0 13.608 9.99"
    >
      <path
        id="Path_53"
        data-name="Path 53"
        d="M-3.533-3.491l5.77,5.454-3.56,3.914"
        transform="translate(7.996 5.653) rotate(90)"
        fill="none"
        stroke="#3a3853"
        stroke-linecap="round"
        strokeWidth="3"
      />
    </svg>
  );
};
