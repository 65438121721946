import React from 'react';
import {
  ConfirmDeleteModalButtons,
  ConfirmDeleteModalIcon,
  ConfirmDeleteModalStyled,
  ConfirmDeleteModalSubTitle,
  ConfirmDeleteModalTitle,
} from 'ui/styled/cartPage.styled';
import clearCart from 'assets/img/delete.svg';

const ConfirmDeleteModal = (props) => {
  const {
    confirmDelete,
    closeModal,
    ConfirmDeleteText,
    DeleteTextTitle,
    ConfirmDelete,
    CancelDelete,
  } = props;
  return (
    <ConfirmDeleteModalStyled>
      <ConfirmDeleteModalIcon>
        <img src={clearCart} alt="Clear Cart" />
      </ConfirmDeleteModalIcon>
      <ConfirmDeleteModalTitle className="headline4">
        {DeleteTextTitle}
      </ConfirmDeleteModalTitle>
      <ConfirmDeleteModalSubTitle className="bodyL">
        {ConfirmDeleteText}
      </ConfirmDeleteModalSubTitle>
      <ConfirmDeleteModalButtons className="button1">
        <div className="delete" onClick={confirmDelete}>
          {ConfirmDelete}
        </div>
        <div className="close" onClick={closeModal}>
          {CancelDelete}
        </div>
      </ConfirmDeleteModalButtons>
    </ConfirmDeleteModalStyled>
  );
};

export default ConfirmDeleteModal;
