import React from "react";
export const PassShowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.712"
    height="16.75"
    viewBox="0 0 19.712 16.75"
  >
    <g id="Show" transform="translate(424.958 -299.174)">
      <path
        id="Path_11039"
        data-name="Path 11039"
        d="M-415.106,379.311a9.939,9.939,0,0,1,5.94,2.031,12.848,12.848,0,0,1,3.81,4.351.768.768,0,0,1-.463,1.151.762.762,0,0,1-.885-.411,10.833,10.833,0,0,0-1.575-2.225,10.208,10.208,0,0,0-4.494-2.994,7.656,7.656,0,0,0-5.662.386,10.75,10.75,0,0,0-5.022,4.761.806.806,0,0,1-.884.495.77.77,0,0,1-.525-1.125,11.518,11.518,0,0,1,1.47-2.2,11.884,11.884,0,0,1,5.469-3.774A9.431,9.431,0,0,1-415.106,379.311Z"
        transform="translate(0 -73.964)"
        fill="#3a3853"
      />
      <path
        id="Path_11040"
        data-name="Path 11040"
        d="M-342.307,425.866a3.783,3.783,0,0,1-3.775-3.789,3.777,3.777,0,0,1,3.8-3.785,3.778,3.778,0,0,1,3.766,3.8A3.783,3.783,0,0,1-342.307,425.866Zm.013-1.542a2.241,2.241,0,0,0,2.232-2.268,2.237,2.237,0,0,0-2.256-2.223,2.238,2.238,0,0,0-2.223,2.256A2.242,2.242,0,0,0-342.294,424.324Z"
        transform="translate(-72.8 -109.942)"
        fill="#3a3853"
      />
      <path
        id="Path_11041"
        data-name="Path 11041"
        d="M-422,354.6a2.259,2.259,0,0,1-.5-.251c-.582-.5-1.151-1.021-1.717-1.542a.754.754,0,0,1-.061-1.072.755.755,0,0,1,1.085-.074q.881.775,1.742,1.572a.737.737,0,0,1,.18.847A.882.882,0,0,1-422,354.6Z"
        transform="translate(-0.448 -48.266)"
        fill="#3a3853"
      />
      <path
        id="Path_11042"
        data-name="Path 11042"
        d="M-211.093,354.624a.858.858,0,0,1-.74-.517.746.746,0,0,1,.2-.859c.467-.43.941-.852,1.413-1.277.11-.1.215-.2.33-.294a.761.761,0,0,1,1.057.078.748.748,0,0,1-.03,1.059q-.871.814-1.773,1.595A1.855,1.855,0,0,1-211.093,354.624Z"
        transform="translate(-196.652 -48.295)"
        fill="#3a3853"
      />
      <path
        id="Path_11043"
        data-name="Path 11043"
        d="M-305.48,301.1c0,.372,0,.744,0,1.116a.775.775,0,0,1-.777.8.771.771,0,0,1-.763-.8q0-1.126,0-2.251a.766.766,0,0,1,.776-.8.769.769,0,0,1,.764.8c0,.013,0,.026,0,.038Q-305.479,300.556-305.48,301.1Z"
        transform="translate(-108.851)"
        fill="#3a3853"
      />
    </g>
  </svg>
);
