import styled from 'styled-components';
import check from 'assets/img/Check.svg';

export const PlansSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: ${(props) => (props.left ? '0 0 0 0' : '0 0 0 0')};
`;

export const AdditionalTitleServ = styled.span`
  color: #f2d20e;
  padding-left: 10px;
`;

export const PlanItemTitle = styled.div`
  .plan-title {
    color: #3a3853;
    margin-bottom: 12px;
    font-weight: 700;
  }
  .plan-p {
    font-weight: 400;
    color: #8a87a5;
    margin-bottom: 36px;
  }
  @media (max-width: 900px) {
    .dolar {
      height: auto;
      margin-bottom: 8px;
    }
    .plan-title {
      font-size: 22px;
    }
    .plan-p {
      p {
        margin: 0;
      }
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 4px;
    }
  }
`;

export const ItemsList = styled.div`
  margin-bottom: 53px;
  padding-right: ${({ services }) => (services ? '46px' : undefined)};
  ul {
    list-style: none;
    padding-left: 22px;
  }
  li {
    color: #8a87a5;
    font-weight: 400;
    padding-left: 10px;
    list-style-image: url(${check});
  }
  @media (max-width: 900px) {
    margin-bottom: 35px;
    li {
    }
  }
`;
export const ItemsListServices = styled.div`
  padding-right: ${({ services }) => (services ? '46px' : undefined)};
  margin-bottom: 35px;
  width: 100%;
  ul {
    width: 100%;
    list-style: none;
    padding-left: 22px;
    display: ${({ isOfferPage }) => (isOfferPage ? 'flex' : 'grid')};
    flex-direction: ${({ isOfferPage }) => (isOfferPage ? 'column' : 'unset')};
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(2, 1fr);
    row-gap: 5px;
    column-gap: 13px;
    margin: 0;
  }
  li {
    color: #8a87a5;
    font-weight: 400;
    padding-left: 10px;
    list-style-image: url(${check});
    margin-bottom: 16px;
  }
  @media (max-width: 900px) {
    padding-right: 0px;
    ul {
      width: 100%;
      display: inline-block;
    }
  }
`;
export const PlanItemButtons = styled.div`
  display: flex;
  height: 50px;
  gap: 18px;
  .videoBtn {
    height: 50px;
  }
  @media (max-width: 900px) {
    gap: 14px;
    button {
      font-size: 14px;
      height: 52px;
    }
    .cart-btn {
      padding: 0 32px;
    }
    .demo-btn {
      width: 52px;
      padding: 0 18px;
    }
    .videoBtn {
      width: 52px;
      height: 52px;
    }
  }
`;
