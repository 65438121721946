import axios from 'axios';
import { useEffect, useState } from 'react';
import cacheManager from 'utils/cacheManager';

const getDataBaseUrl = process.env.REACT_APP_GET_DATA_BASE_URL;

const useFetchData = (props) => {
  const { url, lang } = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const localeString = lang && lang.value ? `&locale=${lang.value}` : '';
  useEffect(() => {
    setLoading(true);
    setError(false);
    axios
      .get(`${getDataBaseUrl}${url}${localeString}`)
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 1000);
      });

    return () => {
      setData(null);
    };
  }, [lang]);

  return { data, loading, error };
};

export default useFetchData;
