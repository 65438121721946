import React, { useState } from 'react';
import {
  ActiveLine,
  BlogsSliderTitle,
  BlogsSliderWrapper,
  LeftSliderContainer,
  LeftSliderText,
  LeftSliderTitle,
  LeftSliderWrapper,
  LeftSliders,
  RightSliderWithBanner,
  SlidersContainer,
} from 'ui/styled/BlogsParallaxInfoStyled';
import { ImagesRenderer } from 'utils/imagesRenderer';
import { BlogNextIcon } from 'assets/svgIcons/BlogNextIcon';
import { useMediaQuery } from 'react-responsive';
import { htmlParser } from 'utils/htmlUtils';

const BlogsPageSlider = ({ parallaxData }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const sliderData = parallaxData?.list;
  const [activeSlider, setActiveSlider] = useState(0);

  const slideRefs = sliderData?.map(() => React.createRef());

  const toggle = (index) => {
    setActiveSlider(index);
    calculateActiveLinePosition(index);
  };
  const [activeLineTop, setActiveLineTop] = useState(0);
  const [activeLineHeight, setActiveLineHeight] = useState(0);

  const calculateActiveLinePosition = (index) => {
    const element = slideRefs[index].current;
    const top = element.offsetTop;
    const height = element.offsetHeight;

    setActiveLineTop(top);
    setActiveLineHeight(height);
  };

  const isSliderImgData =
    sliderData && sliderData[activeSlider]?.banner?.data?.attributes?.url;

  return (
    <BlogsSliderWrapper>
      {!isTabletOrMobile ? (
        <BlogsSliderTitle className="Blogsheadline1WithCaps">
          {parallaxData?.title}
        </BlogsSliderTitle>
      ) : undefined}

      <SlidersContainer>
        <LeftSliderContainer>
          {!isTabletOrMobile ? (
            <ActiveLine top={activeLineTop} height={activeLineHeight} />
          ) : undefined}

          {sliderData?.map((list, index) => (
            <LeftSliders key={index} ref={slideRefs[index]}>
              <LeftSliderWrapper
                onClick={() => toggle(index)}
                className={activeSlider === index ? 'active' : 'slider'}
              >
                <LeftSliderTitle>{list.title}</LeftSliderTitle>
                <LeftSliderText className="Blogsbody">
                  {htmlParser(list.description)}
                </LeftSliderText>
                <a
                  className="Link"
                  href={list.btnUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="BlogsbodyWithCaps link-text">
                    {list.btnName}
                  </span>
                  <BlogNextIcon />
                </a>
              </LeftSliderWrapper>
            </LeftSliders>
          ))}
        </LeftSliderContainer>

        <RightSliderWithBanner>
          {isSliderImgData && (
            <ImagesRenderer
              url={sliderData[activeSlider]?.banner?.data?.attributes?.url}
              alt={
                sliderData[activeSlider]?.banner?.data?.attributes
                  ?.alternativeText
              }
            />
          )}
        </RightSliderWithBanner>
      </SlidersContainer>
    </BlogsSliderWrapper>
  );
};

export default BlogsPageSlider;
