import { Button } from 'library/button/Button';
import CustomSelect from 'library/Select/Select';
import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import FormCheckBoxState from 'ui/components/formCheckbox/formCheckbox-formState';

import FormInputBasic from 'ui/shared/formComponents/FormInputBasic';
import {
  TransactionsFilterWrapper,
  ExportInputGroup,
  TransactionListRowHalf,
  ExportButton,
  ExportInputGroupDate,
  ExportCheckbox,
} from 'ui/styled/admin.styled';
import formatDate, { DateFormats } from 'utils/date';

const TransactionsFilter = (props) => {
  const {
    getTransactionList,
    transactionFilters,
    setTransactionFilters,
    isOverdue,
    setIsOverdue,
    isFirstPurchase,
    setIsFirstPurchase,
    setPagination,
  } = props;
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const mappingFunction = (params) => {
    if (!params || typeof params !== `object`) return null;
    return params?.map((i) => i?.value);
  };

  const onSubmit = async (data) => {
    const params = {
      paymentId: data?.paymentId,
      idNumber: data?.productId || '',
      status: data?.status?.value || '',
      productType: data?.productType?.value || '',
      period: data?.period?.value || '',
      query: data?.query || '',
      comments: mappingFunction(data?.comments)?.toString() || '',
      fromDate: fromDate
        ? formatDate({
            inputDate: fromDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      toDate: toDate
        ? formatDate({
            inputDate: toDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
    };
    getTransactionList(params, true);
    const filtersData = {
      ...params,
      comments: data?.comments,
      fromDate,
      toDate,
    };
    setTransactionFilters(filtersData);
  };

  useEffect(() => {
    if (transactionFilters) {
      reset({
        paymentId: transactionFilters.paymentId,
        productId: transactionFilters.productId,
        query: transactionFilters.query,
        status: transactionFilters?.status && {
          label: transactionFilters?.status,
          value: transactionFilters?.status,
        },
        productType: transactionFilters?.productType && {
          label: transactionFilters?.productType,
          value: transactionFilters?.productType,
        },
        period: transactionFilters?.period && {
          label: transactionFilters?.period,
          value: transactionFilters?.period,
        },
        fromDate: transactionFilters.fromDate,
        toDate: transactionFilters.toDate,
        comments:
          typeof transactionFilters?.comments === 'string'
            ? [
                {
                  label: transactionFilters?.comments,
                  value: transactionFilters?.comments,
                },
              ]
            : transactionFilters?.comments,
      });
      setFromDate(transactionFilters.fromDate);
      setToDate(transactionFilters.toDate);
    }
  }, []);

  return (
    <TransactionsFilterWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ExportInputGroup>
          <TransactionListRowHalf>
            <Controller
              render={({ field }) => (
                <FormInputBasic
                  {...field}
                  type="text"
                  placeholder="Payment ID"
                  borderColor={errors.paymentId}
                />
              )}
              control={control}
              name="paymentId"
            />
          </TransactionListRowHalf>
          <TransactionListRowHalf>
            <Controller
              render={({ field }) => (
                <FormInputBasic
                  {...field}
                  type="text"
                  placeholder="Product ID"
                  borderColor={errors.productId}
                />
              )}
              control={control}
              name="productId"
            />
          </TransactionListRowHalf>
        </ExportInputGroup>
        <ExportInputGroup>
          <Controller
            render={({ field }) => (
              <FormInputBasic
                {...field}
                type="text"
                placeholder="Search by keyword"
                borderColor={errors.keyword}
              />
            )}
            control={control}
            name="query"
          />
        </ExportInputGroup>
        <ExportInputGroup>
          <Controller
            render={({ field }) => (
              <CustomSelect
                {...field}
                type="text"
                search={false}
                isClearable
                placeholder="Products"
                options={[
                  {
                    label: 'Solution',
                    value: 'SOLUTION',
                  },
                  {
                    label: 'Service',
                    value: 'SERVICE',
                  },
                  {
                    label: 'Hardware',
                    value: 'HARDWARE',
                  },
                ]}
              />
            )}
            defaultValue={transactionFilters?.productType}
            control={control}
            name="productType"
          />
        </ExportInputGroup>
        <ExportInputGroup>
          <TransactionListRowHalf>
            <Controller
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  type="text"
                  search={false}
                  isClearable
                  placeholder="Time Period"
                  options={[
                    {
                      label: 'Monthly',
                      value: 'MONTH1',
                    },
                    {
                      label: 'Annual',
                      value: 'MONTH12',
                    },
                    {
                      label: 'On Premise',
                      value: 'UNLIMITED',
                    },
                  ]}
                />
              )}
              control={control}
              defaultValue={transactionFilters?.period}
              name="period"
            />
          </TransactionListRowHalf>
          <TransactionListRowHalf>
            <Controller
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  type="text"
                  search={false}
                  isClearable
                  placeholder="Status"
                  options={[
                    {
                      label: 'Active',
                      value: 'ACTIVE',
                    },
                    {
                      label: 'Canceling',
                      value: 'CANCELING',
                    },
                    {
                      label: 'Canceled',
                      value: 'CANCELED',
                    },
                  ]}
                />
              )}
              control={control}
              defaultValue={transactionFilters?.status}
              name="status"
            />
          </TransactionListRowHalf>
        </ExportInputGroup>
        <ExportInputGroupDate>
          <TransactionListRowHalf>
            <ReactDatePicker
              dateFormat="d MMM yyyy"
              selected={fromDate}
              placeholderText="From Date"
              onChange={(date) => setFromDate(date)}
              isClearable
            />
          </TransactionListRowHalf>
          <TransactionListRowHalf>
            <ReactDatePicker
              dateFormat="d MMM yyyy"
              selected={toDate}
              placeholderText="To Date"
              onChange={(date) => setToDate(date)}
              isClearable
            />
          </TransactionListRowHalf>
        </ExportInputGroupDate>
        <ExportCheckbox>
          <Controller
            render={({ field }) => (
              <CustomSelect
                {...field}
                type="text"
                search={false}
                isMulti
                isClearable
                placeholder="Select Comments"
                options={[
                  {
                    label: 'REGISTRATION_1C_CONNECT',
                    value: 'REGISTRATION_1C_CONNECT',
                  },
                  {
                    label: 'REGISTRATION_1C_PORTAL',
                    value: 'REGISTRATION_1C_PORTAL',
                  },
                  {
                    label: 'DB_URL',
                    value: 'DB_URL',
                  },
                ]}
              />
            )}
            control={control}
            defaultValue={transactionFilters?.comments}
            name="comments"
          />
        </ExportCheckbox>
        <ExportInputGroup>
          <FormCheckBoxState
            checked={isOverdue}
            label="Filter Overdue transactions"
            labelPosition="right"
            labelOnchange
            value="yes"
            onChange={() => {
              setIsOverdue(!isOverdue);
            }}
          />
        </ExportInputGroup>
        <ExportInputGroup>
          <FormCheckBoxState
            checked={isFirstPurchase}
            label="Filter First Purchases"
            labelPosition="right"
            labelOnchange
            value="yes"
            onChange={() => {
              setIsFirstPurchase(!isFirstPurchase);
            }}
          />
        </ExportInputGroup>

        <ExportButton>
          <Button className="submit">Filter</Button>
        </ExportButton>
      </form>
    </TransactionsFilterWrapper>
  );
};

export default TransactionsFilter;
