import React from 'react';
import {
  CopiedIcon,
  CopiedText,
  NotificationStyled,
} from './Notification.styled';

import ReactDOM from 'react-dom';
import CheckIconGreen from 'assets/svgIcons/CheckIconGreen';

const Notification = ({ copiedText }) => {
  return ReactDOM.createPortal(
    <NotificationStyled>
      <CopiedIcon>
        <CheckIconGreen />
      </CopiedIcon>
      <CopiedText>
        <>{copiedText}</>
      </CopiedText>
    </NotificationStyled>,
    document.getElementById('root'),
  );
};

export default Notification;
