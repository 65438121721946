import { userTypes } from 'constants/global.constants';
import React from 'react';
import { MoreDetailsModalWrapper, DetailsRow } from 'ui/styled/admin.styled';

const MoreDetailsModal = ({ selectedTransaction }) => {
  return (
    <MoreDetailsModalWrapper>
      <DetailsRow>
        <div className="left">User Name</div>
        <div className="right">{selectedTransaction?.fullName || '-'}</div>
      </DetailsRow>
      <DetailsRow>
        <div className="left">ID/CIN:</div>
        <div className="right">{selectedTransaction?.idNumber || '-'}</div>
      </DetailsRow>
      <DetailsRow>
        <div className="left">Identificator</div>
        <div className="right">{selectedTransaction?.customerType || '-'}</div>
      </DetailsRow>
      <DetailsRow>
        <div className="left">E-Mail address</div>
        <div className="right">{selectedTransaction?.username || '-'}</div>
      </DetailsRow>
      <DetailsRow>
        <div className="left">Company phone</div>
        <div className="right">{selectedTransaction?.mobile || '-'}</div>
      </DetailsRow>
      {selectedTransaction?.customerType &&
      selectedTransaction?.customerType === userTypes.LEGAL ? (
        <DetailsRow>
          <div className="left">Representative Phone</div>
          <div className="right">{selectedTransaction?.repMobile || '-'}</div>
        </DetailsRow>
      ) : null}
      <DetailsRow>
        <div className="left">Field</div>
        <div className="right">
          {selectedTransaction?.fieldOfActivity || '-'}
        </div>
      </DetailsRow>
      <DetailsRow>
        <div className="left">Is First Purchase</div>
        <div className="right">
          {selectedTransaction?.firstPurchase ? <span> Yes </span> : '-'}
        </div>
      </DetailsRow>
      <DetailsRow>
        <div className="left">Comments </div>
        <div className="right">{selectedTransaction?.comment || '-'}</div>
      </DetailsRow>
      <DetailsRow>
        <div className="left">Status</div>
        <div className="right">{selectedTransaction?.status || '-'}</div>
      </DetailsRow>
    </MoreDetailsModalWrapper>
  );
};
export default MoreDetailsModal;
