import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { textRenderer } from 'utils/helpers';
import { getDataUrlTypes, imagesBaseUrl } from 'constants/global.constants';
import BlogParagraph from './BlogParagraph';
import {
  AccordionWrapper,
  LeftSectionBlogs,
  RightSectionBlogs,
  SeciontBlockBlogs,
} from 'ui/styled/BlogPageStyled';
import BlogsMiddleInfoContainer from './BlogMiddleInfoContainer';
import BlogPageAccordion from './BlogPageAccordion';
import SliderComponent from './BlogPageCarousel';
import { SliderWrapper } from 'ui/styled/SliderComponentStyled';
import ParagraphWithPicture from './ParagraphWithPicture';
import BlogBanner from './BlogBanner';
import { useParams } from 'react-router-dom';
import useFetchData from 'hooks/useFetchData';
import _ from 'underscore';
import ComponentWithLoaderAndError from 'ui/shared/componentWithLoaderAndError/ComponentWithLoaderAndError';
import { useMediaQuery } from 'react-responsive';
import CustomHead from 'ui/components/customHead/custom-head';
import BlogsPageSlider from './BlogsPageSlider';

const BlogPage = (props) => {
  const { slug } = useParams();
  const { data: blogsData, error: blogsDataError } = useFetchData({
    url: getDataUrlTypes.blogs(slug),
  });
  const BlogsDataProducts = blogsData?.data
    ? blogsData?.data[0]?.attributes
    : null;
  const BlogsDataParagraphs = BlogsDataProducts?.list_blocks;
  const WhyChooseSection = BlogsDataProducts?.whyChoose;
  const ParallaxData = BlogsDataProducts?.slider;
  const BlogFaqDataTitle = BlogsDataProducts?.accordion;
  const BlogFaqAccordionData = BlogsDataProducts?.accordion?.block;
  const BlogReviewData = BlogsDataProducts?.review;
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const BlogBannerData = BlogsDataProducts?.banner;
  const { setIsOpen, setDemoModal } = props;

  if (!blogsData) return <div></div>;

  return (
    <ComponentWithLoaderAndError data={blogsData} error={blogsDataError}>
      <CustomHead seo={blogsData?.data[0]?.attributes?.seo} />
      <>
        {BlogsDataParagraphs?.map((paragraph, index) => {
          const isEven = index % 2 === 0;
          const isFirstBlog = index === 0;
          const flexDirection = isTabletOrMobile
            ? isEven
              ? 'column-reverse'
              : 'column'
            : !isTabletOrMobile && isEven
            ? ''
            : '';

          return (
            <SeciontBlockBlogs
              key={paragraph.id}
              style={{ flexDirection }}
              isEven={isEven}
            >
              {isEven ? (
                <>
                  <LeftSectionBlogs leftToRight={isEven}>
                    <BlogParagraph
                      buttonsFromUrl={slug}
                      setIsOpen={setIsOpen}
                      setDemoModal={setDemoModal}
                      firstBlog={isFirstBlog}
                      additionalTitle={paragraph?.badge}
                      planAdditionalTitle={paragraph?.badge}
                      title={ReactHtmlParser(textRenderer(paragraph?.title))}
                      description={textRenderer(paragraph?.description)}
                      paragraphButton={textRenderer(paragraph?.btnName)}
                      paragraphButtonUrl={textRenderer(paragraph?.btnUrl)}
                    />
                  </LeftSectionBlogs>

                  <RightSectionBlogs
                    className={isEven ? 'top-bg-img' : 'blog-bg-img'}
                    style={{
                      backgroundImage:
                        isTabletOrMobile &&
                        paragraph?.responsiveBanner?.data?.attributes?.url
                          ? `url(${imagesBaseUrl}${paragraph?.responsiveBanner.data.attributes.url})`
                          : `url(${imagesBaseUrl}${paragraph?.banner?.data?.attributes?.url})`,
                    }}
                  />
                </>
              ) : (
                <>
                  <RightSectionBlogs
                    className={isEven ? 'top-bg-img' : 'blog-bg-img'}
                    rightToLeft={!isEven}
                    style={{
                      backgroundImage:
                        isTabletOrMobile &&
                        paragraph?.responsiveBanner?.data?.attributes?.url
                          ? `url(${imagesBaseUrl}${paragraph?.responsiveBanner?.data?.attributes?.url})`
                          : `url(${imagesBaseUrl}${paragraph?.banner?.data?.attributes?.url})`,
                    }}
                  />
                  <LeftSectionBlogs leftToRight={isEven} rightToLeft={!isEven}>
                    <BlogParagraph
                      buttonsFromUrl={slug}
                      setIsOpen={setIsOpen}
                      setDemoModal={setDemoModal}
                      firstBlog={isFirstBlog}
                      additionalTitle={paragraph?.badge}
                      planAdditionalTitle={paragraph?.badge}
                      title={ReactHtmlParser(textRenderer(paragraph?.title))}
                      description={textRenderer(paragraph?.description)}
                      paragraphButton={textRenderer(paragraph?.btnName)}
                      paragraphButtonUrl={textRenderer(paragraph?.btnUrl)}
                    />
                  </LeftSectionBlogs>
                </>
              )}
            </SeciontBlockBlogs>
          );
        })}
      </>
      <BlogsMiddleInfoContainer data={WhyChooseSection} />
      <BlogsPageSlider parallaxData={ParallaxData} />
      <AccordionWrapper>
        <BlogPageAccordion
          BlogFaqAccordionData={BlogFaqAccordionData}
          BlogFaqDataTitle={BlogFaqDataTitle}
        />
      </AccordionWrapper>
      {BlogBannerData ? (
        <BlogBanner BlogBannerData={BlogBannerData} />
      ) : undefined}

      {BlogReviewData?.map((data) => (
        <SliderWrapper>
          <div className="Blogsheadline1WithCaps slider-title">
            {data?.title}
          </div>
          {isTabletOrMobile ? (
            <ParagraphWithPicture personData={data?.person} />
          ) : undefined}
          {BlogReviewData?.length < 1 ? undefined : (
            <SliderComponent items={BlogReviewData} />
          )}
        </SliderWrapper>
      ))}
      {!isTabletOrMobile
        ? BlogReviewData?.map((i) => (
            <ParagraphWithPicture personData={i?.person} />
          ))
        : undefined}
    </ComponentWithLoaderAndError>
  );
};

export default BlogPage;
