import styled from 'styled-components';
import sideBg from 'assets/img/sideBg.svg';

export const ChangeProperties = styled.div`
  display: flex;
  justify-content: center;
  width: 444px;
  margin: auto;
  padding: 70px 0 123px 0;
  @media (max-width: 900px) {
    height: 100%;
    width: 100%;
    padding: 28px 28px 20px 28px;
  }
  .react-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(58, 56, 83, 0.2);
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 50px;
      @media (max-width: 900px) {
        padding: 0;
        margin: 0 25px;
        margin-bottom: 34px;
      }
    }
    .react-tabs__tab {
      border: none;
      color: #8a87a5;
      padding: 22px 0px;
      border-radius: 0;
      @media (max-width: 900px) {
        padding: 22px 0px;
      }
    }
    .react-tabs__tab:after {
      background: transparent;
    }
    .react-tabs__tab:focus {
      color: #3a3853;
      border-radius: 0;
      box-shadow: none;
      border-color: #ffdc04;
    }
    .react-tabs__tab--selected {
      border-bottom: 3px solid #ffdc04;
      color: #3a3853;
      border-radius: 0;
      box-shadow: none;
      border-color: #ffdc04;
      outline: none;
      padding-bottom: 20px;
      font-weight: 500;
      background: transparent;
      @media (max-width: 900px) {
        height: 100%;
      }
    }
    .react-tabs__tab-panel--selected {
      width: 100%;
    }
  }
`;

export const UserProfileWrapper = styled.div`
  position: relative;
  height: 100%;
`;
export const UserProfileBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: url(${sideBg});
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
  background-position: bottom right;
  @media (max-width: 900px) {
    display: none;
  }
`;
