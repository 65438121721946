import React from 'react';
import {
  CartTableHeader,
  CartHeaderName,
  CartHeaderQyt,
  CartHeaderPrice,
  CartHeaderTotal,
} from 'ui/styled/cartPage.styled';
import { getTextFromDataByKey } from 'utils/helpers';

const CartPageHeadComponent = ({ data }) => {
  return (
    <CartTableHeader className="bodyS">
      <CartHeaderName>
        {getTextFromDataByKey('productName', data)}
      </CartHeaderName>
      <CartHeaderQyt> {getTextFromDataByKey('quantity', data)}</CartHeaderQyt>
      <CartHeaderPrice> {getTextFromDataByKey('ptice', data)}</CartHeaderPrice>
      <CartHeaderTotal>
        {getTextFromDataByKey('totalPrice', data)}
      </CartHeaderTotal>
    </CartTableHeader>
  );
};

export default CartPageHeadComponent;
