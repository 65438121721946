import React, { useContext, useEffect, useState } from 'react';
import {
  LeftSection,
  RightSection,
  SeciontBlock,
} from 'ui/components/common/layout';
import PlansList from 'ui/components/plansSection/PlansList';
import YouTube from 'react-youtube';
import video from 'assets/img/video.svg';
import { Button } from 'library/button/Button';
import PlanTitle from 'ui/components/plansSection/PlanTitle';

import { TOKEN_NAME } from 'constants/global.constants';
import TopParagraphWithInput from 'ui/components/plansSection/TopParagraphWithInput';
import OurPartners from 'ui/components/outPartners/OurPartners';
import {
  PlanItemButtons,
  PlansSectionWrapper,
} from 'ui/styled/PlansSection.styled';
import { getUrlParams, textRenderer, youtube_parser } from 'utils/helpers';
import { ImagesRenderer } from 'utils/imagesRenderer';

import { imagesBaseUrl } from 'constants/global.constants';
import ComponentWithLoaderAndError from 'ui/shared/componentWithLoaderAndError/ComponentWithLoaderAndError';
import Modal from 'library/modal/Modal';
import AddToCartComponent from './AddToCartComponent';
import DemoModalComponent from './Demo/DemoModal';
import cacheManager from 'utils/cacheManager';
import SolutionSliderComp from './SolutionSliderComp';
import {
  PlayIconWrapper,
  PlayIconWrapperMobile,
  SliderImgWrapper,
  VideoBackDropClose,
  VideoBackDropWrapper,
} from 'ui/styled/home.styled';
import { PlayIcon } from 'assets/svgIcons/PlayIcon';
import closeIcon from 'assets/img/closeVideoIcon.svg';
import { StrapiDataContext } from 'Main';
import { useMediaQuery } from 'react-responsive';
import { CartIcon } from 'assets/svgIcons/CartIcon';
import { DownloadIcon } from 'assets/svgIcons/Download';
import CustomHead from 'ui/components/customHead/custom-head';
import { authTypes } from 'ui/components/Auth/Auth';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/reducers/user/user.slice';

const HomePage = (props) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const { isCartOpen, setIsCartOpen, setIsOpen, cartData, getCartData } = props;
  const { homeData, homeProducts } = useContext(StrapiDataContext);
  const [selectedProductFromBlogs, setSelectedProductFromBlogs] =
    useState(null);
  const [authType, setAuthType] = useState(authTypes.login);
  const isUserLoggedIn = useSelector(userSelectors.selectIsUserLoggedIn);

  const [addToCartModal, setAddToCartModal] = useState(false);
  const [demoModal, setDemoModal] = useState(false);
  const [videoBackdrop, setVideoBackdrop] = useState(false);
  const [selectedItemVideo, setSelectedItemVideo] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const tokenCache = cacheManager(TOKEN_NAME);
  const token = tokenCache.get();
  const homePageData = homeData.data ? homeData?.data?.data?.attributes : null;
  const homePageProducts = homeProducts.data ? homeProducts?.data?.data : null;
  const homePageProductsToShow = homePageProducts
    ? homePageProducts.filter(
        (item) =>
          item.attributes.offerPage === null ||
          item.attributes.offerPage === 'standard' ||
          item.attributes.offerPage === 'all',
      )
    : [];

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  useEffect(() => {
    const { product, demo, register } = getUrlParams();
    const productKeyFromBlogs = homePageProducts?.find(
      (i) => i.attributes.slug === product,
    );
    if (product) {
      setSelectedProductFromBlogs(productKeyFromBlogs);
      setAddToCartModal(true);
    }
    if (demo) {
      setAddToCartModal(false);
      setDemoModal(true);
    }
    if (register && !isUserLoggedIn) {
      setAddToCartModal(false);
      setDemoModal(false);

      setAuthType(authTypes.register);
      props.setIsOpen(true);
      localStorage.setItem('authType', authTypes.register);
    }
  }, [selectedProductFromBlogs, isUserLoggedIn]);

  if (!homePageData) return <div></div>;

  return (
    <>
      <CustomHead seo={homePageData.seo} />
      <Modal
        width="60%"
        height="60%"
        isOpen={videoBackdrop}
        className="youtube-modal"
        canCloseOnOutsideClick={false}
        closeModal={() => {
          setVideoBackdrop(false);
        }}
      >
        <VideoBackDropWrapper>
          {selectedItemVideo ? (
            <YouTube videoId={youtube_parser(selectedItemVideo)} opts={opts} />
          ) : null}
          <VideoBackDropClose onClick={() => setVideoBackdrop(false)}>
            <img src={closeIcon} alt="close" />
          </VideoBackDropClose>
        </VideoBackDropWrapper>
      </Modal>

      <ComponentWithLoaderAndError
        data={homePageData || homePageProducts}
        error={homePageData?.error || homePageProducts.error}
      >
        <Modal
          closeIcon
          width="640px"
          height="auto"
          className="homeAddcartModal"
          isOpen={addToCartModal}
          canCloseOnOutsideClick={false}
          closeModal={() => {
            setAddToCartModal(false);
          }}
        >
          <AddToCartComponent
            selectedProductFromBlogs={selectedProductFromBlogs}
            selectedProduct={selectedProduct}
            closeModal={() => setAddToCartModal(false)}
            getCartData={getCartData}
            cartData={cartData}
            isCartOpen={isCartOpen}
            setIsCartOpen={setIsCartOpen}
            setIsOpen={setIsOpen}
            token={token}
          />
        </Modal>
        <Modal
          closeIcon
          width="640px"
          height="auto"
          isOpen={demoModal}
          canCloseOnOutsideClick={false}
          className="demoAddModal"
          closeModal={() => {
            setDemoModal(false);
          }}
        >
          <DemoModalComponent
            closeModal={() => {
              setDemoModal(false);
            }}
            selectedProduct={selectedProduct}
            selectedProductFromBlogs={selectedProductFromBlogs}
            homePageData={homePageData}
            isTabletOrMobile={isTabletOrMobile}
          />
        </Modal>

        {homePageData ? (
          <SeciontBlock
            style={{
              flexDirection: isTabletOrMobile ? 'column-reverse' : undefined,
            }}
          >
            <LeftSection leftToRight>
              <TopParagraphWithInput
                title={textRenderer(homePageData?.header?.title)}
                description={textRenderer(homePageData?.header?.description)}
                registerButton={textRenderer(
                  homePageData?.header?.registration,
                )}
                setIsOpen={setIsOpen}
              />
            </LeftSection>

            <RightSection
              id="top-bg-img"
              className="top-bg-img"
              style={{
                backgroundImage: `url(
                ${imagesBaseUrl}${homePageData?.header?.banner?.data?.attributes?.url}
              )`,
              }}
            >
              <SliderImgWrapper>
                {/* {homePageData?.header?.videoEnable ? ( */}
                <>
                  {isTabletOrMobile ? (
                    <PlayIconWrapperMobile
                      onClick={() => {
                        setVideoBackdrop(true);
                        setSelectedItemVideo(
                          homePageData?.header?.youtubeVideo,
                        );
                      }}
                    >
                      <PlayIcon />
                    </PlayIconWrapperMobile>
                  ) : (
                    <PlayIconWrapper
                      onClick={() => {
                        setVideoBackdrop(true);
                        setSelectedItemVideo(
                          homePageData?.header?.youtubeVideo,
                        );
                      }}
                    >
                      <PlayIcon />
                    </PlayIconWrapper>
                  )}
                </>
                {/* ) : null} */}
              </SliderImgWrapper>
            </RightSection>
          </SeciontBlock>
        ) : null}

        <SolutionSliderComp data={homePageData?.banners} />

        {homePageProductsToShow ? (
          <>
            {homePageProductsToShow.map((product, index) => {
              const showDemo = product?.attributes?.prices?.find(
                (item) => item.isDemo,
              );
              if (index % 2 !== 0) {
                return (
                  <SeciontBlock
                    key={product.id + index}
                    style={{
                      flexDirection: isTabletOrMobile
                        ? 'column-reverse'
                        : undefined,
                    }}
                  >
                    <LeftSection leftToRight>
                      <PlansSectionWrapper left>
                        <PlanTitle
                          visualPrice={product?.attributes?.visualPrice}
                          planTitle={product?.attributes?.name}
                          planText={product?.attributes?.description}
                        />

                        <PlansList planItems={product?.attributes?.bullet} />
                        <PlanItemButtons>
                          <Button
                            className="cart-btn"
                            background="#FFDC04"
                            color="#242333"
                            padding="7px 38px"
                            fontWeight="500"
                            onClick={() => {
                              setSelectedProduct(product);
                              setAddToCartModal(true);
                            }}
                          >
                            <CartIcon style={{ marginRight: '12px' }} />
                            {product?.attributes?.buyBtn}
                          </Button>
                          {showDemo && showDemo.isDemo ? (
                            <Button
                              width="150px"
                              background="rgba(255, 220, 4, 0.3)"
                              color="#242333"
                              fontWeight="500"
                              className="demo-btn"
                              onClick={() => {
                                setSelectedProduct(product);
                                setDemoModal(true);
                              }}
                            >
                              <DownloadIcon
                                style={{
                                  marginRight: isTabletOrMobile ? '0' : '7px',
                                }}
                              />
                              {isTabletOrMobile
                                ? null
                                : product?.attributes?.demoBtn}
                            </Button>
                          ) : null}
                          <img
                            className="videoBtn"
                            src={video}
                            alt="videoBtn"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setVideoBackdrop(true);
                              setSelectedItemVideo(product.attributes.video);
                            }}
                          />
                        </PlanItemButtons>
                      </PlansSectionWrapper>
                    </LeftSection>

                    <RightSection>
                      <ImagesRenderer
                        url={
                          !isTabletOrMobile
                            ? product?.attributes?.img?.data?.attributes?.url
                            : product?.attributes?.responsiveImg?.data
                                ?.attributes?.url
                        }
                        alt={
                          product?.attributes?.img?.data?.attributes
                            ?.alternativeText
                        }
                      />
                    </RightSection>
                  </SeciontBlock>
                );
              }
              return (
                <SeciontBlock
                  key={product.id}
                  style={{
                    flexDirection: isTabletOrMobile ? 'column' : undefined,
                  }}
                >
                  <LeftSection>
                    <ImagesRenderer
                      url={
                        !isTabletOrMobile
                          ? product?.attributes?.img?.data?.attributes?.url
                          : product?.attributes?.responsiveImg?.data?.attributes
                              ?.url
                      }
                      alt={
                        product.attributes?.img?.data?.attributes
                          ?.alternativeText
                      }
                    />
                  </LeftSection>

                  <RightSection rightToLeft>
                    <PlansSectionWrapper>
                      <PlanTitle
                        visualPrice={product?.attributes?.visualPrice}
                        planTitle={product?.attributes?.name}
                        planText={product?.attributes?.description}
                      />

                      <PlansList planItems={product?.attributes?.bullet} />
                      <PlanItemButtons>
                        <Button
                          background="#FFDC04"
                          color="#242333"
                          padding="7px 40px"
                          fontWeight="500"
                          className="cart-btn"
                          onClick={() => {
                            setSelectedProduct(product);
                            setAddToCartModal(true);
                          }}
                        >
                          <CartIcon style={{ marginRight: '12px' }} />
                          {product?.attributes?.buyBtn}
                        </Button>
                        {showDemo && showDemo.isDemo ? (
                          <Button
                            width="150px"
                            background="rgba(255, 220, 4, 0.3)"
                            color="#242333"
                            fontWeight="500"
                            className="demo-btn"
                            onClick={() => {
                              setDemoModal(true);
                              setSelectedProduct(product);
                            }}
                          >
                            <DownloadIcon
                              style={{
                                marginRight: isTabletOrMobile ? '0' : '7px',
                              }}
                            />
                            {isTabletOrMobile
                              ? null
                              : product?.attributes?.demoBtn}
                          </Button>
                        ) : null}

                        <img
                          className="videoBtn"
                          src={video}
                          alt="videoBtn"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setVideoBackdrop(true);
                            setSelectedItemVideo(product?.attributes?.video);
                          }}
                        />
                      </PlanItemButtons>
                    </PlansSectionWrapper>
                  </RightSection>
                </SeciontBlock>
              );
            })}
          </>
        ) : null}

        {homePageData && (
          <OurPartners partnersData={homePageData?.ourPartners} />
        )}
      </ComponentWithLoaderAndError>
    </>
  );
};
export default HomePage;
