import { Button } from 'library/button/Button';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/reducers/user/user.slice';
import FormInputBasic from 'ui/shared/formComponents/FormInputBasic';
import {
  TrainingsModalWrapper,
  TrainingsModalTitle,
  TrainingModalForm,
  TrainingModalFormGroup,
  TrainingsStepContainer,
  TrainingsButton,
} from 'ui/styled/cartPage.styled';
import { getTextFromDataByProductKey } from 'utils/helpers';
import { isEmail } from 'utils/validator';

const TrainingsModal = (props) => {
  const {
    data,
    translatesData,
    trainingsEmailsObj,
    setTrainingsEmailsObj,
    closeModal,
    setPayConfirmModal,
    translatesCard,
  } = props;

  const methods = useForm({
    reValidateMode: 'onSubmit',
  });
  const {
    control,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors, isDirty },
    reset,
    watch,
    setValue,
  } = methods;

  const translateKeys = translatesData?.data.attributes.enumTranslate;
  const translateTrainingsKeys = translatesCard?.traningModal;

  const activeUser = useSelector(userSelectors.userInfo);

  const [trainingModalSteps, setTrainingModalSteps] = useState(0);

  const trainingsData = data[trainingModalSteps];

  const onSubmit = (values) => {
    setTrainingModalSteps((prev) =>
      data.length - 1 === trainingModalSteps ? prev : prev + 1,
    );
    setTrainingsEmailsObj((prev) => {
      return {
        ...prev,
        [trainingsData.product.productKey]: Object.values(values),
      };
    });
    reset();
    if (data.length - 1 === trainingModalSteps) {
      closeModal();
      setPayConfirmModal(true);
    }
  };

  const values = getValues();

  useEffect(() => {
    if (!values[`email${data[trainingModalSteps].product.productKey}0`]) {
      reset({
        [`email${data[trainingModalSteps].product.productKey}0`]:
          activeUser.username,
      });
    }
  }, [values]);

  const renderInputs = () => {
    const formElements = [];
    for (let i = 0; i < trainingsData.quantity; i++) formElements.push(i);
    return formElements.map((item, index) => {
      return (
        <TrainingModalFormGroup
          key={data[trainingModalSteps].product.productKey + index}
        >
          <Controller
            render={({ field }) => (
              <FormInputBasic
                {...field}
                type="text"
                search={false}
                placeholder={translateTrainingsKeys?.emailLabel}
                borderColor={
                  errors[
                    `email${
                      data[trainingModalSteps].product.productKey + index
                    }`
                  ]
                }
              />
            )}
            rules={{
              required: 'Is Required',
              validate: isEmail,
            }}
            control={control}
            name={`email${data[trainingModalSteps].product.productKey + index}`}
          />
        </TrainingModalFormGroup>
      );
    });
  };

  const backButtonHanlder = () => {
    setTrainingModalSteps((prev) => prev - 1);
    reset({});
    for (let i = 0; i < trainingsData.quantity; i++) {
      setValue(
        `email${data[trainingModalSteps - 1].product.productKey + i}`,
        trainingsEmailsObj[data[trainingModalSteps - 1].product.productKey][i],
      );
    }
  };

  const stringToShow = getTextFromDataByProductKey(
    trainingsData?.product?.productKey,
    translateKeys,
  ).split(' ');

  return (
    <TrainingsModalWrapper>
      <TrainingsStepContainer>
        <TrainingsModalTitle className="headline4">
          <div className="title headline4">
            {stringToShow.map((item, index) =>
              index === stringToShow.length - 1 ? (
                <span style={{ color: '#3A3853' }}>{item}</span>
              ) : (
                <span style={{ color: '#F5C600' }}>{item}</span>
              ),
            )}
          </div>
          <div className="subtitle bodyL">
            {translateTrainingsKeys.leftTitle}{' '}
            <span> {translateTrainingsKeys.rightTitle} </span>
          </div>
        </TrainingsModalTitle>
        <TrainingModalForm>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Scrollbars
                autoHeight
                autoHeightMin={50}
                autoHeightMax={420}
                renderView={(props) => <div {...props} className="view" />}
                renderThumbHorizontal={(props) => (
                  <div {...props} className="renderThumbHorizontal" />
                )}
                renderTrackHorizontal={(props) => (
                  <div {...props} className="renderTrackHorizontal" />
                )}
              >
                {renderInputs()}
              </Scrollbars>
              <TrainingsButton>
                <Button type="submit" className="submit">
                  {translateTrainingsKeys?.nextButton}
                </Button>
                {trainingModalSteps === 0 ? null : (
                  <Button
                    type="button"
                    className="back"
                    onClick={backButtonHanlder}
                  >
                    {translateTrainingsKeys?.backButton}
                  </Button>
                )}
              </TrainingsButton>
            </form>
          </FormProvider>
        </TrainingModalForm>
      </TrainingsStepContainer>
    </TrainingsModalWrapper>
  );
};

export default TrainingsModal;
