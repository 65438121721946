import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  BIllingBackground,
  CurrentProductsTitle,
  CurrentProductsWrapper,
  CurrentTabsAndInputWrapper,
  MobileUrlWrapper,
  TabListHeader,
} from 'ui/styled/currentProducts.styled';
import OnPremiseTable from './OnPremise';
import { Input } from 'library/input/Input';
import BillingTable from './BillingTable';
import { CopyIcon } from 'assets/svgIcons/CopyIcon';
import { StrapiDataContext } from 'Main';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelectors } from 'store/reducers/user/user.slice';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { debounce } from 'lodash';
import Notification from 'library/notification/Notification';
import { useMediaQuery } from 'react-responsive';
import CustomHead from 'ui/components/customHead/custom-head';

const CurrentProducts = () => {
  const { settingsData, currentProductsData: data } =
    useContext(StrapiDataContext);

  const activeBillingItemData = JSON.parse(
    localStorage.getItem('activeBillingItem'),
  );
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  // paginations
  const [onPermisepagination, setOnPermisepagination] = useState({
    offset: 0,
    limit: 10,
    count: 0,
    call: false,
  });
  const [billingpagination, setBillingpagination] = useState({
    offset: 0,
    limit: 10,
    count: 0,
    call: false,
  });

  let [searchParams, setSearchParams] = useSearchParams();
  const [productIdFromSearch, setProductIdFromSearch] = useState(' ');
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const productIdFromSearchParam = id !== '0' && id !== 'billing';
  const [defaultTabIndex, setDefaultTabIndex] = useState(0);

  const [searchValue, setSearchValue] = useState('');
  const [searchValueLabel, setSearchValueLabel] = useState('');
  const [billingData, setBillingData] = useState(null);
  const [onPremiseData, setOnPremiseData] = useState(null);

  const onPremiseTitle = data?.data?.data.attributes.onPremise.title;
  const billingTitle = data?.data?.data.attributes.billing.title;
  const currentProductsData = data?.data?.data?.attributes;

  const tabs = {
    Billing: billingTitle,
    onPremise: onPremiseTitle,
  };

  const [activeTab, setActiveTab] = useState();
  const activeUser = useSelector(userSelectors.userInfo);
  const activeUserUrl = activeUser?.notes;

  useEffect(() => {
    if (productIdFromSearchParam) {
      setDefaultTabIndex(1);
    }
    if (activeBillingItemData) {
      setDefaultTabIndex(1);
      setProductIdFromSearch(activeBillingItemData.id);
      setBillingpagination({
        ...billingpagination,
        offset: Number(activeBillingItemData.offset),
        limit: Number(activeBillingItemData.limit),
      });
    }
  }, [productIdFromSearchParam]);

  const debounceHandler = useCallback(
    debounce((input) => setSearchValue(input), 1000),
    [],
  );
  const handleText = (input) => {
    if (activeTab === tabs.onPremise) {
      setOnPermisepagination((prev) => {
        return {
          ...prev,
          call: false,
          offset: 0,
        };
      });
    }
    if (activeTab === tabs.Billing) {
      setBillingpagination((prev) => {
        return {
          ...prev,
          call: false,
          offset: 0,
        };
      });
    }
    debounceHandler(input);
  };

  const baseUrlReplaceData =
    settingsData?.data?.data?.attributes?.baseUrlReplace;

  const [isCopiedDiv, setIsCopiedDiv] = useState(false);

  const handleCopyClick = () => {
    setIsCopiedDiv(true);
    setTimeout(() => {
      setIsCopiedDiv(false);
    }, 2500);
  };

  const userUrl =
    activeUserUrl &&
    activeUserUrl.replace(baseUrlReplaceData?.key, baseUrlReplaceData?.value);

  const IsSearch =
    (defaultTabIndex === 0 && onPremiseData && onPremiseData.length > 0) ||
    (defaultTabIndex === 1 && billingData && billingData.length > 0);

  const isOfferInUrlParams = location?.pathname?.includes('isOffer');
  const additionalParam = isOfferInUrlParams ? 'isOffer' : 'default';

  return (
    <>
      <CustomHead seo={data?.data?.data.attributes?.seo} />
      {isCopiedDiv ? (
        <Notification copiedText="URL Coppied Successfully" />
      ) : null}
      <>
        {isTabletOrMobile ? (
          activeUserUrl != null ? (
            <MobileUrlWrapper>
              <BIllingBackground className="bodyL">
                <span>Your URL: </span>
                <a className="url" href={activeUserUrl} target="_blank">
                  {userUrl}
                </a>
                <CopyToClipboard text={activeUserUrl} onCopy={handleCopyClick}>
                  <CopyIcon paddin />
                </CopyToClipboard>
              </BIllingBackground>
            </MobileUrlWrapper>
          ) : undefined
        ) : (
          <CurrentProductsTitle>
            {data?.data?.data?.attributes?.title}
            {activeUserUrl != null ? (
              <BIllingBackground className="bodyL">
                <span>Your URL: </span>
                <a className="url" href={activeUserUrl} target="_blank">
                  {userUrl}
                </a>
                <CopyToClipboard text={activeUserUrl} onCopy={handleCopyClick}>
                  <CopyIcon paddin />
                </CopyToClipboard>
              </BIllingBackground>
            ) : undefined}
          </CurrentProductsTitle>
        )}
      </>
      <CurrentProductsWrapper
        className={
          (onPremiseData &&
            onPremiseData.length === 0 &&
            defaultTabIndex === 0) ||
          (billingData && billingData.length === 0 && defaultTabIndex === 1)
            ? 'emptyTable'
            : undefined
        }
      >
        <Tabs
          default
          selectedIndex={defaultTabIndex}
          onSelect={(index) => setDefaultTabIndex(index)}
        >
          <TabListHeader>
            <TabList>
              <Tab
                onClick={() => {
                  setActiveTab(tabs.onPremise);
                  setSearchValue('');
                  setSearchValueLabel('');
                  navigate(
                    `/currentProducts/${additionalParam}/id/${'billing'}`,
                  );
                }}
              >
                {onPremiseTitle}
              </Tab>
              <Tab
                onClick={() => {
                  setActiveTab(tabs.Billing);
                  setSearchValue('');
                  setSearchValueLabel('');
                }}
              >
                {billingTitle}
              </Tab>
            </TabList>
            {IsSearch ? (
              <CurrentTabsAndInputWrapper>
                <Input
                  className="bodyM"
                  search
                  color="#DDDBED"
                  type="text"
                  placeholder={
                    data?.data?.data?.attributes?.onPremiseSearch?.placeholder
                  }
                  height="54px"
                  border="1px solid #DDDBED"
                  value={searchValueLabel}
                  onChange={(e) => {
                    setSearchValueLabel(e.target.value);
                    handleText(e.target.value);
                  }}
                />
              </CurrentTabsAndInputWrapper>
            ) : undefined}
          </TabListHeader>
          <TabPanel>
            <OnPremiseTable
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onPremiseData={onPremiseData}
              setOnPremiseData={setOnPremiseData}
              settingsData={settingsData}
              currentProductsData={currentProductsData?.onPremise}
              notFoundData={
                data?.data?.data?.attributes?.onPremiseSearch?.notFound
              }
              searchValue={searchValue}
              pagination={onPermisepagination}
              setPagination={setOnPermisepagination}
              isTabletOrMobile={isTabletOrMobile}
            />
          </TabPanel>
          <TabPanel>
            <BillingTable
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              billingData={billingData}
              setBillingData={setBillingData}
              settingsData={settingsData}
              defaultTabIndex={defaultTabIndex}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              currentProductsData={currentProductsData}
              notFoundData={
                data?.data?.data?.attributes?.billingSearch?.notFound
              }
              strapiData={data?.data?.data?.attributes}
              setDefaultTabIndex={setDefaultTabIndex}
              productIdFromSearch={productIdFromSearch}
              searchValue={searchValue}
              pagination={billingpagination}
              setPagination={setBillingpagination}
              isTabletOrMobile={isTabletOrMobile}
              emptyBilling={!billingData}
            />
          </TabPanel>
        </Tabs>
      </CurrentProductsWrapper>
    </>
  );
};

export default CurrentProducts;
