// @ts-nocheck
import styled from 'styled-components';
import React from 'react';
import reactBg from 'assets/img/reactBg.svg';

export const MainContainer = (props) => {
  const classes = 'maincontainer ' + props.className;
  return (
    <MainContainerStyled className={classes} id={props.id}>
      {props.children}
    </MainContainerStyled>
  );
};
export const MainContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  @media (max-width: 900px) {
    justify-content: flex-start;
    /* display: unset; */
    overflow-x: scroll;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const LeftSection = styled.div`
  height: 100%;
  flex-basis: 48%;
  padding: ${(props) => (props.leftToRight ? '54px 5% 0 14%' : '0 0 0 0')};
  img {
    max-width: 100%;
  }
  @media (max-width: 1540px) {
    padding: ${(props) => (props.leftToRight ? '54px 5% 0 8%' : '0 0 0 0')};
    flex-basis: 100%;
  }
  @media (max-width: 1290px) {
    padding: ${(props) => (props.leftToRight ? '54px 5% 0 5%' : '0 0 0 0')};
    flex-basis: 100%;
  }
  @media (max-width: 900px) {
    padding: ${(props) => (props.leftToRight ? '28px 22px 0 22px' : '0 0 0 0')};
    flex-basis: 100%;
  }
`;
export const RightSection = styled.div`
  height: 100%;
  flex-basis: 50%;
  padding: ${(props) => (props.rightToLeft ? '54px 14% 0 5%' : '0 0 0 0')};
  img {
    max-width: 100%;
  }
  @media (max-width: 1540px) {
    min-width: 50%;
  }
  @media (max-width: 1290px) {
    flex-basis: 100%;
  }
  @media (max-width: 900px) {
    padding: ${(props) => (props.rightToLeft ? '25px 22px 0 5%' : '0 0 0 0')};
  }
`;

export const LeftSectionServices = styled.div`
  height: 100%;
  flex-basis: 40%;
  padding: ${({ isOfferPage }) => (isOfferPage ? '0 0 0 15%' : '24px 0 0 15%')};
  background: ${({ isOfferPage }) =>
    isOfferPage ? 'none' : `url(${reactBg})`};
  background-repeat: no-repeat;
  height: 361px;

  img {
    max-width: 100%;
  }
  @media (max-width: 1685px) {
    padding: 24px 0 0 8%;
  }
  @media (max-width: 1540px) {
    background-position: -129px;
  }
  @media (max-width: 900px) {
    flex-basis: 100%;
    width: 100%;
    min-height: 226px;
    max-height: 226px;
    margin-bottom: 69px;
    background: url(${reactBg});
    border-bottom-right-radius: ${(props) =>
      props.isEven ? undefined : '132px'};
    border-bottom-left-radius: ${(props) =>
      props.isEven ? '132px' : undefined};
    padding: ${(props) => (props.isEven ? undefined : '19px 28px 0px 28px')};
  }
`;
export const RightSectionServices = styled.div`
  height: 100%;
  img {
    max-width: 100%;
  }
  flex-basis: 56%;
  padding: ${(hardware) => (hardware ? '0px 9% 0px 4%' : undefined)};
  @media (max-width: 900px) {
    flex-basis: 100%;
    width: 100%;
    padding: ${({ isOfferPage }) =>
      isOfferPage ? '0 28px !important' : '0px 5% 0px 9%'};
  }
`;

export const LogoBottomStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SeciontBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '80px')};
  .top-bg-img {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 542px;
    position: relative;
    transition: background-image 0.2s ease-in-out;
  }
  @media (max-width: 1540px) {
    .top-bg-img {
      min-width: 50%;
    }
  }
  @media (max-width: 1290px) {
    .top-bg-img {
      min-width: 50%;
      flex-basis: 100%;
    }
  }
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    margin-bottom: 40px;
    .top-bg-img {
      min-height: 229px;
      max-height: 229px;
      border-bottom-right-radius: 140px;
    }
  }
`;
