import { Button } from 'library/button/Button';
import CustomSelect from 'library/Select/Select';
import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormInputBasic from 'ui/shared/formComponents/FormInputBasic';
import { DownloadIcon } from 'assets/svgIcons/Download';
import {
  TransactionsExportWrapper,
  ExportInputGroup,
  ExportButton,
  UserTypeCheck,
  ExportInputGroupDate,
  TransactionListRowHalf,
} from 'ui/styled/admin.styled';
import { userTypes } from 'constants/global.constants';
import { TickIcon } from 'assets/svgIcons/TickIcon';
import formatDate, { DateFormats } from 'utils/date';
import ReactDatePicker from 'react-datepicker';

const UsersExport = (props) => {
  const {
    setUserExportFields,
    userExportFields,
    getUserExportFile,
    loading,
    activeUserType,
    setActiveUserType,
    setPagination,
  } = props;
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const onSubmit = async (data) => {
    setPagination((prev) => {
      return {
        ...prev,
        call: false,
      };
    });
    const params = {
      status: data?.verified?.value || '',
      customerType: data?.customerType?.value || '',
      query: data?.query ? encodeURIComponent(data?.query) : '',
      fromDate: fromDate
        ? formatDate({
            inputDate: fromDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      toDate: toDate
        ? formatDate({
            inputDate: toDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
    };
    const exportData = {
      ...data,
      fromDate,
      toDate,
    };
    setUserExportFields(exportData);
    getUserExportFile(params);
  };

  useEffect(() => {
    if (userExportFields) {
      reset({
        query: userExportFields?.query,
        verified: userExportFields?.verified,
        customerType: userExportFields?.customerType,
      });
      setFromDate(userExportFields.fromDate);
      setToDate(userExportFields.toDate);
    }
  }, []);

  return (
    <TransactionsExportWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ExportInputGroup>
          <Controller
            render={({ field }) => (
              <FormInputBasic
                {...field}
                type="text"
                placeholder="Search by keyword"
                borderColor={errors.email}
              />
            )}
            control={control}
            name="query"
          />
        </ExportInputGroup>
        <ExportInputGroup>
          <Controller
            render={({ field }) => (
              <CustomSelect
                {...field}
                type="text"
                search={false}
                isClearable
                placeholder="User status"
                options={[
                  {
                    label: 'Incomplete',
                    value: 'INCOMPLETE',
                  },
                  {
                    label: 'Verified',
                    value: 'VERIFIED',
                  },
                  {
                    label: 'Demo',
                    value: 'DEMO',
                  },
                  {
                    label: 'Payed',
                    value: 'PAYED',
                  },
                ]}
              />
            )}
            control={control}
            defaultValue={userExportFields?.verified}
            name="verified"
          />
        </ExportInputGroup>
        <ExportInputGroupDate>
          <TransactionListRowHalf>
            <ReactDatePicker
              dateFormat="d MMM yyyy"
              selected={fromDate}
              placeholderText="From Date"
              onChange={(date) => setFromDate(date)}
              isClearable
            />
          </TransactionListRowHalf>
          <TransactionListRowHalf>
            <ReactDatePicker
              dateFormat="d MMM yyyy"
              selected={toDate}
              placeholderText="To Date"
              onChange={(date) => setToDate(date)}
              isClearable
            />
          </TransactionListRowHalf>
        </ExportInputGroupDate>
        <ExportInputGroup>
          <UserTypeCheck
            active={userTypes.PERSONAL === activeUserType}
            onClick={() => {
              if (userTypes.PERSONAL === activeUserType) {
                setActiveUserType(null);
                return;
              }
              setActiveUserType(userTypes.PERSONAL);
            }}
          >
            {userTypes.PERSONAL === activeUserType ? <TickIcon /> : null}
            Persona
          </UserTypeCheck>
          <UserTypeCheck
            active={userTypes.LEGAL === activeUserType}
            onClick={() => {
              if (userTypes.LEGAL === activeUserType) {
                setActiveUserType(null);
                return;
              }
              setActiveUserType(userTypes.LEGAL);
            }}
          >
            {userTypes.LEGAL === activeUserType ? <TickIcon /> : null}
            Corporate
          </UserTypeCheck>
        </ExportInputGroup>

        <ExportButton>
          <Button type="submit" className="submit" loading={loading}>
            <span style={{ paddingRight: '10px', marginTop: '3px' }}>
              <DownloadIcon />
            </span>
            Export
          </Button>
        </ExportButton>
      </form>
    </TransactionsExportWrapper>
  );
};

export default UsersExport;
