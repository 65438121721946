import { LoadingIcon } from 'assets/svgIcons/Loading.icon';
import { userTypes } from 'constants/global.constants';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import api from 'services/api';
import FormInput from 'ui/shared/formComponents/FormInput';
import {
  MoreDetailsModalWrapper,
  DetailsRow,
  CustomerId,
  UserUrlInput,
  SaveButton,
} from 'ui/styled/admin.styled';
import { getTextFromDataByKey } from 'utils/helpers';

const UserInfoModal = ({
  selectedUser,
  getUserList,
  searchValue,
  translatesData,
}) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm();
  const userUrlVal = watch('userUrl');
  const [loading, setLoading] = useState(false);

  const fieldsOfActivityData = translatesData
    ? translatesData?.data?.attributes?.fieldOfActivityTranslate
    : [];

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await api.admin.updateUserNote({
        userId: selectedUser?.user_id,
        notes: data?.userUrl,
      });
      getUserList({
        query: searchValue,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    reset({
      userUrl: selectedUser?.customer?.notes,
    });
  }, []);
  return (
    <MoreDetailsModalWrapper
      style={{
        paddingTop: '14px',
      }}
    >
      <CustomerId>
        <span>User ID: #</span>
        {selectedUser?.customer?.customerId}
      </CustomerId>
      <DetailsRow>
        <div className="left">
          {selectedUser.customer.customerType === userTypes.PERSONAL
            ? 'ID:'
            : 'CIN:'}
        </div>
        <div className="right">{selectedUser?.customer.idNumber || '-'}</div>
      </DetailsRow>
      <DetailsRow>
        <div className="left">Identificator:</div>
        <div className="right">{selectedUser?.customer?.customerType}</div>
      </DetailsRow>
      <DetailsRow>
        <div className="left">E-Mail:</div>
        <div className="right">{selectedUser?.customer?.email}</div>
      </DetailsRow>
      <DetailsRow>
        <div className="left">Phone:</div>
        <div className="right">{selectedUser?.customer?.mobile || '-'}</div>
      </DetailsRow>
      {selectedUser.customer.customerType === userTypes.LEGAL ? (
        <DetailsRow>
          <div className="left">Representative Phone:</div>
          <div className="right">
            {selectedUser?.customer?.repMobile || '-'}
          </div>
        </DetailsRow>
      ) : null}
      <DetailsRow>
        <div className="left">Field:</div>
        <div className="right">
          {getTextFromDataByKey(
            selectedUser?.customer?.fieldOfActivity,
            fieldsOfActivityData,
          )}
        </div>
      </DetailsRow>
      <DetailsRow>
        <div className="left">Status:</div>
        <div className="right">{selectedUser?.status}</div>
      </DetailsRow>
      {selectedUser?.user_id ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserUrlInput>
            <Controller
              render={({ field }) => (
                <FormInput {...field} type="text" label="User URL" />
              )}
              control={control}
              name="userUrl"
            />
            {userUrlVal && userUrlVal.length > 0 && isDirty ? (
              <SaveButton type="submit">
                {loading ? (
                  <LoadingIcon width="20px" height="20px" color="#8a87a5" />
                ) : (
                  'Save'
                )}
              </SaveButton>
            ) : null}
          </UserUrlInput>
        </form>
      ) : null}
    </MoreDetailsModalWrapper>
  );
};
export default UserInfoModal;
