import { IncrementalInputCounter } from 'library/incrementalInput/incrementalInput.styled';
import React, { useEffect, useMemo, useState } from 'react';
import {
  CartBodyRow,
  CartRowName,
  CartRowQyt,
  CartRowPrice,
  CartRowTotal,
  MobileCartRow,
  MobileCartRowHead,
  MobileCartRowBody,
} from 'ui/styled/cartPage.styled';
import clearCart from 'assets/img/delete.svg';
import { getTextFromDataByProductKey } from 'utils/helpers';
import ToolTipComponent from 'library/tooltip/ToolTipComponent';
import infoIcon from 'assets/img/info.svg';
import { useMediaQuery } from 'react-responsive';
import { productTypesFromCart } from 'constants/global.constants';
import { debounce } from 'lodash';

const CartRowComponent = (props) => {
  const {
    row,
    updateItemInCart,
    key,
    isPermise,
    translatesData,
    isFirstBasic,
    isMonthly,
    servicesData,
  } = props;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const translateKeys = translatesData?.data.attributes.enumTranslate;

  const isPermiseVal = row.isPermise;
  const isBasic = row.product.productKey.includes('_BASIC');

  const [inputValue, setInputValue] = useState(
    isPermiseVal ? row.quantity - 1 : row.quantity,
  );

  const persmisBasicKeyHandler = () => {
    if (row.quantity === 1 && isPermise && row.product.basePrice && !isBasic) {
      return `${row.product.productKey}`;
    }
    return row.product.productKey;
  };

  useEffect(() => {
    if (row) {
      setInputValue(isPermiseVal ? row.quantity - 1 : row.quantity);
    }
  }, [row]);

  const priceToRender = row.product.price;

  const debounceFn = useMemo(() => debounce(handleDebounceFn, 500), []);

  function handleDebounceFn(key, inputValue) {
    updateItemInCart(key, inputValue);
  }

  function handleChange(key, inputValue, hasBasic) {
    const filteredObjects = servicesData?.filter((obj) => {
      return (
        obj.attributes &&
        obj.attributes.prices &&
        obj.attributes.prices.some((price) => {
          return key.startsWith(price?.productKey);
        })
      );
    });
    const checkIfCountableServicesItem =
      filteredObjects[0]?.attributes?.countable;

    if (checkIfCountableServicesItem === false) return;

    const val = inputValue ? inputValue : 0;
    if (inputValue <= 999999999) {
      setInputValue(val);
      debounceFn(key, hasBasic ? Number(val) + 1 : Number(val));
    }
  }

  return isTabletOrMobile ? (
    <MobileCartRow>
      <MobileCartRowHead>
        <div className="title">
          {getTextFromDataByProductKey(persmisBasicKeyHandler(), translateKeys)}
        </div>
        <div className="actionButtons">
          <span
            className="delete"
            onClick={() => updateItemInCart(row.product.productKey, 0)}
          >
            <img src={clearCart} alt="Clear Cart" />
          </span>
        </div>
      </MobileCartRowHead>
      <MobileCartRowBody>
        <div className="rowCounter">
          <IncrementalInputCounter>
            <span
              className="decrement"
              style={{
                opacity: isBasic && row.productQwt > 1 ? 0.3 : 1,
              }}
              vvvv
              onClick={() => {
                updateItemInCart(
                  row.product.productKey,
                  row.hasBasicItem ? row.productQwt - 1 : row.quantity - 1,
                );
              }}
            >
              -
            </span>
            <input
              type="text"
              onChange={(e) => {
                if (isBasic && row.productQwt > 1) return;
                if (/^(?:[0-9]*)$/.test(e.target.value)) {
                  handleChange(
                    row?.product?.productKey,
                    e.target.value,
                    row.hasBasicItem,
                  );
                }
              }}
              readOnly={isBasic && row.productQwt > 1}
              value={isBasic && row.productQwt > 1 ? 1 : inputValue}
              min="0"
            />
            <span
              className="increment"
              style={{
                opacity:
                  row.product.type === productTypesFromCart.SUPPORTS ||
                  (isBasic && row.productQwt > 1)
                    ? 0.3
                    : 1,
              }}
              onClick={() => {
                if (row.product.type === productTypesFromCart.SUPPORTS) return;
                updateItemInCart(
                  row.product.productKey,
                  row.hasBasicItem ? row.productQwt + 1 : row.quantity + 1,
                );
              }}
            >
              +
            </span>
          </IncrementalInputCounter>
        </div>
        <div className="priceCol">
          <div className="priceTotal">
            <span className="titleSpan">In Total:</span>
            <span className="priceSpan">
              <span>{`₾ `}</span>
              {!isBasic
                ? (
                    priceToRender *
                    (isPermiseVal && row.quantity > 1
                      ? row.quantity - 1
                      : row.quantity)
                  ).toFixed(2)
                : priceToRender.toFixed(2)}
            </span>
          </div>
          <div className="priceEach">
            <span className="titleSpan">Price:</span>
            <span className="priceSpan">{`₾ ${
              priceToRender.toFixed(2) || ''
            }`}</span>
          </div>
        </div>
      </MobileCartRowBody>
    </MobileCartRow>
  ) : (
    <CartBodyRow key={key} className="bodyL">
      <CartRowName>
        <span>
          {getTextFromDataByProductKey(persmisBasicKeyHandler(), translateKeys)}
        </span>
      </CartRowName>
      <CartRowQyt>
        <IncrementalInputCounter>
          <span
            className="decrement"
            style={{
              opacity: isBasic && row.productQwt > 1 ? 0.3 : 1,
            }}
            onClick={() => {
              if (isBasic && row.productQwt > 1) return;
              updateItemInCart(
                row.product.productKey,
                row.hasBasicItem ? row.productQwt - 1 : row.quantity - 1,
              );
            }}
          >
            -
          </span>
          <input
            type="text"
            onChange={(e) => {
              if (/^(?:[0-9]*)$/.test(e.target.value)) {
                handleChange(
                  row?.product?.productKey,
                  e.target.value,
                  row.hasBasicItem,
                );
              }
            }}
            readOnly={isBasic && row.productQwt > 1}
            value={isBasic && row.productQwt > 1 ? 1 : inputValue}
            min="0"
          />
          <span
            className="increment"
            style={{
              opacity:
                row.product.type === productTypesFromCart.SUPPORTS ||
                (isBasic && row.productQwt > 1)
                  ? 0.3
                  : 1,
            }}
            onClick={() => {
              if (
                row.product.type === productTypesFromCart.SUPPORTS ||
                (isBasic && row.productQwt > 1)
              )
                return;
              updateItemInCart(
                row.product.productKey,
                row.hasBasicItem ? row.productQwt + 1 : row.quantity + 1,
              );
            }}
          >
            +
          </span>
        </IncrementalInputCounter>
      </CartRowQyt>
      <CartRowPrice>
        <span className="pad-5">₾</span> {priceToRender.toFixed(2)}
      </CartRowPrice>
      <CartRowTotal>
        <span className="pad-5">₾</span>
        {!isBasic
          ? (
              priceToRender *
              (isPermiseVal && row.quantity > 1
                ? row.quantity - 1
                : row.quantity)
            ).toFixed(2)
          : priceToRender.toFixed(2)}
        <span
          className="delete"
          onClick={() => updateItemInCart(row.product.productKey, 0)}
        >
          <img src={clearCart} alt="Clear Cart" />
        </span>
      </CartRowTotal>
    </CartBodyRow>
  );
};

export default CartRowComponent;
