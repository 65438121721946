import React from 'react';
import {
  CheckboxLabelStyled,
  CheckboxWrapperStyled,
} from './formCheckbox.styled';
import CheckboxEmpty, { CheckboxChecked } from './formCheckboxSvgComp';

export const FormCheckBoxState = ({
  name,
  value,
  checked,
  label,
  stroke,
  labelPosition,
  labelColored,
  labelColor,
  disabled,
  hrefAttr,
  onChange,
  labelOnchange,
}) => {
  return (
    <CheckboxWrapperStyled disabled={disabled}>
      {labelPosition === 'left' && (
        <CheckboxLabelStyled>{label}</CheckboxLabelStyled>
      )}
      <input
        disabled={disabled}
        style={{ display: 'none' }}
        type="checkbox"
        name={name}
        value={value}
        defaultChecked={checked}
        checked={checked}
        onChange={() => onChange()}
      />
      <span
        style={{ margin: '0 16px 0 0' }}
        onClick={() => {
          if (disabled) return;
          onChange();
        }}
      >
        {checked ? <CheckboxChecked /> : <CheckboxEmpty stroke={stroke} />}
      </span>
      {labelPosition === 'right' && (
        <CheckboxLabelStyled
          className="CheckboxLabelStyled"
          style={{ color: labelColor }}
          onClick={() => (labelOnchange ? onChange() : null)}
        >
          <span style={{ color: stroke }}>{label}</span>
          <a href={hrefAttr ? hrefAttr : ''} target="_blank" rel="noreferrer">
            <span style={{ color: labelColor }}>{labelColored}</span>
          </a>
        </CheckboxLabelStyled>
      )}
    </CheckboxWrapperStyled>
  );
};
export default FormCheckBoxState;
