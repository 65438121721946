export const arrayRequired = (value, error = 'Required') => {
  const array = Array.isArray(value) ? value : Array(value);
  return !array.length ? error : undefined;
};

export const required = (value, error = 'Required') => {
  if (Array.isArray(value)) return arrayRequired(value);
  return !value || !String(value).trim() ? error : undefined;
};

const startingWithZeroregExp = /^0[0-9].*$/;

export const minNumber = (
  value,
  equals = false,
  length = 0,
  error = 'Required',
) => {
  const startsWithZero = startingWithZeroregExp.test(String(value));
  if (startsWithZero) return error;
  return equals
    ? Number(value) >= length
      ? undefined
      : error
    : Number(value) > length
    ? undefined
    : error;
};

export const maxNumber = (
  value,
  length = 0,
  error = 'Required',
  equals = false,
) => {
  const startsWithZero = startingWithZeroregExp.test(String(value));
  if (startsWithZero) return error;
  if (!equals && Number(value) <= 0) return error;
  return Number(value) <= length ? undefined : error;
};

const startsWithRegex = new RegExp('^(13)');

export const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const passwordRegex = new RegExp(
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/,
);

export const onlyLatinAndRegex = new RegExp(
  '^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$',
);

export const onlyLatinAndNumbers = new RegExp('[^A-Za-z0-9]+');

const emailLengthValidator = (email) => {
  const str = email.split('@');
  if (str[0].length > 40) {
    return true;
  } else {
    return false;
  }
};

export const isEmail = (value, error = 'Not Valid Email Format') => {
  if (emailLengthValidator(value)) {
    error = 'Max 40 Symbols';
  }
  return !emailRegex.test(value) || emailLengthValidator(value)
    ? error
    : undefined;
};

export const startsWithCheker = (
  value,
  error = 'The code should start with 13',
) => {
  return !startsWithRegex.test(value) ? error : undefined;
};

export const onlyLatinValidator = (value, error = 'Invalid Name') => {
  if (value.length < 2) {
    error = 'Min 2 Symbols';
  }
  if (value.length > 100) {
    error = 'Max 100 Symbols';
  }
  return !onlyLatinAndRegex.test(value) || value.length > 100
    ? error
    : undefined;
};

export const maxAllowedInvitationCode = (
  value,
  error = 'cis.text.register.validations.max_32_symbols_allowed',
) => {
  return value.length > 32 ? error : undefined;
};

export const isValidPassword = (value, error = 'Invalid Password') => {
  if (value?.length > 100) {
    error = 'Max 100 Symbols Allowed';
  }
  return !passwordRegex.test(value) ? error : undefined;
};

const onlyEnglishLettersExp = /^((?:\s*[A-Za-z-]\s*){1,})$/;

export const onlyLatinLetters = (value, error = 'Only Latins Allowed') => {
  if (!value) {
    return undefined;
  }
  return !onlyEnglishLettersExp.test(value) ? error : undefined;
};

export const isValidUrl = (
  value,
  error = 'cis.text.register.validations.invalid_url_format',
) => {
  const urlRegex =
    '(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})';
  const url = new RegExp(urlRegex, 'i');
  return !url.test(value) ? error : undefined;
};

export const composeValidators =
  (...validators) =>
  (value) => {
    const composeValidatorsReducer = (error = 'Error', validator) =>
      error || validator(value);

    return Array.isArray(validators[0])
      ? validators[0].reduce(composeValidatorsReducer, undefined)
      : validators.reduce(composeValidatorsReducer, undefined);
  };
