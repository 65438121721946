import auth from './auth';
import cart from './cart';
import user from './user';
import admin from './admin';

export default {
  auth,
  cart,
  user,
  admin,
};
