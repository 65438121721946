import styled from 'styled-components';
import sideBg from 'assets/img/sideBg.svg';

export const ServicesParagraphTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  color: #3a3853;
  padding: 0px 0px 0px 15%;
  margin-bottom: ${(props) => (props.secondParagraph ? '11px ' : '11px')};
  background: url(${sideBg});
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
  background-position: bottom right;
  background-size: 65%;
  span {
    padding-left: 8px;
    margin-right: auto;
    color: #bab8c7;
    font-size: 30px;
    font-weight: 500;
  }
  @media (max-width: 1685px) {
    padding: 0 0 0 8%;
  }
  @media (max-width: 900px) {
    margin-bottom: 0px;
    background: none;
    padding: ${(props) =>
      props.secondParagraph ? '0px 0px 20px 22px' : '28px 0px 20px 22px'};
    height: auto;
  }
`;

export const ServicesImgSection = styled.div`
  position: relative;
  img {
    max-height: 311px;
    @media (max-width: 900px) {
      min-height: 239px;
      max-height: 239px;
      border-bottom-right-radius: 140px;
      transform: ${(props) => (props.isEven ? 'scaleX(-1)' : undefined)};
      padding: ${(props) =>
        props.isEven ? '0px 0px 0px 6%' : '0px 6% 0px 0px'};
    }
  }
`;
export const PlanListServices = styled.div`
  display: flex;
`;
export const ImgSection = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  .imgRect {
    z-index: 2;
    display: inline-block;
    position: relative;
  }
  .img1 {
    z-index: 2;
    display: inline-block;
    top: 47px;
    left: 30%;
    position: absolute;
  }

  .imgRect-1 {
    position: relative;
    z-index: 1;
    display: inline-block;
  }
  .img2 {
    z-index: 2;
    display: inline-block;
    top: 40px;
    left: -80px;
    position: absolute;
  }
`;
