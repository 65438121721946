import { Button } from 'library/button/Button';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import FormInputBasic from 'ui/shared/formComponents/FormInputBasic';
import {
  BtnGroupMobile,
  RegisterRowFull,
  RegisterRowItem,
} from 'ui/styled/auth.styled';
import { DemoModalFormStyled } from 'ui/styled/home.styled';
import api from 'services/api';
import CustomSelect from 'library/Select/Select';

const TestEmailForm = (props) => {
  const methods = useForm();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    const params = {
      type: data.type,
      language: data.language.value,
      email: data.email || '',
    };
    try {
      await api.admin.sendTestMails(params);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
      props.setTestEmailOpen(false);
    }
  };

  return (
    <DemoModalFormStyled>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <RegisterRowFull>
            <RegisterRowItem>
              <Controller
                render={({ field }) => (
                  <FormInputBasic
                    {...field}
                    type="text"
                    placeholder="Type"
                    borderColor={errors.type}
                  />
                )}
                rules={{
                  required: 'Is Required',
                }}
                control={control}
                name="type"
              />
            </RegisterRowItem>
          </RegisterRowFull>
          <RegisterRowFull>
            <RegisterRowItem>
              <Controller
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    type="select"
                    search={false}
                    isClearable
                    placeholder="Language"
                    options={[
                      {
                        label: 'KA',
                        value: 'KA',
                      },
                      {
                        label: 'EN',
                        value: 'EN',
                      },
                      {
                        label: 'RU',
                        value: 'RU',
                      },
                    ]}
                    className={errors.language ? 'errorLang' : ''}
                  />
                )}
                rules={{ required: 'Is Required' }}
                control={control}
                name="language"
              />
            </RegisterRowItem>
          </RegisterRowFull>
          <RegisterRowFull>
            <RegisterRowItem>
              <Controller
                render={({ field }) => (
                  <FormInputBasic
                    {...field}
                    type="email"
                    placeholder="email"
                    borderColor={errors.email}
                  />
                )}
                control={control}
                name="email"
                rules={{ required: 'Is Required' }}
              />
            </RegisterRowItem>
          </RegisterRowFull>
          <BtnGroupMobile>
            <Button loading={loading} className="submit">
              Send
            </Button>
          </BtnGroupMobile>
        </form>
      </FormProvider>
    </DemoModalFormStyled>
  );
};

export default TestEmailForm;
