import React, { useEffect, useState } from 'react';
import { EmailConfIcon } from 'assets/svgIcons/EmailConfIcon';
import {
  RegisterTextWrapper,
  RegisterVerifyWrapper,
  ResendCodeBlock,
  VerifyDesc,
  VerifyIconBlock,
  VerifyTitle,
} from 'ui/styled/auth.styled';
import { setTimeOutUtil } from 'utils/setTimeOutUtil';
import { getTextFromDataByKey } from 'utils/helpers';
import { useMediaQuery } from 'react-responsive';

const ForgotPasswordVerify = (props) => {
  const { resendPasswordResetRequest, userName, verifyEmailData } = props;

  const [counter, setCounter] = useState(0);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  useEffect(() => {
    counter > 0 && setTimeOutUtil(setCounter, 1000, counter - 1);
  }, [counter]);

  useEffect(() => {
    setCounter(30);
  }, []);

  return (
    <RegisterVerifyWrapper>
      {isTabletOrMobile ? (
        <RegisterTextWrapper>
          <VerifyIconBlock>
            <EmailConfIcon />
          </VerifyIconBlock>
          <VerifyTitle>
            {getTextFromDataByKey('title', verifyEmailData)}
          </VerifyTitle>
          <VerifyDesc>
            {`${getTextFromDataByKey(
              'textBeforeEmail',
              verifyEmailData,
            )} ${userName} ${getTextFromDataByKey(
              'textAfterEmail',
              verifyEmailData,
            )}`}
          </VerifyDesc>
        </RegisterTextWrapper>
      ) : (
        <>
          <VerifyIconBlock>
            <EmailConfIcon />
          </VerifyIconBlock>
          <VerifyTitle>
            {getTextFromDataByKey('title', verifyEmailData)}
          </VerifyTitle>
          <VerifyDesc>
            {`${getTextFromDataByKey(
              'textBeforeEmail',
              verifyEmailData,
            )} ${userName} ${getTextFromDataByKey(
              'textAfterEmail',
              verifyEmailData,
            )}`}
          </VerifyDesc>
        </>
      )}

      <ResendCodeBlock className="bodyL">
        <span>
          {counter === 0
            ? null
            : getTextFromDataByKey('dontReceiveEmail', verifyEmailData)}
        </span>
        {counter === 0 ? (
          <span
            className="resend"
            onClick={() => {
              resendPasswordResetRequest();
              setCounter(30);
            }}
          >
            {getTextFromDataByKey('resend', verifyEmailData)}
          </span>
        ) : (
          <span className="timer">
            00:<span>{counter}</span>
          </span>
        )}
      </ResendCodeBlock>
    </RegisterVerifyWrapper>
  );
};

export default ForgotPasswordVerify;
