import styled, { css } from 'styled-components';

export const ModalBackgroundStyled = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(20, 22, 31, 0.7);
  padding: 1em;
  overflow: overlay;

  opacity: ${(props) => {
    switch (props.fadeType) {
      case 'in':
        return '1';
      default:
        return '0';
    }
  }};
  transition: ${(props) => {
    switch (props.fadeType) {
      case 'in':
        return 'opacity 0.15s;';
      case 'out':
        return 'opacity 0.15s;';
      default:
        return '';
    }
  }};
`;

export const ModalBody = styled.div`
  position: absolute;
  border-radius: 25px;
  color: #000;
  box-sizing: border-box;
  box-shadow: 0px 23px 66px #bfbecc3c;
  background-color: #fff;
  flex: 0 0 auto;
  overflow: ${(props) => props.overflow || 'auto'};
  padding: ${(props) => props.spacing || '40px 101px 50px 101px'};
  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
           {
            width: ${({ width }) => width || '25%'};
            height: ${({ height }) => height || '20%'};
          }
        `;
      case 'large':
        return css`
           {
            width: ${({ width }) => width || '60%'};
            height: ${({ height }) => height || '70%'};
          }
        `;
      default:
        return css`
           {
            width: ${({ width }) => width || '30%'};
            height: ${({ height }) => height || 'auto'};
          }
        `;
    }
  }}

  transform: ${(props) => {
    switch (props.fadeType) {
      case 'in':
        return 'scale(1)';
      default:
        return 'scale(0.92)';
    }
  }};

  transition: ${(props) => {
    switch (props.fadeType) {
      case 'in':
        return 'transform 0.15s;';
      case 'out':
        return 'transform 0.15s;';
      default:
        return '';
    }
  }};

  @media (max-width: 900px) {
    width: 100%;
    max-height: 100%;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-height: 700px) {
    padding: ${(props) => props.spacing || '40px 101px 50px 101px'};
  }
`;

export const ModalTopPanel = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  align-items: flex-start;
  justify-content: space-between;
  .close_icon {
    position: absolute;
    right: 54px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const ModalTitle = styled.span`
  line-height: 1.25;
  letter-spacing: 0.04px;
  text-align: left;
  color: #242333;
  padding-top: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  .help-icon {
    cursor: pointer;
    margin: 6px 0 0 5px;
    svg {
      width: 25px;
      .fill-none {
        fill: none !important;
      }
      :hover {
        path {
          fill: #0ecbfd;
        }
      }
    }
  }
`;

export const Modalinfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 0 30px;
`;
