import { Button } from 'library/button/Button';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import {
  TransactionsFilterWrapper,
  ExportButton,
  ExportInputGroupDate,
  TransactionListRowHalf,
} from 'ui/styled/admin.styled';
import formatDate, { DateFormats } from 'utils/date';

const TransactionHistoryFilter = ({
  loading,
  transactionHistoryFilters,
  setTransactionHistoryFilters,
  getPaymentHistoryList,
  setPagination,
}) => {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const onSubmit = async () => {
    const params = {
      fromDate: fromDate
        ? formatDate({
            inputDate: fromDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      toDate: toDate
        ? formatDate({
            inputDate: toDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
    };
    const filtersData = {
      ...params,
      fromDate,
      toDate,
    };
    setTransactionHistoryFilters(filtersData);
    getPaymentHistoryList(params, true);
  };

  useEffect(() => {
    if (transactionHistoryFilters) {
      setFromDate(transactionHistoryFilters.fromDate);
      setToDate(transactionHistoryFilters.toDate);
    }
  }, []);

  return (
    <TransactionsFilterWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ExportInputGroupDate>
          <TransactionListRowHalf>
            <ReactDatePicker
              dateFormat="d MMM yyyy"
              selected={fromDate}
              placeholderText="From Date"
              onChange={(date) => setFromDate(date)}
              isClearable
            />
          </TransactionListRowHalf>
          <TransactionListRowHalf>
            <ReactDatePicker
              dateFormat="d MMM yyyy"
              selected={toDate}
              placeholderText="To Date"
              onChange={(date) => setToDate(date)}
              isClearable
            />
          </TransactionListRowHalf>
        </ExportInputGroupDate>
        <ExportButton>
          <Button className="submit" loading={loading}>
            Filter
          </Button>
        </ExportButton>
      </form>
    </TransactionsFilterWrapper>
  );
};

export default TransactionHistoryFilter;
