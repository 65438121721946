import React from "react";
import Collapsible from "react-collapsible";

const Accordion = ({
  children,
  open = false,
  trigger,
  transitionTime = 200,
  onOpen,
  onClose,
}) => {
  return (
    <Collapsible
      open={open}
      trigger={trigger}
      transitionTime={transitionTime}
      onOpen={onOpen}
      onClose={onClose}
    >
      {children}
    </Collapsible>
  );
};

export default Accordion;
