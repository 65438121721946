import { CartIcon } from 'assets/svgIcons/CartIcon';
import React, { useContext, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  CartDropdownList,
  CartDropdownStyled,
  CartTotalSummary,
  GoToCartButton,
  EmptyCartWrapper,
  EmptyCartImg,
  EmptyCartDesc,
  ClearCartButton,
} from '../header.styled';
import CartListItem from './CartListItem';
import EmptyCart from 'assets/img/emptyCart.svg';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { getTextFromDataByKey } from 'utils/helpers';
import { useSelector } from 'react-redux';
import { appSelectors } from 'store/reducers/app/app.slice';
import { StrapiDataContext } from 'Main';
import api from 'services/api';

const CartDropdown = (props) => {
  const { cartList, getCartData, close, translatesData, isOfferInUrlParams } =
    props;

  const lastItemFromCart = useSelector(appSelectors.selectLastItemFromCart);
  const { cartStrapiData, servicesList } = useContext(StrapiDataContext);
  const location = useLocation();
  const ref = useRef(null);

  const cartApiData = cartStrapiData.data
    ? cartStrapiData.data.data.attributes
    : null;
  const servicesData = servicesList?.data ? servicesList.data.data : null;

  const clearAllCart = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    try {
      await api.cart.deleteAllItemFromCart();
      getCartData();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        close();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  function add(acc, a) {
    return acc + a.product.price * a.quantity;
  }

  const cartListChanged = cartList.filter((item) => item.quantity > 0);

  const totalPrice = cartListChanged.reduce(add, 0);

  useEffect(() => {
    var element = document.getElementById('CartDropdownStyled');
    element.classList.add('lastCartDropdownStyled');
    setTimeout(() => {
      element.classList.remove('lastCartDropdownStyled');
    }, 2000);
  }, []);

  return (
    <CartDropdownStyled ref={ref} id="CartDropdownStyled">
      {cartListChanged.length === 0 ? (
        <EmptyCartWrapper>
          <EmptyCartImg>
            <img src={EmptyCart} alt="Empty Cart" />
          </EmptyCartImg>
          <EmptyCartDesc className="bodyS">
            {getTextFromDataByKey(
              'emptyCardDropDown',
              translatesData?.data.data?.attributes?.headerTranslates,
            )}
          </EmptyCartDesc>
        </EmptyCartWrapper>
      ) : (
        <>
          <Scrollbars
            autoHeight
            autoHeightMin={50}
            autoHeightMax={300}
            autoHide
          >
            <CartDropdownList>
              {cartListChanged.map((item, index) => {
                return (
                  <CartListItem
                    className={
                      item.product.productKey === lastItemFromCart
                        ? 'lastCartListItem'
                        : ''
                    }
                    data={item}
                    key={item.productId + index}
                    getCartData={getCartData}
                    translatesData={translatesData}
                    onClick={props.close}
                    servicesData={servicesData}
                  />
                );
              })}
            </CartDropdownList>
            <span id="bottomCart"></span>
          </Scrollbars>
          <CartTotalSummary className="bodyM">
            <div className="total-text">
              {getTextFromDataByKey(
                'subtotalPrice',
                translatesData?.data?.data?.attributes?.headerTranslates,
              )}
            </div>
            <div className="total-price">{`₾ ${totalPrice.toFixed(2)}`}</div>
          </CartTotalSummary>
          <Link
            to={
              location.pathname === '/offer' || isOfferInUrlParams
                ? `/cart/isOffer/${'default'}`
                : '/cart'
            }
          >
            <GoToCartButton onClick={props.close}>
              <CartIcon />
              <span>
                {getTextFromDataByKey(
                  'goToCart',
                  translatesData?.data?.data?.attributes?.headerTranslates,
                )}
              </span>
            </GoToCartButton>
          </Link>
          <ClearCartButton onClick={(e) => clearAllCart(e)}>
            {getTextFromDataByKey('clearCart', cartApiData?.table?.translate)}
          </ClearCartButton>
        </>
      )}
    </CartDropdownStyled>
  );
};

export default CartDropdown;
