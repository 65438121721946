import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'library/button/Button';
import api from 'services/api';
import {
  BillingModalProductDescr,
  BillingModalProductInfo,
  BillingModalProductWrapper,
  BillingModalTotalPrice,
  BillingModalWrapper,
  DashedLine,
  InfoNoteBlock,
} from 'ui/styled/billingModalStyled';
import formatDate, { DateFormats } from 'utils/date';
import {
  ChangeCard,
  CheckoutFormCardBlock,
  CheckoutFormSubmit,
  SavedCard,
  SavedCardIcon,
  SavedCardMask,
  SavedCardTitle,
  SavedCartInfoWrapper,
} from 'ui/styled/cartPage.styled';
import {
  creditCardType,
  getTextFromDataByKey,
  getTextFromDataByPeriod,
  getTextFromDataByProductKey,
} from 'utils/helpers';
import visaIcon from 'assets/img/visa.svg';
import masterCardIcon from 'assets/img/mastercard.svg';
import Modal from 'library/modal/Modal';
import FormErrorComponent from 'ui/components/common/FormError';
import ErrorAndSuccessModal from '../Cart/CartPageComponents/ErrorAndSuccessModal';
import { htmlParser } from 'utils/htmlUtils';
import { StrapiDataContext } from 'Main';
import BottomSheet from 'library/bottomSheet/BottomSheet';

const BillingTableModal = ({ onPaymentSuccess, ...props }) => {
  const { cartStrapiData } = useContext(StrapiDataContext);

  const [savedCardInfo, setSavedCardInfo] = useState(null);
  const [cardSuccessModal, setCardSuccessModal] = useState(false);

  const [repayLoading, setRepayLoading] = useState(false);
  const [repayError, setRepayError] = useState(null);

  const {
    productId,
    translateKeys,
    getPurchaseHistoryData,
    closeModal,
    payNowModalData,
    isTabletOrMobile,
    errorTranslatesData,
    setConfirmCardModalOpen,
  } = props;

  const getPaymentCartInfo = async () => {
    await api.cart.getPaymentCard().then((res) => {
      setSavedCardInfo(res.data.mask);
    });
  };

  const payNowButton = async () => {
    setRepayLoading(true);
    try {
      await api.cart.repayItem(productId.purchasedItemId);
      setCardSuccessModal(true);
      onPaymentSuccess(productId.purchasedItemId);
      closeModal();
    } catch (e) {
      setRepayError(e);
    } finally {
      setRepayLoading(false);
    }
  };

  const renderCardIcon = () => {
    if (!savedCardInfo) return '';

    if (
      creditCardType(savedCardInfo) === '5' ||
      creditCardType(savedCardInfo) === '2'
    ) {
      return <img src={masterCardIcon} alt="MASTERCARD" />;
    }
    if (creditCardType(savedCardInfo) === '4') {
      return <img src={visaIcon} alt="Visa" />;
    }
  };

  useEffect(() => {
    getPaymentCartInfo();
  }, []);

  const renderCardName = () => {
    if (!savedCardInfo) return '';
    if (
      creditCardType(savedCardInfo) === '5' ||
      creditCardType(savedCardInfo) === '2'
    ) {
      return 'Mastercard';
    }
    if (creditCardType(savedCardInfo) === '4') {
      return 'Visa';
    }
  };

  const onChangeCard = () => {
    setConfirmCardModalOpen(true);
    closeModal();
  };

  return (
    <>
      <BillingModalWrapper>
        {isTabletOrMobile ? (
          <BottomSheet
            isOpen={cardSuccessModal}
            padding="40px 0"
            closeBottomSheet={() => setCardSuccessModal(false)}
          >
            <ErrorAndSuccessModal
              title={
                cartStrapiData?.data?.data?.attributes?.card.addCardSuccess
                  .title
              }
              subTitle={htmlParser(
                cartStrapiData?.data?.data?.attributes?.card.addCardSuccess
                  .description,
              )}
            />
          </BottomSheet>
        ) : (
          <Modal
            closeIcon
            width="590px"
            height="auto"
            isOpen={cardSuccessModal}
            closeModal={() => {
              setCardSuccessModal(false);
            }}
          >
            <ErrorAndSuccessModal
              title={
                cartStrapiData?.data?.data?.attributes?.card.addCardSuccess
                  .title
              }
              subTitle={htmlParser(
                cartStrapiData?.data?.data?.attributes?.card.addCardSuccess
                  .description,
              )}
            />
          </Modal>
        )}
        <InfoNoteBlock className="headline4">
          {getTextFromDataByProductKey(
            productId?.product.productKey,
            translateKeys,
          )}
        </InfoNoteBlock>
        {isTabletOrMobile && repayError ? (
          <FormErrorComponent
            error={repayError}
            errorTranslatesData={errorTranslatesData}
          />
        ) : null}

        <BillingModalProductWrapper>
          <BillingModalProductInfo>
            {getTextFromDataByKey('ID_number', payNowModalData)}
          </BillingModalProductInfo>
          <DashedLine />
          <BillingModalProductDescr>
            {` #${productId?.purchasedItemId}`}
          </BillingModalProductDescr>
        </BillingModalProductWrapper>

        <BillingModalProductWrapper>
          <BillingModalProductInfo>
            {getTextFromDataByKey('time_period', payNowModalData)}
          </BillingModalProductInfo>
          <DashedLine />
          <BillingModalProductDescr>
            {getTextFromDataByPeriod(
              productId?.product.productKey,
              translateKeys,
            )}
          </BillingModalProductDescr>
        </BillingModalProductWrapper>

        <BillingModalProductWrapper>
          <BillingModalProductInfo>
            {getTextFromDataByKey('start_date', payNowModalData)}
          </BillingModalProductInfo>
          <DashedLine />

          <BillingModalProductDescr>
            {formatDate({
              inputDate: productId?.createTime,
              outputFormat: DateFormats.DefaultWithoutLines,
            })}
          </BillingModalProductDescr>
        </BillingModalProductWrapper>

        <BillingModalTotalPrice className="bodyL">
          <BillingModalProductInfo style={{ background: 'transparent' }}>
            {getTextFromDataByKey('total_price', payNowModalData)}
          </BillingModalProductInfo>
          {/* <DashedLine /> */}
          <div className="totalPrice">{`₾ ${productId?.upcomingPayment.toFixed(
            2,
          )}`}</div>
        </BillingModalTotalPrice>

        <CheckoutFormCardBlock
          className="bodyL"
          style={{ border: 'none', padding: '10px', marginBottom: '40px' }}
        >
          <>
            <SavedCard>
              <SavedCardIcon>{renderCardIcon()}</SavedCardIcon>
              {savedCardInfo ? (
                <SavedCartInfoWrapper>
                  <SavedCardMask className="bodyL">
                    {`** ${savedCardInfo.substr(savedCardInfo.length - 4)}`}
                  </SavedCardMask>
                  <SavedCardTitle className="bodyM">
                    {renderCardName()}
                  </SavedCardTitle>
                </SavedCartInfoWrapper>
              ) : undefined}
            </SavedCard>
            <ChangeCard onClick={onChangeCard}>
              {getTextFromDataByKey('changeCard', payNowModalData)}
            </ChangeCard>
          </>
        </CheckoutFormCardBlock>
        {!isTabletOrMobile && repayError ? (
          <FormErrorComponent
            error={repayError}
            errorTranslatesData={errorTranslatesData}
          />
        ) : null}
        <CheckoutFormSubmit style={{ padding: '0 10px' }}>
          <Button
            className="submit button1"
            type="button"
            loading={repayLoading}
            onClick={payNowButton}
          >
            {getTextFromDataByKey('payNow', payNowModalData)}
          </Button>
        </CheckoutFormSubmit>
      </BillingModalWrapper>
    </>
  );
};

export default BillingTableModal;
