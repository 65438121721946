import React from 'react';

export const AddIcon = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="57"
    viewBox="0 0 58 57"
    {...rest}
  >
    <g id="Add" transform="translate(-277 -677)">
      <rect
        id="Rectangle_48"
        data-name="Rectangle 48"
        width="58"
        height="57"
        rx="6"
        transform="translate(277 677)"
        fill="#f8f7fb"
      />
      <g id="Group_122" data-name="Group 122" transform="translate(1 264)">
        <line
          id="Line_18"
          data-name="Line 18"
          x2="13"
          transform="translate(298.5 441.5)"
          fill="none"
          stroke="#3a3853"
          strokeWidth="3"
        />
        <path
          id="Path_84"
          data-name="Path 84"
          d="M0,0V13"
          transform="translate(305 435)"
          fill="none"
          stroke="#3a3853"
          strokeWidth="3"
        />
      </g>
    </g>
  </svg>
);
