import { Button } from 'library/button/Button';
import React, { useState } from 'react';
import {
  OldPriceOverLineComp,
  PlansPrice,
  ServicesPlanListWrapper,
  ServicesPlanParagraph,
  ServicesPlanTitle,
  ServicesPlanWrapper,
  VisualAndOldPriceWrapper,
} from 'ui/styled/servicesPlansStyled';
import PlansListServices from 'ui/components/plansSection/PlansListServices';
import api from 'services/api';
import { htmlParser } from 'utils/htmlUtils';
import cacheManager from 'utils/cacheManager';
import { TOKEN_NAME } from 'constants/global.constants';
import { CartIcon } from 'assets/svgIcons/CartIcon';
import { useMediaQuery } from 'react-responsive';

const ServicesPlans = (props) => {
  const {
    servicesPlanTitle,
    servicesPlanPrices,
    servicesPlanParagraph,
    planItems,
    buttonText,
    productKey,
    cartData,
    openModal,
    updateItemInCart,
    isSupport,
    setIsInCart,
    setSelectedKey,
    setSelectedCartItem,
    productItem,
    activeSupportItem,
    setActiveSupportItem,
    setSelectedSupportItem,
    buttonTexts,
    setAuthModalOpen,
    visualPrice,
    oldPrice,
    isOfferPage,
  } = props;

  const tokenCache = cacheManager(TOKEN_NAME);
  const token = tokenCache.get();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  const [isFirstPurchase, setIsFirstPurchase] = useState(true);

  const productKeyFromPrices = productKey[0]?.productKey;
  const productSalePercentage = productKey[0]?.sale;
  const isAddedToCart = cartData?.filter(
    (item) => item?.product?.productKey === productKeyFromPrices,
  );

  const checkIfFirstBuy = async () => {
    if (!token) {
      setAuthModalOpen(true);
      return;
    }
    if (isSupport && activeSupportItem?.modal) {
      if (
        activeSupportItem?.modal?.title === null ||
        activeSupportItem?.modal?.description === null ||
        activeSupportItem?.modal?.title === undefined ||
        activeSupportItem?.modal?.description === undefined
      ) {
        setActiveSupportItem(activeSupportItem?.modal);
        setSelectedSupportItem(activeSupportItem);
        updateItemInCart(productKeyFromPrices, isAddedToCart);
        return;
      }
      openModal();
      setSelectedKey(productKeyFromPrices);
      setSelectedCartItem(productItem);
      setActiveSupportItem(
        activeSupportItem?.modal ? activeSupportItem?.modal : undefined,
      );
      setSelectedSupportItem(activeSupportItem);
      setIsInCart(isAddedToCart);
      return;
    }
    if (isSupport && activeSupportItem?.modal) {
      openModal();
      setSelectedKey(productKeyFromPrices);
      setSelectedCartItem(productItem);
      setActiveSupportItem(
        activeSupportItem?.modal ? activeSupportItem?.modal : undefined,
      );
      setSelectedSupportItem(activeSupportItem);
      setIsInCart(isAddedToCart);
      return;
    }
    if (isSupport && !activeSupportItem?.modal) {
      openModal();
      setSelectedKey(productKeyFromPrices);
      setSelectedCartItem(productItem);
      setActiveSupportItem(
        activeSupportItem?.modal ? activeSupportItem?.modal : undefined,
      );
      setSelectedSupportItem(activeSupportItem);
      setIsInCart(isAddedToCart);
      return;
    } else {
      setActiveSupportItem(activeSupportItem?.modal);
      setSelectedSupportItem(activeSupportItem);
      updateItemInCart(productKeyFromPrices, isAddedToCart);
    }
  };

  const buttonTextToShow =
    isSupport && activeSupportItem?.modal
      ? isAddedToCart?.length > 0
        ? buttonTexts?.btnRemove
        : !isFirstPurchase && isSupport
        ? buttonTexts?.btnCancel
        : buttonText
      : buttonText;

  const visualPriceHanlder = () => {
    if (!oldPrice) return visualPrice;
    if (oldPrice && oldPrice === visualPrice) return visualPrice;
    if (oldPrice)
      return (
        <OldPriceOverLineComp>
          <span className="new">{visualPrice}</span>
          <span className="old">{oldPrice}</span>
        </OldPriceOverLineComp>
      );
  };

  return (
    <ServicesPlanWrapper>
      <ServicesPlanTitle className={isOfferPage ? 'isOfferPage' : ''}>
        <div className="title">{`${servicesPlanTitle} ${
          isTabletOrMobile || !visualPrice || isOfferPage ? '' : '-'
        }`}</div>
        <VisualAndOldPriceWrapper
          style={{ marginBottom: isOfferPage ? '6px' : '0' }}
        >
          {visualPriceHanlder()}
          {productSalePercentage ? (
            <span className="offer bodyL">{productSalePercentage}</span>
          ) : null}
        </VisualAndOldPriceWrapper>

        {servicesPlanPrices ? (
          <PlansPrice> {servicesPlanPrices} </PlansPrice>
        ) : null}
      </ServicesPlanTitle>

      <ServicesPlanParagraph className="bodyL">
        {htmlParser(servicesPlanParagraph)}
      </ServicesPlanParagraph>

      <ServicesPlanListWrapper>
        <PlansListServices
          planItems={planItems}
          buttonText={buttonText}
          isOfferPage={isOfferPage}
        />
      </ServicesPlanListWrapper>

      <Button
        fontWeight="500"
        height="50px"
        background="#FFDC04"
        color="#242333"
        padding="0 37px"
        onClick={() => checkIfFirstBuy()}
      >
        <CartIcon style={{ marginRight: '12px' }} />
        {buttonTextToShow}
      </Button>
    </ServicesPlanWrapper>
  );
};
export default ServicesPlans;
