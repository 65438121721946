export const getKeyFromErrorObject = (errorObject = {}) =>
  errorObject.response &&
  errorObject.response.data &&
  errorObject.response.data.errorKey;

export const getErrorMessageFromErrorObject = (errorObject = {}) =>
  errorObject.response &&
  errorObject.response.data &&
  errorObject.response.data.errorMessage;

export const getStatusTextFromErrorObject = (errorObject = {}) =>
  errorObject.response && errorObject.response.statusText;

export const getTypeFromErrorObject = (errorObject = {}) =>
  errorObject.response &&
  errorObject.response.data &&
  errorObject.response.data.errorType;

export const getParamsFromErrorObject = (errorObject = {}) =>
  errorObject.response &&
  errorObject.response.data &&
  errorObject.response.data.params;

export const getErrorObjectFromBlob = async (error) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      error.response.data = JSON.parse(reader.result);
      resolve(Promise.reject(error));
    };

    reader.onerror = () => {
      reject(error);
    };

    reader.readAsText(error.response.data);
  });
};

export const textRenderer = (text) => {
  if (!text) return '';
  return text;
};

export function on(obj, ...args) {
  obj.addEventListener(...args);
}

export function off(obj, ...args) {
  obj.removeEventListener(...args);
}

export const getTextFromDataByKey = (name, dataToFilter) => {
  if (!name && !dataToFilter) return '';
  const filteredData =
    dataToFilter && dataToFilter.find((item) => item.key === name);
  return filteredData ? filteredData.value : name;
};
export const getLanguageFromDataByKey = (name, dataToFilter) => {
  if (!name && !dataToFilter) return '';
  const filteredData =
    dataToFilter && dataToFilter.find((item) => item.key === name);
  return filteredData ? filteredData.desktop : name;
};
export const getTextFromDataByPeriod = (name, dataToFilter) => {
  if (!name && !dataToFilter) return '';
  const filteredData =
    dataToFilter && dataToFilter.find((item) => item.productKey === name);
  return filteredData ? filteredData.timePeriod : name;
};

export const getTextFromDataByProductKey = (name, dataToFilter) => {
  if (!name && !dataToFilter) return '';
  const filteredData =
    dataToFilter && dataToFilter.find((item) => item.productKey === name);

  return filteredData ? filteredData.productName : name;
};

export const focusonFormsGroup = (elemId) => {
  const elmnt = document.getElementById(`${elemId}`);
  elmnt && elmnt.scrollIntoView({ behavior: 'smooth', block: 'end' });
};

export const creditCardType = (cardNumber) => {
  if (!cardNumber) return;
  const checkCardType = cardNumber.charAt(0);
  return checkCardType;
};

export const replaceEnterSymbol = (text = '') =>
  text
    .replace(/(\r\n|\n|\r)/gm, '<div class="line-break"></div>')
    .replace(/&nbsp;/, '');

export function youtube_parser(url) {
  var regExp =
    /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
  var match = url.match(regExp);
  return match && match[1].length == 11 ? match[1] : false;
}

export const truncateString = (str, truncateDigit) => {
  if (!str) return;
  const truncateVal = truncateDigit ? truncateDigit : 5;
  const start = str.substr(0, truncateVal);
  const end = str.substr(str.length - truncateVal, truncateVal);
  return start + ' ... ' + end;
};

export const getFileAndName = (res) => {
  const [, filename] = res.headers['content-disposition'].split('filename=');
  return { data: res.data, filename };
};

export const downloadBlobFile = (file, filename) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  if (link && link.parentNode) link.parentNode.removeChild(link);
};

export const selectOptionsAddapter = (data) => {
  if (!data) return [];

  return data.map((item) => {
    return {
      label: item.value,
      value: item.key,
    };
  });
};

export const stripHtml = (text) =>
  String(text)
    .replace(/&nbsp;/g, '')
    .replace(/<[^>]+>/g, '')
    .replace(/(\r\n|\n|\r)/gm, ' ');

export const getUrlParams = () => {
  const queryString = window.location.search.substring(1).split(',');
  const params = new URLSearchParams(queryString.join('&'));

  const product = params.get('product');
  const demo = params.get('demo') === 'true';
  const register = params.get('register') === 'true';

  return { product, demo, register };
};
