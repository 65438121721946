import styled from 'styled-components';

export const ConfirmAddCardModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 54px 0px 0px;
  text-align: center;
  @media (max-width: 900px) {
    padding: 14px 0px 0px;
    width: 100%;
  }
`;
export const ConfirmAddCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;
export const ConfirmAddCardModalTitle = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 20px;
    color: #3a3853;
    font-weight: 700;
  }
  .subtitle {
    color: #8a87a5;
    font-weight: 400;
    margin-bottom: 26px;
  }
`;

export const ConfirmAddCardModalForm = styled.div`
  width: 100%;

  .agreement {
    margin-bottom: 47px;
    @media (max-width: 900px) {
      margin-top: 0px;
    }
  }
`;

export const ConfirmAddCardModalButton = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .submit {
    width: 100%;
    height: 50px;
    background: #ffdc04;
    color: #242333;
    :disabled {
      background: rgba(138, 135, 165, 0.3);
    }
  }
`;

export const ConfirmAddCardModalButtons = styled.div`
  width: 100%;
  @media (max-width: 900px) {
  }
  @media (max-height: 700px) {
  }
`;
