import styled from 'styled-components';
import sideBg from 'assets/img/sideBg.svg';

export const TermsHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: url(${sideBg});
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
  background-position: bottom right;
  margin-bottom: 39px;
`;

export const MobileTermsHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 22px 20px 22px;
  .title {
    font-size: 22px;
    font-weight: bold;
    color: #242333;
    margin-bottom: 8px;
  }
  .date {
    font-size: 14px;
    color: #8a87a5;
  }
`;

export const TermsHeaderInner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 14%;
  @media (max-width: 1540px) {
    padding: 0 8%;
  }
  @media (max-width: 900px) {
    padding: 0 22px;
  }
  .title {
    color: #242333;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .date {
    font-weight: normal;
    color: #bab8c7;
  }
`;

export const TermsBody = styled.div`
  display: flex;
  height: 100%;
  padding: 0 14%;
  position: relative;
  @media (max-width: 1540px) {
    padding: 0 8%;
  }
`;
export const MobileTermsFilter = styled.div`
  margin-bottom: 24px;
  .react-select__placeholder {
    margin-top: -4px;
    color: #3a3853 !important;
    font-weight: normal;
    font-size: 14px;
  }
  .errorBorder {
    border-color: #f55e1b;
  }
  .react-select__control {
    border-color: ${({ border }) =>
      border ? '#f55e1b !important' : undefined};
  }

  .react-select__control {
    height: 52px;
    border: 1px solid #dddbed;
    background: transparent;
    width: 100%;
    outline: none !important;
  }
  .react-select__control:hover,
  .react-select__control:focus {
    border-color: #dddbed !important;
    box-shadow: none;
    outline: none;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__value-container {
    padding-left: 26px;
  }
  .react-select__single-value,
  .react-select__input-container {
    color: #3a3853;
    margin-top: 0px;
    font-size: 14px;
    font-weight: normal;
  }

  .react-select__indicator svg {
    width: 18px;
  }
  .react-select__indicator svg path {
    fill: #3a3853;
  }
  .react-select__option--is-focused {
    background-color: #ffdc04;
  }
  .react-select__menu {
    border: none;
    background: #fff;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 33px 76px #83819f48;
    padding: 10px 18px;
  }
  .react-select__option {
    color: #8a87a5;
    padding-left: 27px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 5px;
    font-weight: 500;
  }
  .react-select__option:hover,
  .react-select__option--is-focused {
    background: none;
    background-color: none;
  }

  .react-select__option--is-selected,
  .react-select__option:active {
    background-color: rgba(235, 234, 244, 0.38) !important;
    color: #3a3853;
    font-weight: bold;
  }
`;
export const MobileTermsList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style-type: none;
    font-weight: normal;
  }
`;
export const MobileTermsListItem = styled.div`
  margin-bottom: 32px;
  .title {
    color: #242333;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  .text {
    font-size: 12px;
    color: #8a87a5;
    line-height: 22px;
  }
`;
export const MobileTermsNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const MobileTermsNavTitle = styled.div`
  font-size: 22px;
  color: #1b1d46;
  margin-bottom: 21px;
  font-weight: bold;
  padding-left: 16px;
`;
export const MobileTermsNavList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 270px;
  overflow-y: auto;
  overflow-x: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  .active {
    background: #fdf6e7;
    border-radius: 6px;
    color: #242333;
    font-weight: bold;
  }
`;
export const MobileTermsNavListItem = styled.div`
  color: #7c7ea8;
  font-size: 14px;
  padding: 16px 15px;
`;
export const MobileTermsContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 22px;
  position: relative;
`;

export const ScrollSpyNav = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-right: 30px;
  a {
    width: 100%;
    color: #8a87a5;
    transition: all 0.1s;
    text-decoration: none;
    border-radius: 6px;
    text-align: left;
  }
  .selected {
    background: rgba(253, 246, 231, 0.7);
    border-radius: 6px;
    color: #3a3853;
    font-weight: 700;
  }
  a div {
    display: block;
    padding: 18px 28px !important;
    line-height: normal;
    letter-spacing: normal;
  }
  .activeScrollSpyNavItem {
    background: rgba(253, 246, 231, 0.7);
    color: rgba(58, 56, 83, 1) !important;
    font-weight: bold;
  }
`;
