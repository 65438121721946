import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { login } from 'store/reducers/user/user.actions';
import { Button } from 'library/button/Button';
import {
  LoginWrapper,
  LoginWelcomeText,
  LoginStyled,
  RegisterRowItem,
  RegisterRowFull,
  MobileBg,
  BtnGroupMobile,
} from 'ui/styled/auth.styled';
import { authTypes } from '../Auth';
import { getTextFromDataByKey } from 'utils/helpers';
import FormErrorComponent from 'ui/components/common/FormError';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FormInputBasic from 'ui/shared/formComponents/FormInputBasic';
import { useMediaQuery } from 'react-responsive';

const Login = (props) => {
  const { loginData, getCartData } = props;
  const { setAuthType, setUserName, closeAuthModal, errorTranslatesData } =
    props;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const transactionIdFromStroge = localStorage.getItem('transactionId');
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  const onSubmit = async (data) => {
    setFormError(null);
    localStorage.removeItem('verifyError');
    const params = {
      username: data.email,
      password: data.password,
    };
    setLoading(true);
    setUserName(data.email);
    try {
      const res = await dispatch(login(params));
      await getCartData(res?.token);
      closeAuthModal();
      if (transactionIdFromStroge) {
        navigate(`transactionHistory/${transactionIdFromStroge}`, {
          replace: true,
        });
      }
    } catch (e) {
      setFormError(e);
      if (e?.errorMessage === 'User is not verified') {
        setSearchParams({ resend: 'verifyResend' });
        setAuthType(authTypes.registerVerify);
        localStorage.setItem('authType', authTypes.registerVerify);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setFormError(null);
      localStorage.removeItem('verifyError');
    };
  }, []);

  const verifyError = localStorage.getItem('verifyError');

  return loginData ? (
    <>
      {isTabletOrMobile ? <MobileBg /> : undefined}
      <LoginWrapper>
        <LoginWelcomeText className="headline4" login>
          {getTextFromDataByKey('title', loginData)}
        </LoginWelcomeText>
        {formError ? (
          <FormErrorComponent
            error={formError}
            errorTranslatesData={errorTranslatesData}
          />
        ) : null}
        {verifyError ? (
          <FormErrorComponent
            error={{ errorMessage: verifyError }}
            errorTranslatesData={errorTranslatesData}
          />
        ) : null}
        <LoginStyled>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RegisterRowFull>
              <RegisterRowItem>
                <Controller
                  render={({ field }) => (
                    <FormInputBasic
                      {...field}
                      type="text"
                      placeholder={getTextFromDataByKey('email', loginData)}
                      borderColor={errors.email}
                    />
                  )}
                  rules={{
                    required: 'Is Required',
                  }}
                  control={control}
                  name="email"
                />
              </RegisterRowItem>
            </RegisterRowFull>
            <RegisterRowFull className="lastInLogin">
              <RegisterRowItem>
                <Controller
                  render={({ field }) => (
                    <FormInputBasic
                      {...field}
                      type="password"
                      placeholder={getTextFromDataByKey('password', loginData)}
                      borderColor={errors.password}
                    />
                  )}
                  rules={{
                    required: 'Is Required',
                  }}
                  control={control}
                  name="password"
                />
                <span
                  className="forgetPass"
                  onClick={() => setAuthType(authTypes.forget)}
                >
                  {getTextFromDataByKey('forgot', loginData)}
                </span>
              </RegisterRowItem>
            </RegisterRowFull>
            <BtnGroupMobile>
              <Button loading={loading} className="submit">
                {getTextFromDataByKey('logInBtn', loginData)}
              </Button>
            </BtnGroupMobile>
          </form>
        </LoginStyled>
      </LoginWrapper>
    </>
  ) : null;
};

export default Login;
