import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const ToolTipComponent = (props) => {
  const { title, children, position = 'top' } = props;
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 11px 26px #72718626;',
      padding: '13px 24px',
      borderRadius: 8,
      fontSize: 12,
      color: '#8A87A5',
      width: 180,
      textAlign: 'center',
    },
  }));

  return (
    <LightTooltip title={title} placement={position}>
      {children}
    </LightTooltip>
  );
};

export default ToolTipComponent;
