import React, { useContext, useEffect, useState } from 'react';
import {
  FaqParagraphTitle,
  FaqHeaderSection,
  FaqParagraphText,
  FaqHeaderWithText,
  FaqInputWrapper,
  FaqInputSeperator,
  FaqMobileHead,
} from 'ui/styled/FaqStyled';
import { Input } from 'library/input/Input';
import FaqAccordions from './FaqAccordions';
import ComponentWithLoaderAndError from 'ui/shared/componentWithLoaderAndError/ComponentWithLoaderAndError';
import CustomSelect from 'library/Select/Select';
import { StrapiDataContext } from 'Main';
import { useMediaQuery } from 'react-responsive';
import CustomHead from 'ui/components/customHead/custom-head';

const FAQ = () => {
  const { faqData: data } = useContext(StrapiDataContext);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const FaqPageData = data.data ? data.data.data.attributes : null;
  const notFoundData = data?.data?.data?.attributes?.search?.notFound;
  const [accordiondata, setAccordionData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [allTags, setAllTags] = useState('');

  useEffect(() => {
    if (FaqPageData) {
      setAccordionData(FaqPageData.accordion);
    }
  }, [FaqPageData]);

  const TagNameArray = () => {
    const TagNameArrayNew = FaqPageData?.tagDropDownTranslate.map((i) => {
      return {
        label: i.value,
        value: i.key,
      };
    });

    return TagNameArrayNew;
  };

  const changeTagsHandler = (e) => {
    if (e.value === 'ALL') {
      setAccordionData(FaqPageData.accordion);
      return;
    }
    const result = FaqPageData.accordion.filter((item) => item.tag === e.value);

    setAccordionData(result);
    setAllTags(e);
  };

  useEffect(() => {
    if (TagNameArray()) {
      setAllTags(TagNameArray()[0]);
    }
  }, []);

  return (
    <ComponentWithLoaderAndError data={FaqPageData} error={data.error}>
      <CustomHead seo={data?.data?.data.attributes?.seo} />
      {FaqPageData ? (
        <>
          {isTabletOrMobile ? (
            <>
              <FaqMobileHead>{FaqPageData?.search?.title}</FaqMobileHead>
              <FaqInputWrapper>
                <Input
                  fontSize="14px"
                  search
                  type="text"
                  opacity="0.4"
                  placeholder={FaqPageData?.search?.placeholder}
                  height="44px"
                  padding="15px 0px 15px 0px"
                  border="1px solid #DDDBED"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </FaqInputWrapper>
            </>
          ) : (
            <>
              <FaqHeaderSection>
                <FaqHeaderWithText>
                  <FaqParagraphTitle>
                    {FaqPageData?.search?.title}
                  </FaqParagraphTitle>
                  <FaqParagraphText className="bodyL">
                    {FaqPageData?.search?.subTitle}
                  </FaqParagraphText>
                </FaqHeaderWithText>
              </FaqHeaderSection>

              <FaqInputWrapper>
                <Input
                  className="bodyM"
                  search
                  type="text"
                  opacity="0.4"
                  placeholder={FaqPageData?.search?.placeholder}
                  height="50px"
                  padding="0px 0px 0px 25px"
                  border="1px solid #DDDBED"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <FaqInputSeperator />
                <CustomSelect
                  className="react-select-custom"
                  value={allTags}
                  onChange={changeTagsHandler}
                  placeholder=""
                  options={TagNameArray() || []}
                ></CustomSelect>
              </FaqInputWrapper>
            </>
          )}
          <FaqAccordions
            accordionData={accordiondata}
            searchValue={searchValue}
            notFoundData={notFoundData}
          />
        </>
      ) : null}
    </ComponentWithLoaderAndError>
  );
};

export default FAQ;
