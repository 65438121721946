import styled from 'styled-components';
import sideBg from 'assets/img/sideBg.svg';

export const CartPageStyled = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 46px 14% 100px 14%;
  background: url(${sideBg});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 50%;
  .delete-confirm {
    padding: 100px 104px 80px 104px !important;
  }
  @media (max-width: 1685px) {
    padding: 46px 8% 100px 8%;
  }
  @media (max-width: 1290px) {
    padding: 46px 5% 100px 5%;
  }
  @media (max-width: 900px) {
    position: relative;
    padding: 28px 22px 10px 22px;
    background: none;
  }
`;

export const CartPageItemsBlock = styled.div`
  flex-basis: 63%;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    flex-basis: 100%;
  }
`;
export const CartPageHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 56px;
  margin-bottom: 25px;
`;
export const CartPageTitle = styled.div`
  color: #3a3853;
  font-weight: bold;
`;
export const MobileCartHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  .title {
    color: #242333;
    font-size: 22px;
    font-weight: bold;
    text-align: left;
  }
  .clear {
    text-align: right;
    color: #242333;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
  }
`;
export const CartPageClearCart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #dddbed;
  border-radius: 5px;
  color: #242333;
  font-weight: bold;
  padding: 15px 16px;
  cursor: pointer;
  span {
    padding-left: 10px;
  }
`;
export const CartPageCheckoutBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 35%;
  background: #fff;
  box-shadow: 0px 23px 66px #bfbecc27;
  border-radius: 19px;
  padding: 54px 50px;
  @media (max-width: 900px) {
    padding: 0;
    flex-basis: 100%;
    background: none;
    box-shadow: none;
    border-radius: 0;
  }
`;
export const CartPageProductsList = styled.div`
  @media (max-width: 900px) {
    height: calc(100vh - 278px);
    overflow-y: auto;
    overflow-x: scroll;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
export const CartProductsListByType = styled.div`
  margin-bottom: 30px;
  @media (max-width: 900px) {
    margin-bottom: 34px;
  }
`;
export const CartListHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 56px;
  margin-bottom: 30px;
  @media (max-width: 900px) {
    margin-bottom: 14px;
  }
`;
export const CartListTypePayment = styled.div`
  color: #b9b7c9;
  display: flex;
  align-items: center;
  .info {
    padding-left: 11px;
    cursor: pointer;
  }
`;
export const SeparatorBorder = styled.div`
  background: #eceaf7;
  height: 1px;
  margin: 15px 60px 0 21px;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const CartListTypeTitle = styled.div`
  color: #8a87a5;
`;
export const CartListProducts = styled.div`
  margin-left: -21px;
  .increment {
    right: 0px;
    top: -2px;
    font-size: 18px;
  }
  input {
    color: #3a3853;
  }
  .decrement {
    font-size: 23px;
    padding-left: 13px;
    top: -2px;
  }
  .productName {
    width: 221px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  table {
    width: 100%;
    border: none;
    font-weight: 'Ubuntu', sans-serif;
  }
  tr,
  td,
  th,
  tr {
    border: none;
  }
  .pad-5 {
    padding-right: 5px;
  }
  .delete {
    position: absolute;
    right: 26px;
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    transition: all 0.3s;
  }
  .MuiTableCell-head,
  .MuiTableCell-root {
    padding-left: 0;
    font-family: 'Ubuntu', sans-serif;
    letter-spacing: 0 !important;
    line-height: normal;
  }
  .MuiTableCell-head {
    color: #b9b7c9 !important;
  }
  .MuiTableCell-root {
    color: #242333;
    font-weight: 500;
    padding: 18px 60px 18px 21px;
  }
  .MuiTableRow-root {
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
    :hover {
      box-shadow: 0px 13px 66px #bfbecc45;
      border-radius: 8px;
      .delete {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .MuiTableRow-head {
    :hover {
      border-radius: 0;
      box-shadow: none;
    }
  }
  @media (max-width: 900px) {
    margin-left: 0;
  }
`;

export const CheckoutFormStyled = styled.div`
  @media (max-width: 900px) {
    .CheckoutFormSubmit {
      padding: 0 34px;
      position: absolute;
      bottom: 40px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
`;

export const CheckoutFormTitle = styled.div`
  font-weight: bold;
  margin-bottom: 30px;
`;
export const CheckoutFormGroup = styled.div`
  margin-bottom: 18px;
  position: relative;
  .react-select__placeholder {
    margin-top: -4px;
    color: #d6d4de !important;
  }
  .errorBorder {
    border-color: #f55e1b;
  }
  .react-select__control {
    border-color: ${({ border }) =>
      border ? '#f55e1b !important' : undefined};
  }
  input {
    width: 100%;
    height: 50px;
    border: 1px solid #dddbed;
    background: transparent;
    padding-left: 28px;
    color: #3a3853;
    border-radius: 5px;
    transition: all 0.3s;
    cursor: default;
    :focus {
      border: 1px solid #3a3853;
    }
    ::placeholder {
      color: #d6d4de;
    }
  }

  .react-select__control {
    height: 58px;
    border: 1px solid #dddbed;
    background: transparent;
    width: 100%;
    outline: none !important;
  }
  .react-select__control:hover,
  .react-select__control:focus {
    border-color: #dddbed !important;
    box-shadow: none;
    outline: none;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__value-container {
    padding-left: 28px;
  }
  .react-select__single-value,
  .react-select__input-container {
    color: #3a3853;
    margin-top: -5px;
  }

  .react-select__indicator svg {
    width: 18px;
  }
  .react-select__indicator svg path {
    fill: #3a3853;
  }
  .react-select__option--is-focused {
    background-color: #ffdc04;
  }
  .react-select__menu {
    border: none;
    background: #fff;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 33px 76px #83819f48;
    padding: 10px 18px;
  }
  .react-select__option {
    color: #8a87a5;
    padding-left: 27px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 5px;
    font-weight: 500;
  }
  .react-select__option:hover,
  .react-select__option--is-focused {
    background: none;
    background-color: none;
  }

  .react-select__option--is-selected,
  .react-select__option:active {
    background-color: rgba(235, 234, 244, 0.38) !important;
    color: #3a3853;
    font-weight: bold;
  }
  @media (max-width: 900px) {
    input {
      height: 52px;
    }
    .react-select__control {
      height: 52px;
    }
  }
`;
export const CheckoputFormTotals = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  .total {
    margin-top: 22px;
    div {
      color: #242333;
      font-weight: 500;
    }
    @media (max-width: 900px) {
      margin-top: 10px;
    }
  }
`;
export const CheckoutFormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  @media (max-width: 900px) {
    margin-bottom: 8px;
  }
`;
export const FormRowLeft = styled.div`
  color: #8a87a5;
  max-width: 70%;
  line-height: normal;
`;
export const FormRowRight = styled.div`
  color: #8a87a5;
  font-weight: 500;
`;
export const CheckoutFormCardBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 68px;
  border: 1px solid #dddbed;
  margin-bottom: 18px;
  border-radius: 5px;
  padding: 10px 15px 10px 20px;
  height: 66px;
`;
export const SavedCard = styled.div`
  display: flex;
`;
export const ChangeCard = styled.div`
  align-self: end;
  padding-bottom: 9px;
  cursor: pointer;
  color: #3a3853;
  font-weight: bold;
  text-decoration: underline;
`;
export const CheckoutFormSubmit = styled.div`
  width: 100%;
  position: relative;
  button {
    width: 100%;
    padding: 14px 0;
    border-radius: 5px;
    background: #ffdc04;
    color: #242333;
    font-weight: 500;
  }
  @media (max-width: 900px) {
    button {
      font-size: 14px;
      font-weight: 500;
      height: 52px;
    }
    margin-top: auto;
  }
`;
export const EmptyCartWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10%;
`;
export const EmptyCartIcon = styled.div`
  margin-bottom: 32px;
`;
export const EmptyCartTitle = styled.div`
  color: #3a3853;
  font-weight: 500;
  margin-bottom: 14px;
`;
export const EmptyCartSubTitle = styled.div`
  color: #8a87a5;
  line-height: 26px;
  max-width: 400px;
`;

export const ConfirmDeleteModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => (props.confirmBuy ? '27px 0 0 0' : '54px 0 0 0')};
  text-align: center;
  @media (max-width: 900px) {
    padding: 14px 0 0 0;
    .delete {
      max-width: 211px;
      margin: auto;
    }
  }
`;
export const ConfirmDeleteModalIcon = styled.div`
  margin-bottom: 14px;
  img {
    width: 35px;
    height: 37px;
  }
  @media (max-width: 900px) {
    img {
      width: 27px;
      height: 27px;
    }
  }
`;
export const ConfirmPayModalIcon = styled.div`
  margin-bottom: 30px;
`;
export const ConfirmDeleteModalTitle = styled.div`
  font-weight: bold;
  color: #3a3853;
  margin-bottom: 12px;
  @media (max-width: 900px) {
    margin-bottom: 0;
    &.error {
      margin-bottom: 18px;
    }
  }
`;
export const ConfirmDeleteModalSubTitle = styled.div`
  line-height: 24px;
  color: #8a87a5;
  margin-bottom: 40px;
  font-weight: 400;
  p {
    margin: 0px;
  }
  @media (max-width: 900px) {
    margin-bottom: 20px !important;
  }
`;
export const ConfirmDeleteModalButtons = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  margin-bottom: 35px;
  width: 100%;
  .delete {
    border-radius: 5px;
    border: none;
    outline: none;
    background: #ffdc04;
    color: #242333;
    padding: 17px 12px;
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .close {
    text-decoration: underline;
    color: #3a3853;
    cursor: pointer;
  }
  @media (max-width: 900px) {
    margin-bottom: 0;
    .delete {
      height: 52px;
      font-size: 14px;
      padding: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const CartTableHeader = styled.div`
  display: flex;
  color: #b9b7c9;
  margin-bottom: 15px;
`;
export const CartHeaderName = styled.div`
  flex-basis: 35%;
  text-align: left;
`;
export const CartHeaderQyt = styled.div`
  flex-basis: 20%;
  text-align: center;
`;
export const CartHeaderPrice = styled.div`
  flex-basis: 20%;
  text-align: right;
`;

export const CartHeaderTotal = styled.div`
  flex-basis: 25%;
  padding-right: 60px;
  display: flex;
  justify-content: flex-end;
  text-align: right;
`;
export const MobileCartTotalOverlay = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 99;
  min-height: 96px;
  background: #fff;
  box-shadow: 0px -7px 20px #c6c7d51f;
  width: 100%;
  left: 0;
  right: 0;
  padding: 22px 28px 12px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const MobileCartOverlayTotals = styled.div`
  display: flex;
  flex-direction: column;
  .price {
    font-size: 18px;
    font-weight: bold;
    color: #242333;
    margin-bottom: 2px;
  }
  .subTotal {
    color: #bab8c7;
    font-size: 12px;
  }
`;
export const MobileCartOverlayNextBtn = styled.div`
  display: flex;
  button {
    background: #ffdc04;
    border-radius: 4px;
    color: #242333;
    min-width: 160px;
    padding: 0 20px;
    height: 48px;
    font-weight: 500;
  }
`;
export const MobileCartRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
`;
export const MobileCartRowBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  align-items: center;
  .priceTotal {
    margin-bottom: 2px;
    text-align: right;
  }
  .titleSpan {
    font-size: 14px;
    color: #8a87a5;
    padding-right: 3px;
    text-align: right;
  }
  .priceSpan {
    color: #242333;
    font-size: 14px;
    font-weight: bold;
    text-align: right;
  }
  .priceEach {
    text-align: right;
    .priceSpan {
      font-size: 14px;
      color: #bab8c7;
      font-weight: normal;
    }
  }
`;
export const MobileCartRowHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f7f7fb;
  border-radius: 4px;
  min-height: 37px;
  padding: 11px 13px;
  margin-bottom: 14px;
  .title {
    max-width: 80%;
    font-size: 14px;
    font-weight: 500;
    color: #242333;
    text-align: left;
  }
  .actionButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .delete {
      visibility: visible;
      opacity: 1;
      position: static;
    }
  }
`;

export const CartBodyRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
  transition: all 0.3s;
  :hover {
    box-shadow: 0px 13px 66px #bfbecc45;
    border-radius: 8px;
    .delete {
      visibility: visible;
      opacity: 1;
    }
  }
`;
export const CartRowName = styled.div`
  flex-basis: 35%;
  padding-left: 21px;
  font-weight: 500;
  display: flex;
  align-items: center;
  span {
    display: inline-block;
    max-width: 310px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .info {
    padding-left: 12px;
  }
`;
export const CartRowQyt = styled.div`
  flex-basis: 20%;
  display: flex;
  justify-content: center;
  padding-left: 20px;
`;
export const CartRowPrice = styled.div`
  flex-basis: 20%;
  text-align: right;
  font-weight: 500;
`;
export const CartRowTotal = styled.div`
  flex-basis: 25%;
  display: flex;
  text-align: right;
  font-weight: 500;
  justify-content: flex-end;
  padding-right: 60px;
`;

export const SaveToPersonalInfoWrapper = styled.div`
  border-bottom: 1px solid #eceaf7;
  padding-bottom: 20px;
  margin-top: 25px;
`;

export const NoCardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  a {
    display: flex;
    width: 100%;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
`;
export const AddCardIcon = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
`;

export const AddCardText = styled.div`
  color: #bab8c7;
  .title {
    margin-bottom: 5px;
  }
  .add {
    text-decoration: underline;
    color: #3a3853;
    font-weight: 500;
    cursor: pointer;
  }
`;

export const ErrorAndSuccessModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 50px;
  text-align: center;
  @media (max-width: 900px) {
    padding-top: 23px;
  }
  @media (max-width: 600px) {
    padding-top: 0px;
  }
`;
export const ErrorAndSuccessModalIcon = styled.div`
  margin-bottom: 15px;
  @media (max-width: 900px) {
    margin-bottom: 21px;
    img {
      height: 64px;
    }
  }
`;
export const ErrorAndSuccessModalTitle = styled.div`
  color: #3a3853;
  font-weight: bold;
  margin-bottom: 10px;
  @media (max-width: 900px) {
    margin-bottom: 13px;
  }
`;
export const ErrorAndSuccessModalSubTitle = styled.div`
  color: #8a87a5;
  line-height: 34px;
`;

export const SavedCardIcon = styled.div`
  display: flex;
  margin-right: 18px;
`;
export const SavedCardMask = styled.div`
  color: #3a3853;
  font-weight: bold;
  margin-bottom: 4px;
`;
export const SavedCardTitle = styled.div`
  color: #bab8c7;
`;
export const SavedCartInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TrainingsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const TrainingsStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

export const TrainingsModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  .title {
    margin-bottom: 8px;
    span {
      padding: 0 3px;
      :first-child {
        padding-left: 0;
      }
    }
  }
  .subtitle {
    color: #8a87a5;
    span {
      color: #3a3853;
      font-weight: bold;
    }
  }
`;
export const TrainingModalFormGroup = styled.div`
  margin-bottom: 18px;
  position: relative;
  input {
    border: 1px solid #dddbed;
    color: #3a3853;
    outline: none;
    height: 50px;
    width: 100%;
    border-radius: 5px;
    padding-left: 20px;
    transition: all 0.3s;
    :focus {
      border-color: #3a3853;
    }
  }
  .errorBorder {
    border-color: #f55e1b;
  }
`;
export const TrainingModalForm = styled.div`
  .view {
    overflow-x: hidden !important;
    padding-right: 14px;
  }
`;
export const TrainingsButton = styled.div`
  width: 100%;
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .submit {
    width: 100%;
    height: 50px;
    background: #ffdc04;
    color: #242333;
    :disabled {
      background: rgba(138, 135, 165, 0.3);
    }
  }
  .back {
    background: none;
    text-decoration: underline;
    color: #242333;
    margin-top: 20px;
  }
`;
