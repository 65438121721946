import styled from 'styled-components';
import sideBg from 'assets/img/sideBg.svg';

export const AdminPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;

  .MuiTableRow-root:last-child td,
  .MuiTableRow-root:last-child th {
    border: 0px solid !important;
  }
  .MuiPaper-root {
    box-shadow: none;
  }
  .MuiTableHead-root {
    background-color: rgba(235, 234, 244, 0.4);
  }
  .MuiTableCell-head {
    color: #bab8c7;
    font-size: 12px;
    font-weight: 400;
    border-bottom: none !important;
    font-family: ${({ lang }) =>
      lang === 'ka'
        ? 'Helvetica Neue LT GEO !important'
        : `'Ubuntu', sans-serif`};
  }
  .MuiTableCell-root {
    padding: 14px;
    border-bottom: 1px solid #f0eff7;
    font-family: ${({ lang }) =>
      lang === 'ka'
        ? 'Helvetica Neue LT GEO !important'
        : `'Ubuntu', sans-serif`};
  }
  .MuiTableCell-body {
    color: #3a3853;
    font-size: 16px;
    font-weight: 400;
  }
  .MuiTableBody-root .MuiTableRow-root {
    transition: all 0.3s;
    border: none;
    :hover {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 13px 50px #bfbecc45;
      border-radius: 6px;
      border: none;
      cursor: pointer;
    }
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        :first-child {
          /* padding-left: 0px; */
          /* display: flex; */
          height: 74px;
          align-items: center;
          margin-left: 30px;
        }
        :last-child {
          /* padding-right: 0px; */
          /* display: flex; */
          height: 74px;
          align-items: center;
          margin-right: 30px;
        }
      }
    }
  }

  table thead tr th:first-child {
    border-radius: 6px 0px 0px 6px;
    padding-left: 30px;
  }
  table thead tr th:last-child {
    border-radius: 0px 6px 6px 0px;
    padding-right: 30px;
  }
  .greenDot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 5px;
    background-color: #2cc678;
  }
  .redDot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-left: 5px;
    background-color: #f55e1b;
  }
  .centredFlex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .userInfo {
    display: flex;
    flex-direction: column;

    .username {
      color: #3a3853;
      font-weight: 500;
    }
    .username-elipsis {
      @media (max-width: 1940px) {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
        max-width: 85px;
      }
    }
    .type {
      color: #8a87a5;
      font-size: 14px;
    }
    .underline {
      text-decoration: underline;
      font-weight: 500;
    }
  }
  .adminTablesPagination {
    padding: 80px 0 0 0;
    margin: 0;
  }
`;

export const TransactionsListWrapper = styled.div`
  .MuiPaper-root {
    min-height: 422px;
  }
  .MuiTableHead-root {
    background: transparent !important;
  }
  .price {
    font-weight: 500;
  }
  @media (max-width: 1540px) {
    .prices {
      min-width: 75px;
    }
  }
`;

export const AdminPanelBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 14%;
  margin-top: -130px;
  .react-tabs {
    .react-tabs {
      display: flex;
      flex-direction: column;
    }
    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(58, 56, 83, 0.2);
      display: flex;
      gap: 60px;
    }
    .react-tabs__tab {
      display: flex;
      border: none;
      color: #8a87a5;
      padding: 0px;
    }
    #react-tabs-2 {
      margin-right: 0px !important;
    }
    .react-tabs__tab:after {
      background: transparent;
    }
    .react-tabs__tab:focus {
      color: #3a3853;
      border-radius: 0;
      box-shadow: none;
      border-color: #ffdc04;
    }
    .react-tabs__tab--selected {
      border-bottom: 3px solid #ffdc04;
      color: #3a3853;
      border-radius: 0;
      box-shadow: none;
      border-color: #ffdc04;
      outline: none;
      padding-bottom: 33px;
    }
    .react-tabs__tab-panel--selected {
      width: 100%;
    }
  }
`;

export const TabListHeaderAdmin = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
`;
export const AdminTabsAndInputWrapper = styled.div`
  display: flex;
  svg {
    height: 15px;
    width: 15px;
  }
  input {
    border: 1px solid #dddbed;
    border-radius: 5px;
    cursor: text;
    color: #3a3853;
    background: #fff;
    :focus {
      ::-webkit-input-placeholder {
        color: white;
      }
    }
    ::placeholder {
      color: #dddbed;
    }
  }
`;

export const InputWrapperAdmin = styled.div`
  input {
    height: 50px;
    padding-left: 56px;
    :focus {
      border-color: #dddbed;
    }
  }
  margin-right: 14px;
  label {
    div {
      top: 16px;
    }
  }
`;
export const AdminFilterButton = styled.div`
  button {
    padding: 0 34px;
    font-size: 16px;
    font-weight: 500;
    color: #242333;
  }
  margin-right: 14px;
`;
export const AdminExportButton = styled.div`
  button {
    padding: 0 34px;
    font-size: 16px;
    font-weight: 500;
    color: #242333;
  }
  margin-right: 14px;
`;

export const AdminHeaderTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: bold;
  color: #3a3853;
  padding: 0px 0px 0px 15%;
  background: url(${sideBg});
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  background-position: bottom right;
  background-size: 65%;
  span {
    padding-left: 8px;
    margin-right: auto;
    color: #bab8c7;
    font-size: 30px;
    font-weight: 500;
  }
`;

export const MoreDetailsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 35px;
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  .left {
    color: #8a87a5;
    font-size: 14px;
  }
  .right {
    color: #3a3853;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const TransactionsFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  .react-select__clear-indicator {
    cursor: pointer;
    padding: 0;
    margin-right: -5px;
  }
`;
export const TransactionsExportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 35px;
`;
export const ExportCheckbox = styled.div`
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  div {
    :first-child {
      width: 100%;
    }
  }
  .react-multi-select__placeholder {
    margin-top: 0px;
    font-size: 14px;
    color: #d6d4de !important;
  }
  .errorBorder {
    border-color: #f55e1b;
  }
  .react-multi-select__control {
    border-color: ${({ border }) =>
      border ? '#f55e1b !important' : undefined};
  }
  input {
    width: 100%;
    height: 50px;
    border: 1px solid #dddbed;
    background: transparent;
    padding-left: 28px;
    color: #3a3853;
    border-radius: 5px;
    transition: all 0.3s;
    cursor: default;
    :focus {
      border: 1px solid #3a3853;
    }
    ::placeholder {
      color: #d6d4de;
    }
  }
  .react-multi-select__control {
    min-height: 50px;
  }
  background: transparent;
  width: 100%;
  outline: none !important;

  .react-multi-select__control:hover {
    border-color: #dddbed !important;
  }
  .react-multi-select__control:focus {
    border-color: #dddbed !important;
    border-radius: 4px !important;
    box-shadow: none;
    outline: none;
    -webkit-box-align: center;
    align-items: center;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 200px;
    position: relative;
    box-sizing: border-box;
    outline: none;
  }
  .react-multi-select__indicator-separator {
    display: none;
  }
  .react-multi-select__value-container {
    padding-left: 27px;
  }
  .react-multi-select__value-container--has-value {
    flex-wrap: nowrap;
    padding: 0px;
  }
  .react-multi-select__single-value,
  .react-multi-select__input-container {
    color: #3a3853;
    font-size: 14px;
    margin-top: -5px;
  }

  .react-multi-select__indicator svg {
    width: 18px;
  }
  .react-multi-select__indicator svg path {
    fill: #3a3853;
  }
  .react-multi-select__option--is-focused {
    background-color: #ffdc04;
  }
  .react-multi-select__menu {
    border: none;
    background: #fff;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 33px 76px #83819f48;
    padding: 10px 8px;
  }
  .react-multi-select__option {
    color: #8a87a5;
    padding-left: 27px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
  }
  .react-multi-select__option:hover,
  .react-multi-select__option--is-focused {
    background: none;
    background-color: none;
  }

  .react-multi-select__option--is-selected,
  .react-multi-select__option:active {
    background-color: rgba(235, 234, 244, 0.38) !important;
    color: #3a3853;
    font-weight: bold;
  }
  .react-multi-select__multi-value__label {
    border-radius: 2px;
    color: #3a3853;
    font-size: 14px;
    overflow: hidden;
    padding: 0px;
    padding-left: 6px;
  }
  .react-multi-select__multi-value {
    background-color: #fff;
    padding-left: 0px;
    margin: 5px;
    min-width: 90px;
  }

  .react-multi-select__multi-value__remove {
    color: #8a87a5;
    :hover {
      background-color: #fff;
      color: #8a87a5;
    }
  }
`;
export const ExportInputGroup = styled.div`
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  div {
    :first-child {
      width: 100%;
    }
  }
  .react-select__indicators {
    height: 50px;
  }
  .react-select__placeholder {
    margin-top: -4px;
    font-size: 14px;
    color: #d6d4de !important;
  }
  .errorBorder {
    border-color: #f55e1b;
  }
  .react-select__control {
    border-color: ${({ border }) =>
      border ? '#f55e1b !important' : undefined};
  }
  input {
    width: 100%;
    height: 50px;
    border: 1px solid #dddbed;
    background: transparent;
    padding-left: 28px;
    color: #3a3853;
    border-radius: 5px;
    transition: all 0.3s;
    cursor: default;
    :focus {
      border: 1px solid #3a3853;
    }
    ::placeholder {
      color: #d6d4de;
    }
  }

  .react-select__control {
    height: 50px;
    border: 1px solid #dddbed;
    background: transparent;
    width: 100%;
    outline: none !important;
  }
  .react-select__control:hover,
  .react-select__control:focus {
    border-color: #dddbed !important;
    box-shadow: none;
    outline: none;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__value-container {
    padding-left: 28px;
  }
  .react-select__single-value,
  .react-select__input-container {
    color: #3a3853;
    font-size: 14px;
    margin-top: -5px;
  }

  .react-select__indicator svg {
    width: 18px;
  }
  .react-select__indicator svg path {
    fill: #3a3853;
  }
  .react-select__option--is-focused {
    background-color: #ffdc04;
  }
  .react-select__menu {
    border: none;
    background: #fff;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 33px 76px #83819f48;
    padding: 10px 18px;
  }
  .react-select__option {
    color: #8a87a5;
    padding-left: 27px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
  }
  .react-select__option:hover,
  .react-select__option--is-focused {
    background: none;
    background-color: none;
  }

  .react-select__option--is-selected,
  .react-select__option:active {
    background-color: rgba(235, 234, 244, 0.38) !important;
    color: #3a3853;
    font-weight: bold;
  }
`;
export const ExportInputGroupDate = styled.div`
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  .react-datepicker {
    border-color: #dddbed;
    border-radius: 10px;
    box-shadow: 0 0 13px #00000021;
  }
  .react-datepicker__month-container {
    font-family: 'Ubuntu', sans-serif;
  }
  .react-datepicker__header {
    background-color: #fff8cc;
    color: #242333;
    border-color: #dddbed;
  }
  .react-select__placeholder {
    margin-top: -4px;
    font-size: 14px;
    color: #d6d4de !important;
  }
  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: #242333;
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: '';
    display: block;
    height: 6px;
    position: absolute;
    top: 9px;
    width: 6px;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background-color: #ffdc04;
    color: #fff;
  }
  .errorBorder {
    border-color: #f55e1b;
  }
  .react-select__control {
    border-color: ${({ border }) =>
      border ? '#f55e1b !important' : undefined};
  }
  .react-select__indicators {
    height: 50px;
  }
  input {
    width: 100%;
    height: 50px;
    border: 1px solid #dddbed;
    background: transparent;
    padding-left: 28px;
    color: #3a3853;
    border-radius: 5px;
    transition: all 0.3s;
    cursor: default;
    :focus {
      border: 1px solid #3a3853;
    }
    ::placeholder {
      color: #d6d4de;
    }
  }

  .react-select__control {
    height: 50px;
    border: 1px solid #dddbed;
    background: transparent;
    width: 100%;
    outline: none !important;
  }
  .react-select__control:hover,
  .react-select__control:focus {
    border-color: #dddbed !important;
    box-shadow: none;
    outline: none;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__value-container {
    padding-left: 28px;
  }
  .react-select__single-value,
  .react-select__input-container {
    color: #3a3853;
    font-size: 14px;
    margin-top: -5px;
  }

  .react-select__indicator svg {
    width: 18px;
  }
  .react-select__indicator svg path {
    fill: #3a3853;
  }
  .react-select__option--is-focused {
    background-color: #ffdc04;
  }
  .react-select__menu {
    border: none;
    background: #fff;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 33px 76px #83819f48;
    padding: 10px 18px;
  }
  .react-select__option {
    color: #8a87a5;
    padding-left: 27px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
  }
  .react-select__option:hover,
  .react-select__option--is-focused {
    background: none;
    background-color: none;
  }

  .react-select__option--is-selected,
  .react-select__option:active {
    background-color: rgba(235, 234, 244, 0.38) !important;
    color: #3a3853;
    font-weight: bold;
  }
`;

export const ExportButton = styled.div`
  position: relative;
  padding-top: 20px;
  svg {
    width: 18px;
  }
  .submit {
    border: none;
    background: #ffdc04;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    color: #242333;
    outline: none;
    cursor: pointer;
    font-weight: bold;
  }
`;
export const TransactionListRowHalf = styled.div`
  position: relative;
  flex-basis: 49%;
`;
export const CheckBoxGroup = styled.div`
  margin-bottom: 18px;
  .CheckboxLabelStyled {
    font-size: 16px;
    color: #3a3853;
    font-weight: 400;
    color: #8a87a5;
  }
`;

export const UserTypeCheck = styled.div`
  flex-basis: 49%;
  cursor: pointer;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #3a3853;
  border-radius: 4px;
  background-color: ${({ active }) => (active ? '#FFF8CC' : '#f0eff7')};
  svg {
    margin-right: 6px;
  }
`;
export const CustomerId = styled.div`
  font-size: 14px;
  color: #8a87a5;
  margin-bottom: 28px;
`;
export const SaveButton = styled.button`
  text-decoration: underline;
  font-size: 14px;
  color: #3a3853;
  font-weight: 500;
  position: absolute;
  cursor: pointer;
  right: 28px;
  top: 19px;
  border: none;
  background: none;
  outline: none;
  svg {
    margin-top: -2px;
  }
`;
export const UserUrlInput = styled.div`
  width: 100%;
  position: relative;
  margin-top: 35px;
  input {
    height: 54px;
    font-size: 14px;
    border-color: #dddbed;
  }
  .input-label {
    font-size: 14px;
  }
`;
