import React, { useContext } from 'react';
import {
  HardwareImgSection,
  HardwareParagraphTitle,
  HardwareSectionsWrapper,
} from 'ui/styled/hardware.styled';

import HardwarePlans from '../Hardware/hardwarePlans';
import {
  LeftSectionServices,
  RightSectionServices,
} from 'ui/components/common/layout';
import { ImagesRenderer } from 'utils/imagesRenderer';
import ComponentWithLoaderAndError from 'ui/shared/componentWithLoaderAndError/ComponentWithLoaderAndError';
import { StrapiDataContext } from 'Main';
import { getTextFromDataByKey } from 'utils/helpers';
import { ServicesPageWrapper } from 'library/table/TableWrapperStyled';
import CustomHead from 'ui/components/customHead/custom-head';

const Hardware = (props) => {
  const {
    cartData,
    getCartData,
    setIsCartOpen,
    setIsOpen: setAuthModalOpen,
  } = props;

  const {
    hardwareData: data,
    settingsData,
    hardwarePage,
  } = useContext(StrapiDataContext);

  const hardwareData = data.data ? data.data.data : null;

  return (
    <ComponentWithLoaderAndError data={hardwareData} error={data.error}>
      <CustomHead seo={hardwarePage?.data?.data?.attributes?.seo} />
      <HardwareParagraphTitle>
        {getTextFromDataByKey(
          'hardware',
          settingsData?.data?.data?.attributes?.globalTranslate,
        )}
      </HardwareParagraphTitle>
      <ServicesPageWrapper>
        {hardwareData &&
          hardwareData.map((item, index) => {
            return (
              <HardwareSectionsWrapper key={item.id + index}>
                <LeftSectionServices isEven={(index + 1) % 2 == 0}>
                  <HardwareImgSection isEven={(index + 1) % 2 == 0}>
                    <ImagesRenderer
                      url={item?.attributes?.img?.data?.attributes?.url}
                      alt={
                        item?.attributes?.img?.data?.attributes?.alternativeText
                      }
                    />
                  </HardwareImgSection>
                </LeftSectionServices>

                <RightSectionServices hardware>
                  <HardwarePlans
                    servicesPlanPrices=""
                    servicesPlanTitle={item?.attributes?.name}
                    servicesPlanParagraph={item?.attributes?.description}
                    buttonText={item?.attributes?.button}
                    productKey={item?.attributes?.prices}
                    cartData={cartData}
                    getCartData={getCartData}
                    setIsCartOpen={setIsCartOpen}
                    setAuthModalOpen={setAuthModalOpen}
                  />
                </RightSectionServices>
              </HardwareSectionsWrapper>
            );
          })}
      </ServicesPageWrapper>
    </ComponentWithLoaderAndError>
  );
};

export default Hardware;
