import styled from 'styled-components';

export const TopParagraphWithInputStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ParagraphStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeadingStyled = styled.div`
  color: #242333;
  line-height: 54px;
  font-weight: 700;
  width: 80%;
  margin-bottom: 20px;
  align-items: center;
  @media (max-width: 900px) {
    line-height: 30px;
    font-size: 22px !important;
    margin-bottom: 14px;
    width: 95%;
  }
`;

export const TextStyled = styled.div`
  color: #8a87a5;
  margin-bottom: 100px;
  font-weight: 400;
  line-height: 34px;
  max-width: 85%;
  p {
    margin: 0;
  }
  @media (max-width: 1536px) {
    margin-bottom: 50px;
  }
  @media (max-width: 900px) {
    line-height: 22px;
    max-width: 95%;
    margin-bottom: 20px;
    p {
      margin: 0;
    }
  }
`;

export const ButtonWrapper = styled.div`
  button {
    height: 50px;
  }
  @media (max-width: 900px) {
    width: 100%;
    button {
      height: 52px;
      font-size: 14px;
      width: 100%;
    }
  }
`;

export const SearchFormWrapper = styled.div`
  display: flex;
  .videoBtn {
    padding: 24px 19px 23px 22px;
    background: rgba(183, 179, 155, 0.14);
    border-radius: 5px;
    margin-left: 25px;
    cursor: pointer;
  }
`;
