import React from 'react';
export const PlayIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="87" height="87" viewBox="0 0 87 87">
		<g id="play" transform="translate(-946 -366)">
			<circle id="Ellipse_1"
				data-name="Ellipse 1" cx="43.5" cy="43.5" r="43.5" transform="translate(946 366)" fill="#242333" />
			<path id="Polygon_1"
				data-name="Polygon 1"
				d="M6.674,1.212a1,1,0,0,1,1.652,0l5.608,8.225A1,1,0,0,1,13.108,11H1.892a1,1,0,0,1-.826-1.563Z"
				transform="translate(997 402) rotate(90)" fill="#fff" />
		</g>
	</svg>

);