import React from 'react';

export const AddIconBlog = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="41"
    viewBox="0 0 42 41"
    {...rest}
  >
    <g id="Show" transform="translate(-285 -3617)">
      <rect
        id="Rectangle_48"
        data-name="Rectangle 48"
        width="42"
        height="41"
        rx="20.5"
        transform="translate(285 3617)"
        fill="#c3bfde"
        fill-opacity="0.16"
      />
      <g id="Group_122" data-name="Group 122" transform="translate(1 3196)">
        <line
          id="Line_18"
          data-name="Line 18"
          x2="13"
          transform="translate(298.5 441.5)"
          fill="none"
          stroke="#3a3853"
          stroke-width="2.4"
        />
        <path
          id="Path_84"
          data-name="Path 84"
          d="M0,0V13"
          transform="translate(305 435)"
          fill="none"
          stroke="#3a3853"
          stroke-width="2.4"
        />
      </g>
    </g>
  </svg>
);
