import Input from 'library/input/Input';
import React from 'react';
import { FormInputErrorWrapper } from 'ui/styled/layout';

const FormInputBasic = (props) => {
  const errorMessageHandler = (error) => {
    if (!error) return;
    const fieldError = error.message;
    return <div>{fieldError}</div>;
  };

  return (
    <>
      <Input {...props} type={props.type} />
      {props.borderColor ? (
        <FormInputErrorWrapper className="bodyM">
          {/* {errorMessageHandler(props.borderColor)} */}
        </FormInputErrorWrapper>
      ) : null}
    </>
  );
};

export default FormInputBasic;
