import React, { useContext, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import api from 'services/api';
import Pagination from 'library/Pagination/Pagination';
import formatDate, { DateFormats } from 'utils/date';
import { TransactionsListWrapper } from 'ui/styled/admin.styled';
import Modal from 'library/modal/Modal';
import {
  getTextFromDataByProductKey,
  getTextFromDataByPeriod,
  getTextFromDataByKey,
} from 'utils/helpers';
import {
  ItemHead,
  ItemIdAndDate,
  ItemPrice,
  TransactionsItemBody,
  ItemTitle,
  ItemRows,
  ItemLeftColumn,
  MobileTransactionsHistory,
  MobileTransactionsHistoryItem,
  TransactionsTableWrapper,
  ItemRightColumn,
} from 'library/table/TableWrapperStyled';
import { useMediaQuery } from 'react-responsive';
import {
  SeperatorLine,
  TableWrapperTransactions,
  TransactionTableColoredHeader,
} from 'ui/styled/transactionsTable.styled';
import { TableCheckIcon } from 'assets/svgIcons/TableCheckIcon';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import { StrapiDataContext } from 'Main';
import TransactionHistoryFilter from './TransactionHistoryFilter';
import EmptyTransactionsInfo from 'ui/components/common/EmptyTransactionsInfo';
import TableLoading from 'ui/components/common/TableLoading';

const TransactionsHistoryList = (props) => {
  const {
    translatesData,
    isOpenTransactionsFilter,
    setIsOpenTransactionsFilter,
    pagination,
    setPagination,
  } = props;

  const translateKeys = translatesData?.data.attributes.enumTranslate;

  const [paymentData, setPaymentData] = useState([]);
  const [transactionHistoryFilters, setTransactionHistoryFilters] =
    useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [headerColumnsData, setHeaderColumnsData] = useState([]);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const contextData = useSelector((state) => state.app.language, _.isEqual);
  const { transactionHistoryData: data } = useContext(StrapiDataContext);

  const NotFoundData = data?.data?.data?.attributes?.search?.notFound;
  const [paymentDataError, setPaymentDataError] = useState(false);

  const TransactionHistoryTranslates =
    data?.data?.data?.attributes?.transactionTable?.translate;

  const getTransactionList = async (values, isFilter) => {
    setLoading(true);
    if (isFilter) {
      setPagination(() => {
        return {
          offset: 0,
          limit: 5,
          count: 0,
          call: false,
        };
      });
    }
    const params = {
      fromDate: values?.fromDate
        ? formatDate({
            inputDate: values.fromDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      toDate: values?.toDate
        ? formatDate({
            inputDate: values.toDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      offset: isFilter ? 0 : pagination?.offset,
      limit: pagination?.limit,
    };
    try {
      const result = await api.admin.getPaymentHistoryList(params);
      setPaymentData(result?.list);
      setDataCount(result?.count);
      setPagination((prev) => {
        return {
          ...prev,
          count: result?.count,
        };
      });
      isOpenTransactionsFilter && setIsOpenTransactionsFilter(false);
    } catch (e) {
      console.error(e);
      setPaymentDataError(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationClick = (i) => {
    setPagination({
      ...pagination,
      offset: i * pagination.limit,
      call: true,
    });
  };

  useEffect(() => {
    if (!pagination.call) return;
    getTransactionList({ ...transactionHistoryFilters });
  }, [pagination.offset]);

  useEffect(() => {
    getTransactionList({
      ...transactionHistoryFilters,
    });
  }, []);

  const headerColumns = [
    {
      key: 'email',
      value: getTextFromDataByKey('ელ.ფოსტა', TransactionHistoryTranslates),
      align: 'left',
    },
    {
      key: 'product_name',
      value: getTextFromDataByKey('product_name', TransactionHistoryTranslates),
      align: 'left',
    },
    {
      value: getTextFromDataByKey('ID_number', TransactionHistoryTranslates),
      key: 'ID_number',
      align: 'left',
    },
    {
      value: getTextFromDataByKey('time_period', TransactionHistoryTranslates),
      key: 'time_Period',
      align: 'right',
    },
    {
      value: getTextFromDataByKey('price', TransactionHistoryTranslates),
      key: 'price',
      align: 'right',
    },
  ];

  useEffect(() => {
    setHeaderColumnsData(
      headerColumns?.map((item) => ({
        Header: item?.value,
        accessor: item?.key,
      })),
    );
  }, [translatesData]);

  return (
    <>
      <Modal
        closeIcon
        width="520px"
        height="auto"
        title="Transaction Filter"
        className="admin-details-modal"
        isOpen={isOpenTransactionsFilter}
        closeModal={() => setIsOpenTransactionsFilter(false)}
      >
        <TransactionHistoryFilter
          loading={loading}
          getPaymentHistoryList={getTransactionList}
          transactionHistoryFilters={transactionHistoryFilters}
          setTransactionHistoryFilters={setTransactionHistoryFilters}
          setPagination={setPagination}
        />
      </Modal>

      {paymentDataError ? (
        <EmptyTransactionsInfo noData={NotFoundData} />
      ) : (
        <TransactionsListWrapper>
          {loading ? (
            <TableLoading />
          ) : (
            <>
              {paymentData && paymentData.length === 0 ? (
                <EmptyTransactionsInfo notFoundData={NotFoundData} />
              ) : (
                <Pagination
                  currentPage
                  limit={pagination.limit}
                  offset={pagination.offset}
                  count={dataCount || pagination.count}
                  // length={paymentData?.limit}
                  className="adminTablesPagination"
                  onClick={handlePaginationClick}
                >
                  <TransactionsTableWrapper style={{ margin: 0 }}>
                    {isTabletOrMobile ? (
                      <MobileTransactionsHistory>
                        {paymentData?.map((row, index) => {
                          return (
                            <MobileTransactionsHistoryItem key={index}>
                              <ItemHead>
                                <ItemIdAndDate>
                                  <div className="id">ID: {row?.paymentId}</div>
                                  <div className="date">
                                    {formatDate({
                                      inputDate: row?.paymentTime,
                                      outputFormat:
                                        DateFormats.DefaultWithoutLines,
                                    })}
                                  </div>
                                </ItemIdAndDate>
                                <ItemPrice>{`₾ ${row?.amount?.toFixed(
                                  2,
                                )}`}</ItemPrice>
                              </ItemHead>
                              {row?.purchaseList.map((row, index) => {
                                return (
                                  <TransactionsItemBody key={index}>
                                    <ItemTitle>
                                      <span className="id">{`# ${row.purchasedItemId} - `}</span>
                                      {getTextFromDataByProductKey(
                                        row.productKey,
                                        translateKeys,
                                      )}
                                    </ItemTitle>
                                    <ItemRows>
                                      <ItemLeftColumn>
                                        <div className="timePeriod">
                                          {getTextFromDataByKey(
                                            'time_period',
                                            TransactionHistoryTranslates,
                                          )}
                                          {`:`}
                                        </div>
                                        <div className="price">
                                          {getTextFromDataByKey(
                                            'price',
                                            TransactionHistoryTranslates,
                                          )}
                                          {`:`}
                                        </div>
                                      </ItemLeftColumn>
                                      <ItemRightColumn>
                                        <div className="periodValue">
                                          {getTextFromDataByPeriod(
                                            row.productKey,
                                            translateKeys,
                                          )}
                                        </div>
                                        <div className="priceValue">{`₾ ${row?.price?.toFixed(
                                          2,
                                        )}`}</div>
                                      </ItemRightColumn>
                                    </ItemRows>
                                  </TransactionsItemBody>
                                );
                              })}
                            </MobileTransactionsHistoryItem>
                          );
                        })}
                      </MobileTransactionsHistory>
                    ) : (
                      <>
                        {paymentData?.map((row) => (
                          <>
                            <TransactionTableColoredHeader className="bodyL">
                              <div className="iconAndID">
                                <TableCheckIcon />
                                <span className="first">
                                  ID: {row?.paymentId}
                                </span>
                                <SeperatorLine />
                                <span className="first">
                                  {' '}
                                  EXT ID: {row?.externalId}
                                </span>
                                <SeperatorLine />
                                <span className="second">
                                  {formatDate({
                                    inputDate: row?.paymentTime,
                                    outputFormat:
                                      DateFormats.DefaultWithoutLines,
                                  })}
                                </span>
                              </div>
                              <div
                                style={{
                                  paddingRight: '42px',
                                  minWidth: '90px',
                                }}
                              >
                                <span className="third">
                                  {`₾ ${row?.amount.toFixed(2)}`}
                                </span>
                              </div>
                            </TransactionTableColoredHeader>

                            <TableWrapperTransactions lang={contextData.value}>
                              <TableContainer component={Paper}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      {headerColumnsData?.map((i, index) => (
                                        <>
                                          <TableCell
                                            align={`${i.align}`}
                                            width={
                                              index === 0 ? '30%' : undefined
                                            }
                                            style={{
                                              textAlign:
                                                index === 0 ||
                                                index === 1 ||
                                                index === 2
                                                  ? 'left'
                                                  : 'right',
                                            }}
                                          >
                                            {i.Header}
                                          </TableCell>
                                        </>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {row?.purchaseList?.map((item, index) => (
                                      <TableRow
                                        key={index}
                                        sx={{
                                          '&:last-child td, &:last-child th': {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          align="left"
                                        >
                                          {row.email}
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          align="left"
                                        >
                                          {getTextFromDataByProductKey(
                                            item.productKey,
                                            translateKeys,
                                          )}
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          style={{
                                            textAlign: 'left',
                                          }}
                                        >
                                          {item.purchasedItemId}
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          style={{
                                            textAlign: 'right',
                                          }}
                                        >
                                          {getTextFromDataByPeriod(
                                            item.productKey,
                                            translateKeys,
                                          )}
                                        </TableCell>
                                        <TableCell
                                          className="prices"
                                          style={{
                                            textAlign: 'right',
                                          }}
                                          align="right"
                                        >{`₾ ${item.price.toFixed(
                                          2,
                                        )}`}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </TableWrapperTransactions>
                          </>
                        ))}
                      </>
                    )}
                  </TransactionsTableWrapper>
                </Pagination>
              )}
            </>
          )}
        </TransactionsListWrapper>
      )}
    </>
  );
};

export default TransactionsHistoryList;
