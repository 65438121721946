import { TOKEN_NAME } from 'constants/global.constants';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { logout } from 'store/reducers/user/user.actions';
import cacheManager from 'utils/cacheManager';

const ProtectedRoute = (props) => {
  const tokenCache = cacheManager(TOKEN_NAME);
  const token = tokenCache.get();
  const dispatch = useDispatch();

  if (!token) {
    props.openLogin();
    dispatch(logout(false, true));
    return <Navigate to="/" />;
  }
  return props.children;
};

export default ProtectedRoute;
