import React from 'react';

export const MobileAvatar = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <g id="User" transform="translate(-1463 -47)">
        <circle
          id="Ellipse_308"
          data-name="Ellipse 308"
          cx="24"
          cy="24"
          r="24"
          transform="translate(1463 47)"
          fill="#ebeaf4"
          opacity="0.6"
        />
        <g
          id="Group_52594"
          data-name="Group 52594"
          transform="translate(-219.69 7)"
        >
          <path
            id="noun-user-1701813"
            d="M245.073,152.46a5.809,5.809,0,0,0-3.015,10.783,8.8,8.8,0,0,0-5.789,8.249c-.009.191,0,.164,0,.164h1.389s.01.027,0-.164a7.412,7.412,0,0,1,14.825,0c0,.186,0,1,0,1h1.392s0-.817,0-1a8.8,8.8,0,0,0-5.788-8.249,5.809,5.809,0,0,0-3.015-10.783Zm0,1.394a4.423,4.423,0,1,1-4.432,4.423A4.416,4.416,0,0,1,245.073,153.854Z"
            transform="translate(1461.734 -99.461)"
            fill="#242333"
            stroke="#242333"
            stroke-width="0.4"
          />
          <path
            id="Path_66430"
            data-name="Path 66430"
            d="M.262.1H10.7V1.915H-.01a.3.3,0,0,1-.3-.3V.4C-.31.234.1.1.262.1Z"
            transform="translate(1698 71.459)"
            fill="#242333"
          />
        </g>
      </g>
    </svg>
  );
};
