import { get, post, put, del } from 'services/axios';

export default {
  purchaseProduct: async (params) => {
    const result = await put('product/purchase', params);
    return result.data;
  },

  getCartList: async () => {
    return await get('product/cart');
  },

  addItemToCart: async (productKey, quantity, promo) => {
    await put(
      promo
        ? `product/cart?productKey=${productKey}&quantity=${quantity}&promoCode=${promo}`
        : `product/cart?productKey=${productKey}&quantity=${quantity}`,
    );
  },
  repayItem: async (purchasedItemId) => {
    const result = await post('product/repay', {
      purchasedItemId: purchasedItemId,
    });
    return result.data;
  },

  deleteAllItemFromCart: () => del('product/cart'),

  repayProduct: async (params) => {
    await post('product/repay', params);
  },

  addPaymentCard: async (successUrl, errorUrl) => {
    return await post(
      `payment/card?successRedirectUrl=${successUrl}&errorRedirectUrl=${errorUrl}`,
    );
  },
  getPaymentCard: async () => {
    return await get('payment/card');
  },
  deleteCartInfo: async (cardId) => {
    return await del(`payment/card?cardId=${cardId}`);
  },
  cancelBillingItem: async (purchasedItemId) => {
    await put('product/cancel', {
      purchasedItemId: purchasedItemId,
    });
  },
};
