import React from 'react';

export const RightArrowCIrcle = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 39 39"
      className="icon-color-2"
      {...rest}
    >
      <g id="Next" transform="translate(-940.93 -3825.973)" opacity="0.5">
        <g id="next-2" data-name="next" transform="translate(957.533 3839.598)">
          <g
            id="Group_40"
            data-name="Group 40"
            transform="translate(0 12.216) rotate(-90)"
          >
            <g id="Group_39" data-name="Group 39" transform="translate(0)">
              <path
                id="Path_52"
                data-name="Path 52"
                d="M10.543,0,6.108,4.368,1.673,0,0,1.661,6.108,7.723l6.108-6.062Z"
                fill="#8A87A5"
                opacity={0.5}
              />
            </g>
          </g>
        </g>
        <g
          id="Ellipse_357"
          data-name="Ellipse 357"
          transform="translate(940.93 3825.973)"
          fill="none"
          stroke="#8A87A5"
          stroke-width="2"
          opacity={0.5}
        >
          <circle cx="19.5" cy="19.5" r="19.5" stroke="none" />
          <circle cx="19.5" cy="19.5" r="18.5" fill="none" />
        </g>
      </g>
    </svg>
  );
};
