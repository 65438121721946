import { get, post, put } from 'services/axios';

export default {
  getPurchaseHistory: async (onPremise, query, offset, limit) => {
    return await get(
      `user/report/purchase-history?onPremise=${onPremise}&query=${query}&offset=${offset}&limit=${limit}`,
    );
  },
  getPaymentHistoryById: async (paymentId) => {
    return await get(`user/report/payment-receipt?paymentId=${paymentId}`);
  },

  getPaymentHistory: async (offset, limit) => {
    return await get(
      `user/report/payment-history?offset=${offset}&limit=${limit}`,
    );
  },

  purchaseDemo: async (params) => {
    return await post('user/public/purchase-demo', params);
  },

  changeDefaultLanguage: async (language) => {
    return await put('user/language', { language: language });
  },
};
