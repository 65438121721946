import React from 'react';
import { Rating } from 'react-simple-star-rating';
import { StarRatingWrapper } from 'ui/styled/StarRatingStyled';

const StarRating = ({ starsData }) => {
  const starScore = starsData / 2 || 0;
  return (
    <StarRatingWrapper>
      <Rating
        allowFraction
        fillColor="rgb(242, 210, 14)"
        iconsCount={5}
        initialValue={starScore}
        readonly={true}
      />
    </StarRatingWrapper>
  );
};

export default StarRating;
