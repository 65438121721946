import styled from 'styled-components';

export const LabelStyled = styled.label`
  flex: 1 1 auto;
  outline: none;
  position: relative;
  display: flex;
  align-items: center;
  .errorBorder {
    border-color: #f55e1b;
  }

  & > label.input-label:not([input-type='checkbox']):not([input-type='radio']) {
    color: rgba(58, 56, 83, 0.6);
    position: absolute;
    left: 15px;
    top: 50%;
    padding: 0px 5px;
    margin-top: -8px;
    background-color: ${({ theme, outLineBg }) =>
      outLineBg ? outLineBg : '#fff'};
    transition: all 0.2s ease;
    pointer-events: none;
    width: 80%;
    @media (max-width: 1510px) {
      font-size: 14px;
    }
    @media (max-width: 900px) {
      font-size: 14px !important;
    }
  }

  label {
    ${({ disabled }) => {
      return {
        opacity: disabled && 0.5,
      };
    }}
  }
`;
