import styled, { css } from 'styled-components';

export const PaginationButtonsConatainerStyled = styled.div`
  margin-top: 105px;
  width: 100%;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 14%;

  @media (max-width: 1290px) {
    padding: 0 5%;
  }
  @media (max-width: 900px) {
    padding: 0 22px;
  }

  .pagination {
    width: 39px;
    height: 39px;
    color: #8a87a5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0px 6px;
  }

  .pagination.active {
    border: 2px solid #242333;
    border-radius: 50%;
    pointer-events: none;
    color: #242333;
  }
  .pagination.active :last-child {
    background-color: grey;
  }

  .pagination.disabled {
    background: none;
    color: #829cb4;
    pointer-events: none;
  }
  .icons {
    background-color: red;
  }
  ${({ currentPage }) =>
    currentPage &&
    css`
       {
        padding: 0px 15%;
      }
    `}
`;
