import React from 'react';
import ReactDOM from 'react-dom';
import {
  BottmoSheetBody,
  BottomSheetBackgroundStyled,
  BottomSheetSwipeTrigger,
} from './bottomSheet.styled';

const BottomSheet = ({
  isOpen,
  closeBottomSheet,
  children,
  height,
  canCloseOnOutsideClick = true,
  background,
  padding,
  style,
  isFullSize = false,
  overflowHidden,
  withoutPortal = false,
  ...rest
}) => {
  const handleClose = (e) => {
    if (e) e.preventDefault();
    setTimeout(
      () => typeof closeBottomSheet === 'function' && closeBottomSheet(e),
      100,
    );
  };

  if (isOpen && !withoutPortal) {
    return ReactDOM.createPortal(
      <BottomSheetBackgroundStyled
        isOpen={isOpen}
        overflowHidden={overflowHidden}
        style={{ overflow: overflowHidden ? 'hidden' : undefined }}
        onClick={canCloseOnOutsideClick && handleClose}
      >
        <BottmoSheetBody
          className="BottmoSheetBody"
          height={height}
          style={style}
          background={background}
          isFullSize={isFullSize}
          padding={padding}
          onClick={(e) => e.stopPropagation()}
          {...rest}
        >
          {children}
        </BottmoSheetBody>
      </BottomSheetBackgroundStyled>,
      document.body,
    );
  }
  if (isOpen && withoutPortal) {
    return (
      <BottomSheetBackgroundStyled
        isOpen={isOpen}
        overflowHidden={overflowHidden}
        style={{ overflow: overflowHidden ? 'hidden' : undefined }}
        onClick={canCloseOnOutsideClick && handleClose}
      >
        <BottmoSheetBody
          className="BottmoSheetBody"
          height={height}
          style={style}
          background={background}
          isFullSize={isFullSize}
          padding={padding}
          onClick={(e) => e.stopPropagation()}
          {...rest}
        >
          {children}
        </BottmoSheetBody>
      </BottomSheetBackgroundStyled>
    );
  }

  return null;
};

export default BottomSheet;
