import { imagesBaseUrl } from 'constants/global.constants';
import React from 'react';
import { Helmet } from 'react-helmet';
import { stripHtml } from 'utils/helpers';

const CustomHead = ({ seo }) => {
  const seoFromBackend = Boolean(seo);
  const nameTags =
    (seo &&
      Array.isArray(seo.name) &&
      seo.name.filter((i) => i.content || i.img)) ||
    [];

  const propertyTags =
    (seo &&
      Array.isArray(seo.property) &&
      seo.property.filter((i) => Boolean(i.content || i.img))) ||
    [];

  if (seoFromBackend) {
    return (
      <Helmet>
        {nameTags.map((i, ind) => {
          const hasImage = i.img?.data?.attributes?.url && !i.content;
          const content = hasImage
            ? `${imagesBaseUrl}${i.img?.data?.attributes?.url}`
            : stripHtml(i.content);

          return (
            <meta
              key={`${i.name}${ind}`}
              name={i.name}
              content={content || ''}
            />
          );
        })}

        {propertyTags.map((i, ind) => {
          const hasImage = i.img?.data?.attributes?.url && !i.content;
          const content = hasImage
            ? `${imagesBaseUrl}${i.img?.data?.attributes?.url}`
            : stripHtml(i.content);

          return (
            <meta
              key={`${i.name}${ind}`}
              property={i.name}
              content={content || ''}
            />
          );
        })}
      </Helmet>
    );
  }
  return <></>;
};

export default CustomHead;
