import styled from 'styled-components';
import tick from 'assets/img/tick.svg';

export const HeaderStyled = styled.header`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 22px 14%;
  width: 100%;
  align-items: center;
  transition: all 0.3s;
  position: fixed;
  top: 0;
  z-index: 999;
  background: #fff;
  .login-btn {
    padding: 16px 21px;
  }
  a {
    text-decoration: none;
    cursor: pointer;
  }
  img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover Button {
    color: #242333;
  }
  @media (max-width: 1685px) {
    padding: 22px 8%;
  }
  @media (max-width: 1290px) {
    padding: 22px 5%;
  }
  @media (max-width: 900px) {
    padding: 14px 22px;
    .logo-main {
      height: 43px;
    }
  }
`;
export const HeaderArrow = styled.span``;
export const HeaderNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const HeaderNav = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-right: 41px;
    align-items: center;
    color: #8a87a5;
    cursor: pointer;
    font-weight: 'normal';
    color: '#8A87A5';
    font-weight: 400;
  }
  .active {
    color: #242333;
    font-weight: 500;
  }
  @media (max-width: 1290px) {
    a {
      margin-right: 30px;
    }
  }
`;
export const UserProfileDropDown = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
export const UserAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff8cc;
  color: #3a3853;
  margin-right: 18px;
  text-transform: uppercase;
`;
export const UserNameBlock = styled.div`
  display: flex;
  cursor: pointer;
`;
export const UserNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
  width: max-content;
  .name {
    color: #242333;
    margin-bottom: 3px;
    display: flex;
    align-self: flex-start;
  }
  .account {
    color: #8a87a5;
  }
`;

export const UserDropDownOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 230px;
  top: 66px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 33px 76px #83819f48;
  padding: 40px 41px 50px 41px;
  z-index: 2;
`;
export const DropListItem = styled.div`
  a {
    color: #8a87a5;
  }
  margin-bottom: 26px;
`;
export const UserDropList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 42px;
  ${DropListItem} {
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const LogoutButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #fff8cc;
  color: #242333;
  border-radius: 5px;
  padding: 13px 39px;
  .arrow {
    padding-left: 6px;
  }
`;

export const CartCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 2px solid #242333;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  top: -14px;
  right: -17px;
  background: #fff;
  color: #242333;
  font-weight: bold;
  line-height: 28px;
  @media (max-width: 900px) {
    width: 22px;
    height: 22px;
    line-height: 22px;
    top: -11px;
    right: -11px;
  }
`;
export const HeaderSeperator = styled.div`
  margin: 0 35px;
  height: 37px;
  width: 1px;
  background: #dddbed;
`;
export const LanguageChooserWrapper = styled.div`
  color: #8a87a5;
  cursor: pointer;
  font-weight: normal;
  color: #8a87a5;
  min-width: 52px;
  width: 100%;
  .react-select__control {
    border: none;
    background: none;
    box-shadow: none !important;
    width: max-content;
  }
  .react-select__control:hover,
  .react-select__control:focus {
    border-color: transparent !important;
    box-shadow: none;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__value-container {
    padding: 0;
  }
  .react-select__single-value,
  .react-select__input-container {
    color: #8a87a5;
  }
  .react-select__indicator {
    padding: 0;
  }
  .react-select__indicator svg {
    width: 14px;
  }
  .react-select__indicator svg path {
    fill: #3a3853;
  }
  .react-select__option--is-focused {
    background-color: #ffdc04;
  }
  .react-select__menu {
    border: none;
    background: #fff;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0px 33px 76px #83819f48;
    padding: 10px 18px;
    width: 200px;
    margin-left: -40px;
  }
  .react-select__option {
    color: #8a87a5;
    padding-left: 27px;
    cursor: pointer;
    transition: all 0.3s;
    border-radius: 5px;
    font-weight: 500;
    :nth-child(2) {
      font-family: 'Helvetica Neue LT GEO';
    }
  }
  .react-select__option:hover,
  .react-select__option--is-focused {
    background: none;
    background-color: none;
  }

  .react-select__option--is-selected,
  .react-select__option:active {
    background-color: rgba(235, 234, 244, 0.38) !important;
    color: #3a3853;
    font-weight: bold;
    :after {
      content: url(${tick});
      float: right;
    }
  }
`;

export const CartIconWrapper = styled.div`
  margin-right: 40px;
  position: relative;
  cursor: pointer;
  @media (max-width: 900px) {
    margin-right: 0;
  }
`;
export const CartComponentStyled = styled.div`
  position: relative;
  .lastCartDropdownStyled {
    .lastCartListItem {
      background: rgba(235, 234, 244, 0.38);
    }
  }
`;
export const GoToCartButton = styled.div`
  background: #fff8cc;
  width: 100%;
  padding: 15px 0;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  color: #242333;
  a {
    color: #242333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    padding-left: 12px;
  }
`;
export const CartDropdownStyled = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  background: #fff;
  box-shadow: 0px 33px 46px #8a87a51a;
  min-width: 370px;
  padding: 32px 40px;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  margin-right: -1px;
  div {
    :first-child {
      overflow-x: hidden !important;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  ${GoToCartButton} {
    width: 100%;
  }
`;
export const CartDropdownList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
`;

export const CartDropdownListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  :hover {
    background: rgba(235, 234, 244, 0.38);
  }
`;
export const CartListItemLeft = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 70%;
  .decrement {
    font-size: 22px;
    left: -5px;
    top: -2px;
  }
  input {
  }
  .increment {
    font-size: 21px;
    right: 2px;
    top: -2px;
  }
`;
export const CartListItemTitle = styled.div`
  color: rgba(36, 35, 51, 1);
  margin-bottom: 10px;
  overflow: hidden;
  height: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 172px;
`;
export const CartListItemCounter = styled.div`
  input {
    width: 84px;
    height: 30px;
    border: 1px solid #dddbed;
    border-radius: 4px;
    outline: 0;
    text-align: center;
    font-weight: 500;
  }
  .decrement {
    position: absolute;
    color: #b9b7c9;
    font-size: 22px;
    left: 1px;
    padding-top: 0px;
    height: 30px;
    width: 30px;
    text-align: center;
  }
  .increment {
    position: absolute;
    color: #b9b7c9;
    font-size: 20px;
    right: 6px;
    padding-top: 2px;
    height: 30px;
    width: 25px;
  }
`;
export const CartListItemRight = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
`;
export const CartIemDelete = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
`;
export const CartListItemPrice = styled.div`
  color: #3a3853;
  margin-bottom: 2px;
  font-weight: bold;
  text-align: right;
`;
export const CartListItemType = styled.div`
  color: #b9b7c9;
  text-align: right;
`;

export const CartTotalSummary = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  margin: 18px 0 42px 0;
  .total-text {
    color: #8a87a5;
  }
  .total-price {
    color: #3a3853;
    font-weight: bold;
  }
`;

export const EmptyCartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const EmptyCartImg = styled.div`
  margin-bottom: 22px;
`;

export const EmptyCartDesc = styled.div`
  line-height: 21px;
  color: #bab8c7;
`;

export const MobileCartAndBurger = styled.div`
  display: flex;
  align-items: center;
`;
export const MobileCartIcon = styled.div`
  margin-right: 24px;
  position: relative;
  z-index: 99;
`;
export const MobileMenuBurger = styled.div`
  margin-top: -5px;
`;

export const MobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 62px 28px;
`;

export const MenuTitle = styled.div`
  color: #242333;
  margin-bottom: 25px;
  font-weight: bold;
  padding-left: 26px;
`;

export const MobileMenuNav = styled.div`
  display: flex;
  flex-direction: column;
  a {
    color: #3a3853;
    padding: 17px 26px;
    text-decoration: none;
  }
  .active {
    background: rgba(253, 246, 231, 0.7);
    border-radius: 5px;
    font-weight: 500;
  }
`;
export const MenuSeperator = styled.div`
  width: 100%;
  background: #e6e5eb;
  height: 1px;
  margin: 29px 0 36px 0;
  @media (max-width: 700px) {
    margin: 10px 0px 15px;
  }
`;
export const MobileLanguageChooser = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 26px;
  .active {
    font-weight: 500;
  }
`;
export const MobileLanguageItem = styled.div`
  color: #8a87a5;
  margin-bottom: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    width: 9px;
  }
`;

export const MobileLoginLogoutButton = styled.div`
  position: absolute;
  bottom: 62px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 48px;
  button {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    height: 52px;
    background-color: #ffdc04;
    color: #242333;
  }
  @media (max-width: 900px) {
    bottom: 13px;
  }
  @media (max-height: 700px) {
    margin: unset;
    position: relative;
    padding: 0px 28px;
    margin-top: 58px;
  }
`;

export const MobileUserInfo = styled.div`
  display: flex;
  margin-bottom: 28px;
`;
export const MobileUserAvatar = styled.div`
  margin-right: 18px;
`;

export const MobileUserName = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  .name {
    margin-bottom: 3px;
    font-weight: 500;
    color: #242333;
  }
  .email {
    font-size: 14px;
    color: #8a87a5;
  }
`;

export const ClearCartButton = styled.div`
  margin: 20px 0 0 0;
  color: #3a3853;
  text-decoration: underline;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
`;
