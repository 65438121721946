import styled from 'styled-components';

export const TopParagraphStyled = styled.div`
  display: flex;
  flex-direction: column;
`;
export const BlogParagraphStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 77px;

  @media (max-width: 1540px) {
    margin-bottom: 0px;
  }
  @media (max-width: 900px) {
    margin-bottom: 30px;
  }
`;
export const GreyHeadingStyled = styled.div`
  background: rgba(185, 183, 201, 0.26) 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 10px;
  display: flex;
  justify-content: center;
  max-width: fit-content;
  min-height: 30px;
  max-height: 30px;
  overflow: hidden;
  margin-bottom: 16px;

  @media (max-width: 900px) {
    min-height: 25px;
    max-height: 25px;
    margin-bottom: 12px;
    max-width: max-content;

    .addit-title-grey {
      font-size: 12px;
      padding: 6px 12px;
    }
  }
`;
export const ParagraphStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeadingStyled = styled.div`
  color: #242333;
  line-height: 54px;
  font-weight: 500;
  width: 100%;
  align-items: center;

  p {
    margin-bottom: 24px;
    margin-top: 0px;
  }
  @media (max-width: 900px) {
    line-height: 30px;
    font-size: 22px !important;
    margin-bottom: 14px;
    width: 95%;
    p {
      margin: 0;
    }
  }
`;

export const BlogTextStyled = styled.div`
  color: #8a87a5;
  font-weight: 400;
  line-height: 34px;
  max-width: 100%;
  p {
    margin: 0;
  }
  @media (max-width: 1536px) {
    margin-bottom: 50px;
  }
  @media (max-width: 900px) {
    line-height: 22px;
    max-width: 95%;
    margin-bottom: 0px;
    p {
      margin: 0;
    }
  }
`;

export const ButtonWrapper = styled.div`
  button {
    height: 50px;
  }
  @media (max-width: 900px) {
    width: 100%;
    button {
      height: 52px;
      font-size: 14px;
      width: 100%;
    }
  }
`;

export const SearchFormWrapper = styled.div`
  display: flex;
  .videoBtn {
    padding: 24px 19px 23px 22px;
    background: rgba(183, 179, 155, 0.14);
    border-radius: 5px;
    margin-left: 25px;
    cursor: pointer;
  }
`;

export const BlogButtonWrapper = styled.div`
  @media (max-width: 900px) {
    width: 100%;
    button {
      height: 52px !important;
      font-size: 14px;
      width: 100%;
      padding: 0px;
    }
  }
`;
