import React from 'react';
import GlobalError from 'ui/components/common/GlobalError';
import GlobalLoadingComponent from 'ui/components/common/GlobalLoading';

const ComponentWithLoaderAndError = (props) => {
  const { error, children, data } = props;

  if (error) {
    return <GlobalError />;
  }
  return children;
};

export default ComponentWithLoaderAndError;
