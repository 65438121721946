import { get, post, put } from 'services/axios';

export default {
  userRegister: async (params) => {
    await post('user/public/register', params);
  },
  resendVerificationEmail: async (username) => {
    await post('user/public/send-verification', { username: username });
  },

  userVerify: async (verificationCode) => {
    await post('user/public/verify', { verificationCode: verificationCode });
  },

  login: (params) => post('user/public/login', params),

  logout: async () => await put('user/logout'),

  getCurrentUser: async () => await get('user'),

  changePassword: async (oldPassword, newPassword) => {
    await put('user/password', {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  },
  updatePersonalInfo: async (params) => {
    await put('user', params);
  },

  sendPasswordReset: async (username) => {
    await post('user/public/reset-password-request', { username: username });
  },

  checkPasswordResetOtp: async (username, otp) => {
    const result = await post('user/public/reset-password-otp', {
      username: username,
      otp: otp,
    });

    return result.data;
  },

  resetPassword: async (newPassword, resetKey, username) => {
    await post('user/public/reset-password', {
      newPassword: newPassword,
      resetKey: resetKey,
      username: username,
    });
  },
};
