import styled from 'styled-components';

export const IncrementalInputCounter = styled.div`
  position: relative;
  ${({ width, height }) => {
    return {
      width: width ? width : '84px',
      height: height ? height : '34px',
    };
  }};
  input {
    border: 1px solid #dddbed;
    border-radius: 4px;
    outline: 0;
    text-align: center;
    font-weight: 500;
    ${({ width, height }) => {
      return {
        width: width ? width : '84px',
        height: height ? height : '34px',
      };
    }};
  }
  .decrement {
    position: absolute;
    color: #b9b7c9;
    font-size: 30px;
    left: 0px;
    height: 100%;
    width: 20px;
    display: flex;
    align-items: center;
    text-align: revert;
    padding-left: 21px;
    top: -1px;
    cursor: pointer;
    user-select: none;
  }
  .increment {
    position: absolute;
    color: #b9b7c9;
    font-size: 24px;
    right: 0px;
    height: 100%;
    width: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    top: -1px;
    cursor: pointer;
    user-select: none;
  }
  .errorColor {
    color: #d85947;
  }
`;
