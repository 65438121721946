import React, { useContext, useEffect, useState } from 'react';
import {
  SeperatorLine,
  TableWrapperTransactions,
  TransactionHistoryMobileHead,
  TransactionsHistoryWrapper,
  TransactionTableColoredHeader,
  TransactionTableTitle,
} from 'ui/styled/transactionsTable.styled';
import { TableCheckIcon } from 'assets/svgIcons/TableCheckIcon';
import api from 'services/api';
import formatDate, { DateFormats } from 'utils/date';
import Pagination from '../../../library/Pagination/Pagination';
import {
  getTextFromDataByKey,
  getTextFromDataByPeriod,
  getTextFromDataByProductKey,
} from 'utils/helpers';
import Modal from 'library/modal/Modal';
import TransactionsModal from './TransactionsModal';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import Empty from 'assets/img/Empty.svg';
import EmptyTransactionsInfo from 'ui/components/common/EmptyTransactionsInfo';
import { StrapiDataContext } from 'Main';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import _ from 'underscore';
import TableLoading from 'ui/components/common/TableLoading';
import {
  ItemHead,
  ItemIdAndDate,
  ItemPrice,
  TransactionsItemBody,
  ItemTitle,
  ItemRows,
  ItemLeftColumn,
  MobileTransactionsHistory,
  MobileTransactionsHistoryItem,
  TransactionsTableWrapper,
  ItemRightColumn,
} from 'library/table/TableWrapperStyled';

import { useMediaQuery } from 'react-responsive';
import CustomHead from 'ui/components/customHead/custom-head';

const TransactionHistoryTable = (props) => {
  const { settingsData } = props;
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const contextData = useSelector((state) => state.app.language, _.isEqual);
  const { transactionHistoryData: data } = useContext(StrapiDataContext);
  const translateKeys = settingsData?.data?.data?.attributes?.enumTranslate;
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: isTabletOrMobile ? 3 : 10,
    count: 0,
  });
  const [paymentData, setPaymentData] = useState([]);
  const [paymentDataLoading, setPaymentDataLoading] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [headerColumnsData, setHeaderColumnsData] = useState([]);
  const NotFoundData = data?.data?.data?.attributes?.search?.notFound;

  const [paymentDataError, setPaymentDataError] = useState(false);

  const getPaymentHistoryData = async () => {
    setPaymentDataLoading(true);
    try {
      const data = await api.user.getPaymentHistory(
        pagination.offset,
        pagination.limit,
      );
      setDataCount(data.data?.count);
      setPaymentData(data?.data.list);
    } catch (e) {
      console.error(e);
      setPaymentDataError(true);
    } finally {
      setTimeout(() => {
        setPaymentDataLoading(false);
      }, 1000);
    }
  };
  const handlePaginationClick = (i) => {
    setPagination({
      ...pagination,
      offset: i * pagination.limit,
    });
  };
  useEffect(() => {
    getPaymentHistoryData();
  }, [pagination]);

  const TransactionHistoryTitle =
    data?.data?.data?.attributes?.transactionTable?.title;

  const TransactionHistoryTranslates =
    data?.data?.data?.attributes?.transactionTable?.translate;

  const checkModalTranslates = data?.data?.data?.attributes?.checkModal;

  const getPaymentHistoryById = async (paymentId) => {
    if (!paymentId || paymentId == 0) return;
    console.log('testt paymentId', paymentId);
    try {
      const res = await api.user.getPaymentHistoryById(paymentId);
      setIsModalOpen(true);
      setSelectedProductList(res.data);
    } catch (e) {
      console.error(e);
    }
  };
  const IdModalClickHandler = (paymentId) => {
    setIsModalOpen(true);
    getPaymentHistoryById(paymentId);
  };

  useEffect(() => {
    if (!id) {
      return;
    } else {
      getPaymentHistoryById(id);
    }
  }, [id]);

  const isOfferInUrlParams = location?.pathname?.includes('isOffer');
  const additionalParam = isOfferInUrlParams ? 'isOffer' : 'default';

  const handleDeleteParam = () => {
    navigate(`/transactionHistory/${additionalParam}/id/${'0'}`);
  };

  const closeModalHandler = () => {
    handleDeleteParam();
    localStorage.removeItem('transactionId');
    setIsModalOpen(false);
  };

  const headerColumns = [
    {
      key: 'product_name',
      value: getTextFromDataByKey('product_name', TransactionHistoryTranslates),
      align: 'left',
    },
    {
      value: getTextFromDataByKey('ID_number', TransactionHistoryTranslates),
      key: 'ID_number',
      align: 'left',
    },
    {
      value: getTextFromDataByKey('time_period', TransactionHistoryTranslates),
      key: 'time_Period',
      align: 'right',
    },
    {
      value: getTextFromDataByKey('price', TransactionHistoryTranslates),
      key: 'price',
      align: 'right',
    },
  ];

  useEffect(() => {
    setHeaderColumnsData(
      headerColumns?.map((item) => ({
        Header: item?.value,
        accessor: item?.key,
      })),
    );
  }, [data]);

  // cleanUp

  useEffect(() => {
    return () => {
      localStorage.removeItem('transactionId');
    };
  }, []);

  return paymentDataError ? (
    <EmptyTransactionsInfo noData={NotFoundData} />
  ) : (
    <TransactionsHistoryWrapper>
      <CustomHead seo={data?.data?.data?.attributes?.seo} />
      <Modal
        closeIcon
        width="40%"
        height="auto"
        isOpen={isModalOpen}
        className="transactions"
        closeModal={closeModalHandler}
        overflowBody
      >
        <>
          <img
            src={Empty}
            style={{ width: '100%' }}
            alt="bg"
            className="emptyBg"
          />
          <TransactionsModal
            order={selectedProductList}
            settingsData={settingsData}
            isTabletOrMobile={isTabletOrMobile}
            checkModalTranslates={checkModalTranslates}
          />
        </>
      </Modal>
      <>
        {isTabletOrMobile ? (
          <TransactionHistoryMobileHead>
            {TransactionHistoryTitle}
          </TransactionHistoryMobileHead>
        ) : (
          <TransactionTableTitle>
            {TransactionHistoryTitle}
          </TransactionTableTitle>
        )}
        {paymentDataLoading ? (
          <TableLoading />
        ) : (
          <>
            {paymentData && paymentData.length === 0 ? (
              <EmptyTransactionsInfo notFoundData={NotFoundData} />
            ) : (
              <Pagination
                currentPage
                limit={pagination.limit}
                offset={pagination.offset}
                count={dataCount || pagination.count}
                length={paymentData?.limit}
                className="orders-pagination"
                onClick={handlePaginationClick}
              >
                <TransactionsTableWrapper>
                  {isTabletOrMobile ? (
                    <MobileTransactionsHistory>
                      {paymentData.map((row, index) => {
                        return (
                          <MobileTransactionsHistoryItem key={index}>
                            <ItemHead
                              onClick={() => {
                                IdModalClickHandler(row?.paymentId);
                              }}
                            >
                              <ItemIdAndDate>
                                <div className="id">ID: {row?.paymentId}</div>
                                <div className="date">
                                  {formatDate({
                                    inputDate: row?.paymentTime,
                                    outputFormat:
                                      DateFormats.DefaultWithoutLines,
                                  })}
                                </div>
                              </ItemIdAndDate>
                              <ItemPrice>
                                {`₾ ${row?.amount?.toFixed(2)}`}
                              </ItemPrice>
                            </ItemHead>
                            {row?.purchaseList.map((row, index) => {
                              return (
                                <TransactionsItemBody key={index}>
                                  <ItemTitle>
                                    <span className="id">{`# ${row.purchasedItemId} - `}</span>
                                    {getTextFromDataByProductKey(
                                      row.productKey,
                                      translateKeys,
                                    )}
                                  </ItemTitle>
                                  <ItemRows>
                                    <ItemLeftColumn>
                                      <div className="timePeriod">
                                        {getTextFromDataByKey(
                                          'time_period',
                                          TransactionHistoryTranslates,
                                        )}
                                        {`:`}
                                      </div>
                                      <div className="price">
                                        {getTextFromDataByKey(
                                          'price',
                                          TransactionHistoryTranslates,
                                        )}
                                        {`:`}
                                      </div>
                                    </ItemLeftColumn>
                                    <ItemRightColumn>
                                      <div className="periodValue">
                                        {getTextFromDataByPeriod(
                                          row.productKey,
                                          translateKeys,
                                        )}
                                      </div>
                                      <div className="priceValue">{`₾ ${row?.price?.toFixed(
                                        2,
                                      )}`}</div>
                                    </ItemRightColumn>
                                  </ItemRows>
                                </TransactionsItemBody>
                              );
                            })}
                          </MobileTransactionsHistoryItem>
                        );
                      })}
                    </MobileTransactionsHistory>
                  ) : (
                    <>
                      {paymentData?.map((row) => (
                        <>
                          <TransactionTableColoredHeader
                            className="bodyL"
                            onClick={() => {
                              IdModalClickHandler(row?.paymentId);
                            }}
                          >
                            <div className="iconAndID">
                              <TableCheckIcon />
                              <span className="first">
                                ID: {row?.paymentId}
                              </span>
                              <SeperatorLine />
                              <span className="second">
                                {formatDate({
                                  inputDate: row?.paymentTime,
                                  outputFormat: DateFormats.DefaultWithoutLines,
                                })}
                              </span>
                            </div>
                            <div
                              style={{ paddingRight: '42px', minWidth: '90px' }}
                            >
                              <span className="third">
                                {`₾ ${row?.amount.toFixed(2)}`}
                              </span>
                            </div>
                          </TransactionTableColoredHeader>

                          <TableWrapperTransactions lang={contextData.value}>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {headerColumnsData?.map((i, index) => (
                                      <>
                                        <TableCell
                                          align={`${i.align}`}
                                          width={
                                            index === 0 ? '30%' : undefined
                                          }
                                          style={{
                                            textAlign:
                                              index === 0 || index === 1
                                                ? 'left'
                                                : 'right',
                                          }}
                                        >
                                          {i.Header}
                                        </TableCell>
                                      </>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {row?.purchaseList?.map((row, index) => (
                                    <TableRow
                                      key={index}
                                      sx={{
                                        '&:last-child td, &:last-child th': {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        align="left"
                                      >
                                        {getTextFromDataByProductKey(
                                          row.productKey,
                                          translateKeys,
                                        )}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.purchasedItemId}
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        style={{
                                          textAlign: 'right',
                                        }}
                                      >
                                        {getTextFromDataByPeriod(
                                          row.productKey,
                                          translateKeys,
                                        )}
                                      </TableCell>
                                      <TableCell
                                        className="prices"
                                        style={{
                                          textAlign: 'right',
                                        }}
                                        align="right"
                                      >{`₾ ${row.price.toFixed(2)}`}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </TableWrapperTransactions>
                        </>
                      ))}
                    </>
                  )}
                </TransactionsTableWrapper>
              </Pagination>
            )}
          </>
        )}
      </>
    </TransactionsHistoryWrapper>
  );
};

export default TransactionHistoryTable;
