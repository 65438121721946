import styled, { css } from 'styled-components';

export const InputStyled = styled.input`
  border-radius: 5px;
  background: transparent;
  border: 1px solid rgba(58, 56, 83, 0.6);
  height: 50px;
  width: 100%;
  outline: none;
  transition: all 0.3s ease;
  padding: 0 20px;
  color: #3a3853;
  box-shadow: none;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  height: ${(props) => (props.search ? '30px' : undefined)};

  &[disabled] {
    background-color: transparent;
    opacity: 0.5;
  }

  ${({ readOnly }) =>
    readOnly &&
    css`
       {
        opacity: 0.8;
      }
    `}

  ${({ AbsoluteComp }) =>
    AbsoluteComp &&
    css`
       {
        padding-right: 5rem;
      }
    `}

	:focus {
    ${({ disableFocus }) => {
      return {
        borderColor: disableFocus ? 'none' : '#3A3853',
      };
    }}

    ~ span svg.calendar-icon path {
      fill: ${({ theme }) => theme.INPUT_BORDER_COLOR_FOCUSED};
    }
  }

  &:focus
    ~ label.input-label:not([input-type='checkbox']):not([input-type='radio']),
  &:not(:placeholder-shown)
    ~ label.input-label:not([input-type='checkbox']):not([input-type='radio']) {
    top: 0;
    font-size: 12px !important;
    left: 14px;
    width: auto;
    color: #3a3853 !important;
  }

  &:focus
    ~ label.input-label:not([input-type='checkbox']):not([input-type='radio']) {
    ${({ disableFocus }) => {
      return {
        color: disableFocus ? '#6b7689' : '#3A3853',
      };
    }}
  }

  ${({ switcher }) =>
    switcher &&
    css`
       {
        display: none !important;
      }
    `}

  ${({ inputType }) => {
    switch (inputType) {
      case 'filter':
        return css`
           {
            height: 47px;
          }
        `;
      default:
        return css`
           {
            height: ${({ theme }) => theme.INPUT_HEIGHT};
          }
        `;
    }
  }}

	${({ errorText, theme }) =>
    errorText &&
    css`
       {
        border-color: red !important;
        /* background-color: ${theme.INPUT_ERROR_BACKGROUND_COLOR}; */

        &:not(:placeholder-shown)
          ~ label.input-label:not([input-type='checkbox']):not([input-type='radio']),
        &:focus
          ~ label.input-label:not([input-type='checkbox']):not([input-type='radio']) {
          color: red;
        }
      }
    `}
`;

export const DoubleInputContainerStyled = styled.div`
  border-radius: 33px;
  border: 1px solid ${({ theme }) => theme.INPUT_BORDER_COLOR};
  height: ${({ theme }) => theme.INPUT_HEIGHT};
  width: 100%;
  outline: none;
  transition: all 0.3s ease;
  padding: 0 30px;
  display: flex;
  align-items: center;

  :focus-within {
    border-color: ${({ theme }) => theme.INPUT_BORDER_COLOR_FOCUSED};

    ~ label.input-label-double {
      color: ${({ theme }) => theme.INPUT_BORDER_COLOR_FOCUSED};
    }
  }

  ${({ errorText, theme }) =>
    errorText &&
    css`
       {
        border-color: ${theme.INPUT_ERROR_BORDER_COLOR} !important;
        /* background-color: ${theme.INPUT_ERROR_BACKGROUND_COLOR}; */

        ~ label.input-label-double {
          color: ${theme.INPUT_ERROR_BORDER_COLOR} !important;
        }
      }
    `}

  ${({ inputType }) => {
    switch (inputType) {
      case 'filter':
        return css`
           {
            height: 47px;
          }
        `;
      default:
        return css`
           {
            height: ${({ theme }) => theme.INPUT_HEIGHT};
          }
        `;
    }
  }}
`;

export const SearchInput = styled.div`
  position: absolute;
  left: 20px;
  top: 19px;
`;

export const DoubleInputStyled = styled.input`
  border: 0;
  background: transparent;
  /* width: 40%; */
  /* flex: 1 1 40%; */
  width: ${(props) =>
    props.secondInput ? 'calc(50% - 40px)' : 'calc(100% - 40px)'};
  letter-spacing: 0.5px;
  font-family: ${({ theme }) => theme.INPUT_FONT};
  font-size: ${({ theme }) => theme.INPUT_FONT_SIZE};
  line-height: ${({ theme }) => theme.INPUT_LINE_HEIGHT};
  box-shadow: none;

  ::placeholder {
    color: ${(props) => props.theme.INPUT_PLACEHOLDER_COLOR};
    opacity: 0.7;
  }
`;

export const Seperator = styled.span`
  width: 12px;
  color: #2d2d52;
  margin: 0 10px;
`;

export const AbsoluteCompStyled = styled.span`
  cursor: pointer;
  padding: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 20px;
  height: 100%;
  font-size: ${({ theme }) => theme.INPUT_FONT_SIZE};
  span {
    font-size: ${({ theme }) => theme.INPUT_FONT_SIZE};
  }
  ${({ numberSpinners }) => {
    return {
      right: numberSpinners ? '50px' : '20px',
    };
  }}
  @media (max-width: 900px) {
    ${({ numberSpinners, readOnly, disabled }) => {
      return {
        right: numberSpinners ? '100px' : '20px',
        display: readOnly && disabled ? 'none !important' : 'inline-flex',
      };
    }}
  }
`;
