import { EmptyPageIcon } from 'assets/svgIcons/EmptyPageIcon';
import React from 'react';
import {
  EmptyPageText,
  EmptyTransactionsWrapper,
  NoDataText,
} from 'ui/styled/emptyTransactionsInfoStyled';
import { htmlParser } from 'utils/htmlUtils';

const EmptyTransactionsInfo = (props) => {
  return !props.noData ? (
    <EmptyTransactionsWrapper
      style={{ marginTop: `${props.notFoundData}` ? '' : '', paddingTop: '3%' }}
    >
      <EmptyPageIcon />
      <EmptyPageText className="bodyL">
        {props.notFoundData?.title}
      </EmptyPageText>
      {props.notFoundData?.description ? (
        <EmptyPageText className="bodyL">
          {htmlParser(props.notFoundData.description)}
        </EmptyPageText>
      ) : null}
    </EmptyTransactionsWrapper>
  ) : (
    <EmptyTransactionsWrapper
      style={{ marginTop: `${props.noData}` ? '' : '', paddingTop: `${props}` }}
    >
      <EmptyPageIcon />
      <NoDataText className="bodyL"> {props.noData?.title}</NoDataText>
    </EmptyTransactionsWrapper>
  );
};
export default EmptyTransactionsInfo;
