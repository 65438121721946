import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  RegisterStyled,
  RegisterRowHalf,
  RegisterRowItem,
  RegisterRowFull,
  PasswordRequirementsForAuth,
  BtnGroupMobile,
  FormFieldsErrorWrapper,
} from 'ui/styled/auth.styled';
import { authTypes, tabs } from '../Auth';
import { PassShowIcon } from 'assets/svgIcons/PassShowIcon';
import api from 'services/api';
import { Button } from 'library/button/Button';
import { getTextFromDataByKey } from 'utils/helpers';
import FormErrorComponent from 'ui/components/common/FormError';
import {
  isEmail,
  isValidPassword,
  onlyLatinLetters,
  onlyLatinValidator,
  required,
} from 'utils/validator';
import FormCheckBoxState from 'ui/components/formCheckbox/formCheckbox-formState';
import { userTypes } from 'constants/global.constants';
import { PassHideIcon } from 'assets/svgIcons/PassHideIcon';
import { Link } from 'react-router-dom';
import { appSelectors } from 'store/reducers/app/app.slice';
import { useSelector } from 'react-redux';
import FormInput from 'ui/shared/formComponents/FormInput';
import { useMediaQuery } from 'react-responsive';

const Register = (props) => {
  const activeLanguage = useSelector(appSelectors.selectLanguage);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [agreeTermsError, setAgreeTermsError] = useState(false);
  const {
    activeTab,
    setAuthType,
    setUserName,
    registrationData,
    errorTranslatesData,
  } = props;
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const isPersonal = activeTab === tabs.personal;

  const onSubmit = async (data) => {
    if (!agreeTerms) {
      setAgreeTermsError(true);
      return;
    }
    setLoading(true);
    setFormError(null);

    const params = {
      username: data.email,
      fullName: isPersonal
        ? data.firstName + ' ' + data.lastName
        : data.companyName,
      password: data.password,
      mobile: data.mobile,
      repMobile: isPersonal ? null : data.repMobile,
      customerType: isPersonal ? userTypes.PERSONAL : userTypes.LEGAL,
      language: activeLanguage.value.toUpperCase(),
    };

    try {
      await api.auth.userRegister(params);
      setUserName(data.email);
      setAuthType(authTypes.registerVerify);
      localStorage.setItem('authType', authTypes.registerVerify);
    } catch (e) {
      setFormError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setFormError(null);
    };
  }, []);

  const newPasswordValue = watch('password');

  const checkForMinCharacters = () => {
    if (!newPasswordValue) return;

    return newPasswordValue.length >= 8 ? true : false;
  };
  const checkForOnlySymbols = () => {
    if (!newPasswordValue) return;
    var symbols = new RegExp(/[^A-Z a-z0-9]/);
    return symbols.test(newPasswordValue) ? true : false;
  };
  const checkForUpperCase = () => {
    if (!newPasswordValue) return;
    let matches = newPasswordValue.match(/[A-Z]/);
    let matchesLower = newPasswordValue.match(/[a-z]/);
    return matches != null && matchesLower != null ? true : false;
  };
  const checkForNumbers = () => {
    if (!newPasswordValue) return;
    let matches = newPasswordValue.match(/\d+/g);
    return matches != null ? true : false;
  };
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  return (
    <RegisterStyled className={formError ? 'formErr' : ''}>
      {formError ? (
        <FormErrorComponent
          error={formError}
          errorTranslatesData={errorTranslatesData}
        />
      ) : null}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={
          activeTab === tabs.personal
            ? 'registerModalPersonal'
            : 'registerModalCompany'
        }
      >
        {activeTab === tabs.personal ? (
          <>
            <RegisterRowHalf className="bodyM">
              <RegisterRowItem errors={errors.firstName}>
                <Controller
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label={getTextFromDataByKey(
                        'firstName',
                        registrationData,
                      )}
                      borderColor={errors.firstName}
                    />
                  )}
                  rules={{
                    validate: {
                      required,
                      onlyLatinLetters,
                      onlyLatinValidator,
                    },
                  }}
                  control={control}
                  name="firstName"
                />
                {errors?.firstName?.type === 'onlyLatinLetters' ? (
                  <FormFieldsErrorWrapper>
                    {getTextFromDataByKey('onlyLatins', registrationData)}
                  </FormFieldsErrorWrapper>
                ) : null}
              </RegisterRowItem>
              <RegisterRowItem errors={errors.lastName}>
                <Controller
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label={getTextFromDataByKey('lastName', registrationData)}
                      borderColor={errors.lastName}
                    />
                  )}
                  rules={{
                    validate: {
                      required,
                      onlyLatinLetters,
                      onlyLatinValidator,
                    },
                  }}
                  control={control}
                  name="lastName"
                />
                {errors?.lastName?.type === 'onlyLatinLetters' ? (
                  <FormFieldsErrorWrapper>
                    {getTextFromDataByKey('onlyLatins', registrationData)}
                  </FormFieldsErrorWrapper>
                ) : null}
              </RegisterRowItem>
            </RegisterRowHalf>
          </>
        ) : (
          <>
            <RegisterRowFull className="bodyM">
              <RegisterRowItem errors={errors.companyName}>
                <Controller
                  render={({ field }) => (
                    <FormInput
                      {...field}
                      type="text"
                      label={getTextFromDataByKey(
                        'companyName',
                        registrationData,
                      )}
                      borderColor={errors.companyName}
                    />
                  )}
                  rules={{
                    validate: {
                      required,
                      onlyLatinLetters,
                      onlyLatinValidator,
                    },
                  }}
                  control={control}
                  name="companyName"
                />
                {errors?.companyName?.type === 'onlyLatinLetters' ? (
                  <FormFieldsErrorWrapper>
                    {getTextFromDataByKey('onlyLatins', registrationData)}
                  </FormFieldsErrorWrapper>
                ) : null}
              </RegisterRowItem>
            </RegisterRowFull>
            {!isTabletOrMobile ? (
              <RegisterRowHalf className="bodyM">
                <RegisterRowItem>
                  <Controller
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        type="tel"
                        label={getTextFromDataByKey(
                          'mobileNumber',
                          registrationData,
                        )}
                        borderColor={errors.mobile}
                      />
                    )}
                    rules={{
                      required: 'Is Required',
                    }}
                    control={control}
                    name="mobile"
                  />
                </RegisterRowItem>
                <RegisterRowItem>
                  <Controller
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        type="tel"
                        label={getTextFromDataByKey(
                          'repMobile',
                          registrationData,
                        )}
                        borderColor={errors.repMobile}
                      />
                    )}
                    rules={{
                      required: 'Is Required',
                    }}
                    control={control}
                    name="repMobile"
                  />
                </RegisterRowItem>
              </RegisterRowHalf>
            ) : (
              <RegisterRowFull className="bodyM">
                <RegisterRowItem>
                  <Controller
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        type="tel"
                        label={getTextFromDataByKey(
                          'mobileNumber',
                          registrationData,
                        )}
                        borderColor={errors.mobile}
                      />
                    )}
                    rules={{
                      required: 'Is Required',
                    }}
                    control={control}
                    name="mobile"
                  />
                </RegisterRowItem>
                <RegisterRowItem>
                  <Controller
                    render={({ field }) => (
                      <FormInput
                        {...field}
                        type="tel"
                        label={getTextFromDataByKey(
                          'repMobile',
                          registrationData,
                        )}
                        borderColor={errors.repMobile}
                      />
                    )}
                    rules={{
                      required: 'Is Required',
                    }}
                    control={control}
                    name="repMobile"
                  />
                </RegisterRowItem>
              </RegisterRowFull>
            )}
          </>
        )}

        {activeTab === tabs.personal ? (
          <RegisterRowFull className="bodyM">
            <RegisterRowItem>
              <Controller
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="tel"
                    label={getTextFromDataByKey(
                      'mobileNumber',
                      registrationData,
                    )}
                    borderColor={errors.mobile}
                  />
                )}
                rules={{
                  required: 'Is Required',
                }}
                control={control}
                name="mobile"
              />
            </RegisterRowItem>
          </RegisterRowFull>
        ) : null}

        <RegisterRowFull className="bodyM">
          <RegisterRowItem errors={errors.email}>
            <Controller
              render={({ field }) => (
                <FormInput
                  {...field}
                  type="text"
                  label={getTextFromDataByKey('email', registrationData)}
                  borderColor={errors.email}
                />
              )}
              rules={{
                required: 'Is Required',
                validate: isEmail,
              }}
              control={control}
              name="email"
            />
          </RegisterRowItem>
        </RegisterRowFull>
        <RegisterRowFull className="bodyM last" registerWrap>
          <RegisterRowItem errors={errors.password}>
            <Controller
              render={({ field }) => (
                <FormInput
                  {...field}
                  type={passwordShown ? 'text' : 'password'}
                  label={getTextFromDataByKey('password', registrationData)}
                  borderColor={errors.password}
                />
              )}
              rules={{
                required: 'Is Required',
                validate: isValidPassword,
              }}
              control={control}
              name="password"
            />
            <span
              className={passwordShown ? 'showPass activeShown' : 'showPass'}
              onClick={() => setPasswordShown(!passwordShown)}
            >
              {passwordShown ? <PassShowIcon /> : <PassHideIcon />}
            </span>
          </RegisterRowItem>
        </RegisterRowFull>
        <PasswordRequirementsForAuth
          className="bodyS"
          margin="6px 0px 24px 0px"
        >
          <div>
            <span
              className={
                checkForUpperCase()
                  ? 'isValid'
                  : newPasswordValue &&
                    newPasswordValue.length > 0 &&
                    !checkForUpperCase()
                  ? 'inValid'
                  : ''
              }
            >
              {getTextFromDataByKey('bulletUppercase', registrationData)}
            </span>
            <span
              className={
                checkForNumbers()
                  ? 'isValid'
                  : newPasswordValue &&
                    newPasswordValue.length > 0 &&
                    !checkForNumbers()
                  ? 'inValid'
                  : ''
              }
            >
              {getTextFromDataByKey('bulletNumber', registrationData)}
            </span>
            <span
              className={
                checkForOnlySymbols()
                  ? 'isValid'
                  : newPasswordValue &&
                    newPasswordValue.length > 0 &&
                    !checkForOnlySymbols()
                  ? 'inValid'
                  : ''
              }
            >
              {getTextFromDataByKey('bulletSymbol', registrationData)}
            </span>
            <span
              className={
                checkForMinCharacters()
                  ? 'isValid'
                  : newPasswordValue &&
                    newPasswordValue.length > 0 &&
                    !checkForMinCharacters()
                  ? 'inValid'
                  : ''
              }
            >
              {getTextFromDataByKey('bulletMinCharacters', registrationData)}
            </span>
          </div>
        </PasswordRequirementsForAuth>

        <div className="agreement">
          <Controller
            render={({ field }) => (
              <FormCheckBoxState
                {...field}
                checked={agreeTerms}
                label={getTextFromDataByKey('agreeTerms', registrationData)}
                labelColored={
                  <Link target="_blank" to={`/terms-of-use`}>
                    {getTextFromDataByKey('terms', registrationData)}
                  </Link>
                }
                labelPosition="right"
                value="yes"
                stroke={agreeTermsError ? '#f55e1b' : undefined}
                onChange={() => {
                  setAgreeTerms(!agreeTerms);
                  setAgreeTermsError(false);
                }}
              />
            )}
            control={control}
            name="agreeTerms"
          />
        </div>
        <BtnGroupMobile>
          <Button loading={loading} className="submit button1">
            {getTextFromDataByKey('registrationBtn', registrationData)}
          </Button>
        </BtnGroupMobile>
      </form>
    </RegisterStyled>
  );
};

export default Register;
