import { TickIcon } from 'assets/svgIcons/TickIcon';
import { userTypes } from 'constants/global.constants';
import { Button } from 'library/button/Button';
import CustomSelect from 'library/Select/Select';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import FormInputBasic from 'ui/shared/formComponents/FormInputBasic';
import {
  TransactionsFilterWrapper,
  ExportInputGroup,
  ExportButton,
  UserTypeCheck,
  ExportInputGroupDate,
  TransactionListRowHalf,
} from 'ui/styled/admin.styled';
import formatDate, { DateFormats } from 'utils/date';

const UserListFilter = ({
  loading,
  getUserList,
  userFilters,
  setUserFilters,
  activeUserType,
  setActiveUserType,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const onSubmit = async (data) => {
    const params = {
      status: data?.userStatus?.value || '',
      customerType: activeUserType || '',
      query: data?.query || '',
      fromDate: fromDate
        ? formatDate({
            inputDate: fromDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      toDate: toDate
        ? formatDate({
            inputDate: toDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
    };
    const filtersData = {
      ...params,
      fromDate,
      toDate,
    };
    setUserFilters(filtersData);
    getUserList(params, true);
  };

  useEffect(() => {
    if (userFilters) {
      reset({
        query: userFilters?.query,
        userStatus: userFilters?.status
          ? { label: userFilters?.status, value: userFilters?.status }
          : '',
      });
      setActiveUserType(activeUserType);
      setFromDate(userFilters.fromDate);
      setToDate(userFilters.toDate);
    }
  }, []);

  return (
    <TransactionsFilterWrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ExportInputGroup>
          <Controller
            render={({ field }) => (
              <FormInputBasic
                {...field}
                type="text"
                placeholder="Search by keyword"
                borderColor={errors.query}
              />
            )}
            control={control}
            name="query"
          />
        </ExportInputGroup>
        <ExportInputGroup>
          <Controller
            render={({ field }) => (
              <CustomSelect
                {...field}
                type="text"
                search={false}
                placeholder="User status"
                isClearable
                options={[
                  {
                    label: 'Incomplete',
                    value: 'INCOMPLETE',
                  },
                  {
                    label: 'Verified',
                    value: 'VERIFIED',
                  },
                  {
                    label: 'Demo',
                    value: 'DEMO',
                  },
                  {
                    label: 'Payed',
                    value: 'PAYED',
                  },
                ]}
              />
            )}
            control={control}
            defaultValue={userFilters?.userStatus}
            name="userStatus"
          />
        </ExportInputGroup>
        <ExportInputGroupDate>
          <TransactionListRowHalf>
            <ReactDatePicker
              dateFormat="d MMM yyyy"
              selected={fromDate}
              placeholderText="From Date"
              onChange={(date) => setFromDate(date)}
              isClearable
            />
          </TransactionListRowHalf>
          <TransactionListRowHalf>
            <ReactDatePicker
              dateFormat="d MMM yyyy"
              selected={toDate}
              placeholderText="To Date"
              onChange={(date) => setToDate(date)}
              isClearable
            />
          </TransactionListRowHalf>
        </ExportInputGroupDate>
        <ExportInputGroup>
          <UserTypeCheck
            active={userTypes.PERSONAL === activeUserType}
            onClick={() => {
              if (userTypes.PERSONAL === activeUserType) {
                setActiveUserType(null);
                return;
              }
              setActiveUserType(userTypes.PERSONAL);
            }}
          >
            {userTypes.PERSONAL === activeUserType ? <TickIcon /> : null}
            Personal
          </UserTypeCheck>
          <UserTypeCheck
            active={userTypes.LEGAL === activeUserType}
            onClick={() => {
              if (userTypes.LEGAL === activeUserType) {
                setActiveUserType(null);
                return;
              }
              setActiveUserType(userTypes.LEGAL);
            }}
          >
            {userTypes.LEGAL === activeUserType ? <TickIcon /> : null}
            Corporate
          </UserTypeCheck>
        </ExportInputGroup>

        <ExportButton>
          <Button className="submit" loading={loading}>
            Filter
          </Button>
        </ExportButton>
      </form>
    </TransactionsFilterWrapper>
  );
};

export default UserListFilter;
