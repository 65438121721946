import styled from 'styled-components';

export const CheckboxWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  ${(disabled) => {
    return {
      // opacity: disabled ? 0.8 : 1,
      // cursor: disabled ? 'not-allowed' : 'pointer'
    };
  }}
  @media(max-width:900px) {
    margin-bottom: auto;
    svg {
      width: 26px;
      height: 26px;
    }
  }
  @media (max-height: 760px) {
    margin-bottom: auto;
  }
`;

export const CheckboxLabelStyled = styled.div`
  display: flex;
  font-weight: 400;
  color: #8a87a5;
  a {
    color: #242333;
    padding-left: 2px;
    font-weight: 500;
  }
`;
