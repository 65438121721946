import styled from 'styled-components';

export const LeftSliderTitle = styled.div`
  color: #242333;
  font-weight: 400;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
`;
export const LeftSliderText = styled.div`
  color: #8a87a5;
  font-weight: 400;
  margin-bottom: 28px;
  line-height: 24px;
  @media (max-width: 900px) {
    line-height: 20px;
  }
`;
export const BlogsSliderWrapper = styled.div`
  position: relative;
  padding: 100px 14%;
  background: rgba(252, 250, 245, 1) 0% 0% no-repeat padding-box;
  margin-bottom: 100px;

  @media (max-width: 1540px) {
    padding: 0 8%;
  }

  .active {
    position: relative;
    background: rgba(245, 240, 229, 1) 0% 0% no-repeat padding-box;
    border-radius: 0px 12px 12px 0px;
    transition: all 1s ease-out;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 2px;
      background-color: #9f998b;
      z-index: 2;
      opacity: 1;
    }
    ${LeftSliderTitle} {
      font-weight: 500;
    }
  }

  .slider {
    background-color: unset;
  }

  @media (max-width: 900px) {
    padding: 0 28px;
    margin-bottom: 44px;

    .active::before {
      display: none;
    }
    .active {
      border-radius: 12px;
      padding: 20px 18px;
      transition: all 0.6s ease-out !important;
    }
    .title {
      font-size: 16px;
    }
  }
`;

export const BlogsSliderTitle = styled.div`
  margin-bottom: 33px;
  color: #242333;
  line-height: 50px;
  max-width: 400px;
`;

export const LeftSliderWrapper = styled.div`
  padding: 24px 0px 24px 42px;
  a {
    margin-right: 6px;
    font-weight: 500;
    color: #242333;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  .link-text {
    font-weight: 500;
    margin-right: 6px;
  }
  @media (max-width: 900px) {
    padding: 20px 18px;
  }
`;
export const LeftSliders = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    left: 0%;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #9f998b;
    z-index: 2;
    opacity: 0.4;
  }
  @media (max-width: 900px) {
    ::before {
      display: none;
    }
  }
`;
export const SlidersContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8%;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

export const RightSliderWithBanner = styled.div`
  transition: transform 0.3s ease-out;
  transform: translateX(0);
  img {
    max-height: 485px;
    @media (max-width: 900px) {
      width: 100%;
    }
  }
`;
export const ActiveLine = styled.div`
  position: absolute;
  left: 0;
  top: ${(props) => props?.top}px;
  transition: top 0.3s ease-out;
  width: 2px;
  height: ${(props) => props?.height}px;
  background-color: #9f998b;
  z-index: 2;
`;
