import React from 'react';

export const LeftArrowCIrcle = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="39"
      viewBox="0 0 39 39"
      className="icon-color-1"
    >
      <g id="Preview" opacity="0.5">
        <g id="next" transform="translate(14.674 13.625)">
          <g
            id="Group_40"
            data-name="Group 40"
            transform="translate(0 12.216) rotate(-90)"
          >
            <g id="Group_39" data-name="Group 39" transform="translate(0)">
              <path
                id="Path_52"
                data-name="Path 52"
                d="M10.543,7.723,6.108,3.355,1.673,7.723,0,6.062,6.108,0l6.108,6.062Z"
                fill="#242333"
                opacity={0.5}
              />
            </g>
          </g>
        </g>
        <g
          id="Ellipse_357"
          data-name="Ellipse 357"
          fill="none"
          stroke="#242333"
          stroke-width="2"
          opacity={0.5}
        >
          <circle cx="19.5" cy="19.5" r="19.5" stroke="none" />
          <circle cx="19.5" cy="19.5" r="18.5" fill="none" />
        </g>
      </g>
    </svg>
  );
};
