import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
  CarouselArrow,
  CarouselContainer,
  CarouselButtons,
  IconContainer,
  NewsSliderItem,
} from '../../styled/SliderComponentStyled';
import { RightArrowCIrcle } from 'assets/svgIcons/RightArrowCIrcle';
import { LeftArrowCIrcle } from 'assets/svgIcons/LeftArrowCircle';
import { textRenderer } from 'utils/helpers';
import StarRating from './StarRating';
import { htmlParser } from 'utils/htmlUtils';

const SliderComponent = ({ items }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1540 },
      items: 1,
    },
    laptop: {
      breakpoint: { max: 1540, min: 1440 },
      items: 1,
    },
    lap: {
      breakpoint: { max: 1440, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 510 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 510, min: 0 },
      partialVisibilityGutter: 72,
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous, carouselState }) => {
    const { currentSlide, totalItems, slidesToShow } = carouselState;
    const start = currentSlide === 0;
    const end = totalItems === currentSlide + slidesToShow;

    if (items?.length <= 1) {
      return null;
    }
    return (
      <CarouselButtons className="news-buttons">
        <CarouselArrow
          disable={start}
          onClick={previous}
          className="left-arrow"
        >
          <IconContainer>
            <LeftArrowCIrcle />
          </IconContainer>
        </CarouselArrow>
        <CarouselArrow disable={end} onClick={next} className="right-arrow">
          <IconContainer>
            <RightArrowCIrcle />
          </IconContainer>
        </CarouselArrow>
      </CarouselButtons>
    );
  };

  return (
    <CarouselContainer className="news-carousel">
      <Carousel
        renderButtonGroupOutside
        // partialVisible={isMobile}
        responsive={responsive}
        removeArrowOnDeviceType={['mobile']}
        arrows={false}
        autoPlay={false}
        autoPlaySpeed={10000000}
        infinite
        customButtonGroup={<ButtonGroup />}
        itemClass="news-carousel-item"
      >
        {items?.map((items) => (
          <>
            <StarRating starsData={items?.score} />
            <NewsSliderItem className="Blogsbody" key={items?.id}>
              {htmlParser(textRenderer(items?.description))}
            </NewsSliderItem>
          </>
        ))}
      </Carousel>
    </CarouselContainer>
  );
};

export default SliderComponent;
