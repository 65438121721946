import React from 'react';
import {
  ConfirmDeleteModalButtons,
  ConfirmPayModalIcon,
  ConfirmDeleteModalStyled,
  ConfirmDeleteModalSubTitle,
  ConfirmDeleteModalTitle,
} from 'ui/styled/cartPage.styled';
import confirmCardIcon from 'assets/img/ConfirmCard.svg';
import FormErrorComponent from 'ui/components/common/FormError';
import { Button } from 'library/button/Button';
import { htmlParser } from 'utils/htmlUtils';
import { useMediaQuery } from 'react-responsive';

const ConfirmBuyModal = (props) => {
  const {
    confirmPay,
    totalPrice,
    paymentError,
    paymentLoading,
    data,
    errorTranslatesData,
  } = props;

  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  return (
    <ConfirmDeleteModalStyled confirmBuy>
      <ConfirmPayModalIcon
        style={{
          marginBottom: '19px',
        }}
      >
        <img src={confirmCardIcon} alt="Confirm Cart" />
      </ConfirmPayModalIcon>
      <ConfirmDeleteModalTitle
        className={paymentError ? 'headline4 error' : 'headline4'}
      >{`₾ ${totalPrice.toFixed(2)}`}</ConfirmDeleteModalTitle>
      {paymentError && isTabletOrMobile ? (
        <FormErrorComponent
          error={paymentError}
          errorTranslatesData={errorTranslatesData}
        />
      ) : null}
      <ConfirmDeleteModalSubTitle className="bodyL">
        {htmlParser(data.card.payNow.description)}
      </ConfirmDeleteModalSubTitle>

      <ConfirmDeleteModalButtons
        style={{ marginBottom: '0' }}
        className="button1"
      >
        {paymentError && !isTabletOrMobile ? (
          <FormErrorComponent
            error={paymentError}
            errorTranslatesData={errorTranslatesData}
          />
        ) : null}
        <Button
          loading={paymentLoading}
          className="delete"
          type="submit"
          height="50px"
          onClick={confirmPay}
        >
          {data.card.payNow.btn}
        </Button>
      </ConfirmDeleteModalButtons>
    </ConfirmDeleteModalStyled>
  );
};

export default ConfirmBuyModal;
