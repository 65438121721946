import { userTypes } from 'constants/global.constants';
import { Button } from 'library/button/Button';
import { StrapiDataContext } from 'Main';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import api from 'services/api';
import { appSelectors } from 'store/reducers/app/app.slice';
import { userSelectors } from 'store/reducers/user/user.slice';
import FormErrorComponent from 'ui/components/common/FormError';
import {
  BtnGroupDemo,
  RegisterRowFull,
  RegisterRowItem,
} from 'ui/styled/auth.styled';
import { DemoModalFormStyled } from 'ui/styled/home.styled';
import { getTextFromDataByKey } from 'utils/helpers';
import { Demotabs } from './DemoModal';

const DemoModalForm = (props) => {
  const { activeTab, activeDemoKey, setShowDemoSuccess, homePageData } = props;
  const activeLanguage = useSelector(appSelectors.selectLanguage);
  const { settingsData } = useContext(StrapiDataContext);
  const errorTranslatesData =
    settingsData?.data?.data?.attributes?.errorTranslate;

  const activeUser = useSelector(userSelectors.userInfo);
  const nameValue =
    activeUser && activeUser.fullName ? activeUser.fullName.split(' ') : null;

  const [demoError, setDemoError] = useState(null);
  const [demoLoading, setDemoLoading] = useState(false);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setDemoLoading(true);
    setShowDemoSuccess(false);
    const params =
      activeTab === Demotabs.PERSONAL
        ? {
            email: data.email,
            customerType: userTypes.PERSONAL,
            fullName: data.name + ' ' + data.surname,
            productKey: activeDemoKey,
            mobile: data.phone,
            language: activeLanguage.value.toUpperCase(),
          }
        : {
            customerType: userTypes.LEGAL,
            fullName: data.companyName,
            mobile: data.mobileNumber,
            productKey: activeDemoKey,
            email: data.companyEmail,
            language: activeLanguage.value.toUpperCase(),
          };
    try {
      await api.user.purchaseDemo(params);
      setShowDemoSuccess(true);
    } catch (e) {
      setDemoError(e);
    } finally {
      setDemoLoading(false);
    }
  };

  useEffect(() => {
    if (activeUser) {
      if (activeUser.customerType === userTypes.PERSONAL) {
        reset({
          name: nameValue[0],
          surname: nameValue[1],
          phone: activeUser.mobile,
          email: activeUser.username,
        });
      } else if (activeUser && activeUser.customerType === userTypes.LEGAL) {
        reset({
          companyName: activeUser.fullName,
          mobileNumber: activeUser.mobile,
          companyEmail: activeUser.username,
        });
      }
    }
  }, []);

  return (
    <DemoModalFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        {demoError && isTabletOrMobile ? (
          <FormErrorComponent
            error={demoError}
            errorTranslatesData={errorTranslatesData}
          />
        ) : null}
        <RegisterRowFull>
          {activeTab === Demotabs.PERSONAL ? (
            <>
              <RegisterRowItem>
                <input
                  className={errors.name ? 'errorBorder' : ''}
                  type="text"
                  name="name"
                  {...register('name', { required: true })}
                  placeholder={getTextFromDataByKey(
                    'userName',
                    homePageData?.demoModal?.enum,
                  )}
                />
              </RegisterRowItem>
              <RegisterRowItem>
                <input
                  className={errors.surname ? 'errorBorder' : ''}
                  type="text"
                  name="surname"
                  {...register('surname', { required: true })}
                  placeholder={getTextFromDataByKey(
                    'userSurname',
                    homePageData?.demoModal?.enum,
                  )}
                />
              </RegisterRowItem>
              <RegisterRowItem>
                <input
                  className={errors.phone ? 'errorBorder' : ''}
                  type="text"
                  name="phone"
                  {...register('phone', { required: true })}
                  placeholder={getTextFromDataByKey(
                    'userPhone',
                    homePageData?.demoModal?.enum,
                  )}
                />
              </RegisterRowItem>
              <RegisterRowItem>
                <input
                  className={errors.email ? 'errorBorder' : ''}
                  type="email"
                  name="email"
                  {...register('email', { required: true })}
                  placeholder={getTextFromDataByKey(
                    'userEmail',
                    homePageData?.demoModal?.enum,
                  )}
                />
              </RegisterRowItem>
            </>
          ) : (
            <>
              <RegisterRowItem>
                <input
                  className={errors.companyName ? 'errorBorder' : ''}
                  type="text"
                  name="companyName"
                  {...register('companyName', { required: true })}
                  placeholder={getTextFromDataByKey(
                    'companyName',
                    homePageData?.demoModal?.enum,
                  )}
                />
              </RegisterRowItem>
              <RegisterRowItem>
                <input
                  className={errors.mobileNumber ? 'errorBorder' : ''}
                  type="text"
                  name="mobileNumber"
                  {...register('mobileNumber', { required: true })}
                  placeholder={getTextFromDataByKey(
                    'userPhone',
                    homePageData?.demoModal?.enum,
                  )}
                />
              </RegisterRowItem>
              <RegisterRowItem>
                <input
                  className={errors.companyEmail ? 'errorBorder' : ''}
                  type="email"
                  name="companyEmail"
                  {...register('companyEmail', { required: true })}
                  placeholder={getTextFromDataByKey(
                    'userEmail',
                    homePageData?.demoModal?.enum,
                  )}
                />
              </RegisterRowItem>
            </>
          )}
        </RegisterRowFull>

        {demoError && !isTabletOrMobile ? (
          <FormErrorComponent
            error={demoError}
            errorTranslatesData={errorTranslatesData}
          />
        ) : null}

        <BtnGroupDemo>
          <Button loading={demoLoading} className="submit">
            {getTextFromDataByKey('userButton', homePageData?.demoModal?.enum)}
          </Button>
        </BtnGroupDemo>
      </form>
    </DemoModalFormStyled>
  );
};

export default DemoModalForm;
