import React from "react";
export const DropIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.293"
    height="5.778"
    viewBox="0 0 9.293 5.778"
  >
    <path
      id="drop"
      d="M0,0,3,3.331,0,6.467"
      transform="translate(7.881 1.414) rotate(90)"
      fill="none"
      stroke="#3a3853"
      strokeLinecap="round"
      strokeWidth="2"
    />
  </svg>
);
