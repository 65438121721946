import { TOKEN_NAME } from 'constants/global.constants';
import api from 'services/api';
import cacheManager from 'utils/cacheManager';
import { user } from './user.slice';
import {
  apiHeaderNameResponse,
  deleteAuthHeader,
  setAuthHeader,
} from 'services/axios';

const tokenCache = cacheManager(TOKEN_NAME);

export const getCurrentUser =
  (withLoading = true) =>
  async (dispatch) => {
    if (withLoading) dispatch(user.actions.loginStartAction());

    const { data } = await api.auth.getCurrentUser();

    dispatch(user.actions.loginSuccessAction(data));
  };

export const login = (params) => async (dispatch) => {
  dispatch(user.actions.loginStartAction());
  try {
    const fetchedUser = await api.auth.login(params);
    const userData = {
      userInfo: fetchedUser.data,
      token: fetchedUser.headers[apiHeaderNameResponse.AUTH_TOKEN],
    };
    tokenCache.set(userData.token);
    setAuthHeader(userData.token);
    dispatch(getCurrentUser(false));

    return Promise.resolve(userData);
  } catch (e) {
    dispatch(user.actions.loginErrorAction());
    return Promise.reject(e);
  }
};

export const logout =
  (sendRequest = false, unAuthRedir = false) =>
  async (dispatch) => {
    try {
      if (unAuthRedir) {
        dispatch(user.actions.unAuthRedirAction());
      }
      if (sendRequest) {
        await api.auth.logout();
      }
    } finally {
      dispatch(user.actions.logoutAction());
      dispatch({ type: 'USER_LOGGED_OUT' });
      tokenCache.unset();
      deleteAuthHeader();
    }
  };
