import styled from 'styled-components';

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const SearchIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InputIconWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 5.06px;
  img {
    max-width: 15px;
    max-height: 15px;
  }
`;
export const InputStyled = styled.input`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  outline: 0;
  ::placeholder {
  }

  ${({
    width,
    height,
    fontSize,
    background,
    color,
    border,
    padding,
    opacity,
  }) => {
    return {
      width,
      height,
      border,
      padding,
      opacity,
      'font-size': fontSize,
      backgroundColor: background,
      color,
    };
  }};
`;
