export const TOKEN_NAME = 'token';

export const ROOT_PATH = '';
export const ENTRY_POINT_PATH = `${ROOT_PATH}/solutions`;
export const imagesBaseUrl = process.env.REACT_APP_IMAGES_BASE_URL;

export const getDataUrlTypes = {
  homeIntro:
    'solution?populate=ourPartners.repeatable.media,demoModal.enum,demoModal.success,header.media,header.banner,slider.images,slider.video,banners.XS,banners.MD,banners.LG,seo.name.img,seo.property.img',
  homeProducts:
    'solutions-lists?populate=img,responsiveImg,prices,seo.name.img,seo.property.img,modal&sort=sort',
  hardwareList:
    'hardwares?populate=img,seo.name.img,seo.property.img,prices&sort=sort',
  serviceList:
    'services?populate=img,prices,modal,seo.name.img,seo.property.img,prices.property,cartRemoveModal.checkProducts&sort=sort',
  servicePage: 'services-page?populate=titles,seo.name.img,seo.property.img',
  hardwarePage: 'hardwares-page?populate=titles,seo.name.img,seo.property.img',
  faq: 'faq?populate=accordion.block,search.notFound.img,seo.name.img,seo.property.img,tagDropDownTranslate ',
  terms: 'terms-of-use?populate=accordion,seo.name.img,seo.property.img',
  settings:
    'setting?populate=navigation,language,footer.social.logo,seo.name.img,seo.property.img,footer.contact,footer.navigation,enumTranslate,globalTranslate,dropDownNavigation,headerTranslates,baseUrlReplace,fieldOfActivityTranslate,facebook,errorTranslate,footerNav.pages',
  userProfile:
    'profile?populate=personalInfo,changePassword,seo.name.img,seo.property.img,passwordChange',
  authInfo:
    'auth?populate=registration,logIn,verifyEmail,passwordRecovery,seo.name.img,seo.property.img,forgotPassword,modal.PasswordReset,modal.badUrl,modal.emailVerification,modal.changePassword',
  transactionHistory:
    'transaction?populate=transactionTable.translate,checkModal,seo.name.img,seo.property.img,search.notFound',
  currentProducts:
    'product?populate=onPremise.translate,billing.translate,seo.name.img,seo.property.img,payNow,cancelSubscription,billingSearch.notFound,onPremiseSearch.notFound',
  cart: 'cart?populate=table.translate,clearCart,checkout,seo.name.img,seo.property.img,card.payNow,card.translate,card.addCardSuccess,card.addCardFailed,card.addCardTerms,card.deleteCard,emptyCard,traningModal&locale=ka',
  offers:
    'offer?populate=header.media,header.banner,slider.images,seo.name.img,seo.property.img,slider.video,banners.XS,banners.MD,banners.LG',
  offerProducts:
    'solutions-lists?populate=img,responsiveImg,prices,seo.name.img,seo.property.img,modal&sort=sort',
  blogs: (slug) =>
    `businesses?filters[slug]=${slug}&populate=seo.name.img,seo.property.img,list_blocks.banner,list_blocks.responsiveBanner,whyChoose.icon,slider.list.banner,banner.button,accordion.block,banner.button,review.person.img,banner.desktop,banner.responsive,banner.button`,
};

export const userTypes = {
  LEGAL: 'LEGAL',
  PERSONAL: 'PHYSICAL',
};

export const payTypes = {
  MONTHLY: 'M1',
  ANNUAL: 'Y1',
  ON_PREMISE: 'PREMISE',
};

export const getCartPeriods = {
  MONTH1: 'MONTH1',
  MONTH12: 'MONTH12',
  UNLIMITED: 'UNLIMITED',
};

export const cartSuccessErrorUrls = {
  SUCCESS: 'addCardSuccess',
  ERROR: 'addCardError',
};

export const productTypesFromCart = {
  SERVICE: 'SERVICE',
  SUPPORTS: 'supports',
};
