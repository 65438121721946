import styled from 'styled-components';

export const StarRatingWrapper = styled.div`
  margin-bottom: 28px;
  .style-module_starRatingWrap__q-lJC {
    .style-module_simpleStarRating__nWUxf {
      .star-svg {
        width: 20px;
        height: 19px;
        /* margin-right: 4px; */
      }
      .style-module_emptyIcons__Bg-FZ {
        color: rgb(159, 153, 139) !important;
      }
      .style-module_fillIcons__6---A {
        color: rgb(242, 210, 14) !important;
        /* width: 88% !important; */
      }
    }
  }
`;
