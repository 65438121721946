import React from 'react';
export const CloseIcon = ({ ...rest }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="#fff"
		stroke="#fff"
		strokeWidth="2"
		style={{ cursor: 'pointer', overflow: 'visible' }}
		{...rest}
	>
		<line x1="18" y1="6" x2="6" y2="18" />
		<line x1="6" y1="6" x2="18" y2="18" />
	</svg>
);