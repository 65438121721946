import styled from 'styled-components';

export const TransactionsModalWrapper = styled.div`
  margin-bottom: 45px;
  height: 100%;
  .track-vertical {
    position: absolute;
    width: 6px;
    top: 0;
    right: 0;
    background: transparent;
    height: 100%;
  }
  .thumb-vertical {
    background: rgba(153, 153, 153, 0.38);
    border-radius: 8px;
  }
  .view {
    padding-right: 15px;
    overflow-x: hidden !important;
    margin-right: -9px !important;
    margin-bottom: -9px !important;
  }
  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }
`;

export const TransactionsModalTitle = styled.div`
  color: #242333;
  font-weight: bold;
  margin-bottom: 13px;
  padding-top: 30px;
  padding-left: 15px;
  @media (max-width: 900px) {
    padding-left: 0;
  }
`;
export const TransactionsModalPaymentTime = styled.div`
  color: #8a87a5;
  font-weight: 400;
  margin-bottom: 30px;
  padding-left: 15px;
  @media (max-width: 900px) {
    padding-left: 0;
  }
`;

export const ProductInfoRow = styled.div`
  display: flex;
  margin-bottom: 14px;
  align-items: baseline;
  justify-content: space-between;
  flex-basis: 100%;
  padding: 0px 15px;
  span {
    border-top: 2px dashed #d9d7e2;
    flex: 1 0 auto;
  }
  @media (max-width: 900px) {
    padding: 0;
  }
`;
export const DashedLine = styled.div`
  height: 0;
  margin: 28px 15px;
  border-top: 1px solid #f0eff7;
`;
export const BottomDashedLine = styled.hr`
  border: 0;
  border-top: 4px dashed #f5f5f5;
  width: 100%;
  height: 12px;
  position: absolute;
  bottom: 15px;
  left: 0;
`;
export const ProductNames = styled.div`
  flex: 0 0 auto;
  padding-right: 28px;
  font-weight: 400;
  color: #8a87a5;
`;

export const ProductDetails = styled.div`
  flex: 0 1 auto;
  text-align: end;
  font-weight: 500;
  color: #3a3853;
  margin-left: 20px;
`;
export const ProductsTotalPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(235, 234, 244, 0.4);
  border-radius: 6px;
  padding: 16px 15px;
  margin: 0px 0px 10px 0px;
  .totalPrice {
    font-weight: bold;
    color: #3a3853;
  }
  :before {
    content: '';
    position: absolute;
  }
  @media (max-width: 900px) {
    margin-top: auto;
  }
`;
