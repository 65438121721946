import React from 'react';

export const HideIcon = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="58"
    height="58"
    viewBox="0 0 58 58"
    {...rest}
  >
    <g id="hide" transform="translate(-277 -413)">
      <rect
        id="Rectangle_48"
        data-name="Rectangle 48"
        width="58"
        height="58"
        rx="6"
        transform="translate(277 413)"
        fill="#fff8cc"
      />
      <line
        id="Line_18"
        data-name="Line 18"
        x2="15"
        transform="translate(298.5 442.5)"
        fill="none"
        stroke="#242333"
        strokeWidth="3"
      />
    </g>
  </svg>
);
