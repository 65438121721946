import React from 'react';

export const FilterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.225"
    height="18.1"
    viewBox="0 0 21.225 18.1"
  >
    <g id="Filter" transform="translate(-101.06 -45.7)">
      <path
        id="Path_65313"
        data-name="Path 65313"
        d="M145.03,45.75h2.254V56.457H145.03Z"
        transform="translate(-42.229 0)"
        fill="#242333"
        stroke="#242333"
        strokeWidth="0.1"
      />
      <path
        id="Path_65314"
        data-name="Path 65314"
        d="M320.72,45.75h2.254v5.072H320.72Z"
        transform="translate(-210.175 0)"
        fill="#242333"
        stroke="#242333"
        strokeWidth="0.1"
      />
      <path
        id="Path_65315"
        data-name="Path 65315"
        d="M496.41,45.75h2.254V56.457H496.41Z"
        transform="translate(-378.12 0)"
        fill="#242333"
        stroke="#242333"
        strokeWidth="0.1"
      />
      <path
        id="Path_65316"
        data-name="Path 65316"
        d="M106.746,384.738v-2.254H101.11v2.254H102.8v2.818h2.254v-2.818Z"
        transform="translate(0 -323.806)"
        fill="#242333"
        stroke="#242333"
        strokeWidth="0.1"
      />
      <path
        id="Path_65317"
        data-name="Path 65317"
        d="M282.426,238.328v-2.254H276.79v2.254h1.691v8.453h2.254v-8.453Z"
        transform="translate(-167.936 -183.032)"
        fill="#242333"
        stroke="#242333"
        strokeWidth="0.1"
      />
      <path
        id="Path_65318"
        data-name="Path 65318"
        d="M454.172,384.738v2.818h2.254v-2.818h1.691v-2.254h-5.636v2.254Z"
        transform="translate(-335.882 -323.806)"
        fill="#242333"
        stroke="#242333"
        strokeWidth="0.1"
      />
    </g>
  </svg>
);
