import React from 'react';
import { Button } from 'library/button/Button';
import {
  GreyHeadingStyled,
  TopParagraphStyled,
  HeadingStyled,
  BlogTextStyled,
  BlogButtonWrapper,
  BlogParagraphStyled,
} from 'ui/styled/BlogParagraphStyled';
import { AdditionalTitleServ } from 'ui/styled/PlansSection.styled';
import { htmlParser } from 'utils/htmlUtils';
import {
  BlogParagraphListTitleStyled,
  BlogParagraphListWrapper,
  BlogsPlan,
} from 'ui/styled/BlogPageStyled';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from 'react-router-dom';

const BlogParagraph = (props) => {
  const {
    title,
    description,
    paragraphButton,
    additionalTitle,
    additionalTitleColored,
    firstBlog,
    paragraphButtonUrl,
    planAdditionalTitle,
  } = props;

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate(paragraphButtonUrl);
  };
  function containsUnorderedList(description) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, 'text/html');
    return doc.querySelector('ul') !== null;
  }
  const isBulletList = containsUnorderedList(description);

  return isBulletList ? (
    <>
      <BlogParagraphListTitleStyled>
        {planAdditionalTitle ? (
          <GreyHeadingStyled>
            <div className="addit-title-grey">{planAdditionalTitle}</div>
          </GreyHeadingStyled>
        ) : null}
        <div className="plan-title Blogsheadline1">{title}</div>
      </BlogParagraphListTitleStyled>

      <BlogParagraphListWrapper>
        <BlogsPlan className="Blogsbody">
          {ReactHtmlParser(description)}
        </BlogsPlan>
        {paragraphButton ? (
          <BlogButtonWrapper>
            <Button
              background="#FFDC04"
              color="#242333"
              padding="7px 138px"
              height="60px"
              fontWeight="500"
              className="cart-btn"
              onClick={() => {
                handleButtonClick();
              }}
            >
              {paragraphButton}
            </Button>
          </BlogButtonWrapper>
        ) : undefined}
      </BlogParagraphListWrapper>
    </>
  ) : (
    <TopParagraphStyled>
      <BlogParagraphStyled>
        {additionalTitle ? (
          <GreyHeadingStyled>
            <div className="addit-title-grey">{additionalTitle}</div>
          </GreyHeadingStyled>
        ) : undefined}

        <HeadingStyled
          className={firstBlog ? 'Blogsheadline2' : 'Blogsheadline1WithCaps'}
        >
          {title}
          {additionalTitleColored ? (
            <AdditionalTitleServ>{additionalTitleColored}</AdditionalTitleServ>
          ) : null}
        </HeadingStyled>

        <BlogTextStyled
          className={firstBlog ? 'Blogs-first-paragraph-body' : 'Blogsbody'}
        >
          {htmlParser(description)}
        </BlogTextStyled>
      </BlogParagraphStyled>
      {paragraphButton ? (
        <BlogButtonWrapper>
          <Button
            padding="7px 119px"
            background="#FFDC04"
            color="#242333"
            fontWeight="500"
            height="60px !important"
            onClick={() => handleButtonClick()}
          >
            {paragraphButton}
          </Button>
        </BlogButtonWrapper>
      ) : undefined}
    </TopParagraphStyled>
  );
};

export default BlogParagraph;
