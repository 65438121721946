import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CartDropdownListItem,
  CartListItemLeft,
  CartListItemPrice,
  CartListItemRight,
  CartListItemTitle,
  CartListItemType,
} from '../header.styled';

import api from 'services/api';
import { IncrementalInputCounter } from 'library/incrementalInput/incrementalInput.styled';
import {
  getTextFromDataByPeriod,
  getTextFromDataByProductKey,
} from 'utils/helpers';
import { productTypesFromCart } from 'constants/global.constants';
import { debounce } from 'lodash';

const CartListItem = (props) => {
  const { data, getCartData, className, translatesData, servicesData } = props;

  const isPermise = data.isPermise;
  const isBasic = data.product.productKey.includes('_BASIC');
  const translateKeys = translatesData.data.data.attributes.enumTranslate;
  const [checkIfCountableServicesItem, setCheckIfCountableServicesItem] =
    useState(true);

  const updateItemInCart = async (key, qyt) => {
    const keyToSend = key.split('_BASIC')[0];

    const filteredObjects = servicesData?.filter((obj) => {
      return (
        obj.attributes &&
        obj.attributes.prices &&
        obj.attributes.prices.some((price) => {
          return price.productKey === keyToSend;
        })
      );
    });

    const checkIfCountableServicesItem =
      filteredObjects[0]?.attributes?.countable;

    if (checkIfCountableServicesItem === false && qyt > 0) return;

    try {
      await api.cart.addItemToCart(keyToSend, qyt);
      getCartData();
    } catch (e) {
      console.error(e);
    }
  };

  const persmisBasicKeyHandler = () => {
    if (data.quantity === 1 && data.product.basePrice && !isBasic) {
      return `${data.product.productKey}`;
    }
    return data.product.productKey;
  };
  const persmisBasicPiceHandler = () => {
    return data.product.price.toFixed(2);
  };

  const [inputValue, setInputValue] = useState(
    isPermise ? data.quantity - 1 : data.quantity,
  );

  const debounceFn = useMemo(() => debounce(handleDebounceFn, 1000), []);

  function handleDebounceFn(key, inputValue) {
    updateItemInCart(key, inputValue);
  }

  function handleChange(key, inputValue, hasBasic) {
    const filteredObjects = servicesData?.filter((obj) => {
      return (
        obj.attributes &&
        obj.attributes.prices &&
        obj.attributes.prices.some((price) => {
          return price.productKey === key;
        })
      );
    });

    const isCountable = filteredObjects[0]?.attributes?.countable;
    setCheckIfCountableServicesItem(isCountable);
    if (!isCountable) return;

    const val = inputValue ? inputValue : 0;
    if (inputValue <= 999999999) {
      setInputValue(val);
      debounceFn(key, hasBasic ? Number(val) + 1 : Number(val));
    }
  }

  useEffect(() => {
    if (data) {
      setInputValue(data.hasBasicItem ? data.productQwt - 1 : data.quantity);
    }
  }, [data]);

  return (
    <>
      <CartDropdownListItem
        className={className}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <CartListItemLeft>
          <CartListItemTitle className="bodyM">
            {getTextFromDataByProductKey(
              persmisBasicKeyHandler(),
              translateKeys,
            )}
          </CartListItemTitle>
          <IncrementalInputCounter>
            <span
              className="decrement"
              style={{
                opacity: isBasic && data.productQwt > 1 ? 0.3 : 1,
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (isBasic && data.productQwt > 1) return;
                updateItemInCart(
                  data?.product?.productKey,
                  data.hasBasicItem ? data.productQwt - 1 : data.quantity - 1,
                );
              }}
            >
              -
            </span>
            <input
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onChange={(e) => {
                if (/^(?:[0-9]*)$/.test(e.target.value)) {
                  handleChange(
                    data?.product?.productKey,
                    e.target.value,
                    data.hasBasicItem,
                  );
                }
              }}
              readOnly={isBasic && data.productQwt > 1}
              value={isBasic && data.productQwt > 1 ? 1 : inputValue}
              min="0"
              disabled={!checkIfCountableServicesItem}
            />
            <span
              className="increment"
              style={{
                opacity:
                  data.product.type === productTypesFromCart.SUPPORTS ||
                  (isBasic && data.productQwt > 1)
                    ? 0.3
                    : 1,
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (
                  data.product.type === productTypesFromCart.SUPPORTS ||
                  (isBasic && data.productQwt > 1)
                )
                  return;

                updateItemInCart(
                  data?.product?.productKey,
                  data.hasBasicItem ? data.productQwt + 1 : data.quantity + 1,
                );
              }}
            >
              +
            </span>
          </IncrementalInputCounter>
        </CartListItemLeft>
        <CartListItemRight>
          <CartListItemPrice className="bodyM">{`₾ ${
            isBasic
              ? persmisBasicPiceHandler()
              : (
                  persmisBasicPiceHandler() *
                  (isPermise && data.quantity > 1
                    ? data.quantity - 1
                    : data.quantity)
                ).toFixed(2)
          }`}</CartListItemPrice>
          <CartListItemType className="bodyS">
            {getTextFromDataByPeriod(data?.product?.productKey, translateKeys)}
          </CartListItemType>
        </CartListItemRight>
      </CartDropdownListItem>
    </>
  );
};

export default CartListItem;
