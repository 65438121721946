import styled from 'styled-components';
import sideBg from 'assets/img/sideBg.svg';

export const CurrentProductsWrapper = styled.div`
  &.emptyTable {
    height: inherit;
    padding: 0 15px;
  }
  margin-bottom: 100px;
  .MuiTableHead-root .MuiTableCell-root {
    padding: 14.5px 19px;
  }
  .react-tabs {
    height: inherit;

    .react-tabs {
      display: flex;
      flex-direction: column;
    }
    .react-tabs__tab-list {
      border-bottom: 1px solid rgba(58, 56, 83, 0.2);
      display: flex;
      gap: 80px;
      @media (max-width: 900px) {
        -webkit-box-pack: center;
        justify-content: center;
        width: max-content;
        margin: auto;
        gap: 50px !important;
      }
      @media (max-width: 320px) {
        -webkit-box-pack: center;
        justify-content: center;
        width: max-content;
        margin: auto;
        gap: 15px !important;
      }
    }
    .react-tabs__tab {
      display: flex;
      border: none;
      color: #8a87a5;
      padding: 0px;
    }
    #react-tabs-2 {
      margin-right: 0px !important;
    }
    .react-tabs__tab:after {
      background: transparent;
    }
    .react-tabs__tab:focus {
      color: #3a3853;
      border-radius: 0;
      box-shadow: none;
      border-color: #ffdc04;
    }
    .react-tabs__tab--selected {
      border-bottom: 3px solid #ffdc04;
      color: #3a3853;
      border-radius: 0;
      box-shadow: none;
      border-color: #ffdc04;
      outline: none;
      padding-bottom: 33px;
    }
    .react-tabs__tab-panel--selected {
      width: 100%;
      height: inherit;
    }
  }
`;

export const TabListHeader = styled.div`
  &.emptyTable {
    margin-bottom: 0px;
  }
  display: flex;
  justify-content: space-between;
  padding: 0px 14% 0px 15%;
  margin-bottom: 50px;
  @media (max-width: 1685px) {
    padding: 0px 8% 0px 9%;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin-bottom: 20px;
  }
`;
export const CurrentTabsAndInputWrapper = styled.div`
  svg {
    height: 15px;
    width: 15px;
    fill: #3a3853;
    position: absolute;
    right: 0px;
    margin-right: 23px;
  }
  input {
    min-width: 360px;
    padding: 0px 48px 0px 28px;
    border-radius: 5px;
    cursor: text;
    color: #3a3853;
    :focus {
      ::-webkit-input-placeholder {
        color: white;
      }
    }
    ::placeholder {
      color: #dddbed;
    }
  }
  @media (max-width: 900px) {
    padding: 0 22px;
    margin-top: 30px;
    input {
      height: 44px;
      font-size: 14px;
      min-width: auto;
    }
  }
`;

export const CurrentProductsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1px;
  background: url(${sideBg});
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
  background-position: bottom right;
  background-size: 65%;
  color: #3a3853;
  font-weight: bold;
  padding: 41px 14% 0px 15%;
  @media (max-width: 1685px) {
    padding: 41px 8% 0px 9%;
  }
  @media (max-width: 900px) {
    padding: 71px 22px 0px 22px;
  }
`;
export const BIllingBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f0e4 0% 0% no-repeat padding-box;
  border-radius: 22px;
  padding: 14px 28px;

  span {
    color: #8a87a5;
    font-weight: 400;
    margin-right: 6px;
  }
  .url {
    color: #3a3853;
    font-weight: 400;
    text-decoration: none;
    border-right: 1px solid #e2dfcc;
    padding-right: 20px;
    text-decoration: underline;
  }
  @media (max-width: 900px) {
    min-height: 34px;
    padding: 10px 19px;
    border-radius: 4px;
    justify-content: space-between;
    .url {
      border: none;
      font-size: 12px;
    }
    span {
      font-size: 12px;
    }
  }
`;

export const MobileUrlWrapper = styled.div`
  width: 100%;
  margin-bottom: 26px;
  padding: 0 15px;
`;
export const MobileOnPersmiseTable = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
`;
