import React from 'react';
import { ButtonStyled } from './Buttons.styled';

export const Button = ({
  color,
  type,
  text,
  children,
  onClick,
  width,
  name,
  height,
  background,
  fontSize,
  hidden,
  disabled,
  boxShadow,
  padding,
  border,
  loading = false,
  buttonType,
  id,
  style,
  borderRadius,
  opacity,
  fontWeight,
  className,
}) => {
  return (
    <ButtonStyled
      opacity={opacity}
      fontWeight={fontWeight}
      borderRadius={borderRadius}
      className={className}
      id={id}
      border={border}
      type={type}
      name={name}
      width={width}
      height={height}
      padding={padding}
      boxShadow={boxShadow}
      buttonType={buttonType}
      background={background}
      fontSize={fontSize}
      hidden={hidden}
      color={color}
      disabled={disabled}
      loading={loading ? 'true' : undefined}
      style={style}
      onClick={onClick}
    >
      {children}
      {text}
    </ButtonStyled>
  );
};
