import React from 'react';

export const Arrow = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.738"
      height="7.791"
      viewBox="0 0 9.738 7.791"
      {...rest}
    >
      <g id="arrow" transform="translate(-1614.887 -69.694)">
        <g
          id="Group_40"
          data-name="Group 40"
          transform="translate(1615.137 77.131) rotate(-90)"
        >
          <g id="Group_39" data-name="Group 39" transform="translate(0 0)">
            <path
              id="Path_52"
              data-name="Path 52"
              d="M138.829,10.622l-1.885,1.885V6h-1.372v6.508l-1.885-1.885-.97.97,3.542,3.542,3.542-3.542Z"
              transform="translate(-132.716 -6)"
              fill="#242333"
              stroke="#242333"
              strokeWidth="0.5"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
