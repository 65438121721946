import React, { useContext, useEffect, useState } from 'react';

import {
  CartListProducts,
  CartListTypeTitle,
  CartPageCheckoutBlock,
  CartPageClearCart,
  CartPageHead,
  CartPageItemsBlock,
  CartPageProductsList,
  CartPageStyled,
  CartPageTitle,
  CartProductsListByType,
  SeparatorBorder,
  CartListTypePayment,
  CartListHead,
  CheckoutFormTitle,
  EmptyCartWrapper,
  EmptyCartIcon,
  EmptyCartTitle,
  EmptyCartSubTitle,
  MobileCartHead,
  MobileCartTotalOverlay,
  MobileCartOverlayTotals,
  MobileCartOverlayNextBtn,
} from 'ui/styled/cartPage.styled';
import clearCart from 'assets/img/delete.svg';

import infoIcon from 'assets/img/info.svg';
import CheckoutForm from './CartPageComponents/CheckoutForm';
import {
  cartSuccessErrorUrls,
  getCartPeriods,
} from 'constants/global.constants';
import api from 'services/api';
import ConfirmDeleteModal from './CartPageComponents/ConfirmDeleteModal';
import Modal from 'library/modal/Modal';
import emptyBig from 'assets/img/cartPageEmpty.svg';
import CartRowComponent from './CartPageComponents/CartRowComponent';
import CartPageHeadComponent from './CartPageComponents/CartPageHead';
import ToolTipComponent from 'library/tooltip/ToolTipComponent';
import ErrorAndSuccessModal from './CartPageComponents/ErrorAndSuccessModal';
import { useLocation, useNavigate } from 'react-router-dom';

import { htmlParser } from 'utils/htmlUtils';
import { getTextFromDataByKey } from 'utils/helpers';
import { StrapiDataContext } from 'Main';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'library/button/Button';
import BottomSheet from 'library/bottomSheet/BottomSheet';
import CustomHead from 'ui/components/customHead/custom-head';

const CartPage = (props) => {
  const {
    cartData,
    getCartData,
    settingsData: { data },
  } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const { cartStrapiData, settingsData, servicesList } =
    useContext(StrapiDataContext);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [cardSuccessModal, setCardSuccessModal] = useState(false);
  const [cardErrorModal, setCardErrorModal] = useState(false);
  const [isCheckoutFormOpen, setIsCheckoutFormOpen] = useState(false);
  const servicesData = servicesList?.data ? servicesList.data.data : null;

  useEffect(() => {
    if (
      location.pathname &&
      location.pathname.includes(cartSuccessErrorUrls.SUCCESS)
    ) {
      setCardSuccessModal(true);
      return;
    }
    if (
      location.pathname &&
      location.pathname.includes(cartSuccessErrorUrls.ERROR)
    ) {
      setCardErrorModal(true);
      return;
    }

    getCartData();
  }, []);

  const permiseData = cartData.filter(
    (item) =>
      item.product.period === getCartPeriods.UNLIMITED && item.quantity > 0,
  );
  const monthlyData = cartData.filter(
    (item) =>
      item.product.period === getCartPeriods.MONTH1 && item.quantity > 0,
  );
  const annualyData = cartData.filter(
    (item) =>
      item.product.period === getCartPeriods.MONTH12 && item.quantity > 0,
  );

  const clearAllCart = async () => {
    try {
      await api.cart.deleteAllItemFromCart();
      setIsDeleteModalOpen(false);
      getCartData();
    } catch (e) {
      console.error(e);
    }
  };

  const updateItemInCart = async (key, qyt) => {
    const keyToSend = key.split('_BASIC')[0];
    const filteredObjects = servicesData?.filter((obj) => {
      return (
        obj.attributes &&
        obj.attributes.prices &&
        obj.attributes.prices.some((price) => {
          return price.productKey === keyToSend;
        })
      );
    });
    const checkIfCountableServicesItem =
      filteredObjects[0]?.attributes?.countable;

    if (checkIfCountableServicesItem === false && qyt > 0) return;
    try {
      await api.cart.addItemToCart(keyToSend, qyt);
      getCartData();
    } catch (e) {
      console.error(e);
    }
  };

  const permiseChanged = permiseData.filter((item) => item.quantity > 0);

  const cartApiData = cartStrapiData.data
    ? cartStrapiData.data.data.attributes
    : null;

  function add(acc, a) {
    return acc + a.product.price * a.quantity;
  }

  const monthlyTotal = monthlyData.reduce(add, 0);
  const annualyTotal = annualyData.reduce(add, 0);
  const permiseTotal = permiseData.reduce(add, 0);
  const totalPrice = monthlyTotal + annualyTotal + permiseTotal;

  const isOfferInUrlParams = location?.pathname?.includes('isOffer');
  const additionalParam = isOfferInUrlParams ? 'isOffer' : 'default';

  return (
    <CartPageStyled>
      <CustomHead seo={cartApiData?.seo} />
      {cartApiData ? (
        <>
          {!isTabletOrMobile ? (
            <>
              <Modal
                closeIcon
                width="590px"
                height="auto"
                isOpen={cardSuccessModal}
                closeModal={() => {
                  navigate(`/cart/${additionalParam}/${'default'}`, {
                    replace: true,
                  });
                  setCardSuccessModal(false);
                }}
              >
                <ErrorAndSuccessModal
                  isError={false}
                  title={cartApiData?.card?.addCardSuccess?.title}
                  subTitle={htmlParser(
                    cartApiData?.card?.addCardSuccess?.description,
                  )}
                />
              </Modal>

              <Modal
                closeIcon
                width="590px"
                height="auto"
                isOpen={cardErrorModal}
                closeModal={() => {
                  navigate(`/cart/${additionalParam}/${'default'}`, {
                    replace: true,
                  });
                  setCardErrorModal(false);
                }}
              >
                <ErrorAndSuccessModal
                  isError
                  title={cartApiData?.card?.addCardFailed?.title}
                  subTitle={htmlParser(
                    cartApiData?.card?.addCardFailed?.description,
                  )}
                />
              </Modal>
              <Modal
                closeIcon
                width="500px"
                height="auto"
                isOpen={isDeleteModalOpen}
                className="delete-confirm"
                closeModal={() => setIsDeleteModalOpen(false)}
              >
                <ConfirmDeleteModal
                  closeModal={() => setIsDeleteModalOpen(false)}
                  confirmDelete={clearAllCart}
                  ConfirmDeleteText={htmlParser(
                    cartApiData?.clearCart?.description,
                  )}
                  DeleteTextTitle={cartApiData?.clearCart?.title}
                  ConfirmDelete={cartApiData?.clearCart?.confirm}
                  CancelDelete={cartApiData?.clearCart?.cancel}
                />
              </Modal>
            </>
          ) : (
            <>
              <Modal
                closeIcon
                width="590px"
                height="auto"
                className="mobileCheckoutForm"
                isOpen={isCheckoutFormOpen}
                closeModal={() => {
                  setIsCheckoutFormOpen(false);
                }}
              >
                <CartPageCheckoutBlock>
                  <CheckoutFormTitle>
                    {getTextFromDataByKey('title', cartApiData?.checkout)}
                  </CheckoutFormTitle>
                  <CheckoutForm
                    getCartData={getCartData}
                    data={cartApiData}
                    translatesData={data}
                    cartData={cartData}
                    totals={{
                      monthlyData,
                      annualyData,
                      permiseData,
                    }}
                    settingsData={settingsData}
                  />
                </CartPageCheckoutBlock>
              </Modal>
              <BottomSheet
                isOpen={cardSuccessModal}
                padding="40px 55px"
                closeBottomSheet={() => setCardSuccessModal(false)}
              >
                <ErrorAndSuccessModal
                  isError={false}
                  title={cartApiData?.card?.addCardSuccess?.title}
                  subTitle={htmlParser(
                    cartApiData?.card?.addCardSuccess?.description,
                  )}
                />
              </BottomSheet>
              <BottomSheet
                isOpen={cardErrorModal}
                padding="40px 55px"
                closeBottomSheet={() => setCardErrorModal(false)}
              >
                <ErrorAndSuccessModal
                  isError
                  title={cartApiData?.card?.addCardFailed?.title}
                  subTitle={htmlParser(
                    cartApiData?.card?.addCardFailed?.description,
                  )}
                />
              </BottomSheet>
              <BottomSheet
                isOpen={isDeleteModalOpen}
                padding="40px 55px"
                closeBottomSheet={() => setIsDeleteModalOpen(false)}
              >
                <ConfirmDeleteModal
                  closeModal={() => setIsDeleteModalOpen(false)}
                  confirmDelete={clearAllCart}
                  ConfirmDeleteText={htmlParser(
                    cartApiData?.clearCart?.description,
                  )}
                  DeleteTextTitle={cartApiData?.clearCart?.title}
                  ConfirmDelete={cartApiData?.clearCart?.confirm}
                  CancelDelete={cartApiData?.clearCart?.cancel}
                />
              </BottomSheet>
            </>
          )}
          {cartData.length === 0 ? (
            <EmptyCartWrapper>
              <EmptyCartIcon>
                <img src={emptyBig} alt="empty cart" />
              </EmptyCartIcon>
              <EmptyCartTitle className="bodyL">
                {cartApiData?.emptyCard?.title}
              </EmptyCartTitle>
              <EmptyCartSubTitle className="bodyL">
                {htmlParser(cartApiData?.emptyCard?.description)}
              </EmptyCartSubTitle>
            </EmptyCartWrapper>
          ) : (
            <>
              <CartPageItemsBlock>
                {isTabletOrMobile ? (
                  <MobileCartHead>
                    <div className="title">{cartApiData?.table?.title}</div>
                    <div
                      className="clear"
                      onClick={() => setIsDeleteModalOpen(true)}
                    >
                      {getTextFromDataByKey(
                        'clearCart',
                        cartApiData?.table?.translate,
                      )}
                    </div>
                  </MobileCartHead>
                ) : (
                  <CartPageHead>
                    <CartPageTitle>{cartApiData?.table?.title}</CartPageTitle>
                    <CartPageClearCart
                      onClick={() => setIsDeleteModalOpen(true)}
                      className="bodyL"
                    >
                      <img src={clearCart} alt="Clear Cart" />
                      <span>
                        {getTextFromDataByKey(
                          'clearCart',
                          cartApiData?.table?.translate,
                        )}
                      </span>
                    </CartPageClearCart>
                  </CartPageHead>
                )}
                <CartPageProductsList>
                  {permiseData.length > 0 ? (
                    <CartListHead>
                      <CartListTypeTitle>
                        {getTextFromDataByKey(
                          'premiseTitle',
                          cartApiData?.table?.translate,
                        )}
                      </CartListTypeTitle>
                    </CartListHead>
                  ) : null}

                  {permiseData.length > 0 && !isTabletOrMobile ? (
                    <CartPageHeadComponent
                      data={cartApiData?.table?.translate}
                    />
                  ) : null}

                  {permiseChanged.length > 0 ? (
                    <CartProductsListByType>
                      <CartListProducts>
                        {permiseData.map((row, index) => {
                          return (
                            <CartRowComponent
                              key={index}
                              row={row}
                              updateItemInCart={updateItemInCart}
                              translatesData={data}
                              isPermise
                              servicesData={servicesData}
                              // isFirstBasic={permiseData.length === 1}
                            />
                          );
                        })}

                        <SeparatorBorder />
                      </CartListProducts>
                    </CartProductsListByType>
                  ) : null}

                  {monthlyData.length > 0 ? (
                    <CartListHead>
                      <CartListTypeTitle>
                        {getTextFromDataByKey(
                          'monthlyTitle',
                          cartApiData?.table?.translate,
                        )}
                      </CartListTypeTitle>
                      <CartListTypePayment>
                        {/* <span>Annual Payment - ₾100</span>
                        <span className="info">
                          <ToolTipComponent
                            title="Some description about this information"
                            placement="top"
                          >
                            <img src={infoIcon} alt="info" />
                          </ToolTipComponent>
                        </span> */}
                      </CartListTypePayment>
                    </CartListHead>
                  ) : null}

                  {monthlyData.length > 0 &&
                  permiseData.length <= 0 &&
                  !isTabletOrMobile ? (
                    <CartPageHeadComponent
                      data={cartApiData?.table?.translate}
                    />
                  ) : null}

                  {monthlyData.length > 0 ? (
                    <CartProductsListByType>
                      <CartListProducts>
                        {monthlyData.map((row, index) => {
                          return (
                            <CartRowComponent
                              key={index}
                              row={row}
                              updateItemInCart={updateItemInCart}
                              translatesData={data}
                              isMonthly
                              servicesData={servicesData}
                            />
                          );
                        })}

                        <SeparatorBorder />
                      </CartListProducts>
                    </CartProductsListByType>
                  ) : null}

                  {annualyData.length > 0 ? (
                    <CartListHead>
                      <CartListTypeTitle>
                        {getTextFromDataByKey(
                          'annualyTitle',
                          cartApiData?.table?.translate,
                        )}
                      </CartListTypeTitle>
                      <CartListTypePayment>
                        {/* <span>Annual Payment - ₾100</span>
                        <span className="info">
                          <ToolTipComponent
                            title="Some description about this information"
                            placement="top"
                          >
                            <img src={infoIcon} alt="info" />
                          </ToolTipComponent>
                        </span> */}
                      </CartListTypePayment>
                    </CartListHead>
                  ) : null}

                  {annualyData.length > 0 &&
                  monthlyData.length <= 0 &&
                  permiseData.length <= 0 &&
                  !isTabletOrMobile ? (
                    <CartPageHeadComponent
                      data={cartApiData?.table?.translate}
                    />
                  ) : null}

                  {annualyData.length > 0 ? (
                    <CartProductsListByType>
                      <CartListProducts>
                        {annualyData.map((row, index) => {
                          return (
                            <CartRowComponent
                              key={index}
                              row={row}
                              updateItemInCart={updateItemInCart}
                              translatesData={data}
                              servicesData={servicesData}
                            />
                          );
                        })}

                        <SeparatorBorder />
                      </CartListProducts>
                    </CartProductsListByType>
                  ) : null}
                </CartPageProductsList>
              </CartPageItemsBlock>
              {isTabletOrMobile ? null : (
                <CartPageCheckoutBlock>
                  <CheckoutFormTitle>
                    {getTextFromDataByKey('title', cartApiData?.checkout)}
                  </CheckoutFormTitle>
                  <CheckoutForm
                    getCartData={getCartData}
                    data={cartApiData}
                    translatesData={data}
                    cartData={cartData}
                    totals={{
                      monthlyData,
                      annualyData,
                      permiseData,
                    }}
                    settingsData={settingsData}
                  />
                </CartPageCheckoutBlock>
              )}
            </>
          )}
        </>
      ) : null}
      {isTabletOrMobile && cartData.length > 0 ? (
        <MobileCartTotalOverlay>
          <MobileCartOverlayTotals>
            {/* toDo */}
            <div className="price">{`₾ ${totalPrice.toFixed(2)}`}</div>
            <div className="subTotal">
              {getTextFromDataByKey('total', cartApiData?.checkout)}
            </div>
          </MobileCartOverlayTotals>
          <MobileCartOverlayNextBtn>
            <Button text="Next" onClick={() => setIsCheckoutFormOpen(true)} />
          </MobileCartOverlayNextBtn>
        </MobileCartTotalOverlay>
      ) : null}
    </CartPageStyled>
  );
};

export default CartPage;
