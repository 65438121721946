import React from 'react';
export const CopyIcon = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.398"
    height="17.4"
    viewBox="0 0 17.398 17.4"
    style={{ cursor: 'pointer', marginLeft: '20px' }}
    {...rest}
  >
    <g id="copy" transform="translate(260.532 -306.073)">
      <path
        id="Path_65322"
        data-name="Path 65322"
        d="M-203.989,369.275q0-2.317,0-4.634a2.075,2.075,0,0,1,1.644-2.1,2.464,2.464,0,0,1,.531-.052c3.078,0,5.047,0,8.124,0a2.088,2.088,0,0,1,2.175,2.174q0,4.616,0,9.231a2.09,2.09,0,0,1-2.175,2.174h-8.125a2.09,2.09,0,0,1-2.175-2.174Q-203.992,371.583-203.989,369.275Zm6.809-5.4H-201.8a.7.7,0,0,0-.8.8q0,4.6,0,9.194a.7.7,0,0,0,.8.8h8.087a.7.7,0,0,0,.8-.8q0-4.6,0-9.194a.7.7,0,0,0-.8-.8Z"
        transform="translate(-51.82 -52.792)"
        fill="#3a3853"
        stroke="#3a3853"
        strokeWidth="0.4"
      />
      <path
        id="Path_65323"
        data-name="Path 65323"
        d="M-257.559,318.463v1.348a2.146,2.146,0,0,1-2.749-1.942c-.049-1.217-.013-2.437-.014-3.656q0-2.9,0-5.8a2.041,2.041,0,0,1,1.4-2.021,2.381,2.381,0,0,1,.741-.114c3.1-.007,5.085-.008,8.182,0a2.063,2.063,0,0,1,2.138,2.144c0,.189-1.38.008-1.38.008a.707.707,0,0,0-.768-.772h-8.163a.7.7,0,0,0-.771.768q0,4.635,0,9.27a.707.707,0,0,0,.771.769C-257.973,318.464-257.777,318.463-257.559,318.463Z"
        transform="translate(0 0)"
        fill="#3a3853"
        stroke="#3a3853"
        strokeWidth="0.4"
      />
    </g>
  </svg>
);
