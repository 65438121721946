import React from 'react';

import {
  FlexContainer,
  PartnetsLogoList,
  OurPartnersText,
  OurPartnersParagraph,
  PartnetsInfo,
  PartnersFlexContainer,
} from './ourPartners.styled';
import { ImagesRenderer } from 'utils/imagesRenderer';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

const OurPartners = (props) => {
  const { partnersData } = props;
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
  };

  return (
    <FlexContainer style={{ margin: '0' }}>
      <PartnersFlexContainer>
        {isTabletOrMobile ? (
          <>
            <PartnetsInfo className="partners">
              <OurPartnersText>{partnersData?.title}</OurPartnersText>
              <OurPartnersParagraph className="bodyL">
                {partnersData?.description}
              </OurPartnersParagraph>
            </PartnetsInfo>
            <Slider {...settings}>
              {partnersData?.repeatable?.map((item, index) => {
                return (
                  <PartnetsLogoList key={index + item.id}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ImagesRenderer
                        url={item?.media?.data?.attributes?.url}
                        alt={item?.media?.data?.attributes?.alternativeText}
                      />
                    </a>
                  </PartnetsLogoList>
                );
              })}
            </Slider>
          </>
        ) : (
          <>
            <PartnetsInfo className="partners">
              <OurPartnersText className="headline2">
                {partnersData?.title}
              </OurPartnersText>
              <OurPartnersParagraph className="bodyL">
                {partnersData?.description}
              </OurPartnersParagraph>
            </PartnetsInfo>

            {partnersData?.repeatable?.map((item, index) => {
              return item?.media?.data ? (
                <PartnetsLogoList key={index + item.id}>
                  <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <ImagesRenderer
                      url={item?.media?.data?.attributes?.url}
                      alt={item?.media?.data?.attributes?.alternativeText}
                    />
                  </a>
                </PartnetsLogoList>
              ) : null;
            })}
          </>
        )}
      </PartnersFlexContainer>
    </FlexContainer>
  );
};
export default OurPartners;
