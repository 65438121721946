import HomePage from './ui/pages/Home/Home.js';
import Services from 'ui/pages/Services/Services.js';
import FAQ from 'ui/pages/FAQ/FAQ.js';
import UserProfile from 'ui/pages/UserProfile/UserProfile';
import Hardware from 'ui/pages/Hardware/Hardware.js';
import CurrentProducts from 'ui/pages/CurrentProducts/CurrentProducts.js';
import CartPage from 'ui/pages/Cart/CartPage.js';
import TransactionHistoryTable from 'ui/pages/Transactions/TransactionHistoryTable.js';
import Terms from 'ui/pages/Terms/Terms.js';
import AdminPanel from 'ui/pages/Admin/AdminPanel.js';
import Offers from 'ui/pages/Offers/Offers.js';
import BlogPage from 'ui/pages/Blogs/BlogPage.js';

const routes = [
  {
    path: '/',
    view: HomePage,
    needAuthentication: false,
  },
  {
    path: '/solutions',
    view: HomePage,
    needAuthentication: false,
  },
  {
    path: '/home',
    view: HomePage,
    needAuthentication: false,
  },
  {
    path: '/services',
    view: Services,
    needAuthentication: false,
  },
  {
    path: '/faq',
    view: FAQ,
    needAuthentication: false,
  },
  {
    path: '/faq/:isOffer',
    view: FAQ,
    needAuthentication: false,
  },
  {
    path: '/hardware',
    view: Hardware,
    needAuthentication: false,
  },
  {
    path: '/profile',
    view: UserProfile,
    needAuthentication: true,
  },
  {
    path: '/profile/:isOffer',
    view: UserProfile,
    needAuthentication: true,
  },
  {
    path: '/currentproducts',
    view: CurrentProducts,
    needAuthentication: true,
  },
  {
    path: '/currentproducts/:isOffer/id/:id',
    view: CurrentProducts,
    needAuthentication: true,
  },
  {
    path: '/transactionHistory/:isOffer/id/:id',
    view: TransactionHistoryTable,
    needAuthentication: true,
  },
  {
    path: '/transactionHistory',
    view: TransactionHistoryTable,
    needAuthentication: true,
  },

  {
    path: '/cart',
    view: CartPage,
    needAuthentication: true,
  },

  {
    path: '/cart/:isOffer/:addCard',
    view: CartPage,
    needAuthentication: true,
  },
  {
    path: '/terms-of-use',
    view: Terms,
    needAuthentication: false,
  },
  {
    path: '/terms-of-use/:isOffer',
    view: Terms,
    needAuthentication: false,
  },
  {
    path: '/admin',
    view: AdminPanel,
    needAuthentication: true,
    isAdmin: true,
  },
  {
    path: '/offer',
    view: Offers,
    needAuthentication: false,
  },
  {
    path: '/offer/:isOffer',
    view: Offers,
    needAuthentication: false,
  },
  {
    path: '/solutions/:slug',
    view: BlogPage,
    needAuthentication: false,
  },
];

export default routes;
