import styled from 'styled-components';

export const ChangePassFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & form {
    display: flex;
    margin-bottom: 32px;
    flex-direction: column;
    @media (max-width: 900px) {
      height: 100%;
      margin-bottom: 0px;
    }
  }
  .errorBorder {
    border-color: #f55e1b;
  }
  @media (max-width: 900px) {
    height: 100%;
  }
`;
export const InputWrapper = styled.div`
  input {
    border-radius: 5px;
    height: 50px;
    border: 1px solid rgba(58, 56, 83, 0.2);
    cursor: text;
    outline: none;
    :focus {
      color: #3a3853;
      border: 1px solid rgba(58, 56, 83, 0.6);
    }
    ::placeholder {
      color: #d6d4de;
    }
    @media (max-width: 900px) {
      height: 52px;
      padding-left: 26px;
    }
  }
  @media (max-width: 900px) {
    height: 100%;
  }
`;

export const ChangePass = styled.div`
  display: flex;
  margin-bottom: 35px;
  color: #3a3853;
  font-size: 20px;
`;

export const InputWrapperStyled = styled.div`
  display: flex;
  margin-bottom: 33px;
  flex-direction: column;
  @media (max-width: 900px) {
    /* height: 100%; */
  }
`;
