import styled from 'styled-components';

export const BillingPayButton = styled.div`
  width: 100%;
  min-width: 155px;
  height: 42px;
  background-color: rgba(253, 246, 231, 1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: end !important;
  padding: 11px;
  span {
    font-weight: bold;
    color: #3a3853;
  }
  .pay {
    font-weight: 500;
    font-size: 12px;
    color: #242333;
    margin-left: 7px;
    text-decoration: underline;
    cursor: pointer;
  }
`;
