import React from 'react';

export const HideIconBlog = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="41"
    viewBox="0 0 42 41"
    {...rest}
  >
    <g id="Hide" transform="translate(-285 -3677)">
      <rect
        id="Rectangle_5977"
        data-name="Rectangle 5977"
        width="42"
        height="41"
        rx="20.5"
        transform="translate(285 3677)"
        fill="#fff8cc"
      />
      <g id="Group_53017" data-name="Group 53017" transform="translate(1 3256)">
        <line
          id="Line_18"
          data-name="Line 18"
          x2="13"
          transform="translate(298.5 441.5)"
          fill="none"
          stroke="#3a3853"
          stroke-width="2.4"
        />
      </g>
    </g>
  </svg>
);
