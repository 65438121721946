import React, { useEffect } from 'react';
import { EmailSuccessIcon } from 'assets/svgIcons/EmailSuccessIcon';
import {
  RegisterVerifyWrapper,
  VerifyDesc,
  VerifyIconBlock,
  VerifyTitle,
} from 'ui/styled/auth.styled';
import { authTypes } from '../Auth';

const EmailSuccess = (props) => {
  useEffect(() => {
    return () => {
      localStorage.setItem('authType', authTypes.login);
    };
  }, []);
  const { title, desc } = props;
  return (
    <RegisterVerifyWrapper>
      <VerifyIconBlock>
        <EmailSuccessIcon />
      </VerifyIconBlock>
      <VerifyTitle> {title} </VerifyTitle>
      <VerifyDesc>{desc}</VerifyDesc>
    </RegisterVerifyWrapper>
  );
};

export default EmailSuccess;
