import React from 'react';
import { IncrementalInputCounter } from './incrementalInput.styled';

const IncrementalInputComponent = (props) => {
  const {
    value,
    setValue,
    width,
    height,
    errorColor = false,
    setValueError,
  } = props;

  const increment = () => {
    setValueError(false);
    setValue(value + 1);
  };

  const setExactQuantity = (val) => {
    setValueError(false);
    setValue(val);
  };

  const decrement = () => {
    setValueError(false);
    if (value === 0) return;
    setValue(value - 1);
  };

  return (
    <IncrementalInputCounter width={width} height={height}>
      <span className="decrement" onClick={decrement}>
        -
      </span>
      <input
        className={errorColor ? 'errorColor' : ''}
        type="text"
        onChange={(e) => {
          if (/^(?:[0-9]*)$/.test(e.target.value)) {
            setExactQuantity(e.target.value);
          }
        }}
        value={value}
        min="0"
      />
      <span className="increment" onClick={increment}>
        +
      </span>
    </IncrementalInputCounter>
  );
};

export default IncrementalInputComponent;
