import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { ENTRY_POINT_PATH } from 'constants/global.constants';
import ProtectedRoute from 'library/router/ProtectedRoute';

const ObjectRouteMapper = (props) => {
  const {
    routes,
    setIsOpen,
    cartData,
    setCartData,
    getCartData,
    isCartOpen,
    setIsCartOpen,
    settingsData,
    userRole,
  } = props;

  const routesFiltered =
    userRole === 'USER'
      ? routes.filter((item) => item.path != '/admin')
      : routes;

  return (
    <Routes>
      {routesFiltered.map((route, index) => {
        return route.needAuthentication ? (
          <Route
            key={route.path + index}
            element={
              <ProtectedRoute openLogin={() => setIsOpen(true)}>
                <route.view
                  setIsOpen={setIsOpen}
                  cartData={cartData}
                  getCartData={getCartData}
                  isCartOpen={isCartOpen}
                  setCartData={setCartData}
                  setIsCartOpen={setIsCartOpen}
                  settingsData={settingsData}
                />
              </ProtectedRoute>
            }
            path={route.path}
          />
        ) : (
          <Route
            key={route.path + index}
            element={
              <route.view
                setIsOpen={setIsOpen}
                cartData={cartData}
                getCartData={getCartData}
                isCartOpen={isCartOpen}
                setCartData={setCartData}
                setIsCartOpen={setIsCartOpen}
                settingsData={settingsData}
              />
            }
            path={route.path}
          />
        );
      })}
      <Route path="/" element={<Navigate to={ENTRY_POINT_PATH} />} />
      <Route path="*" element={<Navigate to={ENTRY_POINT_PATH} />} />
    </Routes>
  );
};

export default ObjectRouteMapper;
