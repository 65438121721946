import React, { useState, useEffect, useContext } from 'react';
import {
  ChangePassFormWrapper,
  InputWrapper,
  InputWrapperStyled,
} from 'ui/styled/ChangePassForm.styled';
import { Button } from 'library/button/Button';
import { useForm, Controller } from 'react-hook-form';
import api from 'services/api';
import { PasswordRequirementsForAuth } from 'ui/styled/auth.styled';
import Modal from 'library/modal/Modal';
import EmailSuccess from '../../components/Auth/Register/EmailSuccess';
import { getTextFromDataByKey } from 'utils/helpers';
import FormErrorComponent from 'ui/components/common/FormError';
import {
  FormInputGroup,
  PersonalInfoBtnWrapper,
} from 'ui/styled/PersonalInfoForm.styled';
import { isValidPassword } from 'utils/validator';
import FormInput from 'ui/shared/formComponents/FormInput';
import { StrapiDataContext } from 'Main';
import { htmlParser } from 'utils/htmlUtils';
import { useMediaQuery } from 'react-responsive';
import BottomSheet from 'library/bottomSheet/BottomSheet';

const defaultValues = {
  oldPassword: '',
  newPassword: '',
  repeatPassword: '',
};

const ChangePassForm = (props) => {
  const { changePasswordData, passwordChange } = props;
  const { authData, settingsData } = useContext(StrapiDataContext);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  const registrationData = authData.data
    ? authData.data.data.attributes.registration
    : [];

  const errorTranslatesData =
    settingsData?.data?.data?.attributes?.errorTranslate;

  const {
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const newPasswordValue = watch('newPassword');

  const onSubmit = async (data) => {
    setFormError(null);
    setLoading(true);

    try {
      await api.auth.changePassword(data.oldPassword, data.newPassword);
      reset(defaultValues);
      setIsOpen(true);
    } catch (e) {
      setFormError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setFormError(null);
    };
  }, []);

  const checkForNumbers = () => {
    if (!newPasswordValue) return;
    var matches = newPasswordValue.match(/\d+/g);

    return matches != null ? true : false;
  };

  const checkForUpperCase = () => {
    if (!newPasswordValue) return;
    var matches = newPasswordValue.match(/[A-Z]/);
    let matchesLower = newPasswordValue.match(/[a-z]/);
    return matches != null && matchesLower != null ? true : false;
  };

  const checkForOnlySymbols = () => {
    if (!newPasswordValue) return;
    var symbols = new RegExp(/[^A-Z a-z0-9]/);
    return symbols.test(newPasswordValue) ? true : false;
  };

  const checkForMinCharacters = () => {
    if (!newPasswordValue) return;

    return newPasswordValue.length >= 8 ? true : false;
  };

  return (
    <ChangePassFormWrapper>
      {!isTabletOrMobile ? (
        <Modal
          closeIcon
          width="416px"
          height="auto"
          isOpen={isOpen}
          className="successPass"
          closeModal={() => {
            setIsOpen(false);
          }}
        >
          <div>
            <EmailSuccess
              title={passwordChange?.title}
              desc={htmlParser(passwordChange?.description)}
            />
          </div>
        </Modal>
      ) : (
        <BottomSheet
          isOpen={isOpen}
          closeBottomSheet={() => {
            setIsOpen(false);
          }}
        >
          <EmailSuccess
            title={passwordChange?.title}
            desc={htmlParser(passwordChange?.description)}
            isTabletOrMobile={false}
          />
        </BottomSheet>
      )}

      {formError ? (
        <FormErrorComponent
          error={formError}
          errorTranslatesData={errorTranslatesData}
        />
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapperStyled className="bodyM">
          <InputWrapper>
            <FormInputGroup changePassForm>
              <Controller
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="password"
                    label={getTextFromDataByKey(
                      'oldPassword',
                      changePasswordData,
                    )}
                    borderColor={errors.oldPassword}
                  />
                )}
                control={control}
                rules={{ required: 'Is Required' }}
                name="oldPassword"
              />
            </FormInputGroup>
            <FormInputGroup changePassForm>
              <Controller
                rules={{ required: 'Is Required', validate: isValidPassword }}
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="password"
                    label={getTextFromDataByKey(
                      'newPassword',
                      changePasswordData,
                    )}
                    borderColor={errors.newPassword}
                  />
                )}
                control={control}
                name="newPassword"
              />
            </FormInputGroup>
            <FormInputGroup changePassForm>
              <Controller
                render={({ field }) => (
                  <FormInput
                    {...field}
                    type="password"
                    label={getTextFromDataByKey(
                      'repeatPassword',
                      changePasswordData,
                    )}
                    borderColor={errors.repeatPassword}
                  />
                )}
                rules={{
                  required: 'Is Required',
                  validate: (value) =>
                    value !== newPasswordValue
                      ? 'The passwords does not match'
                      : undefined,
                }}
                control={control}
                name="repeatPassword"
              />
            </FormInputGroup>
          </InputWrapper>

          <PasswordRequirementsForAuth
            className="bodyS"
            margin="0px 0px 64px 0px"
          >
            <div>
              <span
                className={
                  checkForUpperCase()
                    ? 'isValid'
                    : newPasswordValue &&
                      newPasswordValue.length > 0 &&
                      !checkForUpperCase()
                    ? 'inValid'
                    : ''
                }
              >
                {getTextFromDataByKey('bulletUppercase', registrationData)}
              </span>
              <span
                className={
                  checkForNumbers()
                    ? 'isValid'
                    : newPasswordValue &&
                      newPasswordValue.length > 0 &&
                      !checkForNumbers()
                    ? 'inValid'
                    : ''
                }
              >
                {getTextFromDataByKey('bulletNumber', registrationData)}
              </span>
              <span
                className={
                  checkForOnlySymbols()
                    ? 'isValid'
                    : newPasswordValue &&
                      newPasswordValue.length > 0 &&
                      !checkForOnlySymbols()
                    ? 'inValid'
                    : ''
                }
              >
                {getTextFromDataByKey('bulletSymbol', registrationData)}
              </span>
              <span
                className={
                  checkForMinCharacters()
                    ? 'isValid'
                    : newPasswordValue &&
                      newPasswordValue.length > 0 &&
                      !checkForMinCharacters()
                    ? 'inValid'
                    : ''
                }
              >
                {getTextFromDataByKey('bulletMinCharacters', registrationData)}
              </span>
            </div>
          </PasswordRequirementsForAuth>
        </InputWrapperStyled>
        <PersonalInfoBtnWrapper>
          <Button
            type="submit"
            background="#ffdc04"
            width="100%"
            height="50px"
            color="#242333"
            borderRadius="5px"
            fontWeight="500"
            loading={loading}
          >
            {getTextFromDataByKey('saveBtn', changePasswordData)}
          </Button>
        </PersonalInfoBtnWrapper>
      </form>
    </ChangePassFormWrapper>
  );
};
export default ChangePassForm;
