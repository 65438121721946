import React from 'react';
import { FormErrorWrapper } from 'ui/styled/auth.styled';
import { getTextFromDataByKey } from 'utils/helpers';

const FormErrorComponent = ({ error, errorTranslatesData }) => {
  return (
    <FormErrorWrapper className="bodyM">
      {error && error.errorMessage
        ? errorTranslatesData
          ? getTextFromDataByKey(error.errorMessage, errorTranslatesData)
          : error.errorMessage
        : 'Something wrong happened...'}
    </FormErrorWrapper>
  );
};

export default FormErrorComponent;
