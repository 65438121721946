import React, { memo } from 'react';
import { InputStyled, InputWrapper, SearchIconWrapper } from './inputStyled';
import { SearchIcon } from 'assets/svgIcons/SearchIcon';

export const Input = ({
  color,
  placeholder,
  type,
  text,
  children,
  width,
  name,
  height,
  background,
  fontSize,
  hidden,
  disabled,
  boxShadow,
  padding,
  border,
  loading = false,
  buttonType,
  id,
  style,
  borderRadius,
  opacity,
  search = false,
  searchPosition,
  borderColor,
  value,
  ...others
}) => {
  let renderSearchIcon;

  if (search) {
    renderSearchIcon = (
      <SearchIconWrapper searchPosition={searchPosition}>
        <SearchIcon height={height} width={width} />
      </SearchIconWrapper>
    );
  }
  return (
    <InputWrapper>
      {renderSearchIcon}
      <InputStyled
        opacity={opacity}
        borderRadius={borderRadius}
        className={borderColor ? ' errorBorder' : ''}
        id={id}
        border={border}
        type={type}
        name={name}
        width={width}
        height={height}
        padding={padding}
        boxShadow={boxShadow}
        buttonType={buttonType}
        background={background}
        fontSize={fontSize}
        hidden={hidden}
        color={color}
        disabled={disabled}
        loading={loading ? 'true' : undefined}
        style={style}
        search={search}
        value={value}
        text={text}
        placeholder={placeholder}
        {...others}
      />
    </InputWrapper>
  );
};

export default memo(Input);
