import styled from 'styled-components';

export const EmptyTransactionsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  svg {
    margin-bottom: 25px;
  }
  @media (max-width: 900px) {
    padding-top: 10% !important;
    height: calc(100% - 150px);
    text-align: center;
    svg {
      width: 100px;
      margin-bottom: 15px;
    }
  }
  /* @media (max-height: 600px) {
    height: calc(100% - 110px);
  } */
`;
export const EmptyPageText = styled.div`
  color: #b9b7c9;
  font-weight: 400;
`;
export const NoDataText = styled.div`
  color: #f55e1b;
  font-weight: 400;
`;
