import React, { useState } from 'react';
import {
  AdditionalTitleServ,
  PlanItemTitle,
} from 'ui/styled/PlansSection.styled';
import { OldPriceOverLineComp } from 'ui/styled/servicesPlansStyled';
import { htmlParser } from 'utils/htmlUtils';

const PlanTitle = (props) => {
  const {
    visualPrice,
    planText,
    planTitle,
    planAdditionalTitle,
    isOfferPage,
    oldPrice,
  } = props;

  const visualPriceHanlder = () => {
    if (!oldPrice) return visualPrice;
    if (oldPrice && oldPrice === visualPrice) return visualPrice;
    if (oldPrice)
      return (
        <OldPriceOverLineComp>
          <span className="new">{visualPrice}</span>
          <span className="old">{oldPrice}</span>
        </OldPriceOverLineComp>
      );
  };

  return isOfferPage ? (
    <PlanItemTitle>
      <div className="dolarOffer headline3">{visualPriceHanlder()}</div>
      <div className="plan-title headline2">
        {planTitle}
        {planAdditionalTitle ? (
          <AdditionalTitleServ>{planAdditionalTitle}</AdditionalTitleServ>
        ) : null}
      </div>

      <div className="plan-p bodyL">{htmlParser(planText)}</div>
    </PlanItemTitle>
  ) : (
    <PlanItemTitle>
      <div className="plan-title headline2">
        {planTitle}
        {planAdditionalTitle ? (
          <AdditionalTitleServ>{planAdditionalTitle}</AdditionalTitleServ>
        ) : null}
      </div>
      <div className="dolar headline3">
        {visualPrice ? `${visualPrice}` : null}
      </div>
      <div className="plan-p bodyL">{htmlParser(planText)}</div>
    </PlanItemTitle>
  );
};
export default PlanTitle;
