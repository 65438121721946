import React from 'react';
import ErrorIcon from 'assets/img/Error.svg';
import ErrorMobile from 'assets/img/ErrorMobile.svg';
import {
  GlobalErrorTop,
  GlobalErrorTitle,
  GlobalErrorSubTitle,
  GlobalErrorIcon,
  GlobalErrorWrapper,
  GlobalErrorContanctInfo,
} from 'ui/styled/layout';
import { useMediaQuery } from 'react-responsive';
const GlobalError = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  return (
    <GlobalErrorWrapper>
      <GlobalErrorTop>
        {!isTabletOrMobile ? (
          <GlobalErrorIcon>
            <img src={ErrorIcon} alt="Error" />
          </GlobalErrorIcon>
        ) : (
          <GlobalErrorIcon>
            <img src={ErrorMobile} alt="Error" />
          </GlobalErrorIcon>
        )}
        <GlobalErrorTitle className="headline3">
          The site is currently unavailable
        </GlobalErrorTitle>
        <GlobalErrorSubTitle className="bodyL">
          We are working to bring you a better experience soon
        </GlobalErrorSubTitle>
      </GlobalErrorTop>
      <GlobalErrorContanctInfo>
        <a className="email bodyL" href="#">
          1csupport@1soft.ge
        </a>
        <div className="number bodyM">+995 322 23 83 89</div>
      </GlobalErrorContanctInfo>
    </GlobalErrorWrapper>
  );
};

export default GlobalError;
