import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  ParagraphImg,
  ParagraphText,
  ParagraphTitle,
  ParagraphWithPictureWrapper,
  ParagraphWrapper,
} from 'ui/styled/ParagraphWithPictureStyled';
import { htmlParser } from 'utils/htmlUtils';
import { ImagesRenderer } from 'utils/imagesRenderer';

const ParagraphWithPicture = ({ personData }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });

  return (
    <ParagraphWrapper>
      {!isTabletOrMobile ? (
        <ParagraphImg>
          <ImagesRenderer
            url={personData?.img?.data?.attributes?.url}
            alt={personData?.img?.data?.attributes?.alternativeText}
          />
        </ParagraphImg>
      ) : undefined}

      <ParagraphWithPictureWrapper>
        <ParagraphTitle className="BlogsHeadlineWithCaps">
          {personData?.organisation}
        </ParagraphTitle>
        <ParagraphText className="Blogsbody">
          {htmlParser(personData?.position)}
          <span className="name">{personData?.fullName}</span>
        </ParagraphText>
      </ParagraphWithPictureWrapper>
    </ParagraphWrapper>
  );
};

export default ParagraphWithPicture;
