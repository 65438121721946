import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  globalLoading: false,
  globalError: false,
  isAuthModalOpen: false,
  authModalViewType: null,
  lastItemInCart: null,
  isOffersOn: false,
  language: JSON.parse(localStorage.getItem('lang')) || {
    label: 'ქართული',
    secondaryValue: 'ქართ',
    value: 'ka',
  },
};

// slice
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    globalLoadingAction: (state, action) => ({
      ...initialState,
      globalLoading: action.payload,
    }),
    globalErrorAction: (state, action) => ({
      ...initialState,
      globalError: action.payload,
    }),
    languageChangeAction: (state, action) => ({
      ...initialState,
      language: action.payload,
    }),
    authModalOpenAction: (state, action) => ({
      ...initialState,
      isAuthModalOpen: action.payload,
    }),
    changeAuthModalViewTypeAction: (state, action) => ({
      ...initialState,
      authModalViewType: action.payload,
    }),
    addCartLastItem: (state, action) => ({
      ...initialState,
      lastItemInCart: action.payload,
    }),
  },
});

export const {
  globalErrorAction,
  globalLoadingAction,
  languageChangeAction,
  authModalOpenAction,
  addCartLastItem,
  changeAuthModalViewTypeAction,
} = appSlice.actions;

// selectors

export const appSelectors = {
  selectGlobalLoading: createSelector(
    (state) => state.app,
    (app) => app.globalLoading,
  ),
  selectGlobalError: createSelector(
    (state) => state.app,
    (app) => app.globalError,
  ),
  selectLanguage: createSelector(
    (state) => state.app,
    (app) => app.language,
  ),
  selectIsAuthModalOpen: createSelector(
    (state) => state.app,
    (app) => app.isAuthModalOpen,
  ),
  selectAuthModalViewType: createSelector(
    (state) => state.app,
    (app) => app.authModalViewType,
  ),
  selectSupportTrial: createSelector(
    (state) => state.app,
    (app) => app.supportTrial,
  ),
  selectLastItemFromCart: createSelector(
    (state) => state.app,
    (app) => app.lastItemInCart,
  ),
};

export const app = appSlice;

export default appSlice.reducer;
