import styled, { css, keyframes } from 'styled-components';

const buttonLoader = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const animationRule = css`
  ${buttonLoader} 0.6s linear infinite
`;

export const ButtonStyled = styled.button`
  display: flex;
  position: relative;
  justify-content: center;
  align-content: center;
  align-items: center;
  outline: 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  ${({ loading, buttonType }) =>
    loading &&
    css`
      pointer-events: none;
      color: transparent !important;
      opacity: 0.95;

      ::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        margin: -0.64285714em 0 0 -0.64285714em;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 500rem;
        border-top: ${buttonType !== 'text'
          ? `2px solid #3A3853`
          : `2px solid #3A3853`};
        border-right: 2px solid transparent;
        animation: ${animationRule};
      }
    `}

  ${({
    width,
    height,
    fontSize,
    fontWeight,
    background,
    color,
    border,
    padding,
    opacity,
  }) => {
    return {
      width,
      height,
      fontWeight,
      border,
      padding,
      opacity,
      'font-size': fontSize,
      backgroundColor: background,
      color,
    };
  }}
`;
