import { DownloadIcon } from 'assets/svgIcons/Download';
import { Button } from 'library/button/Button';
import CustomSelect from 'library/Select/Select';
import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import FormErrorComponent from 'ui/components/common/FormError';
import FormCheckBoxState from 'ui/components/formCheckbox/formCheckbox-formState';
import FormInputBasic from 'ui/shared/formComponents/FormInputBasic';
import {
  TransactionsFilterWrapper,
  ExportInputGroup,
  TransactionListRowHalf,
  ExportButton,
  ExportInputGroupDate,
  CheckBoxGroup,
  ExportCheckbox,
} from 'ui/styled/admin.styled';
import formatDate, { DateFormats } from 'utils/date';

const TransactionsExport = (props) => {
  const {
    getTransactionsExport,
    loading,
    setTransactionExportFields,
    transactionExportFields,
    onPremiseCheckbox,
    setOnPremiseCheckbox,
    errorTranslatesData,
    formError,
    isFirstPurchase,
    setIsFirstPurchase,
  } = props;
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const mappingFunction = (params) => {
    return params?.map((i) => i?.value);
  };

  const onSubmit = async (data) => {
    const params = {
      paymentId: data.paymentId || '',
      idNumber: data?.idNumber?.toString() || '',
      status: data?.status?.value,
      productType: data?.productType?.value,
      period: data?.period?.value,
      query: data?.query,
      fromDate: fromDate
        ? formatDate({
            inputDate: fromDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      toDate: toDate
        ? formatDate({
            inputDate: toDate,
            outputFormat: DateFormats.DefaultForBack,
          })
        : '',
      comments: mappingFunction(data?.comments)?.toString() || '',
    };
    getTransactionsExport(params);
    const exportData = {
      ...data,
      fromDate,
      toDate,
    };
    setTransactionExportFields(exportData);
  };

  useEffect(() => {
    if (transactionExportFields) {
      reset({
        paymentId: transactionExportFields.paymentId,
        productId: transactionExportFields.productId,
        query: transactionExportFields.query,
        status: transactionExportFields?.userStatus,
        productType: transactionExportFields?.productType,
        period: transactionExportFields?.period,
        fromDate: transactionExportFields.fromDate,
        toDate: transactionExportFields.toDate,
        comments: transactionExportFields?.comments,
      });
      setFromDate(transactionExportFields.fromDate);
      setToDate(transactionExportFields.toDate);
    }
  }, []);

  return (
    <TransactionsFilterWrapper>
      {formError ? (
        <FormErrorComponent
          error={formError}
          errorTranslatesData={errorTranslatesData}
        />
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <ExportInputGroup>
          <TransactionListRowHalf>
            <Controller
              render={({ field }) => (
                <FormInputBasic
                  {...field}
                  type="text"
                  placeholder="Payment ID"
                  borderColor={errors.paymentId}
                />
              )}
              control={control}
              name="paymentId"
            />
          </TransactionListRowHalf>
          <TransactionListRowHalf>
            <Controller
              render={({ field }) => (
                <FormInputBasic
                  {...field}
                  type="text"
                  placeholder="Product ID"
                  borderColor={errors.productId}
                />
              )}
              control={control}
              name="productId"
            />
          </TransactionListRowHalf>
        </ExportInputGroup>
        <ExportInputGroup>
          <Controller
            render={({ field }) => (
              <FormInputBasic
                {...field}
                type="text"
                placeholder="Search by keyword"
                borderColor={errors.keyword}
              />
            )}
            control={control}
            name="query"
          />
        </ExportInputGroup>
        <ExportInputGroup>
          <Controller
            render={({ field }) => (
              <CustomSelect
                {...field}
                type="text"
                search={false}
                isClearable
                placeholder="Products"
                options={[
                  {
                    label: 'Solution',
                    value: 'SOLUTION',
                  },
                  {
                    label: 'Service',
                    value: 'SERVICE',
                  },
                  {
                    label: 'Hardware',
                    value: 'HARDWARE',
                  },
                ]}
              />
            )}
            defaultValue={transactionExportFields?.productType}
            control={control}
            name="productType"
          />
        </ExportInputGroup>
        <ExportInputGroup>
          <TransactionListRowHalf>
            <Controller
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  type="text"
                  search={false}
                  isClearable
                  placeholder="Time Period"
                  options={[
                    {
                      label: 'Monthly',
                      value: 'MONTH1',
                    },
                    {
                      label: 'Annual',
                      value: 'MONTH12',
                    },
                    {
                      label: 'On Premise',
                      value: 'UNLIMITED',
                    },
                  ]}
                />
              )}
              control={control}
              defaultValue={transactionExportFields?.period}
              name="period"
            />
          </TransactionListRowHalf>
          <TransactionListRowHalf>
            <Controller
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  type="text"
                  search={false}
                  isClearable
                  placeholder="Status"
                  options={[
                    {
                      label: 'Active',
                      value: 'ACTIVE',
                    },
                    {
                      label: 'Canceling',
                      value: 'CANCELING',
                    },
                    {
                      label: 'Canceled',
                      value: 'CANCELED',
                    },
                  ]}
                />
              )}
              control={control}
              defaultValue={transactionExportFields?.status}
              name="status"
            />
          </TransactionListRowHalf>
        </ExportInputGroup>
        <ExportInputGroupDate>
          <TransactionListRowHalf>
            <ReactDatePicker
              dateFormat="d MMM yyyy"
              selected={fromDate}
              placeholderText="From Date"
              onChange={(date) => setFromDate(date)}
              isClearable
            />
          </TransactionListRowHalf>
          <TransactionListRowHalf>
            <ReactDatePicker
              dateFormat="d MMM yyyy"
              minDate={new Date()}
              selected={toDate}
              placeholderText="To Date"
              onChange={(date) => setToDate(date)}
              isClearable
            />
          </TransactionListRowHalf>
        </ExportInputGroupDate>

        <CheckBoxGroup>
          <Controller
            render={({ field }) => (
              <FormCheckBoxState
                {...field}
                checked={onPremiseCheckbox}
                label="Transaction history & billing"
                labelPosition="right"
                value="yes"
                labelOnchange
                onChange={() => {
                  setOnPremiseCheckbox(!onPremiseCheckbox);
                }}
              />
            )}
            control={control}
            name="checkBox"
          />
        </CheckBoxGroup>

        <ExportInputGroup>
          <FormCheckBoxState
            checked={isFirstPurchase}
            label="Filter First Purchases"
            labelPosition="right"
            labelOnchange
            value="yes"
            onChange={() => {
              setIsFirstPurchase(!isFirstPurchase);
            }}
          />
        </ExportInputGroup>
        <ExportCheckbox>
          <Controller
            render={({ field }) => (
              <CustomSelect
                {...field}
                type="text"
                search={false}
                isClearable
                placeholder="Select Comments"
                isMulti
                options={[
                  {
                    label: 'REGISTRATION_1C_CONNECT',
                    value: 'REGISTRATION_1C_CONNECT',
                    // isChecked: false,
                  },
                  {
                    label: 'REGISTRATION_1C_PORTAL',
                    value: 'REGISTRATION_1C_PORTAL',
                  },
                  {
                    label: 'DB_URL',
                    value: 'DB_URL',
                  },
                ]}
              />
            )}
            control={control}
            defaultValue={transactionExportFields?.comments}
            name="comments"
          />
        </ExportCheckbox>
        <ExportButton>
          <Button type="submit" className="submit" loading={loading}>
            <span style={{ paddingRight: '10px', marginTop: '3px' }}>
              <DownloadIcon />
            </span>
            Export
          </Button>
        </ExportButton>
      </form>
    </TransactionsFilterWrapper>
  );
};

export default TransactionsExport;
