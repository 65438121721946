import React from 'react';

export const SendEmailIcon = ({ ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.708"
      height="18.217"
      viewBox="0 0 21.708 18.217"
      {...rest}
    >
      <path
        id="mail"
        d="M180.714,260.041h-8.958c-1.281,0-1.5-.821-1.5-2.1,0-2.609,0-8.816,0-13.041v-.4c0-1.016,0-1.873,0-1.873h20.908s0,11.544,0,15.378c0,.968,0,2.038-1.307,2.038C187.07,260.045,183.5,260.041,180.714,260.041Zm9.147-14.673c-.08.065-.132.1-.181.147-2.813,2.431-5.626,5.011-8.44,7.441a.678.678,0,0,1-1.046,0c-2.813-2.43-5.625-5.01-8.438-7.441-.053-.046-.107-.09-.182-.152,0,.073-.006.106-.006.14,0,3.583,0,8.943,0,12.527a.664.664,0,0,0,.761.7H189.09a.673.673,0,0,0,.771-.775c0-3.529,0-8.835,0-12.364ZM189.433,244l-.015-.045H172.01L172,244l8.719,7.536Z"
        transform="translate(-169.86 -242.225)"
        fill="#242333"
        stroke="#242333"
        stroke-width="0.8"
      />
    </svg>
  );
};
