import React, { useContext, useState } from 'react';
import {
  ServicesImgSection,
  ServicesParagraphTitle,
} from 'ui/styled/ServicesStyled';

import ServicesPlans from './ServicesPlans';
import { ServicesSectionsWrapper } from 'ui/styled/servicesPlansStyled';
import {
  LeftSectionServices,
  RightSectionServices,
} from 'ui/components/common/layout';
import { ImagesRenderer } from 'utils/imagesRenderer';
import ComponentWithLoaderAndError from 'ui/shared/componentWithLoaderAndError/ComponentWithLoaderAndError';
import api from 'services/api';
import Modal from 'library/modal/Modal';
import SupportNoteModal from './SupportNoteModal';
import SupportMatchModal from './SupportMatchModal';
import { StrapiDataContext } from 'Main';
import { focusonFormsGroup, getTextFromDataByKey } from 'utils/helpers';
import { ServicesPageWrapper } from 'library/table/TableWrapperStyled';
import { useDispatch } from 'react-redux';
import { addCartLastItem } from 'store/reducers/app/app.slice';
import CustomHead from 'ui/components/customHead/custom-head';

export const servicesCategoryConstanst = {
  TRAININGS: 'trainings',
  SUPPORTS: 'supports',
};

export const supportTypes = {
  OneC_ACCOUNTING_SUPPORT: 'SUPPORT_1C_ACCOUNTING_1M',
  OneC_SHOP_SUPPORT: 'SUPPORT_1C_SHOP_1M',
  COMBO_SUPPORT: 'SUPPORT_COMBO_1M',
};

export const Services = (props) => {
  const {
    cartData,
    getCartData,
    setIsOpen: setAuthModalOpen,
    setIsCartOpen,
    isOfferPage,
  } = props;
  const { servicesList, settingsData, servicesPage } =
    useContext(StrapiDataContext);
  const distpath = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMatchModal, setIsOpenMatchModal] = useState(false);
  const [isInCart, setIsInCart] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);
  const [selectedCartItem, setSelectedCartItem] = useState({});
  const [supportCheck, setSupportCheck] = useState(false);

  const [activeSupportItem, setActiveSupportItem] = useState(null);
  const [selectedSupportItem, setSelectedSupportItem] = useState(null);

  const servicesData = servicesList?.data ? servicesList.data.data : null;

  const trainingsData = servicesData
    ? servicesData.filter(
        (item) =>
          item.attributes.category === servicesCategoryConstanst.TRAININGS,
      )
    : [];
  const supportsData = servicesData
    ? servicesData.filter(
        (item) =>
          item.attributes.category === servicesCategoryConstanst.SUPPORTS,
      )
    : [];

  const trainingsDataToShow = trainingsData
    ? trainingsData.filter(
        (item) =>
          item.attributes.offerPage === null ||
          item.attributes.offerPage === 'standard' ||
          item.attributes.offerPage === 'all',
      )
    : [];

  const supportsDataToShow = supportsData
    ? supportsData.filter(
        (item) =>
          item.attributes.offerPage === null ||
          item.attributes.offerPage === 'standard' ||
          item.attributes.offerPage === 'all',
      )
    : [];

  const traininsOffersData = trainingsData
    ? trainingsData.filter(
        (item) =>
          item.attributes.offerPage === 'offer' ||
          item.attributes.offerPage === 'all',
      )
    : [];

  const supportOffersData = supportsData
    ? supportsData.filter(
        (item) =>
          item.attributes.offerPage === 'offer' ||
          item.attributes.offerPage === 'all',
      )
    : [];

  const trainingsRenderData = isOfferPage
    ? traininsOffersData
    : trainingsDataToShow;

  const supportsRenderData = isOfferPage
    ? supportOffersData
    : supportsDataToShow;

  const updateItemInCart = async (key, isAddedToCart) => {
    getCartData();

    const promo = supportCheck ? 'Y' : undefined;
    const qyt =
      key === 'SUPPORT_1C_SHOP_PLUS_1M'
        ? isInCart.length === 0
          ? 1
          : isInCart[0].quantity + 1
        : isAddedToCart.length > 0
        ? 0
        : 1;

    try {
      await api.cart.addItemToCart(key, qyt, promo);
      getCartData();
      distpath(addCartLastItem(`${key}_BASIC`));
      setIsCartOpen(true);
      focusonFormsGroup('bottomCart');
    } catch (e) {
      console.error(e);
    }
  };
  const updateItemInCartTrainings = async (key, isAddedToCart) => {
    getCartData();

    const qyt =
      isAddedToCart && isAddedToCart.length > 0
        ? isAddedToCart[0]?.quantity + 1
        : 1;

    try {
      await api.cart.addItemToCart(key, qyt);
      getCartData();
      distpath(addCartLastItem(`${key}_BASIC`));
      setIsCartOpen(true);
      focusonFormsGroup('bottomCart');
    } catch (e) {
      console.error(e);
    }
  };

  const isInCartCheck = isInCart.length > 0 ? 0 : 1;

  return (
    <ComponentWithLoaderAndError
      data={trainingsData || supportsData}
      error={servicesList.error}
    >
      <CustomHead seo={servicesPage?.data?.data?.attributes?.seo} />
      <Modal
        closeIcon
        width="600px"
        height="auto"
        isOpen={isOpen}
        spacing="70px 44px 40px 44px"
        className="hardware-modal"
        canCloseOnOutsideClick={false}
        closeModal={() => {
          setIsOpen(false);
          setSupportCheck(undefined);
        }}
      >
        <SupportNoteModal
          closeModal={() => {
            setIsOpen(false);
            setSupportCheck(undefined);
          }}
          cartData={cartData}
          selectedCartItem={selectedCartItem}
          updateItemInCart={() => updateItemInCart(selectedKey, isInCartCheck)}
          selectedKey={selectedKey}
          isInCartCheck={isInCartCheck}
          activeSupportItem={activeSupportItem}
          openMatchModal={() => setIsOpenMatchModal(true)}
          supportCheck={supportCheck}
          setSupportCheck={setSupportCheck}
        />
      </Modal>

      <Modal
        closeIcon
        width="667px"
        height="auto"
        isOpen={isOpenMatchModal}
        spacing="98px 76px"
        className="hardware-modal"
        canCloseOnOutsideClick={false}
        closeModal={() => {
          setIsOpenMatchModal(false);
        }}
      >
        <SupportMatchModal
          closeModal={() => {
            setIsOpenMatchModal(false);
          }}
          activeSupportItem={selectedSupportItem}
          updateItemInCart={() => updateItemInCart(selectedKey, isInCartCheck)}
        />
      </Modal>
      <ServicesPageWrapper isOfferPage={isOfferPage}>
        {!trainingsRenderData ||
        (trainingsRenderData && trainingsRenderData.length === 0) ||
        isOfferPage ? null : (
          <ServicesParagraphTitle>
            {getTextFromDataByKey(
              'trainings',
              settingsData?.data?.data?.attributes?.globalTranslate,
            )}
            {<span></span>}
          </ServicesParagraphTitle>
        )}

        {trainingsRenderData.map((item, index) => {
          return (
            <ServicesSectionsWrapper key={item.id + index}>
              <LeftSectionServices
                isEven={(index + 1) % 2 == 0}
                isOfferPage={isOfferPage}
              >
                <ServicesImgSection isEven={(index + 1) % 2 == 0}>
                  <ImagesRenderer
                    url={item?.attributes?.img?.data?.attributes?.url}
                    alt={
                      item?.attributes?.img?.data?.attributes?.alternativeText
                    }
                  />
                </ServicesImgSection>
              </LeftSectionServices>

              <RightSectionServices isOfferPage={isOfferPage}>
                <ServicesPlans
                  isOfferPage={isOfferPage}
                  servicesPlanTitle={item?.attributes?.name}
                  visualPrice={item?.attributes?.visualPrice}
                  oldPrice={item?.attributes?.oldPrice}
                  servicesPlanParagraph={item?.attributes?.description}
                  buttonText={item?.attributes?.button}
                  planItems={item?.attributes?.bullet}
                  productKey={item?.attributes?.prices}
                  salePercent={item?.attributes?.sale}
                  cartData={cartData}
                  getCartData={getCartData}
                  updateItemInCart={updateItemInCartTrainings}
                  setIsInCart={setIsInCart}
                  openModal={() => setIsOpen(true)}
                  setAuthModalOpen={setAuthModalOpen}
                  setActiveSupportItem={setActiveSupportItem}
                  setSelectedSupportItem={setSelectedSupportItem}
                  buttonTexts={item?.attributes?.modal}
                />
              </RightSectionServices>
            </ServicesSectionsWrapper>
          );
        })}
        {!supportsRenderData ||
        (supportsRenderData && supportsRenderData.length === 0) ||
        isOfferPage ? null : (
          <ServicesParagraphTitle
            secondParagraph
            style={{ background: 'none' }}
          >
            {getTextFromDataByKey(
              'supports',
              settingsData?.data?.data?.attributes?.globalTranslate,
            )}
            {<span></span>}
          </ServicesParagraphTitle>
        )}

        {supportsRenderData.map((item, index) => {
          return (
            <ServicesSectionsWrapper key={item.id + index}>
              <LeftSectionServices
                isEven={(index + 1) % 2 == 0}
                isOfferPage={isOfferPage}
              >
                <ServicesImgSection isEven={(index + 1) % 2 == 0}>
                  <ImagesRenderer
                    url={item?.attributes?.img?.data?.attributes?.url}
                    alt={
                      item?.attributes?.img?.data?.attributes?.alternativeText
                    }
                  />
                </ServicesImgSection>
              </LeftSectionServices>

              <RightSectionServices>
                <ServicesPlans
                  isOfferPage={isOfferPage}
                  servicesPlanTitle={item?.attributes.name}
                  visualPrice={item?.attributes?.visualPrice}
                  oldPrice={item?.attributes?.oldPrice}
                  servicesPlanParagraph={item?.attributes.description}
                  buttonText={item?.attributes?.button}
                  planItems={item?.attributes?.bullet}
                  productKey={item?.attributes?.prices}
                  productItem={item?.attributes}
                  activeSupportItem={item?.attributes}
                  cartData={cartData}
                  getCartData={getCartData}
                  updateItemInCart={updateItemInCart}
                  openModal={() => setIsOpen(true)}
                  setIsInCart={setIsInCart}
                  setSelectedKey={setSelectedKey}
                  setSelectedCartItem={setSelectedCartItem}
                  isSupport
                  type={item?.attributes?.category}
                  setAuthModalOpen={setAuthModalOpen}
                  setActiveSupportItem={setActiveSupportItem}
                  setSelectedSupportItem={setSelectedSupportItem}
                  buttonTexts={item?.attributes?.modal}
                />
              </RightSectionServices>
            </ServicesSectionsWrapper>
          );
        })}
      </ServicesPageWrapper>
    </ComponentWithLoaderAndError>
  );
};

export default Services;
