import React from 'react';

export const DownloadIcon = ({ ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 21.036 19.136"
    {...rest}
  >
    <g id="demo" transform="translate(-1164.75 -1349.75)">
      <g
        id="Group_38"
        data-name="Group 38"
        transform="translate(1165 1359.178)"
      >
        <g id="Group_37" data-name="Group 37">
          <path
            id="Path_51"
            data-name="Path 51"
            d="M22.635,314v5.607a.952.952,0,0,1-.951.951H6.852a.952.952,0,0,1-.951-.951V314H4v5.607c0,1.572.33,2.852,1.9,2.852H22.634c1.572,0,1.9-1.279,1.9-2.852V314Z"
            transform="translate(-4 -313)"
            fill="#242333"
            stroke="#242333"
            strokeWidth="0.5"
          />
        </g>
      </g>
      <g
        id="Group_40"
        data-name="Group 40"
        transform="translate(1169.361 1349)"
      >
        <g id="Group_39" data-name="Group 39" transform="translate(0 -1)">
          <path
            id="Path_52"
            data-name="Path 52"
            d="M141.186,12.4l-2.612,2.612V6h-1.9v9.017L134.06,12.4l-1.344,1.344,4.907,4.907,4.907-4.907Z"
            transform="translate(-131.716 -4)"
            fill="#242333"
            stroke="#242333"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </g>
  </svg>
);
