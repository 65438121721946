import React, { Fragment, useEffect, useState } from 'react';

import cacheManager from 'utils/cacheManager.js';
import { getDataUrlTypes, TOKEN_NAME } from 'constants/global.constants.js';
import { getCurrentUser } from 'store/reducers/user/user.actions.js';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'routes.js';
import RouterSwitch from 'library/router';
import Header from 'ui/shared/header/Header.js';
import Footer from 'ui/shared/footer/Footer.js';
import { MainContainer } from 'ui/components/common/layout.js';
import { languageChangeAction } from 'store/reducers/app/app.slice';
import { GlobalStyle } from 'ui/styled/global';
import api from 'services/api';
import { userSelectors } from 'store/reducers/user/user.slice';
import useFetchData from 'hooks/useFetchData';
import { setAuthHeader } from 'services/axios';
import 'react-tabs/style/react-tabs.css';

import 'react-datepicker/dist/react-datepicker.css';
import _ from 'underscore';
import GlobalLoadingComponent from 'ui/components/common/GlobalLoading';

import { useLocation } from 'react-router-dom';
import { focusonFormsGroup } from 'utils/helpers';
import { useMediaQuery } from 'react-responsive';
import MessengerCustomerChat from 'react-messenger-customer-chat';

export const StrapiDataContext = React.createContext(null);

const Main = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const contextData = useSelector((state) => state.app.language, _.isEqual);

  const activeUser = useSelector(userSelectors.userInfo);
  const tokenCache = cacheManager(TOKEN_NAME);
  const [isOpen, setIsOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const token = tokenCache.get();
  const [cartData, setCartData] = useState([]);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 900 });
  const langCache = cacheManager('lang');
  const langFromCache = langCache.get();

  const {
    data: settingsData,
    loading: settingsLoading,
    error: settingsError,
  } = useFetchData({
    url: getDataUrlTypes.settings,
    lang: contextData,
  });

  const {
    data: homeData,
    error: homeDataError,
    loading: homeDataLoading,
  } = useFetchData({
    url: getDataUrlTypes.homeIntro,
    lang: contextData,
  });
  const {
    data: homeProducts,
    error: homeProductsError,
    loading: homeProductsLoading,
  } = useFetchData({
    url: getDataUrlTypes.homeProducts,
    lang: contextData,
  });

  const { data: offerData, error: offerDataError } = useFetchData({
    url: getDataUrlTypes.offers,
    lang: contextData,
  });
  const { data: offerProducts, error: offerDataProductsError } = useFetchData({
    url: getDataUrlTypes.offerProducts,
    lang: contextData,
  });
  const { data: servicesList, error: servicesListError } = useFetchData({
    url: getDataUrlTypes.serviceList,
    lang: contextData,
  });

  const { data: servicesPage, error: servicesPageError } = useFetchData({
    url: getDataUrlTypes.servicePage,
    lang: contextData,
  });

  const { data: hardwareData, error: hardwareDataError } = useFetchData({
    url: getDataUrlTypes.hardwareList,
    lang: contextData,
  });

  const { data: hardwarePageData, error: hardwarePageDataError } = useFetchData(
    {
      url: getDataUrlTypes.hardwarePage,
      lang: contextData,
    },
  );

  const { data: cartStrapiData, error: cartStrapiDataError } = useFetchData({
    url: getDataUrlTypes.cart,
    lang: contextData,
  });

  const { data: userProfileData, error: userProfileDataError } = useFetchData({
    url: getDataUrlTypes.userProfile,
    lang: contextData,
  });

  const { data: authData, error: authDataError } = useFetchData({
    url: getDataUrlTypes.authInfo,
    lang: contextData,
  });

  const { data: currentProductsData, error: currentProductsDataError } =
    useFetchData({
      url: getDataUrlTypes.currentProducts,
      lang: contextData,
    });

  const { data: faqData, error: faqDataError } = useFetchData({
    url: getDataUrlTypes.faq,
    lang: contextData,
  });

  const { data: termsData, error: termsDataError } = useFetchData({
    url: getDataUrlTypes.terms,
    lang: contextData,
  });

  const { data: transactionHistoryData, error: transactionHistoryDataError } =
    useFetchData({
      url: getDataUrlTypes.transactionHistory,
      lang: contextData,
    });

  const fbSettings = settingsData?.data?.attributes?.facebook;

  const getCartData = async (tokenData) => {
    if (!token && !tokenData) return;
    if (tokenData) {
      tokenCache.set(tokenData);
      setAuthHeader(tokenData);
    }

    try {
      const data = await api.cart.getCartList();
      let dataCopy = data.data;
      let dataRes = dataCopy;

      dataCopy.forEach((item, index, theArray) => {
        if (item.firstPurchase && item.product.basePrice) {
          theArray[index].quantity = theArray[index].quantity - 1;
          theArray[index].productQwt = theArray[index].quantity + 1;
          theArray[index].hasBasicItem = true;
          dataRes.push({
            ...item,
            quantity: 1,
            hasBasicItem: false,
            productQwt: theArray[index].quantity + 1,
            product: {
              ...item.product,
              productKey: item.product.productKey + '_BASIC',
              price: item.product.basePrice,
              basePrice: null,
            },
          });
        }
      });

      setCartData(dataRes);
    } catch (e) {
      console.error(e);
    }
  };

  const initApp = async () => {
    try {
      if (token && token !== '') {
        await dispatch(getCurrentUser());
      }
    } catch (e) {
      tokenCache.unset();
    }
  };

  useEffect(
    function () {
      if (!activeUser) {
        setCartData([]);
      }
    },
    [activeUser],
  );

  useEffect(() => {
    initApp();
    getCartData();

    if (langFromCache) {
      dispatch(languageChangeAction(JSON.parse(langFromCache)));
    }
    const splittedPath = location.pathname.split('/');
    if (!token && splittedPath && splittedPath[2]) {
      localStorage.setItem('transactionId', splittedPath[2]);
    }
  }, []);

  useEffect(() => {
    focusonFormsGroup('MainContainer');
  }, [location.pathname]);

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(fbSettings?.pixelID); // facebookPixelId
        ReactPixel.pageView();
      });
  }, [location.pathname]);

  if (settingsLoading || homeDataLoading || homeProductsLoading) {
    return (
      <>
        <GlobalStyle lang={contextData.value} />
        <GlobalLoadingComponent />
      </>
    );
  }

  const pathnames = [''];
  const pathnamesMobile = ['/profile', '/cart'];

  return (
    <Fragment>
      <MainContainer id="MainContainer">
        <div id="fb-customer-chat" className="fb-customerchat">
          <MessengerCustomerChat
            pageId={fbSettings?.page_id}
            appId={fbSettings?.appID}
            themeColor="#ffdc04"
          />
        </div>
        <StrapiDataContext.Provider
          value={{
            settingsData: {
              data: settingsData,
              error: settingsError,
            },
            homeData: {
              data: homeData,
              error: homeDataError,
            },
            homeProducts: {
              data: homeProducts,
              error: homeProductsError,
            },
            servicesList: {
              data: servicesList,
              error: servicesListError,
            },
            servicesPage: {
              data: servicesPage,
              error: servicesPageError,
            },
            hardwareData: {
              data: hardwareData,
              error: hardwareDataError,
            },
            hardwarePage: {
              data: hardwarePageData,
              error: hardwarePageDataError,
            },
            cartStrapiData: {
              data: cartStrapiData,
              error: cartStrapiDataError,
            },
            userProfileData: {
              data: userProfileData,
              error: userProfileDataError,
            },
            authData: {
              data: authData,
              error: authDataError,
            },
            transactionHistoryData: {
              data: transactionHistoryData,
              error: transactionHistoryDataError,
            },
            currentProductsData: {
              data: currentProductsData,
              error: currentProductsDataError,
            },
            faqData: {
              data: faqData,
              error: faqDataError,
            },
            termsData: {
              data: termsData,
              error: termsDataError,
            },
            offerData: {
              data: offerData,
              error: offerDataError,
            },
            offerProducts: {
              data: offerProducts,
              error: offerDataProductsError,
            },
            activeLanguage: contextData,
          }}
        >
          <Header
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            cartData={cartData}
            getCartData={getCartData}
            isCartOpen={isCartOpen}
            setIsCartOpen={setIsCartOpen}
            settingsData={{
              data: settingsData,
              loading: settingsLoading,
              error: settingsError,
            }}
          />
          <GlobalStyle lang={contextData.value} />
          <>
            <div
              className="main"
              style={{
                height:
                  pathnamesMobile.includes(location.pathname) &&
                  isTabletOrMobile
                    ? '100%'
                    : pathnames.includes(location.pathname) && !isTabletOrMobile
                    ? '100%'
                    : undefined,
              }}
            >
              <RouterSwitch
                routes={routes}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                cartData={cartData}
                getCartData={getCartData}
                isCartOpen={isCartOpen}
                setIsCartOpen={setIsCartOpen}
                setCartData={setCartData}
                userRole={activeUser?.role}
                settingsData={{
                  data: settingsData,
                  loading: settingsLoading,
                  error: settingsError,
                }}
              />
            </div>
          </>
          <Footer
            settingsData={{
              data: settingsData,
              loading: settingsLoading,
              error: settingsError,
            }}
          />
        </StrapiDataContext.Provider>
      </MainContainer>
    </Fragment>
  );
};

export default Main;
