import styled from 'styled-components';
import sideBg from 'assets/img/sideBg.svg';
export const HardwareParagraphTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3a3853;
  font-weight: bold;
  padding: 0px 0px 0px 15%;
  margin-bottom: 11px;
  background: url(${sideBg});
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
  background-position: bottom right;
  background-size: 65%;
  @media (max-width: 1685px) {
    padding: 0 0 0 8%;
  }
  @media (max-width: 900px) {
    background: none;
    padding: 28px 0px 21px 22px;
    height: auto;
    margin-bottom: 0px;
  }
`;

export const HardwareImgSection = styled.div`
  position: relative;
  img {
    max-height: 311px;
    @media (max-width: 900px) {
      min-height: 239px;
      max-height: 239px;
      border-bottom-right-radius: 140px;
      transform: ${(props) => (props.isEven ? 'scaleX(-1)' : undefined)};
      padding: ${(props) =>
        props.isEven ? '0px 0px 0px 6%' : '0px 6% 0px 0px'};
    }
  }
`;
export const HardwareSectionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 46px;
  @media (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 44px;
  }
`;

export const HarwdarePlanWrapper = styled.div`
  flex-direction: column;
  @media (max-width: 900px) {
    button {
      height: 52px;
      font-size: 14px;
      width: 100%;
    }
  }
`;
export const HarwdarePlanTitle = styled.div`
  margin-bottom: 16px;
  font-weight: 500;
  color: #242333;
`;

export const HarwdarePlanParagraph = styled.div`
  font-weight: 400;
  color: #8a87a5;
  margin-bottom: 77px;
  line-height: 26px;
  @media (max-width: 900px) {
    line-height: 21px;
    margin-bottom: 40px;
  }
`;

export const HardwareNoteModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  @media (max-width: 900px) {
    padding: 72px 16px 46px 35px;
  }
`;
export const InfoDescriptionBlock = styled.div`
  color: #3a3853;
  display: flex;
  flex-direction: column;
  margin-bottom: 47px;
  line-height: 28px;
  @media (max-width: 900px) {
    margin-bottom: auto;
    align-items: flex-start;
  }
  .top {
    margin-bottom: 20px;
  }
  .bottom {
    span {
      background-color: rgba(201, 190, 146, 0.18);
      border-radius: 5px;
      padding: 4px;
      margin: 0 4px;
      color: rgba(58, 56, 83, 1);
      font-weight: bold;
    }
    p {
      margin: 0px;
      line-height: 23px;
    }
    @media (max-width: 900px) {
      margin-bottom: 55px;
    }
  }
  .agreement {
    margin-top: 20px;
    @media (max-width: 900px) {
      margin-top: 0px;
    }
  }
  .CheckboxLabelStyled {
    top: -3px;
    position: relative;
    color: rgba(58, 56, 83, 1);
    @media (max-width: 900px) {
      line-height: 18px;
    }
  }
`;

export const InfoNoteBlock = styled.div`
  margin-bottom: 20px;
  color: #242333;
  font-weight: bold;
  text-align: left;
  @media (max-width: 900px) {
    margin-bottom: 30px;
    align-items: flex-start;
    margin-top: 21px;
  }
`;

export const NoteAddToCartButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .cancel {
    display: table;
    margin: 20px auto 0 auto;
  }
  @media (max-width: 900px) {
    button {
      height: 52px;
      font-size: 14px;
      width: 100%;
      padding: 0px 30px;
    }
  }
`;
