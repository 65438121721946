import styled from 'styled-components';
import sideBg from 'assets/img/sideBg.svg';

export const TransactionsHistoryWrapper = styled.div`
  height: ${(props) => (props.noData ? 'inherit' : '')};
  margin-bottom: 100px;
`;
export const TransactionHistoryMobileHead = styled.div`
  margin-bottom: 22px;
  padding: 28px 22px 0 22px;
  font-weight: bold;
`;
export const TransactionTableTitle = styled.div`
  color: #3a3853;
  font-weight: bold;
  margin: 0 0 0px 15%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background: url(${sideBg});
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
  background-position: bottom right;
  background-size: 65%;
  position: relative;
  top: 34px;
  padding-top: 34px;
  @media (max-width: 1540px) {
    margin: 0 0 0px 8%;
    top: 0;
  }
  @media (max-width: 1290px) {
    margin: 0 0 0px 6%;
    top: 0;
  }
  @media (max-width: 900px) {
    padding: 0 22px 0 22px;
  }
`;

export const TransactionTableColoredHeader = styled.div`
  background-color: rgba(235, 234, 244, 0.4);
  border-radius: 6px;
  height: 53px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #242333;
  cursor: pointer;

  .iconAndID {
    display: flex;
    align-items: center;
    padding-left: 45px;
  }
  .first {
    color: #3a3853;
    font-weight: 500;
  }
  .second {
    color: #8a87a5;
    font-weight: 400;
  }
  .third {
    color: #242333;
    font-weight: bold;
  }
  svg {
    margin-right: 15px;
  }
`;

export const TableHeadWrapper = styled.div`
  @media (max-width: 900px) {
    padding: 0 22px;
  }
`;
export const TableWrapperTransactions = styled.div`
  padding: 0 45px;
  .MuiPaper-root {
    box-shadow: none;
  }
  .MuiTableRow-root {
    .MuiTableCell-root {
      :first-child {
        padding-left: 0px;
      }
      :last-child {
        padding-right: 0px;
      }
    }
  }

  .MuiTableCell-head {
    color: #bab8c7;
    font-weight: 400;
    border-bottom: none !important;
    padding: 0px 16px;
    font-family: ${({ lang }) =>
      lang === 'ka'
        ? 'Helvetica Neue LT GEO !important'
        : `'Ubuntu', sans-serif`};
  }
  .MuiTableCell-root {
    text-align: unset;
    border-bottom: 1px solid #f0eff7;
    font-family: ${({ lang }) =>
      lang === 'ka'
        ? 'Helvetica Neue LT GEO !important'
        : `'Ubuntu', sans-serif`};
  }
  .MuiTableCell-body {
    color: #3a3853;
    font-weight: 500;
  }
`;
export const SeperatorLine = styled.span`
  margin: 0 15px;
  height: 25px;
  border-left: 1px solid #dddbed;
`;
