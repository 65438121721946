import styled from 'styled-components';

export const BlogFlexContainer = styled.div`
  @media (max-width: 1540px) {
    padding: 0 8%;
  }
  @media (max-width: 1290px) {
    padding: 0 5%;
  }
  @media (max-width: 900px) {
    padding: 0 22px !important;
    max-height: 200px;
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
    .slick-initialized .slick-slide {
      border: 1px solid #dddbed !important;
      border-radius: 12px !important;
      min-height: 173px !important;
      padding: 0px 20px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      margin-right: 16px !important;
      .icon {
        margin-bottom: 20px !important;
      }
      .name {
        color: #242333;
        margin-bottom: 9px;
        font-weight: 500;
      }
      .text {
        color: #8a87a5;
      }
    }
  }
`;
export const ContainerWrapper = styled.div`
  padding: 0px 14%;
  margin: 179px 0 160px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .icon {
    margin-bottom: 20px;
  }
  .text {
    color: #8a87a5;
    line-height: 26px;
  }
  @media (max-width: 1685px) {
    padding: 0px 8%;
  }
  @media (max-width: 1540px) {
    padding: 0px 0%;
  }
`;
export const BlogMiddleContTitle = styled.div`
  color: #242333;
  margin-bottom: 9px;
  font-weight: 400;
`;

export const SingleInfoContainer = styled.div`
  flex: 0 0 20%;
  margin-bottom: 45px;
`;

export const BlogInfoFlexContainer = styled.div`
  @media (max-width: 900px) {
    justify-content: flex-start;
    align-items: unset;
    flex-direction: column;
    padding-bottom: 0px;
    margin-bottom: 44px;
  }
`;
export const BlogInfoList = styled.div`
  display: flex;
  flex-basis: 12%;
  @media (max-width: 900px) {
    flex-basis: 25%;
    display: flex;
    align-items: center;
    min-height: 40px;
    border: 1px solid #dddbed;
    border-radius: 12px;
  }
`;
