/* eslint-disable max-len */
import React from 'react';

export const CheckboxChecked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g
        id="Group_51934"
        data-name="Group 51934"
        transform="translate(-753 -685)"
      >
        <g id="Check" transform="translate(-41 213)">
          <circle
            id="Ellipse_25"
            data-name="Ellipse 25"
            cx="18"
            cy="18"
            r="18"
            transform="translate(794 472)"
            fill="#ffdc04"
          />
        </g>
        <path
          id="Path_65162"
          data-name="Path 65162"
          d="M0,0,5.379,5.029,1.954,8.656"
          transform="translate(775.58 701.332) rotate(90)"
          fill="none"
          stroke="#242333"
          stroke-linecap="round"
          strokeWidth="3.5"
        />
      </g>
    </svg>
  );
};

export const CheckboxEmpty = ({ stroke = ' #dddbed' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
    >
      <g id="Check" transform="translate(-794 -472)">
        <g
          id="Ellipse_25"
          data-name="Ellipse 25"
          transform="translate(794 472)"
          fill="none"
          stroke={stroke}
          strokeWidth="1.3"
        >
          <circle cx="18" cy="18" r="18" stroke="none" />
          <circle cx="18" cy="18" r="17.35" fill="none" />
        </g>
      </g>
    </svg>
  );
};

export default CheckboxEmpty;
