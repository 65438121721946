import { LeftIconArrow } from 'assets/svgIcons/LeftIconArrow';
import { ArrowRightTableIcon } from 'assets/svgIcons/ArrowRightTableIcon';
import React, { memo } from 'react';
import { PaginationButtonsConatainerStyled } from './paginationStyled';

const Pagination = ({
  children,
  count,
  limit,
  offset,
  onClick,
  pageNeighbours = 1,
  className,
  currentPage,
  isPageLength,
}) => {
  const pageCount = Math.ceil(count / limit);

  const activePageIndex = offset / limit;

  return (
    <div>
      {children}
      {count > limit ? (
        <PaginationButtonsConatainerStyled
          className={className}
          currentPage={currentPage}
        >
          <div
            className={`pagination ${activePageIndex <= 0 && 'disabled'}`}
            onClick={() => activePageIndex > 0 && onClick(activePageIndex - 1)}
          >
            <LeftIconArrow />
          </div>
          {pageCount >= 3 && activePageIndex >= 2 ? (
            <div
              key={0}
              className={`pagination ${0 === activePageIndex && 'active'}`}
              onClick={() => onClick(0)}
            >
              {0 + 1}
            </div>
          ) : null}
          {pageCount &&
            Array(pageCount)
              .fill(undefined)
              .map((o, i) => {
                return [
                  o,
                  pageCount - 1,
                  ...new Array(pageNeighbours * 2 + 1)
                    .fill(undefined)
                    .map((e, j) => activePageIndex + pageNeighbours - j),
                ].includes(i) ? (
                  <div
                    key={i}
                    className={`pagination ${
                      i === activePageIndex && 'active'
                    }`}
                    onClick={() => onClick(i)}
                  >
                    {i + 1}
                  </div>
                ) : (
                  [
                    activePageIndex - pageNeighbours - 1,
                    activePageIndex + pageNeighbours + 1,
                  ].includes(i) && <div className="pagination">...</div>
                );
              })}
          <div
            className={`pagination ${
              activePageIndex >= pageCount - 1 && 'disabled'
            }`}
            onClick={() =>
              activePageIndex < pageCount - 1 && onClick(activePageIndex + 1)
            }
          >
            <div>
              <ArrowRightTableIcon
                rotate="left"
                color={activePageIndex < pageCount - 1 ? '#0ecbfd' : '#829cb4'}
              />
            </div>
          </div>
        </PaginationButtonsConatainerStyled>
      ) : (
        <div style={{ visibility: 'hidden' }}>empty</div>
      )}
    </div>
  );
};

export default memo(Pagination);
